const basePath = '/staff';

// ログイン
export const loginPath = () => `${basePath}/login`;
// Email 確認 (未実装)
export const confirmEmailPath = (token: string) => `${basePath}/confirmEmail/${token}`;
// パスワードリセット
export const resetPasswordPath = () => `${basePath}/resetPassword`;
// パスワードリセット確認
export const confirmResetPasswordPath = (token: string) =>
  `${basePath}/confirmPasswordReset/${token}`;
// トップページ (カレンダー)
export const rootPath = () => `${basePath}`;
// 管理ユーザー一覧 (未実装)
export const staffsPath = () => `${basePath}/staffs`;
// 管理ユーザー新規作成 (未実装)
export const staffNewPath = () => `${basePath}/staffs/new`;
// 管理ユーザー詳細 (未実装)
export const staffDetailPath = (id: string) => `${basePath}/staffs/${id}`;
// リソース一覧
export const resourcesPath = () => `${basePath}/resources`;
// リソース新規作成
export const resourceNewPath = () => `${basePath}/resources/new`;
// リソース詳細
export const resourceDetailPath = (id: string) => `${basePath}/resources/${id}`;
// リソース編集
export const resourceEditPath = (id: string) => `${basePath}/resources/${id}/edit`;
// リソース対応時間
export const resourceWorkingHoursPath = (id: string) => `${basePath}/resources/${id}/workingHours`;
// リソース休日
export const resourceHolidaysPath = (id: string) => `${basePath}/resources/${id}/holidays`;
// スケジュール管理
export const schedulesPath = () => `${basePath}/schedules`;
// ユーザー一覧
export const usersPath = () => `${basePath}/clinicUsers`;
// ユーザー詳細
export const userDetailPath = (id: string) => `${basePath}/clinicUsers/${id}`;
// ペット詳細
export const petDetailPath = (id: string) => `${basePath}/clinicPets/${id}`;
// 予約一覧
export const appointmentsPath = () => `${basePath}/appointments`;
// 予約新規作成
export const appointmentNewPath = () => `${basePath}/appointments/new`;
// 予約詳細 (編修)
export const appointmentDetailPath = (id: string) => `${basePath}/appointments/${id}`;
// スケジュール管理
export const settingsPath = () => `${basePath}/settings`;
// ログアウト
export const logoutPath = () => `${basePath}/logout`;
