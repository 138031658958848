import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useCallback, useState } from 'react';
import type { Appointment } from 'graphql/generated/user/graphql';

interface Props {
  defaltValues?: {
    isFirst?: Appointment['isFirst'];
    symptom?: Appointment['symptom'];
    onset?: Appointment['onset'];
    history?: Appointment['history'];
    note?: Appointment['note'];
  };
  isMedicalConsultation: boolean;
  onBack: () => void;
  onSubmit: (
    data: {
      symptom: Appointment['symptom'];
      onset: Appointment['onset'];
      history: Appointment['history'];
      note: Appointment['note'];
    },
    isFirst: Appointment['isFirst']
  ) => void;
}

export const InterviewForm = (props: Props) => {
  const [isFirst, setIsFirst] = useState<boolean>(props.defaltValues?.isFirst ?? false);
  //フォーム入力内容を保存する
  const [inputInterview, setInputInterview] = useState<{
    symptom: Appointment['symptom'];
    onset: Appointment['onset'];
    history: Appointment['history'];
    note: Appointment['note'];
  }>({
    symptom: props.defaltValues?.symptom ?? '',
    onset: props.defaltValues?.onset ?? '',
    history: props.defaltValues?.history ?? '',
    note: props.defaltValues?.note ?? '',
  });

  const handleClickNext = useCallback(() => {
    props.onSubmit(inputInterview, isFirst);
  }, [props.onSubmit, inputInterview, isFirst]);

  return (
    <Grid gap={2} py={2}>
      <Typography variant="h6" textAlign="center">
        問診を記入してください
      </Typography>

      <Box my={4} display="flex" justifyContent="center">
        <FormControlLabel
          onChange={() => setIsFirst((t) => !t)}
          control={<Checkbox checked={isFirst} />}
          label="初診/当院を初めて利用する"
        />
      </Box>

      <Stack spacing={2}>
        {props.isMedicalConsultation && (
          <>
            <TextField
              label="病気・怪我の部位・症状を教えてください"
              fullWidth
              minRows={3}
              multiline
              value={inputInterview.symptom}
              onChange={(e) => setInputInterview({ ...inputInterview, symptom: e.target.value })}
            />
            <TextField
              label="いつ頃から症状が出ていますか"
              fullWidth
              minRows={3}
              multiline
              value={inputInterview.onset}
              onChange={(e) => setInputInterview({ ...inputInterview, onset: e.target.value })}
            />
            <TextField
              label="今までかかったことのある病気・怪我があれば教えてください"
              fullWidth
              minRows={3}
              multiline
              value={inputInterview.history}
              onChange={(e) => setInputInterview({ ...inputInterview, history: e.target.value })}
            />
          </>
        )}
        <TextField
          label="その他病院に事前に伝えたいことがあればご記載ください"
          fullWidth
          minRows={3}
          multiline
          value={inputInterview.note}
          onChange={(e) => setInputInterview({ ...inputInterview, note: e.target.value })}
        />
      </Stack>

      <Grid mt={6} container>
        <Grid textAlign="center" item xs={6}>
          <Button onClick={props.onBack} sx={{ px: 5 }} variant="contained" color="inherit">
            戻る
          </Button>
        </Grid>
        <Grid textAlign="center" item xs={6}>
          <Button onClick={handleClickNext} sx={{ px: 5 }} variant="contained" color="primary">
            次へ
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
