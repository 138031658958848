import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import ja from 'date-fns/locale/ja';
import { useCallback } from 'react';
import { DailyWorkingHourDaily } from './DailyWorkingHourDaily';
import type { ResourceWorkingHour, Time, DayOfWeek } from 'graphql/generated/staff/graphql';
import { allDayOfWeeks } from 'constants/allDayOfWeeks';

interface Props {
  resourceId: string;
  holidays: DayOfWeek[]; // 曜日
  schedules: ResourceWorkingHour[];
  onCreate: (dayOfWeek: DayOfWeek, start: Time, end: Time) => void;
  onUpdate: (id: ResourceWorkingHour['id'], start: Time, end: Time) => void;
  onDelete: (id: ResourceWorkingHour['id']) => void;
}

export const WorkingHourEditor = (props: Props) => {
  const { resourceId } = props;

  const isHoliday = useCallback(
    (dayOfWeek: DayOfWeek) => {
      return props.holidays.includes(dayOfWeek);
    },
    [props.holidays]
  );

  return (
    <TableContainer sx={{ my: 2, width: '100%' }}>
      <Table>
        <TableBody>
          {allDayOfWeeks.map((dayOfWeek, index) => (
            <TableRow key={`${resourceId}-${index}`}>
              <TableCell>
                <Grid container alignItems="center">
                  <Grid item xs={2}>
                    <Typography>{ja.localize?.day(index, { width: 'narrow' })}</Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <DailyWorkingHourDaily
                      isHoliday={isHoliday(dayOfWeek)}
                      dayOfWeek={dayOfWeek}
                      schedules={props.schedules.filter((s) => s.day === dayOfWeek)}
                      onCreate={props.onCreate}
                      onUpdate={props.onUpdate}
                      onDelete={props.onDelete}
                    />
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
