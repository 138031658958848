import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { useCallback, useState } from 'react';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import type { PasswordResetFormInput } from './hooks/usePasswordResetForm';
import { usePasswordResetForm } from './hooks/usePasswordResetForm';
import Logo from 'graphics/Logo';

interface Props {
  onSubmit: (data: PasswordResetFormInput) => void;
}

export const PasswordResetForm = (props: Props) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = usePasswordResetForm();

  // eslint-disable-next-line no-console
  const onInvalid = (errors: any) => console.error(errors);

  const onSubmit = useCallback(
    (data: PasswordResetFormInput) => {
      props.onSubmit(data);
    },
    [props.onSubmit]
  );

  const onToggleShowPassword = useCallback(() => {
    setShowPassword((prev) => !prev);
  }, []);

  const onToggleShowConfirmPassword = useCallback(() => {
    setShowConfirmPassword((prev) => !prev);
  }, []);

  return (
    <>
      <Stack component="form" onSubmit={handleSubmit(onSubmit, onInvalid)}>
        <Box sx={{ textAlign: 'center', pb: 5 }}>
          <Box sx={{ maxWidth: '500px', margin: '0 auto' }}>
            <Logo />
          </Box>
        </Box>
        <Typography fontWeight="bold" textAlign="center" sx={{ py: 2 }}>
          新しいパスワードを入力して下さい
        </Typography>
        <Typography my={2} textAlign="center">
          新しいパスワードを設定します。
          <br />
          新しいパスワードを入力して変更ボタンを押して下さい。
        </Typography>
        <FormControl sx={{ my: 1.5 }} variant="outlined">
          <InputLabel size="small" error={!!errors.password}>
            パスワード
          </InputLabel>
          <OutlinedInput
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={onToggleShowPassword} edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="パスワード"
            size="small"
            error={'password' in errors}
            {...register('password')}
          />
          {errors.password?.message && (
            <FormHelperText error>{errors.password?.message}</FormHelperText>
          )}
        </FormControl>
        <FormControl sx={{ my: 0.5 }} variant="outlined">
          <InputLabel size="small">新しいパスワード(確認)</InputLabel>
          <OutlinedInput
            type={showConfirmPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={onToggleShowConfirmPassword} edge="end">
                  {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="新しいパスワード(確認)"
            size="small"
            error={'passwordConfirm' in errors}
            {...register('passwordConfirm')}
          />
          {errors.passwordConfirm?.message && (
            <FormHelperText error>{errors.passwordConfirm?.message}</FormHelperText>
          )}
        </FormControl>

        <Typography variant="caption" my={1}>
          半角英数字・記号が使用できます。
          <br />
          英字(大文字・小文字)をそれぞれ１文字以上使用してください
        </Typography>

        <Button
          sx={{ backgroundColor: grey[700], my: 1 }}
          variant="contained"
          type="submit"
          disabled={!isValid}
        >
          パスワードを変更する
        </Button>
      </Stack>
    </>
  );
};
