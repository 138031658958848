import { Box, Typography } from '@mui/material';
// eslint-disable-next-line import/no-duplicates
import { format, parseISO } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import ja from 'date-fns/locale/ja';

interface Props {
  startAt: Date;
  endAt: Date;
}

export const DateCard = (props: Props) => {
  const startAt = typeof props.startAt === 'string' ? parseISO(props.startAt) : props.startAt;
  const endAt = typeof props.endAt === 'string' ? parseISO(props.endAt) : props.endAt;

  return (
    <Box>
      <Typography variant="caption">
        {format(startAt, 'yyyy年MM月dd日(E) HH:mm', { locale: ja })}~{format(endAt, 'HH:mm')}
      </Typography>
    </Box>
  );
};
