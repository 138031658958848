import PetsIcon from '@mui/icons-material/Pets';
import { Avatar, Box, Button, Chip, Divider, Grid, Stack, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { format, parseISO } from 'date-fns';
import type { FC } from 'react';
import { petGenders } from 'constants/petGender';
import type { PetDetailQuery } from 'graphql/generated/staff/graphql';
import { getAge } from 'helpers/date';

interface Props {
  clinicPet: PetDetailQuery['clinicPet'];
  onEdit: () => void;
}

export const Component: FC<Props> = ({ clinicPet, onEdit }) => {
  const pet = clinicPet.pet;
  const birthday = pet?.birthday ? format(parseISO(pet?.birthday), 'yyyy年M月d日') : null;

  return (
    <Box>
      <Grid container spacing={8} alignItems="start">
        <Grid item xs={4}>
          <Stack gap={2} mt={2}>
            <Box display="flex" justifyContent="center">
              <Avatar src={pet?.profileImageURL || undefined} sx={{ width: 200, height: 200 }}>
                <PetsIcon fontSize="large" sx={{ fontSize: 100 }} />
              </Avatar>
            </Box>
            <Button variant="outlined" color="secondary" size="large" fullWidth onClick={onEdit}>
              編集
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={8}>
          <Box display="flex">
            <Chip
              label={
                <Box display="flex">
                  <Typography variant="body2" color={grey[700]}>
                    診察券番号
                  </Typography>
                  <Typography variant="body2" ml={1}>
                    {clinicPet.patientNumber}
                  </Typography>
                </Box>
              }
              size="small"
            />
          </Box>
          <Box mt={3} mb={3}>
            <Typography variant="h4">{pet?.name}</Typography>
            <Typography variant="h6" color={grey[600]}>
              {pet?.animal?.name} / {pet?.animalType?.name}
            </Typography>
          </Box>
          <Box mt={4}>
            <Grid container spacing={3}>
              {[
                ['性別', petGenders.find((p) => p.value === pet?.gender)?.label],
                ['体重', pet?.weight ? `${pet.weight} kg` : null],
                ['年齢', birthday ? `${birthday} (${getAge(pet?.birthday, '歳')})` : ''],
                ['毛色', pet?.furColor],
                ['フード', pet?.food],
                ['マイクロチップ', pet?.microChip],
              ].map(([label, value]) => (
                <Grid key={label} item xs={4}>
                  <Typography color={grey[600]} variant="body2">
                    {label}
                  </Typography>
                  <Typography variant="body1" mt={0.5}>
                    {value}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Grid container mt={4}>
            <Grid item xs={4}>
              <Typography variant="body2" color={grey[700]}>
                ペット保険
              </Typography>
              <Typography variant="body1">{pet?.insurance}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2" color={grey[700]}>
                保険番号
              </Typography>
              <Typography variant="body1">{pet?.insuranceNumber}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box my={8}>
        <Divider />
      </Box>
      <Stack spacing={8} my={8}>
        <Box>
          <Typography variant="h6">飼い主メモ</Typography>
          <Box py={2}>
            <Typography variant="body2" color={grey[700]}>
              {pet?.note}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Typography variant="h6">病院メモ</Typography>
          <Box py={2}>
            <Typography variant="body2" color={grey[700]}>
              {clinicPet.note}
            </Typography>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};
