import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

export interface LoginFormInput {
  email: string;
  password: string;
}

const formSchema = yup.object({
  email: yup
    .string()
    .required('メールアドレスが入力されていません')
    .email('正しい形式でメールアドレスを入力してください'),
  password: yup
    .string()
    .required('パスワードを入力してください')
    .min(8, 'パスワードは8桁以上必要です'),
});

export const useLoginForm = () =>
  useForm<LoginFormInput>({
    resolver: yupResolver(formSchema),
  });
