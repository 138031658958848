import { Avatar, Box, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import PetsIcon from '@mui/icons-material/Pets';
import { format, parseISO } from 'date-fns';
import type { PetDetailQuery } from 'graphql/generated/user/graphql';
import { getAge } from 'helpers/date';
import { petGenders } from 'constants/petGender';

interface Props {
  pet: PetDetailQuery['viewer']['pet'];
}

export const PetDetailView = ({ pet }: Props) => {
  const listItem = [
    { label: '名前', value: pet.name },
    { label: '種別', value: pet.animal?.name },
    { label: '種類', value: pet.animalType?.name },
    { label: '性別', value: petGenders.find((g) => g.value === pet.gender)?.label },
    {
      label: '生年月日',
      value: pet.birthday
        ? `${format(parseISO(pet.birthday), 'yyyy/MM/dd')}(${getAge(pet.birthday, '歳')})`
        : '',
    },
    { label: '体重(kg)', value: pet.weight },
    { label: '毛色', value: pet.furColor },
    { label: '加入しているペット保険', value: pet.insurance },
    { label: '保険証番号', value: pet.insuranceNumber },
    { label: '普段食べているフード', value: pet.food },
    { label: 'マイクロチップ番号', value: pet.microChip },
    { label: 'メモ', value: pet.note },
  ];

  return (
    <Box>
      <Box display="flex" justifyContent="center" my={4}>
        <Avatar sx={{ width: 64, height: 64 }} src={pet.profileImageURL || ''}>
          <PetsIcon fontSize="large" />
        </Avatar>
      </Box>
      {listItem.map((v) => (
        <Box
          key={v.label}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          component="dl"
          borderTop={1}
          borderColor={grey[200]}
          m={0}
          py={2}
        >
          <Typography
            variant="caption"
            color={grey[900]}
            fontWeight="bold"
            component="dt"
            flexShrink={0}
            sx={{ wordBreak: 'break-all' }}
          >
            {v.label}
          </Typography>
          <Typography
            ml={1}
            variant="body2"
            component="dd"
            color={v.value ? grey[900] : grey[600]}
            textAlign="right"
            sx={{ wordBreak: 'break-all' }}
          >
            {v.value ?? '-'}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};
