import { Box, Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import type { FC } from 'react';
import Logo from 'graphics/Logo';
import * as paths from 'constants/paths/user';

interface Props {
  clinicSlug?: string;
}

export const SendPasswordResetMail: FC<Props> = ({ clinicSlug }) => {
  return (
    <Box mx="auto" py={5}>
      <Box textAlign="center" py={2}>
        <Box sx={{ maxWidth: '500px', margin: '0 auto' }}>
          <Logo />
        </Box>
      </Box>
      <Box component="h4" sx={{ textAlign: 'center' }}>
        パスワードリセットメールを送信しました。
      </Box>
      <Box my={4} textAlign="center">
        <Typography variant="body2">
          パスワード再設定メールをお客様のメールアドレス宛へ送信しました。
          <br />
          お送りしたメールの内容に従ってパスワードの再設定をお願いします。
        </Typography>
      </Box>
      <Box textAlign="center">
        <Button
          to={clinicSlug ? paths.clinicLoginPath(clinicSlug) : paths.loginPath()}
          component={Link}
        >
          ログイン画面に戻る
        </Button>
      </Box>
    </Box>
  );
};
