import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useQuery } from 'urql';
import { enqueueSnackbar } from 'notistack';
import { Helmet } from 'react-helmet-async';
import { UserPetForm } from 'components/forms/user/UserPetForm';
import type { CreatePetInput } from 'graphql/generated/user/graphql';
import { AnimalsDocument } from 'graphql/generated/user/graphql';
import * as paths from 'constants/paths/user';
import FullScreenLoading from 'components/FullScreenLoading';
import { useCreatePet } from 'hooks/user/forms/useCreatePet';
import * as pageTitles from 'constants/pageTitles/user';

const Page: FC = () => {
  const navigate = useNavigate();
  const [{ data, fetching }] = useQuery({
    query: AnimalsDocument,
  });
  const { createPet } = useCreatePet();

  const onSubmit = useCallback(async (input: CreatePetInput, imageFile?: File) => {
    const { data } = await createPet(input, imageFile);
    if (!data) {
      return;
    }
    const id = 'createPet' in data ? data.createPet.id : data.updatePet.id;
    enqueueSnackbar(`ペット ${input.name}を追加しました`, { variant: 'success' });
    window.scrollTo(0, 0);
    navigate(paths.petDetailPath(id));
  }, []);

  const onCancel = useCallback(() => {
    navigate(paths.petsPath());
  }, []);

  if (fetching || !data) {
    return <FullScreenLoading />;
  }

  return (
    <>
      <Helmet>
        <title>{pageTitles.newPet}</title>
      </Helmet>
      <Typography variant="h5" textAlign="center">
        ペット情報登録
      </Typography>
      <Stack spacing={2} sx={{ alignItems: 'center' }}>
        <UserPetForm animals={data.animals} onSubmit={onSubmit} onCancel={onCancel} />
      </Stack>
    </>
  );
};

export default Page;
