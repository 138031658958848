import type { FC } from 'react';
import { Breadcrumbs, Button, Container, Toolbar, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useMutation, useQuery } from 'urql';
import { useSearchParams, Link as RouterLink } from 'react-router-dom';
import * as pageTitles from 'constants/pageTitles/staff';
import ResourcesTable from 'components/tables/staff/ResourcesTable';
import type { ResourcesQuery, ResourcesQueryVariables } from 'graphql/generated/staff/graphql';
import { UpdateResourceDocument, ResourcesDocument } from 'graphql/generated/staff/graphql';
import * as paths from 'constants/paths/staff';
import FullScreenLoading from 'components/FullScreenLoading';
import NotFoundPage from 'pages/NotFoundPage';

const Page: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const paramKey = 'kind';
  const resourceKindID = searchParams.get(paramKey) || undefined;
  const [{ data, fetching, error }] = useQuery<ResourcesQuery, ResourcesQueryVariables>({
    query: ResourcesDocument,
    variables: { resourceKindID },
  });

  const [, updateResoure] = useMutation(UpdateResourceDocument);

  if (fetching) {
    return <FullScreenLoading />;
  }

  if (error?.networkError) {
    throw error;
  }

  if (!data) {
    return <NotFoundPage />;
  }

  return (
    <>
      <Helmet>
        <title>{pageTitles.resources}</title>
      </Helmet>
      <Toolbar>
        <Container sx={{ display: 'flex' }}>
          <Breadcrumbs sx={{ flexGrow: 1 }}>
            <Typography color="text.primary">{pageTitles.resources}</Typography>
          </Breadcrumbs>
          <Button component={RouterLink} to={paths.resourceNewPath()} variant="contained">
            新規登録
          </Button>
        </Container>
      </Toolbar>
      <Container>
        <ResourcesTable
          data={data}
          kindId={resourceKindID}
          onSelectKind={(id) => setSearchParams({ [paramKey]: id || '' })}
          onUpdateOrder={(id, order) => updateResoure({ input: { id, order } })}
        />
      </Container>
    </>
  );
};

export default Page;
