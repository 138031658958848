import { Box, Button, TextField, Typography } from '@mui/material';
import type { ChangeEvent } from 'react';
import { useCallback, useState } from 'react';
import { format } from 'date-fns';
import type { Time } from 'graphql/generated/staff/graphql';

interface Props {
  isNew: boolean;
  start: Time;
  end: Time;
  onSubmit: (start: Time, end: Time) => void;
  onCancel: () => void;
}

const formatTime = (value: Time) => {
  const time = new Date(0, 0, 0, value.hour, value.minute);
  return format(time, 'HH:mm');
};

const splitTime = (value: string /* HH:mm */) => {
  return {
    hour: Number(value.split(':')[0]),
    minute: Number(value.split(':')[1]),
  };
};

export const SelectTermOfTime = (props: Props) => {
  const [start, setStart] = useState<string>(formatTime(props.start));
  const [end, setEnd] = useState<string>(formatTime(props.end));

  const onChangeStart = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
    setStart(ev.target.value);
  }, []);

  const onChangeEnd = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
    setEnd(ev.target.value);
  }, []);

  const onSubmit = useCallback(() => {
    props.onSubmit(splitTime(start), splitTime(end));
  }, [props.onSubmit, start, end]);

  return (
    <>
      <TextField
        size="small"
        type="time"
        value={start}
        onChange={onChangeStart}
        InputLabelProps={{
          shrink: true,
        }}
        sx={{ width: 150 }}
      />
      <Typography variant="h5" sx={{ px: 3 }}>
        〜
      </Typography>
      <TextField
        size="small"
        type="time"
        InputProps={{
          inputProps: {
            min: start,
          },
        }}
        onChange={onChangeEnd}
        value={end}
        InputLabelProps={{
          shrink: true,
        }}
        sx={{ width: 150 }}
      />

      <Box sx={{ px: 2 }}>
        <Button size="large" variant="outlined" onClick={props.onCancel}>
          取消
        </Button>
      </Box>
      <Box>
        <Button size="large" variant="contained" onClick={onSubmit}>
          {props.isNew ? '追加' : '更新'}
        </Button>
      </Box>
    </>
  );
};
