import type { FC } from 'react';
import { ReactComponent as WhiteLogo } from './graphic-white.svg';
import { ReactComponent as BlackLogo } from './graphic-black.svg';

interface Props {
  width?: number | string;
  height?: number | string;
  color?: 'white' | 'blue';
}

const Graphic: FC<Props> = (props) => {
  if (props.color === 'white') {
    return <WhiteLogo {...props} />;
  }
  return <BlackLogo {...props} />;
};

export default Graphic;
