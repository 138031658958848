import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box } from '@mui/material';
import { UserRow } from './UserRow';
import type { UsersQuery } from 'graphql/generated/staff/graphql';

interface Props {
  data: UsersQuery;
  onSelectUser: (clinicUserId: string) => void;
  onSelectPet: (clinicPetId: string) => void;
  onCreateAppointment: (clinicUserId: string, clinicPetId?: string) => void;
}

export const UsersTable = (props: Props) => {
  if (props.data.clinicUsers.length === 0) {
    return <Box sx={{ textAlign: 'center' }}>データがありません</Box>;
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="right">診察券番号</TableCell>
            <TableCell>ユーザー</TableCell>
            <TableCell align="right">電話番号</TableCell>
            <TableCell>ペット</TableCell>
            <TableCell align="right">前回来院</TableCell>
            <TableCell align="right">予約回数</TableCell>
            <TableCell align="center">操作</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.clinicUsers.map((clinicUser) => (
            <UserRow
              key={clinicUser.id}
              clinicUser={clinicUser}
              onSelectPet={props.onSelectPet}
              onSelectUser={props.onSelectUser}
              onCreateAppointment={props.onCreateAppointment}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
