import type { FC } from 'react';
import { useEffect } from 'react';
import { useCurrentStaff } from 'hooks/staff/useCurrentStaff';
import * as paths from 'constants/paths/staff';

const Page: FC = () => {
  const { logout } = useCurrentStaff();
  useEffect(() => {
    logout();
    window.location.href = paths.loginPath();
  }, []);

  return <></>;
};

export default Page;
