import {
  Button,
  Checkbox,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  styled,
} from '@mui/material';
import { Box } from '@mui/system';
// eslint-disable-next-line import/no-duplicates
import { addHours, format, getDay, parse, startOfDay, startOfWeek } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import ja from 'date-fns/locale/ja';
// eslint-disable-next-line import/order

import type { FC } from 'react';
import { useState, useRef, useMemo, useCallback } from 'react';
import type { SlotInfo } from 'react-big-calendar';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { grey } from '@mui/material/colors';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { AppointmentCell } from './AppointmentCell';
import { isHoliday, isWorkingHour } from './utils/isWorkingHour';
import type { Appointment, Appointments, Resource, Resources } from './types';
import { useClientSize } from 'hooks/useClientSize';
import { DayOfWeek } from 'graphql/generated/staff/graphql';

interface Props {
  date: Date;
  appointments: Appointments;
  resources: Resources;
  slotDuration?: number;
  onSelectSlot?: (slotInfo: SlotInfo) => void;
  onChangeDate?: (date: Date) => void;
  onSelectAppointment?: (appointment: Appointment) => void;
  holidays: DayOfWeek[];
}

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales: { ja },
});

export const AppointmentCalendar: FC<Props> = ({
  resources,
  appointments,
  date,
  slotDuration,
  onChangeDate = () => {},
  onSelectAppointment,
  onSelectSlot,
  holidays,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [selectedResourceIds, setSelectedResourceIds] = useState(resources.map((r) => r.id));
  const allDaysOfWeek = [
    DayOfWeek.Sunday,
    DayOfWeek.Monday,
    DayOfWeek.Tuesday,
    DayOfWeek.Wednesday,
    DayOfWeek.Thursday,
    DayOfWeek.Friday,
    DayOfWeek.Saturday,
  ];

  const scrollToTime = useMemo(() => {
    return addHours(new Date(), -2);
  }, []);

  const clientSize = useClientSize();

  const height = useMemo(() => {
    return clientSize.height - (wrapperRef.current?.offsetTop ?? 0);
  }, [clientSize.height, wrapperRef.current]);

  const slotPropGetter = useCallback(
    (date: Date, resourceId?: string | number) => {
      const resource = resources.find((r) => r.id === resourceId);
      if (!resource) {
        return {};
      }

      if (
        !isWorkingHour(date, resource.workingHours) ||
        isHoliday(date, resource) ||
        holidays.includes(allDaysOfWeek[date.getDay()])
      ) {
        return {
          className: 'rbc-workingTime',
        };
      }

      return {};
    },
    [resources]
  );

  return (
    <Box
      ref={wrapperRef}
      height={height ? `${height - 58}px` : 'calc(100% - 58px)'}
      sx={{
        '& .rbc-events-container': {
          width: '100%',
        },
      }}
    >
      <Box
        sx={{
          height: '58px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
      >
        <Box>
          <FormControl size="small">
            <InputLabel id="select-resources-label">担当</InputLabel>
            <Select
              labelId="select-resources-label"
              label="担当"
              sx={{ minWidth: '200px' }}
              value={selectedResourceIds}
              onChange={(e) => {
                setSelectedResourceIds(e.target.value as string[]);
              }}
              renderValue={(selected) => {
                if (selected.length === resources.length) {
                  return 'すべて';
                }
                return (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected
                      .filter((_, i) => i < 2)
                      .map((value) => (
                        <Chip
                          key={value}
                          label={resources.find((r) => r.id === value)?.name ?? value}
                        />
                      ))}
                    {selected.length > 2 && <Chip label={`+ ${selected.length - 2}`} />}
                  </Box>
                );
              }}
              multiple
            >
              {resources.map((resource) => {
                return (
                  <MenuItem
                    key={resource.id}
                    value={resource.id}
                    disabled={
                      selectedResourceIds.includes(resource.id) && selectedResourceIds.length === 1
                    }
                  >
                    <Checkbox checked={selectedResourceIds.includes(resource.id)} />
                    {resource.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
        <Box>
          <IconButton aria-label="前日" onClick={() => onChangeDate(addHours(date, -24))}>
            <ChevronLeftIcon />
          </IconButton>

          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={ja}
            dateFormats={{
              monthAndYear: 'yyyy年 M月',
            }}
          >
            <DatePicker
              key={date.toISOString()}
              onChange={(newValue) => newValue && onChangeDate(newValue)}
              defaultValue={date}
              slotProps={{
                textField: {
                  size: 'small',
                },
              }}
            />
          </LocalizationProvider>
          <IconButton aria-label="翌日" onClick={() => onChangeDate(addHours(date, 24))}>
            <ChevronRightIcon />
          </IconButton>
          <Button
            onClick={() => onChangeDate(startOfDay(new Date()))}
            variant="outlined"
            color="secondary"
          >
            本日
          </Button>
        </Box>
      </Box>
      <StyledCalendar
        date={date}
        components={{
          event: ({ event }) => <AppointmentCell appointment={event} />,
        }}
        onNavigate={() => {
          /* noop */
        }}
        localizer={localizer}
        toolbar={false}
        views={['day']}
        defaultView="day"
        scrollToTime={scrollToTime}
        events={appointments}
        selectable
        onSelectSlot={onSelectSlot}
        onSelectEvent={onSelectAppointment}
        resources={resources.filter((r) => selectedResourceIds.includes(r.id))}
        startAccessor={(a) => new Date(a.startAt)}
        endAccessor={(a) => new Date(a.endAt)}
        resourceAccessor={(a) => a.resource?.id}
        resourceIdAccessor="id"
        resourceTitleAccessor="name"
        formats={{
          timeGutterFormat: (date) => format(date, 'HH:mm', { locale: ja }),
        }}
        slotPropGetter={slotPropGetter}
        step={slotDuration ?? 15}
      />
    </Box>
  );
};

const StyledCalendar = styled(Calendar<Appointment, Resource>)`
  .rbc-events-container {
    width: '100%';
  }
  .rbc-allday-cell {
    display: 'none';
  }
  .rbc-day-slot .rbc-event,
  .rbc-day-slot .rbc-background-even {
    display: block;
  }
  .rbc-time-column .rbc-timeslot-group {
    background-color: #fff;
  }
  .rbc-row {
    background-color: #fff;
  }
  .rbc-time-slot {
    padding-top: 0;
    color: ${grey[600]};
  }
  .rbc-time-gutter .rbc-timeslot-group {
    border: none;
  }
  .rbc-label {
    vertical-align: top;
    line-height: 0;
    font-size: 13px;
  }
  .rbc-time-gutter.rbc-time-column .rbc-timeslot-group:first-of-type .rbc-label {
    line-height: 1;
  }
  .rbc-header {
    padding: 8px 0;
  }
  .rbc-calendar {
    overflow-y: scroll;
  }
  .rbc-timeslot-group {
    min-height: 130px;
  }
  .rbc-event {
    border: none;
    background-color: transparent;
    padding: 0;
  }
  .rbc-event-label {
    display: none;
  }
  .rbc-allday-cell {
    display: none;
  }
  .rbc-time-header-content > .rbc-row.rbc-row-resource {
    border-bottom: none;
  }
  .rbc-day-slot {
    background-color: transparent;
  }
  .rbc-day-slot > * {
  }
  .rbc-day-slot .rbc-event,
  .rbc-day-slot .rbc-background-event {
    overflow: inherit;
  }
  .rbc-workingTime {
    width: 100%;
    color: transparent;
    background: repeating-linear-gradient(
      -45deg,
      #fafafa,
      #fafafa 10px,
      #f3f3f3 10px,
      #f3f3f3 18px
    );
    border: none;
    max-width: none;
  }
`;
