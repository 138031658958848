import { styled, TableCell } from '@mui/material';
import { grey, yellow } from '@mui/material/colors';

interface ScheduleCellProps {
  isHover: boolean;
  isAvailable?: boolean;
}

export const ScheduleCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'isHover' && prop !== 'isAvailable',
})<ScheduleCellProps>(({ isHover, isAvailable = null }) => ({
  textAlign: 'center',
  padding: '10px 1px',
  borderRight: `1px solid ${grey[300]} !important`,
  backgroundColor: isHover ? yellow[100] : !isAvailable ? grey[100] : 'inherit',
  cursor: isAvailable ? 'pointer' : !isAvailable ? 'not-allowed' : 'inherit',
}));
