import { Box, List } from '@mui/material';
import { Item } from './Item';
import type { Pet, PetsQuery } from 'graphql/generated/user/graphql';

interface Props {
  pets: PetsQuery['viewer']['pets'];
  onSelect: (petId: Pet['id']) => void;
}

export const PetsTable = ({ pets, ...props }: Props) => {
  if (pets.length === 0) {
    return <Box sx={{ textAlign: 'center' }}>データがありません</Box>;
  }

  return (
    <List dense>
      {pets.map((pet) => (
        <Item key={pet.id} pet={pet} onSelect={props.onSelect} />
      ))}
    </List>
  );
};
