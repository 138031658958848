import { Box, Avatar } from '@mui/material';
import type { FC } from 'react';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';

interface Props {
  defaultSource?: string | null;
  onSelect?: (file: File) => Promise<void>;
}

const AvatarDropzone: FC<Props> = ({ onSelect, defaultSource }) => {
  const [avatarHover, setAvatarHover] = useState(false);
  const [avatarSource, setAvatarSource] = useState<string | undefined>(defaultSource || undefined);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    setAvatarHover(false);
    if (!file) return;
    const reader = new FileReader();
    reader.onload = async () => {
      if (typeof reader.result === 'string') {
        await onSelect?.(file);
        setAvatarSource(reader.result);
      }
    };
    reader.readAsDataURL(file);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
    },
  });

  return (
    <Box
      {...getRootProps()}
      sx={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <input {...getInputProps()} />
      {(isDragActive || avatarHover) && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            bgcolor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 100,
            pointerEvents: 'none',
          }}
        >
          <Box
            component="p"
            sx={{
              color: 'white',
              fontSize: 24,
              fontWeight: 'bold',
            }}
          >
            ここに画像をドロップ
          </Box>
        </Box>
      )}
      <Avatar
        onMouseEnter={() => setAvatarHover(true)}
        onMouseLeave={() => setAvatarHover(false)}
        sx={{
          width: 250,
          height: 250,
        }}
        src={avatarSource}
        variant="rounded"
      />
    </Box>
  );
};

export default AvatarDropzone;
