import { addMinutes, formatISO } from 'date-fns';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { AppointmentComplete } from '../AppointmentComplete/AppointmentComplete';
import { AppointmentConfirm } from '../AppointmentConfirm/AppointmentConfirm';
import { InterviewForm } from '../InterviewForm/InterviewForm';
import { PrivacyPolicy } from '../PrivacyPolicy/PrivacyPolicy';
import { SelectDataAndStaff } from '../SelectDateAndStaff/SelectDataAndStaff';
import { SelectPet } from '../SelectPet/SelectPet';
import { SelectService } from '../SelectService/SelectService';
import { AppointmentStep } from './AppointmentForm';
import type {
  AppointmentFormOptionsQueryClinic,
  AppointmentFormOptionsQueryPet,
  AppointmentFormOptionsQueryResource,
  AppointmentFormOptionsQueryService,
  AppointmentFormOptionsQueryServiceCategory,
} from './types';
import type { AppointmentValues } from './useAppointmentForm';
import { useAppointmentForm } from './useAppointmentForm';
import type {
  Appointment,
  AppointmentFormOptionsQuery,
  ClinicService,
  CreateAppointmentInput,
} from 'graphql/generated/user/graphql';

interface Props {
  defaultValues?: AppointmentValues;
  viewer: AppointmentFormOptionsQuery['viewer'];
  clinic: AppointmentFormOptionsQueryClinic;
  step: AppointmentStep;
  onEditPet: () => void;
  onNewPet: () => void;
  onFinish: () => void;
  onSetStep: (step: AppointmentStep) => void;
  onSubmit: (input: CreateAppointmentInput, onNext: () => void) => Promise<void>;
}

const serviceCategoryFromServiceId = (
  serviceId: ClinicService['id'] | null,
  serviceCategories: AppointmentFormOptionsQueryServiceCategory[]
) => {
  return serviceCategories.find((sc) => sc.services.some((s) => s.clinicService.id === serviceId));
};

export const AppointmentFormContent = (props: Props) => {
  const { viewer, clinic, defaultValues } = props;
  const { values, setPet, setService, setResource, setDate, setInterviews, setIsFirst } =
    useAppointmentForm(defaultValues ?? { isFirst: false });
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState<boolean>(false);

  const serviceCategory = serviceCategoryFromServiceId(
    values.service?.clinicService.id || null,
    clinic.serviceCategories
  );

  const service = serviceCategory?.services.find(
    (s) => s.clinicService.id === values.service?.clinicService.id
  );

  const onSelectPet = useCallback(
    (pet: AppointmentFormOptionsQueryPet) => {
      setPet(pet);
      props.onSetStep(AppointmentStep.SelectService);
    },
    [setPet, props.onSetStep]
  );

  const onSelectClinicService = useCallback(
    (service: AppointmentFormOptionsQueryService) => {
      setService(service);
      props.onSetStep(AppointmentStep.SelectStaffAndDate);
    },
    [setService, props.onSetStep]
  );

  const onSelectResourceAndDate = useCallback(
    (resource: AppointmentFormOptionsQueryResource | null, startAt: Appointment['startAt']) => {
      setResource(resource);
      setDate(startAt, addMinutes(startAt, service?.durationMinutes || 0));
      props.onSetStep(AppointmentStep.Interview);
    },
    [setResource, setDate, props.onSetStep, service?.description]
  );

  const onSubmitInterview = useCallback(
    (
      data: {
        symptom: Appointment['symptom'];
        onset: Appointment['onset'];
        history: Appointment['history'];
        note: Appointment['note'];
      },
      isFirst: Appointment['isFirst']
    ) => {
      setInterviews(data);
      setIsFirst(isFirst);
      props.onSetStep(AppointmentStep.Confirm);
    },
    [setInterviews, props.onSetStep]
  );

  const onSubmit = useCallback(async () => {
    try {
      if (!values.service) {
        throw new Error('サービスの選択は必須です');
      }
      if (!values.pet) {
        throw new Error('ペットの選択は必須です');
      }
      const input: CreateAppointmentInput = {
        clinicServiceId: values.service.clinicService.id,
        startAt: formatISO(values.startAt),
        endAt: formatISO(values.endAt),
        isFirst: values.isFirst,
        petId: values.pet.id,
        resourceId: values.resource?.id ?? null,
        history: values.history ?? '',
        note: values.note ?? '',
        onset: values.onset ?? '',
        symptom: values.symptom ?? '',
      };
      props.onSubmit(input, () => {
        props.onSetStep(AppointmentStep.Complete);
      });
    } catch (e: any) {
      enqueueSnackbar(e.message, {
        variant: 'error',
      });
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }, [values]);

  if (showPrivacyPolicy) {
    return (
      <PrivacyPolicy
        text={props.clinic.latestAgreement?.text ?? ''}
        onClose={() => setShowPrivacyPolicy(false)}
      />
    );
  }

  switch (props.step) {
    case AppointmentStep.SelectPet:
      return (
        <SelectPet
          defaultPet={values.pet}
          pets={viewer.pets}
          clinic={clinic}
          onNewPet={props.onNewPet}
          onEditPet={props.onEditPet}
          onSelect={onSelectPet}
        />
      );

    case AppointmentStep.SelectService:
      return (
        <SelectService
          serviceCategories={clinic.serviceCategories}
          onBack={() => props.onSetStep(AppointmentStep.SelectPet)}
          onSelect={onSelectClinicService}
        />
      );

    case AppointmentStep.SelectStaffAndDate:
      if (serviceCategory && service) {
        return (
          <SelectDataAndStaff
            defaultResource={values.resource}
            clinic={clinic}
            serviceCategory={serviceCategory}
            service={service}
            onSelect={onSelectResourceAndDate}
            onBack={() => props.onSetStep(AppointmentStep.SelectService)}
          />
        );
      }
      throw new Error('必須事項がたりません: スタッフ・日時選択');

    case AppointmentStep.Interview:
      return (
        <InterviewForm
          defaltValues={{
            isFirst: values.isFirst,
            symptom: values.symptom,
            onset: values.onset,
            history: values.history,
            note: values.note,
          }}
          isMedicalConsultation={serviceCategory?.name === '診療'}
          onBack={() => props.onSetStep(AppointmentStep.SelectStaffAndDate)}
          onSubmit={onSubmitInterview}
        />
      );

    case AppointmentStep.Confirm:
      if (values.startAt && values.endAt && values.pet) {
        return (
          <AppointmentConfirm
            appointment={{
              ...values,
              startAt: values.startAt,
              endAt: values.endAt,
              clinicPet: {
                pet: values.pet,
              },
              service: values.service,
              resource: values.resource || { id: '', name: 'スタッフを指名しない' },
            }}
            isUpdate={!!props.defaultValues}
            onSelectPet={() => props.onSetStep(AppointmentStep.SelectPet)}
            onSelectService={() => props.onSetStep(AppointmentStep.SelectService)}
            onSelectDate={() => props.onSetStep(AppointmentStep.SelectStaffAndDate)}
            onSelectInterview={() => props.onSetStep(AppointmentStep.Interview)}
            onPrivacyPolicy={() => setShowPrivacyPolicy(true)}
            onSubmit={onSubmit}
          />
        );
      }
      throw new Error('必須事項がたりません: 入力内容確認画面');

    case AppointmentStep.Complete:
      return <AppointmentComplete onMypage={props.onFinish} />;

    default:
      return null;
  }
};
