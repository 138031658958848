import { Box, Container, CssBaseline, ThemeProvider } from '@mui/material';
import { useCallback, useEffect, useMemo } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { Client, Provider, useQuery } from 'urql';
import { UserAppBar } from './UserAppBar/UserAppBar';
import * as paths from 'constants/paths/user';
import type { ClinicUserLayoutQuery } from 'graphql/generated/user/graphql';
import { ClinicUserLayoutDocument } from 'graphql/generated/user/graphql';
import { useUserGraphQLContext } from 'hooks/user/useUserGraphQLContext';
import theme from 'styles/theme';
import { useCookie } from 'hooks/useCookie';
import config from 'config';

interface Props {
  auth: boolean;
}

const Content = () => {
  const navigate = useNavigate();
  const { clinicSlug } = useParams();

  const [{ data }] = useQuery<ClinicUserLayoutQuery>({
    query: ClinicUserLayoutDocument,
    variables: {
      slug: clinicSlug,
    },
    pause: !clinicSlug,
  });

  const onClickLogo = useCallback(() => {
    if (clinicSlug) {
      navigate(paths.clinicRootPath(clinicSlug));
    } else {
      navigate(paths.rootPath());
    }
  }, [clinicSlug]);

  return (
    <>
      <UserAppBar clinicName={data?.clinic?.name} onClickLogo={onClickLogo} />
      <Box pt={10}>
        <Container maxWidth="xs" sx={{ bgcolor: 'white' }}>
          <Outlet />
        </Container>
      </Box>
    </>
  );
};

export const UserLayout = ({ auth }: Props) => {
  const navigate = useNavigate();
  const { clinicSlug } = useParams();
  const [accessToken] = useCookie(config.cookieKeys.userAccessToken);
  const context = useUserGraphQLContext();
  const client = useMemo(() => new Client(context), [context]);

  useEffect(() => {
    // ログイン画面でアクセストークンがなかった場合はログイン画面に遷移させる
    if (auth && !accessToken) {
      navigate(clinicSlug ? paths.clinicLoginPath(clinicSlug) : paths.loginPath());
    }
  }, [auth, !accessToken]);

  if (!auth) {
    return (
      <Provider value={client}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Box sx={{ backgroundColor: '#F9F9F9' }}>
            <Container sx={{ my: 0, backgroundColor: 'white', py: 4 }} maxWidth="xs">
              <Outlet />
            </Container>
          </Box>
        </ThemeProvider>
      </Provider>
    );
  }

  return (
    <Provider value={client}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Content />
      </ThemeProvider>
    </Provider>
  );
};
