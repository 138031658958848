import PetsIcon from '@mui/icons-material/Pets';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Avatar, Box, IconButton, Tooltip, Typography } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { grey } from '@mui/material/colors';
import { useCallback } from 'react';
import type { UserDetailQuery } from 'graphql/generated/staff/graphql';
import { petGenders } from 'constants/petGender';
import { getAge } from 'helpers/date';

interface Props {
  clinicPet: NonNullable<UserDetailQuery['clinicUser']>['clinicPets'][number];
  onSelectPet: (petId: string) => void;
}

export const PetRow = (props: Props) => {
  const pet = props.clinicPet?.pet;

  const onSelectPet = useCallback(() => {
    props.onSelectPet(props.clinicPet.id);
  }, [props.onSelectPet, props.clinicPet.id]);

  return (
    <TableRow hover>
      <TableCell align="right">{props.clinicPet?.patientNumber}</TableCell>
      <TableCell>
        <Box display="flex" alignItems="center" color={grey[900]}>
          <Avatar src={pet?.profileImageURL ?? ''}>
            <PetsIcon />
          </Avatar>
          <Box ml={2}>
            <Typography>{pet?.name}</Typography>
          </Box>
        </Box>
      </TableCell>
      <TableCell>{pet?.animalType?.name}</TableCell>
      <TableCell>{pet?.animal?.name}</TableCell>
      <TableCell align="right">{petGenders.find((p) => p.value === pet?.gender)?.label}</TableCell>
      <TableCell align="right">{getAge(pet?.birthday, '歳')}</TableCell>
      <TableCell align="center" width={100}>
        <Tooltip title="詳細を見る">
          <IconButton onClick={onSelectPet}>
            <VisibilityIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};
