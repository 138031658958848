import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  TableContainer as MuiTableContainer,
  TableHead as MuiTableHead,
  Select,
  TableRow,
  TextField,
} from '@mui/material';
import type { ComponentProps, FC } from 'react';
import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  eachDayOfInterval,
  endOfMonth,
  format as formatDate,
  isSameDay,
  startOfMonth,
  // eslint-disable-next-line import/no-duplicates
} from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import ja from 'date-fns/locale/ja';
import styled from '@emotion/styled';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import type { ResourceSchedulesQuery } from 'graphql/generated/staff/graphql';
import * as paths from 'constants/paths/staff';
import { allDayOfWeeks } from 'constants/allDayOfWeeks';

interface Props {
  month: Date;
  onChangeMonth?: (date: Date) => void;
  data: ResourceSchedulesQuery;
  kindId?: string | null;
  onSelectKind?: (id: string | null) => void;
}

export const Component: FC<Props> = ({
  data,
  month,
  onChangeMonth = () => {},
  onSelectKind = () => {},
  kindId,
}) => {
  const { resources, resourceKinds } = data;
  const [dates, setDates] = useState<Date[]>([]);

  useEffect(() => {
    const dates = eachDayOfInterval({
      start: startOfMonth(month),
      end: endOfMonth(month),
    });
    setDates(dates);
  }, [month]);

  return (
    <>
      {resourceKinds.length > 1 && (
        <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', alignItems: 'baseline' }}>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel htmlFor="select-resource-kind" id="select-resource-kind-label">
              種別
            </InputLabel>
            <Select
              id="select-resource-kind"
              labelId="select-resource-kind-label"
              value={kindId || ''}
              onChange={(e) => onSelectKind(e.target.value)}
              label="種別"
            >
              <MenuItem selected={!!kindId} value="">
                <em>全て</em>
              </MenuItem>
              {resourceKinds.map((kind) => (
                <MenuItem key={kind.id} value={kind.id}>
                  {kind.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            type="month"
            required
            value={formatDate(month, 'yyyy-MM')}
            onChange={(e) => {
              e.target.value && onChangeMonth(new Date(e.target.value));
            }}
            variant="standard"
          />
        </Box>
      )}
      {resources.length > 0 ? (
        <TableContainer sx={{ width: 'calc(100% - 240px)', marginRight: '-240px' }}>
          <Table sx={{ width: '100%' }} stickyHeader>
            <TableHead>
              <TableRow component="div">
                <LeftTableCell>名前・種別</LeftTableCell>
                {dates.map((date) => (
                  <TableCell key={date.toISOString()} sx={{ whiteSpace: 'nowrap', width: '3em' }}>
                    {date.getDate()}
                    <br />
                    {formatDate(date, 'EEE', { locale: ja })}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {resources.map((resource) => (
                <TableRow
                  hover
                  key={resource.id}
                  component={RouterLink}
                  to={paths.resourceWorkingHoursPath(resource.id)}
                  sx={{
                    textDecoration: 'none',
                    '&:nth-of-type(odd)': { backgroundColor: 'rgba(0, 0, 0, 0.03)' },
                  }}
                >
                  <LeftTableCell>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div>{resource.name}</div>
                      <Chip
                        label={resource.kind.name}
                        sx={{ ml: 1, fontSize: '0.8em' }}
                        size="small"
                      />
                    </Box>
                  </LeftTableCell>
                  {dates.map((date) => (
                    <TableCell key={date.toISOString()}>
                      {!resource.holidays.find((h) => isSameDay(new Date(h.date), date)) &&
                      resource.workingHours.filter((h) => h.day === allDayOfWeeks[date.getDay()])
                        .length > 0 ? (
                        <CheckCircleOutlineIcon sx={{ color: 'green' }} />
                      ) : (
                        <CloseIcon sx={{ color: 'lightGray' }} />
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box sx={{ textAlign: 'center' }}>データがありません</Box>
      )}
    </>
  );
};

const Table: FC<ComponentProps<typeof MuiTable>> = (props) => (
  <MuiTable {...props} component="div" />
);
const TableBody: FC<ComponentProps<typeof MuiTableBody>> = (props) => (
  <MuiTableBody {...props} component="div" />
);
const TableCell: FC<ComponentProps<typeof MuiTableCell>> = (props) => (
  <MuiTableCell {...props} component="div" sx={{ textAlign: 'center' }} />
);
const TableContainer: FC<ComponentProps<typeof MuiTableContainer>> = (props) => (
  <MuiTableContainer {...props} component="div" />
);
const TableHead: FC<ComponentProps<typeof MuiTableHead>> = (props) => (
  <MuiTableHead {...props} component="div" />
);

const LeftTableCell = styled(TableCell)`
  position: sticky;
  left: 0;
  z-index: 10;
  white-space: nowrap;
  text-align: left;
  border-right: 1px solid rgba(224, 224, 224, 1);
  &.MuiTableCell-root {
    background-color: #fff;
  }
  a:hover &.MuiTableCell-root {
    background-color: #f9f9f9;
  }
`;
