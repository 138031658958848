import { useMutation } from 'urql';
import { useCallback } from 'react';
import { enqueueSnackbar } from 'notistack';
import type { AppointmentStatus } from 'graphql/generated/staff/graphql';
import { UpdateAppointmentDocument } from 'graphql/generated/staff/graphql';

export const useUpdateAppointmentStatus = () => {
  const [result, mutate] = useMutation(UpdateAppointmentDocument);

  const update = useCallback(
    async (id: string, status: AppointmentStatus) => {
      try {
        const res = await mutate({ input: { id, status } });
        enqueueSnackbar('ステータスを更新しました。', { variant: 'success' });
        return res;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        enqueueSnackbar('ステータスの更新に失敗しました。', { variant: 'error' });
      }
      return null;
    },
    [mutate]
  );

  return [result, update] as const;
};
