import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import type { FC } from 'react';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import type { RequestPasswordResetFormInput } from './hooks/useRequestPasswordResetForm';
import { usePasswordResetForm } from './hooks/useRequestPasswordResetForm';
import Logo from 'graphics/Logo';
import * as paths from 'constants/paths/user';

interface Props {
  clinicSlug?: string;
  onSubmit: (data: RequestPasswordResetFormInput) => void;
}

export const RequestPasswordResetForm: FC<Props> = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = usePasswordResetForm();

  // eslint-disable-next-line no-console
  const onInvalid = (errors: any) => console.error(errors);

  const onSubmit = useCallback(
    (data: RequestPasswordResetFormInput) => {
      props.onSubmit(data);
    },
    [props.onSubmit]
  );

  return (
    <>
      <Stack component="form" onSubmit={handleSubmit(onSubmit, onInvalid)}>
        <Box sx={{ textAlign: 'center', pb: 5 }}>
          <Box sx={{ maxWidth: '500px', margin: '0 auto' }}>
            <Logo />
          </Box>
        </Box>

        <Typography fontWeight="bold" textAlign="center" sx={{ py: 2 }}>
          パスワードを忘れた場合
        </Typography>

        <Typography my={2} textAlign="center">
          パスワードをリセットします。
          <br />
          登録したメールアドレスを入力してください。
        </Typography>

        <FormControl sx={{ my: 3 }} variant="outlined">
          <InputLabel size="small">メールアドレス</InputLabel>
          <OutlinedInput
            type="text"
            label="メールアドレス"
            size="small"
            error={'email' in errors}
            {...register('email')}
          />
          {errors.email?.message && <FormHelperText error>{errors.email?.message}</FormHelperText>}
        </FormControl>

        <Button
          sx={{ backgroundColor: grey[700] }}
          variant="contained"
          type="submit"
          disabled={!isValid}
        >
          パスワード再設定用のメールを送信
        </Button>
      </Stack>

      <Box my={1} textAlign="center">
        <Typography variant="body2">上記アドレスに再設定用のメールを送信します。</Typography>
      </Box>

      <Box my={5}>
        <Divider />
      </Box>

      <Box textAlign="center">
        <Button
          to={props.clinicSlug ? paths.clinicLoginPath(props.clinicSlug) : paths.loginPath()}
          component={Link}
        >
          ログイン画面に戻る
        </Button>
      </Box>
    </>
  );
};
