import { enqueueSnackbar } from 'notistack';
import type { FC } from 'react';
import { useCallback, useState } from 'react';
import type { LoginParams } from 'clients/apis/staff';
import { login } from 'clients/apis/staff';
import LoginForm from 'components/forms/staff/LoginForm';
import config from 'config';
import { useCookie } from 'hooks/useCookie';

const Page: FC = () => {
  const [requesting, setRequesting] = useState(false);
  const [error, setError] = useState('');
  const [_, setAccessToken] = useCookie(config.cookieKeys.staffAccessToken);

  const handleSubmit = useCallback(async (values: LoginParams) => {
    setRequesting(true);
    setError('');
    try {
      const res = await login(values);
      if (res.accessToken) {
        setAccessToken(res.accessToken);
        enqueueSnackbar('ログインしました', { variant: 'success' });
      } else {
        throw new Error('accessToken is not found');
      }
    } catch (e) {
      enqueueSnackbar('ログインに失敗しました', { variant: 'error' });
      setError('ログインに失敗しました');
      // eslint-disable-next-line no-console
      console.error(e);
    }
    setRequesting(false);
  }, []);

  return <LoginForm onSubmit={handleSubmit} requesting={requesting} error={error} />;
};

export default Page;
