import type { FC } from 'react';
import { useCallback } from 'react';
import { Breadcrumbs, Container, Link, Toolbar, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useMutation, useQuery } from 'urql';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import * as pageTitles from 'constants/pageTitles/staff';
import type {
  CreateResourceInput,
  ResourceFormOptionsQuery,
} from 'graphql/generated/staff/graphql';
import {
  ResourceDetailFragmentDoc,
  CreateResourceDocument,
  ResourceFormOptionsDocument,
} from 'graphql/generated/staff/graphql';
import * as paths from 'constants/paths/staff';
import NotFoundPage from 'pages/NotFoundPage';
import FullScreenLoading from 'components/FullScreenLoading';
import ResourceForm from 'components/forms/staff/ResourceForm';
import { useFragment } from 'graphql/generated/staff';

const Page: FC = () => {
  const [{ data: masterData, fetching: masterFetching, error: masterError }] =
    useQuery<ResourceFormOptionsQuery>({
      query: ResourceFormOptionsDocument,
    });

  const [, createResource] = useMutation(CreateResourceDocument);
  const navigate = useNavigate();

  const handleSubmit = useCallback(
    async (values: CreateResourceInput) => {
      try {
        const res = await createResource({ input: values });
        if (!res.data?.createResource) {
          throw new Error('データが存在しません');
        }
        const resource = useFragment(ResourceDetailFragmentDoc, res.data.createResource);
        navigate(paths.resourceEditPath(resource.id));
        enqueueSnackbar('新規登録しました', { variant: 'success' });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        enqueueSnackbar('新規登録に失敗しました', { variant: 'error' });
      }
    },
    [createResource]
  );

  if (masterFetching) {
    return <FullScreenLoading />;
  }

  if (masterError?.networkError) {
    throw masterError;
  }

  if (!masterData) {
    return <NotFoundPage />;
  }

  return (
    <>
      <Helmet>
        <title>{pageTitles.resourceNew}</title>
      </Helmet>
      <Toolbar>
        <Container sx={{ display: 'flex' }}>
          <Breadcrumbs sx={{ flexGrow: 1 }}>
            <Link
              component={RouterLink}
              to={paths.resourcesPath()}
              color="text.secondary"
              underline="hover"
            >
              {pageTitles.resources}
            </Link>
            <Typography color="text.primary">新規登録</Typography>
          </Breadcrumbs>
        </Container>
      </Toolbar>
      <Container>
        <ResourceForm
          serviceCategories={masterData.serviceCategories}
          resourceKinds={masterData.resourceKinds}
          onSubmit={handleSubmit}
        />
      </Container>
    </>
  );
};

export default Page;
