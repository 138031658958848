import type { FC } from 'react';
import { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Breadcrumbs, Container, Toolbar, Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from 'urql';
import { addMilliseconds, endOfDay, isBefore, startOfDay } from 'date-fns';
import { useFragment } from 'graphql/generated/user';
import * as pageTitles from 'constants/pageTitles/staff';
import { AppointmentCalendar } from 'components/appointments/staff/AppointmentCalendar/AppointmentCalendar';
import { formatDate, parseDate } from 'helpers/date';
import FullScreenLoading from 'components/FullScreenLoading';
import NotFoundPage from 'pages/NotFoundPage';
import type {
  AppointmentsCalendarQuery,
  AppointmentsCalendarQueryVariables,
  AppointmentsFragment,
} from 'graphql/generated/staff/graphql';
import {
  AppointmentStatus,
  AppointmentsFragmentDoc,
  AppointmentsCalendarDocument,
} from 'graphql/generated/staff/graphql';
import {
  useEditAppointmentDialog,
  useNewAppointmentDialog,
} from 'components/dialogs/staff/AppointmentDialog';
import { allAppointmentStatuses } from 'constants/appointmentStatuses';
import { useConnectivity } from 'hooks/useConnectivity';

const Page: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [appointments, setAppointments] = useState<AppointmentsFragment['appointments']['nodes']>(
    []
  );
  const timeoutId = useRef<number | null>(null);
  const editAppointmentDialog = useEditAppointmentDialog({
    onSave: () => refetch,
  });
  const newAppointmentDialog = useNewAppointmentDialog({
    onSave: () => refetch,
  });
  const date = useMemo(
    () => parseDate(searchParams.get('date') || '') || new Date(),
    [searchParams]
  );
  const [offset, setOffset] = useState(0);
  const [fetchInitialzied, setFetchInitialized] = useState(false);
  const [updatedSince, setUpdatedSince] = useState<Date | null>(null);
  const isOnline = useConnectivity();

  useEffect(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
    setUpdatedSince(null);
    setOffset(0);
    setAppointments([]);
  }, [date]);

  const [{ data, fetching, error }, refetch] = useQuery<
    AppointmentsCalendarQuery,
    AppointmentsCalendarQueryVariables
  >({
    query: AppointmentsCalendarDocument,
    requestPolicy: 'network-only',
    pause: !isOnline,
    variables: {
      from: startOfDay(date).toISOString(),
      to: endOfDay(date).toISOString(),
      statuses: allAppointmentStatuses.filter((s) => s !== AppointmentStatus.Canceled),
      updatedSince: updatedSince?.toISOString(),
      offset,
    },
  });

  const resources = data?.resources;
  const slotDuration = data?.viewer.clinic?.slotDuration;
  const holidays = data?.viewer.clinic?.holidays ?? [];

  const scheduleRefetch = useCallback(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
    timeoutId.current = window.setTimeout(() => {
      refetch({ requestPolicy: 'network-only' });
    }, 10000);
  }, [refetch, isOnline]);

  useEffect(() => {
    const appointmentsData = useFragment(AppointmentsFragmentDoc, data);
    if (fetching || !appointmentsData) {
      return;
    }

    const ids = appointmentsData.appointmentTimestamps.map((a) => a.id);
    const appointmentNodes = appointmentsData.appointments.nodes;
    if (!appointmentNodes) {
      return;
    }

    let newAppointments = [...appointments];

    appointmentNodes.forEach((appointment) => {
      const index = newAppointments.findIndex((a) => a.id === appointment.id);
      if (index === -1) {
        newAppointments.push(appointment);
      } else {
        newAppointments[index] = appointment;
      }
    });

    newAppointments = newAppointments.filter((a) => ids.includes(a.id));
    setAppointments(newAppointments);
    if (appointmentNodes.length === 50) {
      setOffset(offset + 50);
      return;
    }
    setOffset(0);
    const last = appointments
      .map((a) => new Date(a.updatedAt))
      .sort((a, b) => (isBefore(b, a) ? -1 : 1))[0];
    setUpdatedSince(last ? addMilliseconds(last, 1) : null);
    scheduleRefetch();
    setFetchInitialized(true);
  }, [fetching, data]);

  useEffect(() => {
    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, []);

  if (!fetchInitialzied || (fetching && (!appointments || !resources))) {
    return <FullScreenLoading />;
  }

  if (error?.networkError) {
    throw error;
  }

  if (!appointments || !resources) {
    return <NotFoundPage />;
  }

  return (
    <Box>
      <Helmet>
        <title>{pageTitles.calendar}</title>
      </Helmet>
      <Toolbar data-online={isOnline}>
        <Container sx={{ display: 'flex' }}>
          <Breadcrumbs sx={{ flexGrow: 1 }}>
            <Typography color="text.primary">{pageTitles.calendar}</Typography>
          </Breadcrumbs>
        </Container>
      </Toolbar>
      <Container
        sx={{
          height: 'calc(100% - 64px)',
        }}
      >
        <AppointmentCalendar
          appointments={appointments}
          resources={resources}
          date={date}
          slotDuration={slotDuration ?? 15}
          onChangeDate={(d) => {
            setSearchParams({ date: formatDate(d) });
          }}
          onSelectAppointment={(a) => editAppointmentDialog.open(a.id)}
          onSelectSlot={(slotInfo) => {
            newAppointmentDialog.open({
              startAt: slotInfo.start,
              endAt: slotInfo.end,
              resourceId: String(slotInfo.resourceId),
            });
          }}
          holidays={holidays}
        />
      </Container>
      {editAppointmentDialog.render()}
      {newAppointmentDialog.render()}
    </Box>
  );
};

export default Page;
