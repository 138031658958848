import { useMemo } from 'react';
import { Client } from 'urql';
import { useUserGraphQLContext } from '../useUserGraphQLContext';
import type { FileType } from 'graphql/generated/user/graphql';
import { ViewerProfileImageUploadUrlDocument } from 'graphql/generated/user/graphql';

export const useUpdateUserProfileImage = () => {
  const context = useUserGraphQLContext();
  const client = useMemo(() => new Client(context), [context]);
  return async (profileImageFile: File) => {
    const { data } = await client
      .query(ViewerProfileImageUploadUrlDocument, {
        type: profileImageFile.type.replace('image/', '').toUpperCase() as FileType,
        fileName: profileImageFile.name,
      })
      .toPromise();

    if (!data) {
      throw new Error('画像の更新に失敗しました');
    }

    await fetch(data.viewer.profileImageUploadURL, {
      method: 'PUT',
      body: profileImageFile,
      headers: {
        'X-AMZ-ACL': 'public-read',
        'Content-Type': profileImageFile?.type,
      },
    });

    const profileImageURL = `${process.env.REACT_APP_S3_BUCKET}/users/${data.viewer.id}/profile-image/${profileImageFile.name}`;
    return profileImageURL;
  };
};
