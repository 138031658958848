import { Box, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { enqueueSnackbar } from 'notistack';
import type { FC } from 'react';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'urql';
import { Helmet } from 'react-helmet-async';
import FullScreenLoading from 'components/FullScreenLoading';
import { UserPetForm } from 'components/forms/user/UserPetForm';
import * as paths from 'constants/paths/user';
import type { CreatePetInput } from 'graphql/generated/user/graphql';
import { RegisterPetEditDocument } from 'graphql/generated/user/graphql';
import { useUpdatePet } from 'hooks/user/forms/useUpdatePet';
import { useRegisterCookie } from 'hooks/user/useRegisterCookie';
import * as pageTitles from 'constants/pageTitles/user';

const Page: FC = () => {
  useRegisterCookie(true);
  const navigate = useNavigate();
  const { petId = null } = useParams();
  const [{ data, fetching }] = useQuery({
    query: RegisterPetEditDocument,
    pause: !petId,
    variables: {
      petId: petId as string,
    },
  });

  const { updatePet, fetching: updating } = useUpdatePet();

  const onSubmit = useCallback(
    async (input: CreatePetInput & { id?: string }, profileImageFile?: File) => {
      const petId = input.id;
      if (!petId) {
        return;
      }
      try {
        const { error } = await updatePet({ id: petId, ...input }, profileImageFile);

        if (error?.message) {
          throw new Error(`ペット ${input.name}情報の更新に失敗しました。`);
        }

        enqueueSnackbar(`ペット ${input.name}の情報を更新しました`, { variant: 'success' });
        window.scrollTo(0, 0);
        navigate(paths.registerConfirmPath());
      } catch (e: any) {
        // eslint-disable-next-line no-console
        console.log(e);
        enqueueSnackbar(e.message, { variant: 'error' });
      }
    },
    [data?.viewer.pet]
  );

  if (!data || (petId && !data.viewer.pet)) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{pageTitles.registerPetEdit}</title>
      </Helmet>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={updating || fetching}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {!updating ? (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Typography variant="h5">ペット情報登録</Typography>
          <UserPetForm
            initialValues={
              {
                ...data?.viewer.pet,
                animalTypeId: data?.viewer.pet.animalType?.id,
                animalId: data?.viewer.pet.animal?.id,
              } ?? {}
            }
            animals={data.animals}
            onSubmit={onSubmit}
          />
          <Typography sx={{ fontSize: '10px' }}>
            ２頭目以降のペット情報はマイページからいつでも登録・編集が可能です
          </Typography>
        </Box>
      ) : (
        <FullScreenLoading />
      )}
    </>
  );
};

export default Page;
