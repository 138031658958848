import { Box, Typography } from '@mui/material';
import type { FC } from 'react';

const Page: FC = () => (
  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    <Typography variant="h1" component="h2">
      404 Not Found
    </Typography>
  </Box>
);

export default Page;
