import { useCallback, useState } from 'react';
import { Calendar } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import styled from 'styled-components';
import type { OnArgs, Value } from 'react-calendar/dist/cjs/shared/types';
import { TitleContent } from './TitleContent';
import type { DayOfWeek } from 'graphql/generated/staff/graphql';

interface Props {
  activeStartDate: Date;
  clinicHolidays: DayOfWeek[]; // 曜日
  staffHolidays: Date[]; // 日付
  onChangeMonth?: (date: Date) => void;
  onClickDay?: (date: Date) => void;
}

export const StaffHolidayCalendar = (props: Props) => {
  const [value, onChange] = useState<Date | null>(new Date());
  const [startDate, setStartDate] = useState<Date>(props.activeStartDate);

  const onChangeMonth = useCallback(
    ({ action, activeStartDate }: OnArgs) => {
      if (['prev', 'next', 'prev2', 'next2', 'drillDown'].includes(action)) {
        if (activeStartDate) {
          setStartDate(activeStartDate);
          if (props.onChangeMonth) {
            props.onChangeMonth(activeStartDate);
          }
        }
      }
    },
    [props.onChangeMonth, setStartDate]
  );

  const onChangeValue = useCallback(
    (value: Value) => {
      if (value && !Array.isArray(value)) {
        onChange(value);
      }
    },
    [onChange]
  );

  return (
    <StyledStaffCalendar>
      <Calendar
        calendarType="US"
        maxDetail="month"
        minDetail="month"
        locale="ja"
        onClickDay={props.onClickDay}
        onChange={onChangeValue}
        value={value}
        tileContent={(calendarTileProperties) => (
          <TitleContent
            clinicHolidays={props.clinicHolidays}
            staffHolidays={props.staffHolidays}
            {...calendarTileProperties}
          />
        )}
        activeStartDate={startDate}
        onActiveStartDateChange={onChangeMonth}
        formatDay={() => ''}
      />
    </StyledStaffCalendar>
  );
};

const StyledStaffCalendar = styled.div`
  .react-calendar {
    width: 100%;
    border: 0;
  }
  button {
    margin: 3px;
    background-color: white;
    color: black;
    border: 0;
    padding: 0 !important;
    margin: 0 !important;

    &:hover {
      background-color: #556b55;
    }

    &:active {
      background-color: #a5c1a5;
    }
  }
`;
