import styled from '@emotion/styled';
import ErrorIcon from '@mui/icons-material/Error';
import {
  Avatar,
  Box,
  Button,
  Chip,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import type { FC } from 'react';
import { Fragment } from 'react';
import type { UserDetailQuery } from 'graphql/generated/staff/graphql';

interface Props {
  clinicUser: UserDetailQuery['clinicUser'];
  onEdit: () => void;
}

function convertNewlinesToBreaks(text?: string) {
  if (!text) {
    return null;
  }

  return text.split('\n').map((line, index) => {
    return (
      <Fragment key={index}>
        {line}
        {index < text.split('\n').length - 1 && <br />}
      </Fragment>
    );
  });
}

export const Component: FC<Props> = (props: Props) => {
  const clinicUser = props.clinicUser;
  const user = clinicUser?.user;
  return (
    <>
      <Grid container spacing={12}>
        <Grid item xs={4} mt={2}>
          <Stack gap={2}>
            <Box position="relative" display="flex" justifyContent="center" pb={5}>
              {clinicUser?.marked && (
                <Box position="absolute" top={0} left={1} zIndex={1}>
                  <Chip icon={<ErrorIcon />} color="error" label="要注意" size="small" />
                </Box>
              )}
              <Avatar src={user?.profileImageURL ?? ''} sx={{ width: 200, height: 200 }} />
            </Box>
            <Button
              variant="outlined"
              color="secondary"
              size="large"
              fullWidth
              onClick={props.onEdit}
            >
              飼い主情報編集
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={8}>
          <TableContainer>
            <Table>
              <TableBody>
                {[
                  ['診察券番号', clinicUser?.patientNumber],
                  ['名前', user?.name],
                  ['電話番号', user?.phoneNumber],
                  ['Eメール', user?.email],
                  [
                    '住所',
                    `${user?.prefecture?.name}${user?.city}${user?.street} ${user?.building}`,
                  ],
                  [
                    '動物病院メモ',
                    <Box key="pre">{convertNewlinesToBreaks(clinicUser?.note)}</Box>,
                  ],
                ].map(([label, value]) => (
                  <TableRow key={`${label}-${value}`}>
                    <Th component="th" align="right" width={100}>
                      {label}
                    </Th>
                    <TableCell>{value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};

const Th = styled(TableCell)`
  width: 10em;
  color: #aaa;
  font-weight: bold;
`;
