import type { FC } from 'react';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { RegistrationComplete } from 'components/forms/user/RegistrationComplete/RegistrationComplete';
import { useRegisterCookie } from 'hooks/user/useRegisterCookie';
import * as pageTitles from 'constants/pageTitles/user';

const Page: FC = () => {
  const { clinicSlug } = useParams();
  const { setComplete } = useRegisterCookie(true);

  useEffect(() => {
    setComplete();
  }, []);

  return (
    <>
      <Helmet>
        <title>{pageTitles.registerComplete}</title>
      </Helmet>
      <RegistrationComplete clinic={clinicSlug} />
    </>
  );
};

export default Page;
