import { enqueueSnackbar } from 'notistack';
import type { FC } from 'react';
import { useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'urql';
import FullScreenLoading from 'components/FullScreenLoading';
import { AppointmentForm } from 'components/appointments/user/AppointmentForm/AppointmentForm';
import * as pageTitles from 'constants/pageTitles/user';
import * as paths from 'constants/paths/user';
import type { CreateAppointmentInput } from 'graphql/generated/user/graphql';
import {
  AppointmentFormOptionsDocument,
  CreateAppointmentDocument,
} from 'graphql/generated/user/graphql';

const Page: FC = () => {
  const navigate = useNavigate();
  const { clinicSlug } = useParams();
  const [{ data, fetching }] = useQuery({
    query: AppointmentFormOptionsDocument,
    variables: {
      slug: clinicSlug as string,
    },
    pause: !clinicSlug,
  });
  const [_createAppointmentResult, createAppointment] = useMutation(CreateAppointmentDocument);

  const onEditPet = useCallback(() => {
    navigate(paths.clinicPetsPath(clinicSlug));
  }, [navigate, clinicSlug]);

  const onNewPet = useCallback(() => {
    navigate(paths.clinicPetNewPath(clinicSlug));
  }, [navigate]);

  const onFinish = useCallback(() => {
    navigate(paths.clinicRootPath(clinicSlug));
  }, [navigate]);

  const onSubmit = useCallback(
    async (input: CreateAppointmentInput, onNext: () => void) => {
      try {
        const res = await createAppointment({ input });
        if (res.error) {
          throw new Error('予約の受付に失敗しました');
        }
        if (res.data?.createAppointment.id) {
          enqueueSnackbar('予約を受け付けました', { variant: 'success' });
          onNext();
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
        enqueueSnackbar('予約の受付に失敗しました', { variant: 'error' });
      }
    },
    [createAppointment]
  );

  if (!data || fetching) {
    return <FullScreenLoading />;
  }

  if (!data.clinic) {
    throw Error('クリニック情報が空です');
  }

  return (
    <>
      <Helmet>
        <title>{pageTitles.newAppointment}</title>
      </Helmet>
      <AppointmentForm
        clinic={data.clinic}
        viewer={data.viewer}
        onSubmit={onSubmit}
        onEditPet={onEditPet}
        onNewPet={onNewPet}
        onFinish={onFinish}
      />
    </>
  );
};

export default Page;
