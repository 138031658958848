import type { RouteObject } from 'react-router-dom';
import { UserLayout } from 'components/layouts/user/UserLayout';
import * as paths from 'constants/paths/user';
import NotFoundPage from 'pages/NotFoundPage';
import {
  AppointmentDetailPage,
  AppointmentEditPage,
  AppointmentsPage,
  ClinicAppointmentEditPage,
  ClinicAppointmentNewPage,
  ClinicAppointmentsPage,
  ClinicLoginPage,
  ClinicOwnerEditPage,
  ClinicOwnerPage,
  ClinicPetDetailPage,
  ClinicPetEditPage,
  ClinicPetNewPage,
  ClinicPetsPage,
  ClinicRegisterPage,
  ClinicRootPage,
  ConfirmEmailPage,
  ConfirmPasswordResetPage,
  LoginPage,
  OwnerEditPage,
  OwnerPage,
  PasswordResetPage,
  PetDetailPage,
  PetEditPage,
  PetNewPage,
  PetsPage,
  RegisterCompletePage,
  RegisterConfirmPage,
  RegisterPage,
  RegisterPetEditPage,
  RegisterPetNewPage,
  RegisterUserEditPage,
  RootPage,
  SendPasswordResetMailPage,
  SuccessPasswordResetPage,
  ClinicSendPasswordResetMailPage,
  ClinicPasswordResetPage,
  ClinicConfirmEmailPage,
  ClinicRegisterCompletePage,
  ClinicRegisterConfirmPage,
  ClinicRegisterPetEditPage,
  ClinicRegisterPetNewPage,
  ClinicRegisterUserEditPage,
} from 'pages/user';
import LegacyUserRedirect from 'components/redirects/LegacyUserRedirect';

const routes: RouteObject[] = [
  {
    path: paths.rootPath(),
    element: <UserLayout auth={false} />,
    errorElement: <NotFoundPage />,
    children: [
      {
        path: paths.legacyClinicUserPath('login', ':clinicSlug'),
        element: <LegacyUserRedirect path="login" />,
      },
      {
        path: paths.legacyClinicUserPath('register', ':clinicSlug'),
        element: <LegacyUserRedirect path="register" />,
      },
      {
        path: paths.loginPath(),
        element: <LoginPage />,
      },
      {
        path: paths.clinicLoginPath(':clinicSlug'),
        element: <ClinicLoginPage />,
      },
      {
        path: paths.registerUserEditPath(),
        element: <RegisterUserEditPage />,
      },
      {
        path: paths.registerPetNewPath(),
        element: <RegisterPetNewPage />,
      },
      {
        path: paths.registerPetDetailPath(':petId'),
        element: <RegisterPetEditPage />,
      },
      {
        path: paths.registerCompletePath(),
        element: <RegisterCompletePage />,
      },
      {
        path: paths.registerConfirmPath(),
        element: <RegisterConfirmPage />,
      },
      {
        path: paths.confirmPasswordResetPath(),
        element: <ConfirmPasswordResetPage />,
      },
      {
        path: paths.successConfirmPasswordResetPath(),
        element: <SuccessPasswordResetPage />,
      },
      {
        path: paths.passwordResetPath(),
        element: <PasswordResetPage />,
      },
      {
        path: paths.clinicPasswordResetPath(':clinicSlug'),
        element: <ClinicPasswordResetPage />,
      },
      {
        path: paths.sendPasswordResetMailPath(),
        element: <SendPasswordResetMailPage />,
      },
      {
        path: paths.clinicSendPasswordResetMailPath(':clinicSlug'),
        element: <ClinicSendPasswordResetMailPage />,
      },
      {
        path: paths.registerPath(),
        element: <RegisterPage />,
      },
      {
        path: paths.registerClinicPath(':clinicSlug'),
        element: <ClinicRegisterPage />,
      },
      {
        path: paths.confirmEmailPath(),
        element: <ConfirmEmailPage />,
      },
      {
        path: paths.clinicConfirmEmailPath(':clinicSlug'),
        element: <ClinicConfirmEmailPage />,
      },
      {
        path: '*',
        element: <NotFoundPage />,
      },
    ],
  },
  {
    path: paths.rootPath(),
    element: <UserLayout auth={true} />,
    children: [
      {
        path: paths.rootPath(),
        element: <RootPage />,
        index: true,
      },
      {
        path: paths.ownerPath(),
        element: <OwnerPage />,
      },
      {
        path: paths.ownerEditPath(),
        element: <OwnerEditPage />,
      },
      {
        path: paths.petsPath(),
        element: <PetsPage />,
      },
      {
        path: paths.petNewPath(),
        element: <PetNewPage />,
      },
      {
        path: paths.petEditPath(':petId'),
        element: <PetEditPage />,
      },
      {
        path: paths.petDetailPath(':petId'),
        element: <PetDetailPage />,
      },
      {
        path: paths.appointmentsPath(),
        element: <AppointmentsPage />,
      },
      {
        path: paths.appointmentDetailPath(':appointmentId'),
        element: <AppointmentDetailPage />,
      },
      {
        path: paths.appointmentEditPath(':appointmentId'),
        element: <AppointmentEditPage />,
      },
      {
        path: '*',
        element: <NotFoundPage />,
      },
    ],
  },
  {
    path: paths.clinicRootPath(':clinicSlug'),
    element: <UserLayout auth={true} />,
    children: [
      {
        path: paths.clinicRootPath(':clinicSlug'),
        element: <ClinicRootPage />,
        index: true,
      },

      {
        path: paths.clinicRegisterCompletePath(':clinicSlug'),
        element: <ClinicRegisterCompletePage />,
      },
      {
        path: paths.clinicRegisterConfirmPath(':clinicSlug'),
        element: <ClinicRegisterConfirmPage />,
      },
      {
        path: paths.clinicRegisterPetNewPath(':clinicSlug'),
        element: <ClinicRegisterPetNewPage />,
      },
      {
        path: paths.clinicRegisterPetDetailPath(':clinicSlug', ':petId'),
        element: <ClinicRegisterPetEditPage />,
      },
      {
        path: paths.clinicRegisterUserEditPath(':clinicSlug'),
        element: <ClinicRegisterUserEditPage />,
      },
      {
        path: paths.clinicPetsPath(':clinicSlug'),
        element: <ClinicPetsPage />,
      },
      {
        path: paths.clinicPetNewPath(':clinicSlug'),
        element: <ClinicPetNewPage />,
      },
      {
        path: paths.clinicPetDetailPath(':clinicSlug', ':petId'),
        element: <ClinicPetDetailPage />,
      },
      {
        path: paths.petEditPath(':clinicSlug', ':petId'),
        element: <ClinicPetEditPage />,
      },
      {
        path: paths.clinicOwnerPath(':clinicSlug'),
        element: <ClinicOwnerPage />,
      },
      // {
      //   path: paths.cliniower 'owners/:ownerId/info',
      //   element: <UserClinicOwnerInfoIndex />,
      // },
      {
        path: paths.clinicOwnerEditPath(':clinicSlug'),
        element: <ClinicOwnerEditPage />,
      },
      {
        path: paths.clinicAppointmentsPath(':clinicSlug'),
        element: <ClinicAppointmentsPage />,
      },
      {
        path: paths.clinicAppointmentNewPath(':clinicSlug'),
        element: <ClinicAppointmentNewPage />,
      },
      {
        path: paths.clinicAppointmentDetailPath(':clinicSlug', ':appointmentId'),
        element: <AppointmentDetailPage />,
      },
      {
        path: paths.clinicAppointmentEditPath(':clinicSlug', ':appointmentId'),
        element: <ClinicAppointmentEditPage />,
      },
      {
        path: '*',
        element: <NotFoundPage />,
      },
    ],
  },
];

export default routes;
