import type { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { SendPasswordResetMail } from 'components/forms/user/SendPasswordResetMail/SendPasswordResetMail';
import * as pageTitles from 'constants/pageTitles/user';

const Page: FC = () => {
  const { clinicSlug } = useParams();
  return (
    <>
      <Helmet>
        <title>{pageTitles.sendPasswordResetEmail}</title>
      </Helmet>
      <SendPasswordResetMail clinicSlug={clinicSlug} />
    </>
  );
};

export default Page;
