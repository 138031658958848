import type { ComponentProps } from 'react';
import type { Helmet } from 'react-helmet-async';

type Props = ComponentProps<typeof Helmet>;

const baseProps: Props = {};

export const staff: Props = {
  ...baseProps,
  titleTemplate: '%s | アニプロ予約',
  defaultTitle: 'アニプロ予約',
};

export const user: Props = {
  ...baseProps,
};
