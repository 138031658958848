import { addDays, endOfDay, startOfDay } from 'date-fns';
import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Client, Provider, useQuery } from 'urql';
import { Alert, CssBaseline, Snackbar, ThemeProvider } from '@mui/material';
import StaffMainLayout from './StaffMainLayout';
import StaffUnauthenticatedLayout from './StaffUnauthenticatedLayout';
import { useStaffGraphQLContext } from 'hooks/staff/useStaffGraphQLContext';
import { useCurrentStaff } from 'hooks/staff/useCurrentStaff';
import { AppointmentCountDocument } from 'graphql/generated/staff/graphql';
import type { AppointmentCountQuery } from 'graphql/generated/staff/graphql';
import * as paths from 'constants/paths/staff';
import config from 'config';
import FullScreenLoading from 'components/FullScreenLoading';
import theme from 'styles/theme';
import { useConnectivity } from 'hooks/useConnectivity';

interface Props {
  auth: boolean;
}

const Content: FC<Props> = ({ auth }) => {
  const location = useLocation();
  const { initialized, loggedIn, data: currentStaff } = useCurrentStaff();
  const navigate = useNavigate();
  const [today, setToday] = useState<Date>(new Date());
  const timeoutId = useRef<number | null>(null);

  const isOnline = useConnectivity();

  const refetchTimeout = useCallback(() => {
    if (isOnline) {
      refetchCount({
        requestPolicy: 'network-only',
        variables: {
          todayFrom: startOfDay(today).toISOString(),
          todayTo: endOfDay(today).toISOString(),
          tomorrowFrom: startOfDay(addDays(today, 1)).toISOString(),
          tomorrowTo: endOfDay(addDays(today, 1)).toISOString(),
        },
      });
    }
  }, [isOnline, today]);

  const loadCount = useCallback(() => {
    if (timeoutId.current) {
      window.clearTimeout(timeoutId.current);
    }
    timeoutId.current = window.setTimeout(() => {
      const today = new Date();
      setToday(today);
      refetchTimeout();
    }, 10000);
  }, [isOnline, today]);

  const [{ data: appointmentCount, fetching: fetchingCount }, refetchCount] =
    useQuery<AppointmentCountQuery>({
      query: AppointmentCountDocument,
      pause: !isOnline || !auth || !currentStaff || !initialized,
      requestPolicy: 'network-only',
      variables: {
        todayFrom: startOfDay(today).toISOString(),
        todayTo: endOfDay(today).toISOString(),
        tomorrowFrom: startOfDay(addDays(today, 1)).toISOString(),
        tomorrowTo: endOfDay(addDays(today, 1)).toISOString(),
      },
    });

  useEffect(() => {
    return () => {
      if (timeoutId.current) {
        window.clearTimeout(timeoutId.current);
      }
    };
  }, []);

  useEffect(() => {
    if (fetchingCount) return;
    if (!appointmentCount) return;
    loadCount();
  }, [fetchingCount, appointmentCount]);

  useEffect(() => {
    if (!initialized) return;
    if (auth) {
      if (loggedIn) return;
      navigate(`${paths.loginPath()}?redirect=${location.pathname}`);
      return;
    }
    if (!loggedIn) return;
    navigate(paths.rootPath());
  }, [initialized, loggedIn, auth]);

  if (!initialized) {
    return <FullScreenLoading />;
  }

  if (!config.endpoints.staffGraphQL) {
    return <div>Missing staffGraphQL endpoint</div>;
  }

  if (auth && currentStaff) {
    return (
      <>
        <Helmet {...config.helmet.staff} />
        <StaffMainLayout
          currentStaff={currentStaff}
          appointmentCount={
            appointmentCount
              ? {
                  today: appointmentCount?.todayApppointmentCount.totalCount ?? 0,
                  tomorrow: appointmentCount?.tomorrowApppointmentCount.totalCount ?? 0,
                }
              : null
          }
        >
          <Outlet />
          <Snackbar open={!isOnline}>
            <Alert severity="warning">インターネットに接続されていません</Alert>
          </Snackbar>
        </StaffMainLayout>
      </>
    );
  }
  return (
    <StaffUnauthenticatedLayout>
      <Outlet />
    </StaffUnauthenticatedLayout>
  );
};

const StaffLayout: FC<Props> = ({ auth }) => {
  const context = useStaffGraphQLContext();
  const client = useMemo(() => new Client(context), [context]);

  return (
    <Provider value={client}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Content auth={auth} />
      </ThemeProvider>
    </Provider>
  );
};

export default StaffLayout;
