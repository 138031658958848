import type { HTTPMethod } from './HTTPMethod';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const post = <T>(path: string, body?: any): Promise<T> =>
  fetchAPI<T>(path, 'POST', body) as Promise<T>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
// export const put = <T>(path: string, body: any): Promise<T> =>
//   fetchAPI<T>(path, 'PUT', body) as Promise<T>;
// export const get = <T>(path: string): Promise<T> => fetchAPI<T>(path, 'GET') as Promise<T>;
// export const del = <T>(path: string): Promise<void> => fetchAPI<T>(path, 'DELETE') as Promise<void>;

const fetchAPI = async <T>(
  path: string,
  method?: HTTPMethod,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body?: any
): Promise<T | undefined> => {
  const res = await fetch(path, {
    method,
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
  });
  if (res.status === 204) {
    return;
  }
  const data = await res.json();
  return data as T;
};
