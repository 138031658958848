import { enqueueSnackbar } from 'notistack';
import type { FC } from 'react';
import { useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'urql';
import FullScreenLoading from 'components/FullScreenLoading';
import type { LoginFormInput } from 'components/forms/user/LoginForm/hooks/useLoginForm';
import { RegisterForm } from 'components/forms/user/RegisterForm/RegisterForm';
import * as pageTitles from 'constants/pageTitles/user';
import * as paths from 'constants/paths/user';
import type { SignUpWithEmailMutation } from 'graphql/generated/user/graphql';
import {
  ClinicForLoginDocument,
  LatestAppAgreementDocument,
  SignUpWithEmailDocument,
} from 'graphql/generated/user/graphql';

const Page: FC = () => {
  const navigate = useNavigate();
  const { clinicSlug } = useParams();
  const [{ data: agreement }] = useQuery({
    query: LatestAppAgreementDocument,
  });
  const [{ data: clinic }] = useQuery({
    query: ClinicForLoginDocument,
    pause: !clinicSlug,
    variables: {
      slug: clinicSlug as string,
    },
  });
  const [signUpResult, signUpWithEmail] =
    useMutation<SignUpWithEmailMutation>(SignUpWithEmailDocument);

  const onSubmit = useCallback(
    async (body: LoginFormInput) => {
      try {
        const { data, error } = await signUpWithEmail({
          input: {
            ...body,
            appAgreementID: agreement?.latestAppAgreement.id,
            clinicSlug: clinicSlug,
          },
        });
        if (data?.signUpWithEmail?.success) {
          navigate(paths.clinicConfirmEmailPath(clinicSlug), {
            state: { email: body?.email },
          });
        } else if (error?.message.match('email already used')) {
          throw new Error('すでに登録済みメールアドレスです');
        } else {
          throw new Error('ログインに失敗しました');
        }
      } catch (e: any) {
        enqueueSnackbar(e.message, { variant: 'error' });
        // eslint-disable-next-line no-console
        console.error(e);
      }
    },
    [agreement, clinicSlug]
  );

  if (!agreement || !clinic?.clinic) {
    return <FullScreenLoading />;
  }

  return (
    <>
      <Helmet>
        <title>{pageTitles.register}</title>
      </Helmet>
      <RegisterForm
        onSubmit={onSubmit}
        clinic={clinic.clinic}
        agreement={agreement.latestAppAgreement}
        fetching={signUpResult.fetching}
      />
    </>
  );
};

export default Page;
