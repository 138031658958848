import { useCallback, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

type SetValue = (value: string | null) => void;

export const useCookie = <T = string>(name: string): [T | null, SetValue] => {
  const [cookies, setCoookie, removeCookie] = useCookies([name]);

  const [value, setMemoryValue] = useState<T | null>(cookies[name] || null);
  const cookieOpts = { path: '/' };

  const setValue: SetValue = useCallback(
    (value) => {
      if (value === null) {
        removeCookie(name, cookieOpts);
        return;
      }
      setCoookie(name, value, cookieOpts);
    },
    [name, setCoookie, removeCookie]
  );

  useEffect(() => {
    setMemoryValue(cookies[name] || null);
  }, [cookies[name]]);

  return [value, setValue];
};
