import { Box, Button, Typography } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Logo from 'graphics/Logo';

interface Props {
  clinicName?: string;
  onClickLogo: () => void;
}

export const UserAppBar = (props: Props) => {
  return (
    <AppBar position="fixed">
      <Container maxWidth="xs">
        <Toolbar disableGutters>
          <Box width="100%" display="flex" alignItems="center">
            <Box width={155} height={40} component={Button} onClick={props.onClickLogo}>
              <Logo height={40} color="white" />
            </Box>
            <Typography variant="body2" ml={0.5} position="relative" top={1}>
              {props.clinicName}
            </Typography>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
