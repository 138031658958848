import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  FormControl,
  FormHelperText,
  Stack,
  TextField,
} from '@mui/material';
import { useCallback, useState } from 'react';
import { UploadImageButton } from '../UploadImageButton/UploadImageButton';
import { useUserForm } from './hooks/useUserForm';
import type { UpdateViewerInput, UserFormQuery } from 'graphql/generated/user/graphql';

interface Props {
  user: UserFormQuery['viewer'];
  prefectures: UserFormQuery['prefectures'];
  frequencies: UserFormQuery['frequencies'];
  fetching: boolean;
  onSubmit: (data: UpdateViewerInput, profileImageFile?: File) => void;
  onCancel?: () => void;
}

export const UserForm = (props: Props) => {
  const { user: viewer } = props;
  const [imageData, setImageData] = useState<{ url: string; file: File } | null>(null);

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useUserForm({
    defaultValues: {
      profileImageURL: viewer.profileImageURL ?? '',
      name: viewer.name ?? '',
      phoneNumber: viewer.phoneNumber ?? '',
      zipCode: viewer.zipCode ?? '',
      prefectureId: viewer.prefecture?.id ?? '',
      city: viewer.city ?? '',
      street: viewer.street ?? '',
      building: viewer.building ?? '',
      visitingFrequencyId: viewer.visitingFrequency?.id ?? '',
      trimmingFrequencyId: viewer.trimmingFrequency?.id ?? '',
    },
  });

  const onSelectProfileImage = useCallback(
    (file: File, imageUrl: string) => {
      setImageData({ url: imageUrl, file: file });
    },
    [setImageData]
  );

  const onChangeVisitingFrequency = useCallback(
    (e: React.SyntheticEvent<Element, Event>, frequency: { id: string; name: string } | null) => {
      setValue('visitingFrequencyId', frequency?.id ?? '');
    },
    [setValue]
  );

  const onChangeTrimmingFrequency = useCallback(
    (e: React.SyntheticEvent<Element, Event>, frequency: { id: string; name: string } | null) => {
      setValue('trimmingFrequencyId', frequency?.id ?? '');
    },
    [setValue]
  );

  const onChangePrefecture = useCallback(
    (e: React.SyntheticEvent<Element, Event>, prefecture: { id: string; name: string } | null) => {
      setValue('prefectureId', prefecture?.id ?? '');
    },
    [setValue]
  );

  const onSubmit = useCallback(
    (input: UpdateViewerInput) => {
      if (!input.profileImageURL) {
        delete input.profileImageURL;
      }

      if (!input.trimmingFrequencyId) {
        delete input.trimmingFrequencyId;
      }

      if (!input.visitingFrequencyId) {
        delete input.visitingFrequencyId;
      }

      props.onSubmit(input, imageData?.file);
    },
    [props.onSubmit, imageData]
  );

  return (
    <Box onSubmit={handleSubmit(onSubmit)} component="form" width="100%">
      <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" my={4}>
        <Box mb={2}>
          <Avatar
            src={imageData?.url || props.user.profileImageURL || ''}
            sx={{
              textAlign: 'center',
              width: 100,
              height: 100,
            }}
          />
        </Box>
        <UploadImageButton onSelect={onSelectProfileImage} />
      </Box>

      <Stack spacing={3} mt={4} mb={4}>
        <TextField
          label="氏名"
          required
          fullWidth
          error={'name' in errors}
          helperText={errors.name?.message as string}
          {...register('name')}
        />

        <TextField
          label="電話番号"
          required
          fullWidth
          error={'phoneNumber' in errors}
          helperText={errors.phoneNumber?.message as string}
          {...register('phoneNumber')}
        />

        <TextField label="メールアドレス" defaultValue={viewer.email} required fullWidth disabled />

        <TextField
          label="郵便番号"
          required
          fullWidth
          error={'zipCode' in errors}
          helperText={errors.zipCode?.message as string}
          {...register('zipCode')}
        />

        <FormControl sx={{ width: '100%' }}>
          <Autocomplete
            renderInput={(params) => <TextField {...params} label="都道府県" />}
            options={props.prefectures}
            getOptionLabel={(option) => option.name}
            defaultValue={props.prefectures.find((f) => f.id === viewer?.prefecture?.id)}
            onChange={onChangePrefecture}
          />
          {'prefectureId' in errors && (
            <FormHelperText error color="danger">
              {errors.prefectureId?.message as string}
            </FormHelperText>
          )}
        </FormControl>

        <TextField
          label="市区町村"
          required
          fullWidth
          error={'city' in errors}
          helperText={errors.city?.message as string}
          {...register('city')}
        />

        <TextField
          label="番地"
          required
          fullWidth
          error={'street' in errors}
          helperText={errors.street?.message as string}
          {...register('street')}
        />

        <TextField
          label="建物名・部屋番号"
          fullWidth
          error={'building' in errors}
          helperText={errors.building?.message as string}
          {...register('building')}
        />

        <FormControl sx={{ width: '100%' }}>
          <Autocomplete
            renderInput={(params) => (
              <TextField {...params} label="どれぐらいの頻度で動物病院を利用しますか" />
            )}
            options={props.frequencies}
            getOptionLabel={(option) => option.name}
            defaultValue={props.frequencies.find((f) => f.id === viewer?.visitingFrequency?.id)}
            onChange={onChangeVisitingFrequency}
          />
          {'visitingFrequencyId' in errors && (
            <FormHelperText error color="danger">
              {errors.visitingFrequencyId?.message as string}
            </FormHelperText>
          )}
        </FormControl>

        <FormControl sx={{ width: '100%' }}>
          <Autocomplete
            renderInput={(params) => (
              <TextField
                {...params}
                label="どれぐらいの頻度でお店・動物病院のカット・シャンプーなどを利用しますか"
              />
            )}
            options={props.frequencies}
            getOptionLabel={(option) => option.name}
            defaultValue={props.frequencies.find((f) => f.id === viewer?.trimmingFrequency?.id)}
            onChange={onChangeTrimmingFrequency}
          />
          {'trimmingFrequencyId' in errors && (
            <FormHelperText error color="danger">
              {errors.trimmingFrequencyId?.message as string}
            </FormHelperText>
          )}
        </FormControl>
      </Stack>

      <Box>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={props.fetching}
        >
          保存
        </Button>
      </Box>

      {props.onCancel && (
        <Box my={4} textAlign="center">
          <Button onClick={props.onCancel} variant="contained" color="secondary">
            保存せずに戻る
          </Button>
        </Box>
      )}
    </Box>
  );
};
