import type { FC } from 'react';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'urql';
import { enqueueSnackbar } from 'notistack';
import { Box, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Helmet } from 'react-helmet-async';
import { RegisterUserDetailDocument, UpdateViewerDocument } from 'graphql/generated/user/graphql';
import * as paths from 'constants/paths/user';
import { UserForm } from 'components/forms/user/UserForm';
import FullScreenLoading from 'components/FullScreenLoading';
import type { UpdateViewerInput } from 'graphql/generated/user/graphql';
import { useRegisterCookie } from 'hooks/user/useRegisterCookie';
import { useUpdateUserProfileImage } from 'hooks/user/forms/useUpdateUserProfileImage';
import * as pageTitles from 'constants/pageTitles/user';

const Page: FC = () => {
  useRegisterCookie(true);
  const navigate = useNavigate();
  const { clinicSlug } = useParams();
  const updateProfileImage = useUpdateUserProfileImage();
  const [updateResult, update] = useMutation(UpdateViewerDocument);

  const [{ data }] = useQuery({
    query: RegisterUserDetailDocument,
  });

  const onSubmit = useCallback(
    async (input: UpdateViewerInput, profileImageFile?: File) => {
      try {
        if (profileImageFile) {
          input.profileImageURL = await updateProfileImage(profileImageFile);
        }
        const res = await update({ input });
        if (res.error) {
          throw new Error('飼い主情報の更新に失敗しました');
        }
        enqueueSnackbar('飼い主情報を更新しました', { variant: 'success' });
        if (data && data.viewer.pets.length > 0) {
          navigate(paths.clinicRegisterConfirmPath(clinicSlug));
        } else {
          navigate(paths.clinicRegisterPetNewPath(clinicSlug));
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        enqueueSnackbar('飼い主情報の更新に失敗しました', { variant: 'error' });
      }
    },
    [data?.viewer]
  );

  if (!data) {
    return <FullScreenLoading />;
  }

  if (data?.viewer.acceptedAppAgreement?.acceptedAt) {
    navigate(paths.registerConfirmPath());
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{pageTitles.registerUserEdit}</title>
      </Helmet>
      <Box>
        <Typography variant="h5" textAlign="center">
          飼い主様情報登録
        </Typography>
        <Typography variant="body2" my={2} p={1} bgcolor={grey[200]}>
          病院からの連絡や健康診断結果の送付などで使用するため情報は正確に入力をお願いします
        </Typography>
      </Box>
      <UserForm
        fetching={updateResult.fetching}
        user={data.viewer}
        prefectures={data.prefectures}
        frequencies={data.frequencies}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default Page;
