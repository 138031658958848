import { Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
// eslint-disable-next-line import/no-duplicates
import { addDays, addMinutes, eachDayOfInterval, format, isBefore, set } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import ja from 'date-fns/locale/ja';
import { useState } from 'react';
import { ScheduleCell } from './ScheduleCell';
import { TimeCell } from './TimeCell';

interface WeeklyTimeTableProps {
  minTime: number; // 0-23
  maxTime: number; // 0-24
  startDate: Date;
  slotDuration?: number | null; // 1コマの長さ(分)
  checkAvailable: (date: Date) => boolean;
  onSelectDate: (date: Date) => void;
}

const formatTimeTable = (date: Date) => {
  try {
    return format(date, 'HH:mm');
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return false;
  }
};

// 与えられた日付の、minTimeからmaxTimeまでの間の15分ごとの時間を返す
const createIntervalTimes = (
  date: Date,
  minTime: number,
  maxTime: number,
  slotDuration?: number | null
) => {
  const intervals: Date[] = [];
  const startTime = set(date, {
    hours: minTime,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });
  const endTime = set(date, {
    hours: maxTime,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });

  let currentTime = startTime;

  while (isBefore(currentTime, endTime)) {
    intervals.push(currentTime);
    currentTime = addMinutes(currentTime, slotDuration ?? 15);
  }

  return intervals;
};

// 与えられた日時から1週間分の、minTimeからmaxTimeまでの間の1コマごとの時間を返す
const createWeekSet = (
  startDate: Date,
  minTime: number,
  maxTime: number,
  slotDuration?: number | null
) => {
  const week = eachDayOfInterval({
    start: startDate,
    end: addDays(startDate, 6),
  });
  return week.map((current) => createIntervalTimes(current, minTime, maxTime, slotDuration));
};

export const WeeklyTimeTable = (props: WeeklyTimeTableProps) => {
  const intervalTimeEachDays = createWeekSet(
    props.startDate,
    props.minTime,
    props.maxTime,
    props.slotDuration
  );
  const dayCellCount = intervalTimeEachDays.length; // テーブルの横方向のセル数
  const timeCellCount = intervalTimeEachDays[0].length; // テーブルの縦方向のセル数
  const [hoverCell, setHoverCell] = useState<[number, number]>([-1, -1]);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <ScheduleCell
              sx={{ position: 'sticky', left: 0, bgcolor: 'white', zIndex: 10 }}
              isHover={false}
            >
              時間
            </ScheduleCell>
            {intervalTimeEachDays.map((dates, index) => (
              <ScheduleCell
                key={index}
                component="th"
                scope="row"
                isHover={hoverCell[0] === index}
                isAvailable={true}
              >
                {format(dates[0], 'MM/dd (E)', { locale: ja })}
              </ScheduleCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {[...Array(timeCellCount)].map((_, rowIndex) => (
            <TableRow key={rowIndex} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <ScheduleCell
                component="th"
                scope="row"
                isHover={hoverCell[1] === rowIndex}
                isAvailable={true}
                sx={{ position: 'sticky', left: 0, zIndex: 10, px: 1 }}
              >
                {formatTimeTable(intervalTimeEachDays[0][rowIndex])}
              </ScheduleCell>
              {[...Array(dayCellCount)].map((_, colIndex) => {
                const date = intervalTimeEachDays[colIndex][rowIndex];
                const isAvailable = props.checkAvailable(date);
                return (
                  <TimeCell
                    key={`${colIndex}-${rowIndex}`}
                    date={date}
                    colIndex={colIndex}
                    rowIndex={rowIndex}
                    isAvailable={isAvailable}
                    isHover={hoverCell[0] === colIndex && hoverCell[1] === rowIndex}
                    onSelect={props.onSelectDate}
                    onMouseEnter={(col, row) => setHoverCell([col, row])}
                    onMouseLeave={() => setHoverCell([-1, -1])}
                  />
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
