import { useQuery } from 'urql';
import { eachMinuteOfInterval, set } from 'date-fns';
import type {
  AppointmentScheduleQuery,
  AppointmentScheduleQueryVariables,
} from 'graphql/generated/user/graphql';
import { AppointmentScheduleDocument } from 'graphql/generated/user/graphql';

type Props = AppointmentScheduleQueryVariables & {
  isStorybook?: boolean;
};

const today = new Date();

export const useAppointmentScheduleHook = (props: Props) => {
  if (props.isStorybook) {
    return [
      {
        fetching: false,
        data: {
          appointmentSlots: eachMinuteOfInterval(
            {
              start: set(today, { hours: 12, minutes: 0, seconds: 0, milliseconds: 0 }),
              end: set(today, { hours: 18, minutes: 0, seconds: 0, milliseconds: 0 }),
            },
            { step: 15 }
          ).map((date) => ({ date: date.toISOString(), isAvailable: true })) as Array<{
            isAvailable: boolean;
            date: any;
          }>,
        },
      },
    ];
  }
  return useQuery<AppointmentScheduleQuery, AppointmentScheduleQueryVariables>({
    query: AppointmentScheduleDocument,
    variables: props,
    requestPolicy: 'network-only',
  });
};
