import { Paper } from '@mui/material';
import { useCallback } from 'react';
import type { AppointmentFormOptionsQueryResource } from '../AppointmentForm/types';

interface Props {
  resource: AppointmentFormOptionsQueryResource | null;
  isSelected: boolean;
  onChange: (resource: AppointmentFormOptionsQueryResource | null) => void;
}

export const ResourceName = (props: Props) => {
  const onChange = useCallback(() => {
    props.onChange(props.resource || null);
  }, [props.resource]);

  return (
    <Paper
      sx={{
        border: props.isSelected ? '2px solid #1976d2' : 'default',
        m: 1,
        p: 1,
        cursor: 'pointer',
      }}
      onClick={onChange}
    >
      {props.resource?.name || '指名なし'}
    </Paper>
  );
};
