import { Backdrop, CircularProgress } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useMutation } from 'urql';
import { Component } from '../component';
import { UpdateClinicPetDocument } from 'graphql/generated/staff/graphql';
import type { UpdateClinicPetInput } from 'graphql/generated/staff/graphql';

export const useClinicPetDialog = (props?: Partial<UpdateClinicPetInput> | null) => {
  const [open, setOpen] = useState<boolean>(false);
  const [updateClinicPetResult, updateClinicPet] = useMutation(UpdateClinicPetDocument);

  const openDialog = useCallback(() => setOpen(true), [setOpen]);

  const onSubmit = useCallback(
    async (input: Pick<UpdateClinicPetInput, 'note' | 'patientNumber'>) => {
      if (props?.id) {
        try {
          const clinicPet = await updateClinicPet({
            input: {
              id: props.id,
              ...input,
            },
          });

          if (clinicPet.error) {
            throw new Error('ペット情報の更新に失敗しました');
          }
          enqueueSnackbar('ペット情報を更新しました', { variant: 'success' });
          setOpen(false);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
          enqueueSnackbar('ペット情報の更新に失敗しました', { variant: 'error' });
        }
      }
    },
    [props, updateClinicPet]
  );

  const onCancel = useCallback(() => setOpen(false), [setOpen]);

  const render = () => (
    <>
      <Component clinicPet={props} open={open} onSubmit={onSubmit} onCancel={onCancel} />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }}
        open={updateClinicPetResult.fetching}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );

  return {
    open: openDialog,
    render,
  };
};
