import { Box, Button, Typography } from '@mui/material';

interface Props {
  text: string;
  onClose: () => void;
}

export const PrivacyPolicy = (props: Props) => {
  return (
    <>
      <Typography textAlign="center" fontSize="1.3rem" fontWeight="bold" mb={2}>
        個人情報保護方針
      </Typography>
      <Box
        width="100%"
        height="200px"
        border="1px solid #ccc"
        borderRadius="4px"
        p={2}
        overflow="scroll"
      >
        {props.text}
      </Box>
      <Box mt={6} textAlign="center">
        <Button onClick={props.onClose} variant="contained" sx={{ px: 5 }} color="inherit">
          閉じる
        </Button>
      </Box>
    </>
  );
};
