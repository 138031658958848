import { enqueueSnackbar } from 'notistack';
import type { FC } from 'react';
import { useCallback, useState } from 'react';
import type { ResetPasswordParams } from 'clients/apis/staff';
import { resetPassword } from 'clients/apis/staff';
import PasswordResetForm from 'components/forms/staff/PasswordResetForm';

const Page: FC = () => {
  const [requesting, setRequesting] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = useCallback(async (values: ResetPasswordParams) => {
    setRequesting(true);
    setError('');
    try {
      const res = await resetPassword(values);
      if (res.Success) {
        enqueueSnackbar('再設定の案内メールを送信しました', { variant: 'success' });
      } else {
        throw new Error('Failed to reset password');
      }
    } catch (e) {
      enqueueSnackbar('パスワード再設定に失敗しました', { variant: 'error' });
      setError('パスワード再設定に失敗しました');
      // eslint-disable-next-line no-console
      console.error(e);
    }
    setRequesting(false);
  }, []);

  return <PasswordResetForm onSubmit={handleSubmit} requesting={requesting} error={error} />;
};

export default Page;
