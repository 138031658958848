import { useCallback, useState } from 'react';
import { Component } from '../component';

interface Props {
  title: string;
  text: string;
}

export const useTermOfServiceDialog = (props: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const openDialog = useCallback(() => setOpen(true), [setOpen]);

  const onCancel = useCallback(() => setOpen(false), [setOpen]);

  const render = () => (
    <Component open={open} onCancel={onCancel} title={props.title} text={props.text} />
  );

  return {
    open: openDialog,
    render,
  };
};
