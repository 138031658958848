import { Box, Breadcrumbs, Container, TextField, Toolbar, Typography } from '@mui/material';
import type { FC } from 'react';
import { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'urql';
import FullScreenLoading from 'components/FullScreenLoading';
import { useNewAppointmentDialog } from 'components/dialogs/staff/AppointmentDialog';
import { UsersTable } from 'components/users/UsersTable/UsersTable';
import * as pageTitles from 'constants/pageTitles/staff';
import * as paths from 'constants/paths/staff';
import type { UsersQuery, UsersQueryVariables } from 'graphql/generated/staff/graphql';
import { UsersDocument } from 'graphql/generated/staff/graphql';

const Page: FC = () => {
  const appointmentDialog = useNewAppointmentDialog();
  const [word, setWord] = useState('');
  const navigate = useNavigate();
  const [{ data, fetching, error }] = useQuery<UsersQuery, UsersQueryVariables>({
    query: UsersDocument,
    variables: {
      name: '',
      word,
    },
  });

  const onChangeWord = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setWord(event.target.value);
  }, []);

  const onSelectUser = useCallback((clinicUserId: string) => {
    navigate(paths.userDetailPath(clinicUserId));
  }, []);

  const onSelectPet = useCallback((clinicPetId: string) => {
    navigate(paths.petDetailPath(clinicPetId));
  }, []);

  const onCreateAppointment = useCallback(
    (clinicUserId: string, clinicPetId?: string) => {
      appointmentDialog.open({ clinicUserId, clinicPetId });
    },
    [appointmentDialog]
  );

  if (error?.networkError) {
    throw error;
  }

  return (
    <>
      <Helmet>
        <title>{pageTitles.users}</title>
      </Helmet>

      <Toolbar>
        <Container sx={{ display: 'flex' }}>
          <Breadcrumbs sx={{ flexGrow: 1 }}>
            <Typography color="text.primary">{pageTitles.users}</Typography>
          </Breadcrumbs>
        </Container>
      </Toolbar>

      <Container>
        <Box mt={2} mb={4}>
          <TextField label="飼い主を検索" variant="standard" onChange={onChangeWord} />
        </Box>
        {!fetching && data && (
          <UsersTable
            data={data}
            onSelectUser={onSelectUser}
            onSelectPet={onSelectPet}
            onCreateAppointment={onCreateAppointment}
          />
        )}
        {fetching && <FullScreenLoading />}
      </Container>
      {appointmentDialog.render()}
    </>
  );
};

export default Page;
