import type { FC } from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

interface Props {
  path: string;
}

const Component: FC<Props> = ({ path }) => {
  const navigate = useNavigate();
  const { clinicSlug } = useParams();

  useEffect(() => {
    navigate(`/user/${clinicSlug}/${path}`, { replace: true });
  }, []);
  return null;
};

export default Component;
