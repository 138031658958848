import { Box, Button, Divider, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { PetItem } from './PetItem';
import { UserDetailView } from 'components/views/user/UserDetailView/UserDetailView';
import type { Pet, RegisterConfirmDetailQuery } from 'graphql/generated/user/graphql';

interface Props {
  viewer: RegisterConfirmDetailQuery['viewer'];
  onEditOwner: () => void;
  onAddPet: () => void;
  onEditPet: (pet: Pick<Pet, 'id' | 'name'>) => void;
  onDeletePet: (pet: Pick<Pet, 'id' | 'name'>) => void;
}

export const ClinicRegisterConfirm = (props: Props) => {
  return (
    <Box>
      <Typography variant="h5">登録情報確認</Typography>
      <Stack spacing={2} sx={{ width: '100%', my: 2 }}>
        <Box>
          <Typography variant="h6" textAlign="center">
            飼い主様情報
          </Typography>
          <UserDetailView user={props.viewer} />

          <Box textAlign="center" my={2}>
            <Button variant="contained" color="secondary" onClick={props.onEditOwner}>
              修正
            </Button>
          </Box>
        </Box>

        <Divider />

        <Box>
          <Typography variant="h6" textAlign="center">
            ペット情報
          </Typography>
          {props.viewer.pets.map((pet) => (
            <PetItem
              key={pet.id}
              pet={pet}
              onDeletePet={props.onDeletePet}
              onEditPet={props.onEditPet}
            />
          ))}
          <Box textAlign="center" mt={4} mb={8}>
            <Button variant="contained" onClick={props.onAddPet}>
              ペットを追加する
            </Button>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};
