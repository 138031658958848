import { Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material';
import type { ComponentProps, FC } from 'react';
import { useCallback, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import AppointmentForm from 'components/forms/staff/AppointmentForm';
import { useConfirmationDialog } from 'components/dialogs/ConfirmationDialog';

type Props = ComponentProps<typeof AppointmentForm> & {
  open: boolean;
  onCancel?: () => void;
};

export const Component: FC<Props> = ({ open, onCancel = () => {}, ...props }) => {
  const [isDirty, setIsDirty] = useState(false);
  const confirmationDialog = useConfirmationDialog({
    title: '入力を破棄しますか？',
    message: props.appointment ? '変更した内容は破棄されます。' : '入力した内容は破棄されます。',
    acceptLabel: '破棄する',
    destructive: true,
    onAccept: onCancel,
  });

  const handleChangeDirty = useCallback(
    (dirty: boolean) => {
      setIsDirty(dirty);
      props?.onChangeDirty?.(dirty);
    },
    [props]
  );

  return (
    <Dialog open={open} onClose={isDirty ? confirmationDialog.open : onCancel} maxWidth="lg">
      <DialogTitle>
        {props.appointment ? '予約編集' : '新規予約'}
        <IconButton
          aria-label="キャンセル"
          onClick={isDirty ? confirmationDialog.open : onCancel}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <AppointmentForm {...props} onChangeDirty={handleChangeDirty} />
      </DialogContent>
      {confirmationDialog.render()}
    </Dialog>
  );
};
