import { Box, Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import * as paths from 'constants/paths/user';

interface Props {
  clinic?: string;
}

export const RegistrationComplete = (props: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography variant="h5">会員登録完了</Typography>

      <Box sx={{ py: 4, textAlign: 'center' }}>
        会員登録が完了しました
        <br />
        {props.clinic && (
          <Typography>
            続けて予約を行う場合は以下の
            <br />
            予約に進むボタンを押下してください。
            <br />
            <br />
            後日予約する場合は、
            <br />
            ログイン後に予約が可能です。
          </Typography>
        )}
      </Box>

      {props.clinic && (
        <Button
          component={Link}
          to={paths.clinicAppointmentNewPath(props.clinic)}
          color="primary"
          variant="contained"
        >
          予約に進む
        </Button>
      )}

      <Button
        variant="contained"
        sx={{
          width: '140px',
          backgroundColor: 'gray',
          fontSize: '0.8rem',
          color: 'white',
          marginTop: '1rem',
        }}
        to={props.clinic ? paths.clinicRootPath(props.clinic) : paths.rootPath()}
        component={Link}
      >
        マイページTOPへ
      </Button>
    </Box>
  );
};
