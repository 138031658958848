import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Link,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import type { FC } from 'react';
import { useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'urql';
import FullScreenLoading from 'components/FullScreenLoading';
import { UsersAppointmentsTable } from 'components/appointments/staff/UserAppointmentsTable/UsersAppointmentsTable';
import {
  useEditAppointmentDialog,
  useNewAppointmentDialog,
} from 'components/dialogs/staff/AppointmentDialog';
import { useClinicUserDialog } from 'components/dialogs/staff/ClinicUserDialog/hooks/useClinicUserDialog';
import { PetsTable } from 'components/pets/PetsTable/PetsTable';
import { UserDetailView } from 'components/users/UserDetailView.tsx';
import * as pageTitles from 'constants/pageTitles/staff';
import * as paths from 'constants/paths/staff';
import type { UserDetailQuery, UserDetailQueryVariables } from 'graphql/generated/staff/graphql';
import { UserDetailDocument } from 'graphql/generated/staff/graphql';
import NotFoundPage from 'pages/NotFoundPage';

const Page: FC = () => {
  const { id } = useParams() as { id: string };
  const navigate = useNavigate();
  const [{ data, fetching, error }, refetch] = useQuery<UserDetailQuery, UserDetailQueryVariables>({
    query: UserDetailDocument,
    variables: {
      id,
    },
  });

  const newAppointmentDialog = useNewAppointmentDialog();
  const clinicUserDialog = useClinicUserDialog(data?.clinicUser);

  const editAppointmentDialog = useEditAppointmentDialog({
    onSave: () => refetch({ requestPolicy: 'network-only' }),
  });

  const onSelectPet = useCallback((clinicPetId: string) => {
    navigate(paths.petDetailPath(clinicPetId));
  }, []);

  const onCreateAppointment = useCallback(() => {
    newAppointmentDialog.open({ clinicUserId: data?.clinicUser?.id });
  }, [newAppointmentDialog]);

  if (fetching) {
    return <FullScreenLoading />;
  }

  if (error?.networkError) {
    throw error;
  }

  if (!data) {
    return <NotFoundPage />;
  }

  return (
    <>
      <Helmet>
        <title>{pageTitles.userDetail}</title>
      </Helmet>

      <Toolbar>
        <Container sx={{ display: 'flex' }}>
          <Breadcrumbs aria-label="breadcrumb" sx={{ flexGrow: 1 }}>
            <Link underline="hover" color="inherit" component={RouterLink} to={paths.usersPath()}>
              飼い主様一覧
            </Link>
            <Typography color="text.primary">{pageTitles.userDetail}</Typography>
          </Breadcrumbs>
          <Button variant="contained" onClick={onCreateAppointment}>
            新規予約登録
          </Button>
        </Container>
      </Toolbar>

      <Container>
        <Stack spacing={18} mt={10} pb={18}>
          <UserDetailView clinicUser={data.clinicUser} onEdit={() => clinicUserDialog.open()} />

          <Box>
            <Typography variant="h6" textAlign="center" mb={3}>
              ペット情報
            </Typography>
            <PetsTable clinicPets={data.clinicUser?.clinicPets ?? []} onSelectPet={onSelectPet} />
          </Box>

          <Box>
            <Typography variant="h6" textAlign="center" mb={3}>
              予約履歴
            </Typography>
            <UsersAppointmentsTable
              appointments={data.clinicUser?.appointments ?? []}
              onSelectAppointment={editAppointmentDialog.open}
            />
          </Box>
        </Stack>
      </Container>
      {clinicUserDialog.render()}
      {newAppointmentDialog.render()}
      {editAppointmentDialog.render()}
    </>
  );
};

export default Page;
