import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { useCallback, useState } from 'react';
import type { RegisterFormInput } from './hooks/useRegisterForm';
import { useRegisterForm } from './hooks/useRegisterForm';
import { useTermOfServiceDialog } from 'components/dialogs/user/TermOfServiceDialog/hooks/useTermOfServiceDialog';
import * as paths from 'constants/paths/user';
import Logo from 'graphics/Logo';

interface Props {
  clinic?: {
    name: string;
    slug: string;
  };
  agreement: {
    url: string;
    text: string;
  };
  onSubmit: (data: RegisterFormInput) => void;
  fetching?: boolean;
}

export const RegisterForm = (props: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useRegisterForm();
  const dialog = useTermOfServiceDialog({
    title: '利用規約',
    text: props.agreement.text,
  });

  // eslint-disable-next-line no-console
  const onInvalid = (errors: any) => console.error(errors);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const onSubmit = useCallback(
    (data: RegisterFormInput) => {
      props.onSubmit(data);
    },
    [props.onSubmit]
  );

  const onToggleShowPassword = useCallback(() => {
    setShowPassword((prev) => !prev);
  }, []);

  return (
    <>
      <Stack component="form" onSubmit={handleSubmit(onSubmit, onInvalid)}>
        <Box sx={{ textAlign: 'center' }}>
          <Box sx={{ maxWidth: '500px', margin: '0 auto' }}>
            <Logo />
          </Box>
        </Box>

        <Typography fontWeight="bold" textAlign="center" sx={{ py: 2 }}>
          新規会員登録
        </Typography>

        {props.clinic?.name && (
          <Box sx={{ bgcolor: grey[300], py: 2, px: 1, mb: 2 }}>
            <Typography sx={{ textAlign: 'center', fontSize: '12px' }}>
              {props.clinic.name}ではアニプロ予約を使用しています。
              <br />
              ご利用前に会員登録をする必要があります。
              <br />
              以下から登録をお願いします。
            </Typography>
          </Box>
        )}

        <FormControl sx={{ my: 1.5 }} variant="outlined">
          <InputLabel size="small">メールアドレス</InputLabel>
          <OutlinedInput
            type="text"
            label="メールアドレス"
            size="small"
            error={'email' in errors}
            {...register('email')}
          />
          {errors.email?.message && <FormHelperText error>{errors.email?.message}</FormHelperText>}
        </FormControl>

        <FormControl sx={{ my: 1.5 }} variant="outlined">
          <InputLabel size="small" error={!!errors.password}>
            パスワード
          </InputLabel>
          <OutlinedInput
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={onToggleShowPassword} edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="パスワード"
            size="small"
            error={'password' in errors}
            {...register('password')}
          />
          {errors.password?.message && (
            <FormHelperText error>{errors.password?.message}</FormHelperText>
          )}
        </FormControl>

        <Box sx={{ fontSize: '0.8rem' }}>
          半角英数字・記号が使用できます。
          <br />
          英字(大文字・小文字)をそれぞれ１文字以上使用してください
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', mt: 3, mb: 1 }} component="label">
          <Checkbox checked={isChecked} onChange={(e) => setIsChecked(e.target.checked)} />
          <Typography>
            <Button onClick={() => dialog.open()}>利用規約</Button>
            および
            <Button onClick={() => window.open(props.agreement.url, '_blank')}>
              個人情報保護方針
            </Button>
            に同意する
          </Typography>
        </Box>

        <Button
          sx={{ backgroundColor: grey[700] }}
          variant="contained"
          disabled={!isChecked || props.fetching}
          type="submit"
        >
          アカウントを登録
        </Button>
      </Stack>

      <Box my={5}>
        <Divider />
      </Box>

      <Box textAlign="center">
        <Link
          href={props.clinic ? paths.clinicLoginPath(props.clinic.slug) : paths.loginPath()}
          sx={{
            mt: 1,
            textAlign: 'center',
            display: 'block',
            textDecoration: 'none',
          }}
        >
          すでにご登録いただいている方
        </Link>
      </Box>
      {dialog.render()}
    </>
  );
};
