import { isAfter, isBefore, isSameDay, isSameMinute } from 'date-fns';
import type { Resource, WorkingHour, WorkingHours } from '../types';
import { DayOfWeek } from 'graphql/generated/staff/graphql';

const convertDayStringToNum = (day: DayOfWeek) => {
  switch (day) {
    case DayOfWeek.Sunday:
      return 0;
    case DayOfWeek.Monday:
      return 1;
    case DayOfWeek.Tuesday:
      return 2;
    case DayOfWeek.Wednesday:
      return 3;
    case DayOfWeek.Thursday:
      return 4;
    case DayOfWeek.Friday:
      return 5;
    case DayOfWeek.Saturday:
      return 6;
  }
};

// 日付を考慮せずに時間だけで比較する
const isBetweenTime = (date: Date, resourceWorkingHour: WorkingHour) => {
  const { start, end } = resourceWorkingHour;
  const dateTime = new Date(0, 0, 0, date.getHours(), date.getMinutes());
  const startTime = new Date(0, 0, 0, start.hour, start.minute);
  const endTime = new Date(0, 0, 0, end.hour, end.minute);

  if (!isAfter(dateTime, startTime) && !isSameMinute(dateTime, startTime)) {
    return false;
  }

  if (!isBefore(dateTime, endTime)) {
    return false;
  }

  return true;
};

// 指定された日時が稼働時間内か判断
export const isWorkingHour = (date: Date, workingHours: WorkingHours) => {
  return workingHours.some((wh) => {
    // 比較対象が別の曜日であればスキップ
    if (date.getDay() !== convertDayStringToNum(wh.day)) {
      return false;
    }

    // 比較対象が稼働時間内であればtrue
    if (isBetweenTime(date, wh)) {
      return true;
    }

    return false;
  });
};

export const isHoliday = (date: Date, resource: Resource) => {
  return resource.holidays.some((holiday) => {
    return isSameDay(date, new Date(holiday.date));
  });
};
