import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, Stack } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { useCallback } from 'react';
import type {
  AppointmentFormOptionsQueryService,
  AppointmentFormOptionsQueryServiceCategory,
} from '../AppointmentForm/types';
import { ServiceItem } from './ServiceItem';

interface Props {
  serviceCategories: AppointmentFormOptionsQueryServiceCategory[];
  onBack: () => void;
  onSelect: (clinicServiceId: AppointmentFormOptionsQueryService) => void;
}

export const SelectService = (props: Props) => {
  const onSelect = useCallback((service: AppointmentFormOptionsQueryService) => {
    props.onSelect(service);
  }, []);

  return (
    <Box>
      <Box>
        <Typography variant="h6" textAlign="center">
          サービスを選択してください
        </Typography>
      </Box>
      <Box my={4}>
        {props.serviceCategories.map((sc) => (
          <Accordion key={sc.id}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">{sc.name}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack spacing={6} width="100%">
                {sc.services.map((s) => (
                  <ServiceItem key={s.id} service={s} onSelect={onSelect} />
                ))}
              </Stack>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>

      <Box display="flex" alignItems="center" justifyContent="center">
        <Button onClick={props.onBack} sx={{ px: 5, mt: 3 }} variant="contained" color="inherit">
          戻る
        </Button>
      </Box>
    </Box>
  );
};
