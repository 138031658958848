import PetsIcon from '@mui/icons-material/Pets';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Avatar, Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { grey } from '@mui/material/colors';
import { useCallback } from 'react';
import { format, parseISO } from 'date-fns';
import ServiceChips from 'components/ServiceChips';
import type { UserDetailQuery } from 'graphql/generated/staff/graphql';
import AppointmentStatusChip from 'components/appointments/AppointmentStatusChip';
import { getAge } from 'helpers/date';

interface Props {
  appointment: NonNullable<UserDetailQuery['clinicUser']>['appointments'][number];
  onSelectAppointment: (appintmentId: string) => void;
}

export const UserAppointmentRow = (props: Props) => {
  const pet = props.appointment.clinicPet?.pet;

  const onSelectAppointment = useCallback(() => {
    props.onSelectAppointment(props.appointment.id);
  }, [props.appointment]);

  return (
    <TableRow hover>
      <TableCell align="right" width={140}>
        <Typography variant="caption" color={grey[700]}>
          {format(parseISO(props.appointment.startAt), 'yyyy年MM月dd日')}
        </Typography>
        <Typography variant="body2">
          {format(parseISO(props.appointment.startAt), 'HH:mm')}
          &nbsp;-&nbsp;
          {format(parseISO(props.appointment.endAt), 'HH:mm')}
        </Typography>
      </TableCell>
      <TableCell>
        <AppointmentStatusChip status={props.appointment.status} />
      </TableCell>
      <TableCell width={240}>
        <Box display="flex" alignItems="center" color={grey[900]}>
          <Avatar src={pet?.profileImageURL ?? ''}>
            <PetsIcon />
          </Avatar>
          <Box ml={2} textAlign="left">
            <Typography variant="body2">{pet?.name}</Typography>
            <Stack direction="row" spacing={1}>
              <Typography variant="caption" color={grey[600]}>
                {pet?.animal?.name}
              </Typography>
              <Typography variant="caption" color={grey[600]}>
                {getAge(pet?.birthday, '歳')}
              </Typography>
            </Stack>
          </Box>
        </Box>
      </TableCell>
      <TableCell align="right">
        <ServiceChips services={props.appointment.service ? [props.appointment.service] : []} />
      </TableCell>
      <TableCell align="center" width={100}>
        <Tooltip title="詳細を見る">
          <IconButton onClick={onSelectAppointment}>
            <VisibilityIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};
