import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box } from '@mui/material';
import { grey } from '@mui/material/colors';
import { PetRow } from './PetRow';
import type { UserDetailQuery } from 'graphql/generated/staff/graphql';

interface Props {
  clinicPets: NonNullable<UserDetailQuery['clinicUser']>['clinicPets'];
  onSelectPet: (petId: string) => void;
}

export const PetsTable = (props: Props) => {
  if (!props.clinicPets || props.clinicPets.length === 0) {
    return (
      <Box sx={{ textAlign: 'center' }} color={grey[500]}>
        データがありません
      </Box>
    );
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="right" width={120}>
              診察券番号
            </TableCell>
            <TableCell width={240}>名前</TableCell>
            <TableCell>種別</TableCell>
            <TableCell>種類</TableCell>
            <TableCell align="right">性別</TableCell>
            <TableCell align="right">年齢</TableCell>
            <TableCell align="center" width={150}>
              操作
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.clinicPets.map((clinicPet) => (
            <PetRow key={clinicPet.id} clinicPet={clinicPet} onSelectPet={props.onSelectPet} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
