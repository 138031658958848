import { Box, Chip } from '@mui/material';
import type { FC } from 'react';

interface Service {
  id: string;
  name: string;
  category: {
    id: string;
    name: string;
  };
}

interface Props {
  services: Service[];
}

export const Component: FC<Props> = ({ services }) => (
  <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', pointerEvents: 'none' }}>
    {services.map((service) => (
      <Chip key={service.id} label={`${service.category.name}・${service.name}`} />
    ))}
  </Box>
);
