import { Stack, TextField, Link, Box, Paper, Typography } from '@mui/material';
import type { FC } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import SecureInput from '../../SecureInput';
import * as validationRules from '../../validationRules';
import type { LoginParams } from 'clients/apis/staff';
import * as paths from 'constants/paths/staff';

interface Props {
  onSubmit?: (values: LoginParams) => void;
  requesting?: boolean;
  error?: string;
}

export const Component: FC<Props> = ({ onSubmit = () => {}, requesting, error = '' }) => {
  const { handleSubmit, control } = useForm<LoginParams>();

  return (
    <Paper sx={{ p: 5 }}>
      <Stack component="form" onSubmit={handleSubmit(onSubmit)} gap={2}>
        <Typography variant="h5" component="h2">
          ログイン
        </Typography>
        <Controller
          control={control}
          name="email"
          rules={{
            ...validationRules.email,
            required: true,
          }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              type="email"
              label="メールアドレス"
              variant="outlined"
              fullWidth
              required
              error={!!fieldState.error || !!error}
              helperText={fieldState.error?.message}
              disabled={requesting}
            />
          )}
        />
        <Controller
          control={control}
          name="password"
          rules={{
            required: true,
          }}
          render={({ field, fieldState }) => (
            <SecureInput
              {...field}
              type="password"
              label="パスワード"
              variant="outlined"
              fullWidth
              required
              error={!!fieldState.error || !!error}
              helperText={fieldState.error?.message}
              disabled={requesting}
            />
          )}
        />
        {error && (
          <Typography variant="body2" color="error">
            {error}
          </Typography>
        )}
        <LoadingButton
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          loading={requesting}
        >
          ログイン
        </LoadingButton>
        <Box sx={{ mt: 5 }}>
          <Link component={RouterLink} to={paths.resetPasswordPath()}>
            パスワードを忘れた方はこちら
          </Link>
        </Box>
      </Stack>
    </Paper>
  );
};
