import type { RouteObject } from 'react-router-dom';
import StaffLayout from 'components/layouts/staff/StaffLayout';
import * as paths from 'constants/paths/staff';
import {
  AppointmentDetailPage,
  AppointmentNewPage,
  AppointmentsPage,
  ConfirmEmailPage,
  ConfirmResetPasswordPage,
  LoginPage,
  PetDetailPage,
  ResetPasswordPage,
  ResourceDetailPage,
  ResourceEditPage,
  ResourceHolidaysPage,
  ResourceNewPage,
  ResourcesPage,
  ResourceWorkingHoursPage,
  RootPage,
  SchedulesPage,
  StaffDetailPage,
  StaffNewPage,
  StaffsPage,
  UserDetailPage,
  UsersPage,
  SettingsPage,
} from 'pages/staff';
import NotFoundPage from 'pages/NotFoundPage';
import LogoutPage from 'pages/staff/LogoutPage';

const routes: RouteObject[] = [
  {
    path: paths.rootPath(),
    element: <StaffLayout auth={true} />,
    children: [
      {
        path: paths.rootPath(),
        element: <RootPage />,
        index: true,
      },
      {
        path: paths.staffsPath(),
        element: <StaffsPage />,
      },
      {
        path: paths.staffNewPath(),
        element: <StaffNewPage />,
      },
      {
        path: paths.staffDetailPath(':id'),
        element: <StaffDetailPage />,
      },
      {
        path: paths.resourcesPath(),
        element: <ResourcesPage />,
      },
      {
        path: paths.resourceNewPath(),
        element: <ResourceNewPage />,
      },
      {
        path: paths.resourceDetailPath(':id'),
        element: <ResourceDetailPage />,
      },
      {
        path: paths.resourceEditPath(':id'),
        element: <ResourceEditPage />,
      },
      {
        path: paths.resourceWorkingHoursPath(':id'),
        element: <ResourceWorkingHoursPage />,
      },
      {
        path: paths.resourceHolidaysPath(':id'),
        element: <ResourceHolidaysPage />,
      },
      {
        path: paths.schedulesPath(),
        element: <SchedulesPage />,
      },
      {
        path: paths.usersPath(),
        element: <UsersPage />,
      },
      {
        path: paths.userDetailPath(':id'),
        element: <UserDetailPage />,
      },
      {
        path: paths.petDetailPath(':id'),
        element: <PetDetailPage />,
      },
      {
        path: paths.appointmentsPath(),
        element: <AppointmentsPage />,
      },
      {
        path: paths.appointmentNewPath(),
        element: <AppointmentNewPage />,
      },

      {
        path: paths.appointmentDetailPath(':id'),
        element: <AppointmentDetailPage />,
      },
      {
        path: paths.settingsPath(),
        element: <SettingsPage />,
      },
      {
        path: paths.logoutPath(),
        element: <LogoutPage />,
      },
      {
        path: '*',
        element: <NotFoundPage />,
      },
    ],
  },
  {
    path: paths.rootPath(),
    element: <StaffLayout auth={false} />,
    children: [
      {
        path: paths.loginPath(),
        element: <LoginPage />,
      },
      {
        path: paths.confirmEmailPath(':token'),
        element: <ConfirmEmailPage />,
      },
      {
        path: paths.resetPasswordPath(),
        element: <ResetPasswordPage />,
      },
      {
        path: paths.confirmResetPasswordPath(':token'),
        element: <ConfirmResetPasswordPage />,
      },
      {
        path: '*',
        element: <NotFoundPage />,
      },
    ],
  },
].map((route) => ({
  ...route,
  children: route.children?.map((child) => ({
    ...child,
    path: child.path.replace(/^\/staff\//, ''),
  })),
}));

export default routes;
