import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box } from '@mui/material';
import { grey } from '@mui/material/colors';
import { UserAppointmentRow } from './UsersAppointmentRow';
import type { UserDetailQuery } from 'graphql/generated/staff/graphql';

interface Props {
  appointments: NonNullable<UserDetailQuery['clinicUser']>['appointments'];
  onSelectAppointment: (appointmentId: string) => void;
}

export const UsersAppointmentsTable = (props: Props) => {
  if (!props.appointments || props.appointments.length === 0) {
    return (
      <Box sx={{ textAlign: 'center' }} color={grey['500']}>
        データがありません
      </Box>
    );
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="right">日時</TableCell>
            <TableCell width={140} align="center">
              ステータス
            </TableCell>
            <TableCell>ペット</TableCell>
            <TableCell>サービス</TableCell>
            <TableCell align="center">操作</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.appointments.map((appointment) => (
            <UserAppointmentRow
              key={appointment.id}
              appointment={appointment}
              onSelectAppointment={props.onSelectAppointment}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
