import { Avatar, Box, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import type { UserDetailQuery } from 'graphql/generated/user/graphql';

interface Props {
  user: UserDetailQuery['viewer'];
}

export const UserDetailView = ({ user }: Props) => {
  const listItem = [
    { label: '氏名', value: user.name },
    { label: '電話番号', value: user.phoneNumber },
    { label: 'メールアドレス', value: user.email },
    { label: '郵便番号', value: user.zipCode },
    { label: '都道府県', value: user.prefecture?.name },
    { label: '市区町村', value: user.city },
    { label: '以降の住所', value: user.street },
    { label: '建物名・部屋番号', value: user.building },
  ];

  const listItem2 = [
    { label: 'どれぐらいの頻度で動物病院を利用しますか', value: user.visitingFrequency?.name },
    {
      label: 'どれぐらいの頻度でお店・動物病院のカット・シャンプーなどを利用しますか',
      value: user.trimmingFrequency?.name,
    },
  ];

  return (
    <Box>
      <Box display="flex" justifyContent="center" my={4}>
        <Avatar sx={{ width: 64, height: 64 }} src={user.profileImageURL || ''} />
      </Box>
      {listItem.map((v) => (
        <Box
          key={v.label}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          component="dl"
          borderTop={1}
          borderColor={grey[200]}
          m={0}
          py={2}
        >
          <Typography
            variant="caption"
            color={grey[900]}
            fontWeight="bold"
            component="dt"
            flexShrink={0}
            sx={{ wordBreak: 'break-all' }}
          >
            {v.label}
          </Typography>
          <Typography
            ml={1}
            variant="body2"
            component="dd"
            color={v.value ? grey[900] : grey[600]}
            textAlign="right"
            sx={{ wordBreak: 'break-all' }}
          >
            {v.value ?? '-'}
          </Typography>
        </Box>
      ))}
      {listItem2.map((v) => (
        <Box key={v.label} component="dl" borderTop={1} borderColor={grey[200]} m={0} py={1.5}>
          <Typography
            variant="caption"
            color={grey[900]}
            fontWeight="bold"
            component="dt"
            sx={{ wordBreak: 'break-all' }}
          >
            {v.label}
          </Typography>
          <Typography
            variant="body2"
            component="dd"
            mt={0.5}
            color={v.value ? grey[900] : grey[600]}
            sx={{ wordBreak: 'break-all' }}
          >
            {v.value ?? '-'}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};
