import { useCallback } from 'react';
import { useMutation } from 'urql';
import type { CreatePetInput } from 'graphql/generated/user/graphql';
import { UpdatePetDocument, CreatePetDocument } from 'graphql/generated/user/graphql';
import { useUpdatePetProfileImage } from 'hooks/user/forms/useUpdatePetProfileImage';

// 新規ペットの作成
export const useCreatePet = () => {
  const [createResult, createPet] = useMutation(CreatePetDocument);
  const [_updateResult, updatePet] = useMutation(UpdatePetDocument);
  const updatePetProfileImage = useUpdatePetProfileImage();

  const create = useCallback(async (input: CreatePetInput, file?: File) => {
    const res = await createPet({ input });
    const id = res.data?.createPet.id;

    if (id && file) {
      const profileImageURL = await updatePetProfileImage(id, file);
      return await updatePet({ input: { id, profileImageURL } });
    }

    return res;
  }, []);

  return {
    createPet: create,
    fetching: createResult.fetching,
  };
};
