import { Box, Button, Stack, Typography } from '@mui/material';
import type { FC } from 'react';

const Page: FC = () => (
  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    <Stack sx={{ textAlign: 'center' }} gap={2}>
      <Typography variant="h3" component="h1">
        エラーが発生しました
      </Typography>
      <Typography variant="body1" component="p">
        お手数ですが、再読込をお試し下さい。
      </Typography>
      <Button variant="contained" onClick={() => document.location.reload()}>
        再読込
      </Button>
    </Stack>
  </Box>
);

export default Page;
