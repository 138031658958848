import type { ComponentProps } from 'react';
import { useState } from 'react';
import { Component } from './component';

export const useDialog = (params: Omit<ComponentProps<typeof Component>, 'open'>) => {
  const [open, setOpen] = useState(false);

  const handleCancel = () => {
    setOpen(false);
    params.onCancel?.();
  };
  const handleAccept = () => {
    setOpen(false);
    params.onAccept?.();
  };

  const render = () => (
    <Component {...params} open={open} onCancel={handleCancel} onAccept={handleAccept} />
  );

  return {
    open: () => setOpen(true),
    render,
  };
};
