import type { FC } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import NavigationItem from './NavigationItem';
import * as paths from 'constants/paths/staff';
import { formatDate } from 'helpers/date';

interface Props {
  date: Date;
  text: string;
  count: number;
  Icon: FC;
  open: boolean;
}

const CalendarListItem: FC<Props> = ({ date, text, count, Icon, open }) => {
  const location = useLocation();
  const [params] = useSearchParams();
  const paramDate = params.get('date') || params.get('dateFrom') || formatDate(new Date());
  const formatted = formatDate(date);
  const selected = location.pathname === paths.rootPath() && paramDate === formatted;

  return (
    <NavigationItem
      text={text}
      Icon={Icon}
      count={count}
      open={open}
      selected={selected}
      sx={{
        paddingLeft: open ? '30px' : undefined,
        transition: 'padding-left 0.2s ease-out',
      }}
      to={`${paths.rootPath()}?date=${formatted}`}
    />
  );
};

export default CalendarListItem;
