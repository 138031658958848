import type { FC } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Container, Stack, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useQuery } from 'urql';
import { Helmet } from 'react-helmet-async';
import { PetDetailDocument } from 'graphql/generated/user/graphql';
import type {
  CreatePetInput,
  PetDetailQuery,
  UpdatePetInput,
} from 'graphql/generated/user/graphql';
import * as paths from 'constants/paths/user';
import { UserPetForm } from 'components/forms/user/UserPetForm';
import FullScreenLoading from 'components/FullScreenLoading';
import { useUpdatePet } from 'hooks/user/forms/useUpdatePet';
import * as pageTitles from 'constants/pageTitles/user';

const Page: FC = () => {
  const { petId, clinicSlug } = useParams();
  const navigate = useNavigate();
  const [{ data, fetching }] = useQuery<PetDetailQuery>({
    query: PetDetailDocument,
    variables: {
      petId: petId,
    },
    pause: !petId,
  });
  const { updatePet } = useUpdatePet();
  const pet = data?.viewer.pet;

  const onSubmit = useCallback(
    async (input: CreatePetInput & { id?: UpdatePetInput['id'] }, file?: File) => {
      const petId = input.id;
      if (!petId) {
        return;
      }
      const { data } = await updatePet({ id: petId, ...input }, file);

      if (data?.updatePet.id) {
        enqueueSnackbar(`ペット ${input.name}を更新しました`, { variant: 'success' });
      }

      window.scrollTo(0, 0);
      navigate(paths.clinicPetDetailPath(clinicSlug, petId));
    },
    [pet]
  );

  const onCancel = useCallback(() => {
    navigate(paths.clinicPetDetailPath(clinicSlug, petId));
  }, [petId]);

  if (fetching || !data) {
    return <FullScreenLoading />;
  }

  if (!pet) {
    return <Container>ペットが見つかりませんでした</Container>;
  }

  return (
    <>
      <Helmet>
        <title>{pageTitles.petEdit}</title>
      </Helmet>
      <Box mt={4} textAlign="center">
        <Typography variant="h5">ペット情報登録</Typography>
      </Box>

      <Stack spacing={2} pb={4}>
        <UserPetForm
          initialValues={{
            id: pet.id,
            name: pet.name,
            animalTypeId: pet.animalType?.id,
            animalId: pet.animal?.id,
            birthday: pet.birthday,
            insurance: pet.insurance,
            gender: pet.gender,
            weight: pet.weight,
            furColor: pet.furColor,
            insuranceNumber: pet.insuranceNumber,
            food: pet.food,
            microChip: pet.microChip,
            note: pet.note,
            profileImageURL: pet.profileImageURL,
          }}
          animals={data.animals}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
      </Stack>
    </>
  );
};

export default Page;
