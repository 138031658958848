import { Box, Button, Typography } from '@mui/material';

import type { AppointmentViewProps } from '../AppointmentView/AppointmentView';
import { AppointmentView } from '../AppointmentView/AppointmentView';

interface Props {
  appointment: AppointmentViewProps['appointment'];
  isUpdate?: boolean; // 作成 or 更新
  onSelectPet: () => void;
  onSelectService: () => void;
  onSelectDate: () => void;
  onSelectInterview: () => void;
  onPrivacyPolicy: () => void;
  onSubmit: () => void;
}

export const AppointmentConfirm = (props: Props) => {
  return (
    <Box>
      <Box my={4}>
        <Typography variant="h6" textAlign="center">
          内容を確認して
          <br />
          予約確定を選択してください
        </Typography>
        <Typography variant="body2" textAlign="center" color="red" mt={2}>
          変更の際は以降の項目を再選択する必要があります
        </Typography>
      </Box>

      <AppointmentView {...props} />

      <Box textAlign="center" my={4}>
        <Typography variant="body2" textAlign="center">
          予約情報は動物病院等（過去に会員登録もしくは予約をしたことがある動物病院等）及び本サービスを運営する株式会社TYLで共有します
        </Typography>
        <Button onClick={props.onPrivacyPolicy}>動物病院の個人情報保護方針はこちら</Button>
      </Box>

      <Box mt={4} textAlign="center">
        <Button variant="contained" onClick={props.onSubmit}>
          {props.isUpdate ? '予約内容を更新' : '予約確定'}
        </Button>
      </Box>
    </Box>
  );
};
