import PetsIcon from '@mui/icons-material/Pets';
import { Avatar, Box, Stack, Typography } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { grey } from '@mui/material/colors';
import { format, parseISO } from 'date-fns';
import type { MouseEvent } from 'react';
import { useCallback } from 'react';
import AppointmentStatusChip from 'components/appointments/AppointmentStatusChip';
import type { AppointmentStatus, AppointmentsFragment } from 'graphql/generated/staff/graphql';
import ServiceChips from 'components/ServiceChips';
import { getAge } from 'helpers/date';

type Appointment = AppointmentsFragment['appointments']['nodes'][number];

interface Props {
  appointment: Appointment;
  onSelectAppointment?: (appointmentId: string) => void;
  onChangeStatus?: (appointmentId: string, status: AppointmentStatus) => void;
}

export const AppointmentRow = (props: Props) => {
  const { clinicUser, guestUser } = props.appointment;
  const clinicPet = props.appointment.clinicPet;
  const pet = clinicPet?.pet;

  const handleSelectAppointment = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      const { currentTarget } = e;
      const target = e.target as Node;
      const cell = currentTarget?.querySelector('.status-cell');
      if (target && currentTarget && (cell?.contains(target) || !currentTarget.contains(target))) {
        e.preventDefault();
        return;
      }
      props.onSelectAppointment?.(props.appointment.id);
    },
    [props.onSelectAppointment, props.appointment.id]
  );

  const onChangeStatus = useCallback((status: AppointmentStatus) => {
    if (props.onChangeStatus) {
      props.onChangeStatus(props.appointment.id, status);
    }
  }, []);

  return (
    <TableRow hover onClick={handleSelectAppointment} sx={{ cursor: 'pointer' }}>
      <TableCell align="right">
        <Typography variant="caption" color={grey[700]}>
          {format(parseISO(props.appointment.startAt), 'yyyy年MM月dd日')}
        </Typography>
        <Typography variant="body2">
          {format(parseISO(props.appointment.startAt), 'HH:mm')}
          &nbsp;-&nbsp;
          {format(parseISO(props.appointment.endAt), 'HH:mm')}
        </Typography>
      </TableCell>
      <TableCell align="center" className="status-cell">
        <AppointmentStatusChip status={props.appointment.status} onChangeStatus={onChangeStatus} />
      </TableCell>
      <TableCell>
        <Box display="flex" alignItems="center" color={grey[900]} mb={1}>
          <Avatar src={clinicUser?.user?.profileImageURL ?? ''} />
          <Typography variant="body2" ml={1}>
            {clinicUser?.user.name || guestUser?.name}
          </Typography>
        </Box>
        <Typography variant="caption" color={grey[800]}>
          診察券 {clinicUser?.patientNumber}
        </Typography>
      </TableCell>
      <TableCell>
        <Box display="flex" alignItems="center" color={grey[900]} mb={1}>
          <Avatar src={pet?.profileImageURL ?? ''}>
            <PetsIcon />
          </Avatar>
          <Box ml={2} textAlign="left">
            <Typography variant="body2">{pet?.name || guestUser?.petName}</Typography>
            <Stack direction="row" spacing={1}>
              <Typography variant="caption" color={grey[600]}>
                {pet?.animal?.name || guestUser?.animal?.name}
              </Typography>
              <Typography variant="caption" color={grey[600]}>
                {getAge(pet?.birthday, '歳')}
              </Typography>
            </Stack>
          </Box>
        </Box>
        <Typography variant="caption" color={grey[800]}>
          診察券 {clinicPet?.patientNumber}
        </Typography>
      </TableCell>
      <TableCell>
        <Box display="flex" alignItems="center" mb={1}>
          <Avatar
            src={props.appointment.resource?.profileImageURL ?? ''}
            sx={{ width: 20, height: 20 }}
          />
          <Typography variant="body2" ml={0.5}>
            {props.appointment.resource?.name}
          </Typography>
        </Box>
        <ServiceChips services={props.appointment.service ? [props.appointment.service] : []} />
      </TableCell>
      <TableCell align="center">{props.appointment.note ? '入力あり' : '-'}</TableCell>
    </TableRow>
  );
};
