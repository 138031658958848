/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
};

export type Animal = {
  __typename?: 'Animal';
  genders: Array<PetGender>;
  id: Scalars['ID'];
  name: Scalars['String'];
  types: Array<AnimalType>;
};

export type AnimalType = {
  __typename?: 'AnimalType';
  animal: Animal;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type AnimalTypesInput = {
  ids: Array<Scalars['ID']>;
};

export type AppAgreement = {
  __typename?: 'AppAgreement';
  acceptedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  text: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
  version: Scalars['Int'];
};

export type Appointment = {
  __typename?: 'Appointment';
  clinicNote?: Maybe<Scalars['String']>;
  clinicPet?: Maybe<ClinicPet>;
  clinicUser?: Maybe<ClinicUser>;
  createdAt: Scalars['DateTime'];
  endAt: Scalars['DateTime'];
  guestUser?: Maybe<GuestUser>;
  history?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isFirst: Scalars['Boolean'];
  note?: Maybe<Scalars['String']>;
  onset?: Maybe<Scalars['String']>;
  resource?: Maybe<Resource>;
  service?: Maybe<Service>;
  startAt: Scalars['DateTime'];
  status: AppointmentStatus;
  symptom?: Maybe<Scalars['String']>;
  type?: Maybe<AppointmentType>;
  updatedAt: Scalars['DateTime'];
};

export enum AppointmentStatus {
  Booked = 'BOOKED',
  Canceled = 'CANCELED',
  Visited = 'VISITED',
  Waiting = 'WAITING',
}

export type AppointmentTimestamp = {
  __typename?: 'AppointmentTimestamp';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export enum AppointmentType {
  Contact = 'CONTACT',
  Others = 'OTHERS',
  PhoneCall = 'PHONE_CALL',
  Web = 'WEB',
}

export type AppointmentsConnection = {
  __typename?: 'AppointmentsConnection';
  nodes: Array<Appointment>;
  totalCount: Scalars['Int'];
};

export type Authentication = {
  __typename?: 'Authentication';
  createdAt: Scalars['DateTime'];
  externalIdentifier: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type Clinic = {
  __typename?: 'Clinic';
  agreements: Array<ClinicAgreement>;
  animals: Array<Animal>;
  cancellationDeadlineDays?: Maybe<Scalars['Int']>;
  cancellationDeadlineHours?: Maybe<Scalars['Int']>;
  clinicBussinessHour?: Maybe<ClinicBussinessHour>;
  createdAt: Scalars['DateTime'];
  holidays: Array<DayOfWeek>;
  id: Scalars['ID'];
  latestAgreement?: Maybe<ClinicAgreement>;
  logoImageURL?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  reservationLimitDaysFrom?: Maybe<Scalars['Int']>;
  reservationLimitDaysTo?: Maybe<Scalars['Int']>;
  reservationLimitHoursFrom?: Maybe<Scalars['Int']>;
  reservationLimitHoursTo?: Maybe<Scalars['Int']>;
  serviceCategories: Array<ServiceCategory>;
  slotDuration?: Maybe<Scalars['Int']>;
  slug: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ClinicAgreement = {
  __typename?: 'ClinicAgreement';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  text: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  version: Scalars['String'];
};

export type ClinicBussinessHour = {
  __typename?: 'ClinicBussinessHour';
  end: Time;
  id: Scalars['ID'];
  start: Time;
};

export type ClinicBussinessHourInput = {
  end: TimeInput;
  start: TimeInput;
};

export type ClinicPet = {
  __typename?: 'ClinicPet';
  clinic?: Maybe<Clinic>;
  clinicUser: ClinicUser;
  id: Scalars['ID'];
  note: Scalars['String'];
  patientNumber?: Maybe<Scalars['String']>;
  pet?: Maybe<Pet>;
};

export type ClinicService = {
  __typename?: 'ClinicService';
  clinic: Clinic;
  id: Scalars['ID'];
  service: Service;
};

export type ClinicUser = {
  __typename?: 'ClinicUser';
  acceptedClinicAgreement?: Maybe<ClinicAgreement>;
  appointments: Array<Appointment>;
  clinic: Clinic;
  clinicPets: Array<ClinicPet>;
  id: Scalars['ID'];
  marked: Scalars['Boolean'];
  note: Scalars['String'];
  patientNumber?: Maybe<Scalars['String']>;
  point: Scalars['Int'];
  user: User;
};

export type CreateAppointmentInput = {
  clinicNote?: InputMaybe<Scalars['String']>;
  clinicPetId?: InputMaybe<Scalars['ID']>;
  clinicServiceId?: InputMaybe<Scalars['ID']>;
  clinicUserId?: InputMaybe<Scalars['ID']>;
  endAt: Scalars['DateTime'];
  guestUser?: InputMaybe<CreateGuestUserInput>;
  history?: InputMaybe<Scalars['String']>;
  isFirst: Scalars['Boolean'];
  note?: InputMaybe<Scalars['String']>;
  onset?: InputMaybe<Scalars['String']>;
  resourceId: Scalars['ID'];
  startAt: Scalars['DateTime'];
  symptom?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<AppointmentType>;
};

export type CreateGuestUserInput = {
  address?: InputMaybe<Scalars['String']>;
  animalId?: InputMaybe<Scalars['ID']>;
  animalTypeId?: InputMaybe<Scalars['ID']>;
  email?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  petName: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type CreateResourceHolidayInput = {
  date: Scalars['Date'];
  resourceId: Scalars['ID'];
};

export type CreateResourceInput = {
  kindId: Scalars['ID'];
  maxParallel?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  order?: InputMaybe<Scalars['Int']>;
  profile?: InputMaybe<Scalars['String']>;
  profileImageURL?: InputMaybe<Scalars['String']>;
  serviceIds: Array<Scalars['ID']>;
};

export type CreateResourceWorkingHourInput = {
  day: DayOfWeek;
  end: TimeInput;
  resourceId: Scalars['ID'];
  start: TimeInput;
};

export type CreateStaffInput = {
  email: Scalars['String'];
  name: Scalars['String'];
};

export enum DayOfWeek {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
}

export enum FileType {
  Jpeg = 'JPEG',
  Png = 'PNG',
}

export type Frequency = {
  __typename?: 'Frequency';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type GuestUser = {
  __typename?: 'GuestUser';
  address: Scalars['String'];
  animal?: Maybe<Animal>;
  animalType?: Maybe<AnimalType>;
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  petName: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type HolidaysInput = {
  days: Array<DayOfWeek>;
};

export enum Locale {
  En = 'EN',
  Ja = 'JA',
}

export type Mutation = {
  __typename?: 'Mutation';
  createAppointment: Appointment;
  createNewClinicAgreement: ClinicAgreement;
  createResource: Resource;
  createResourceHoliday: ResourceHoliday;
  createResourceWorkingHour: ResourceWorkingHour;
  createStaff: Staff;
  deleteResource?: Maybe<Resource>;
  deleteResourceHoliday?: Maybe<ResourceHoliday>;
  deleteResourceWorkingHour?: Maybe<ResourceWorkingHour>;
  deleteStaff: Staff;
  updateAppointment: Appointment;
  updateClinic: Clinic;
  updateClinicPet: ClinicPet;
  updateClinicUser: ClinicUser;
  updateResource: Resource;
  updateResourceWorkingHour: ResourceWorkingHour;
  updateStaff: Staff;
};

export type MutationCreateAppointmentArgs = {
  input: CreateAppointmentInput;
};

export type MutationCreateNewClinicAgreementArgs = {
  text: Scalars['String'];
};

export type MutationCreateResourceArgs = {
  input: CreateResourceInput;
};

export type MutationCreateResourceHolidayArgs = {
  input: CreateResourceHolidayInput;
};

export type MutationCreateResourceWorkingHourArgs = {
  input: CreateResourceWorkingHourInput;
};

export type MutationCreateStaffArgs = {
  input: CreateStaffInput;
};

export type MutationDeleteResourceArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteResourceHolidayArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteResourceWorkingHourArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteStaffArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateAppointmentArgs = {
  input: UpdateAppointmentInput;
};

export type MutationUpdateClinicArgs = {
  input: UpdateClinicInput;
};

export type MutationUpdateClinicPetArgs = {
  input: UpdateClinicPetInput;
};

export type MutationUpdateClinicUserArgs = {
  input: UpdateClinicUserInput;
};

export type MutationUpdateResourceArgs = {
  input: UpdateResourceInput;
};

export type MutationUpdateResourceWorkingHourArgs = {
  input: UpdateResourceWorkingHourInput;
};

export type MutationUpdateStaffArgs = {
  input: UpdateStaffInput;
};

export type PasswordReset = {
  __typename?: 'PasswordReset';
  createdAt: Scalars['DateTime'];
  externalIdentifier: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type Pet = {
  __typename?: 'Pet';
  animal?: Maybe<Animal>;
  animalType?: Maybe<AnimalType>;
  appointments: Array<Appointment>;
  birthday: Scalars['DateTime'];
  figure: Scalars['String'];
  food?: Maybe<Scalars['String']>;
  furColor?: Maybe<Scalars['String']>;
  gender: PetGender;
  id: Scalars['ID'];
  insurance: Scalars['String'];
  insuranceNumber?: Maybe<Scalars['String']>;
  microChip?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  profileImageURL?: Maybe<Scalars['String']>;
  profileImageUploadURL: Scalars['String'];
  user?: Maybe<User>;
  weight?: Maybe<Scalars['Float']>;
};

export type PetProfileImageUploadUrlArgs = {
  fileName: Scalars['String'];
  type: FileType;
};

export enum PetGender {
  Female = 'FEMALE',
  FemaleNeutered = 'FEMALE_NEUTERED',
  Male = 'MALE',
  MaleNeutered = 'MALE_NEUTERED',
  Others = 'OTHERS',
}

export type Prefecture = {
  __typename?: 'Prefecture';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  animals: Array<Animal>;
  appointment: Appointment;
  appointmentTimestamps: Array<AppointmentTimestamp>;
  appointments: AppointmentsConnection;
  clinicPet: ClinicPet;
  clinicPets: Array<ClinicPet>;
  clinicUser?: Maybe<ClinicUser>;
  clinicUsers: Array<ClinicUser>;
  imageUploadURL: Scalars['String'];
  resource: Resource;
  resourceKinds: Array<ResourceKind>;
  resources: Array<Resource>;
  serviceCategories: Array<ServiceCategory>;
  staff: Staff;
  staffs: Array<Staff>;
  viewer: Staff;
};

export type QueryAppointmentArgs = {
  id: Scalars['ID'];
};

export type QueryAppointmentTimestampsArgs = {
  from?: InputMaybe<Scalars['DateTime']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  resourceId?: InputMaybe<Scalars['ID']>;
  serviceCategoryId?: InputMaybe<Scalars['ID']>;
  serviceId?: InputMaybe<Scalars['ID']>;
  statuses?: InputMaybe<Array<AppointmentStatus>>;
  to?: InputMaybe<Scalars['DateTime']>;
  updatedSince?: InputMaybe<Scalars['DateTime']>;
};

export type QueryAppointmentsArgs = {
  from?: InputMaybe<Scalars['DateTime']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  resourceId?: InputMaybe<Scalars['ID']>;
  serviceCategoryId?: InputMaybe<Scalars['ID']>;
  serviceId?: InputMaybe<Scalars['ID']>;
  statuses?: InputMaybe<Array<AppointmentStatus>>;
  to?: InputMaybe<Scalars['DateTime']>;
  updatedSince?: InputMaybe<Scalars['DateTime']>;
};

export type QueryClinicPetArgs = {
  id: Scalars['ID'];
};

export type QueryClinicPetsArgs = {
  name?: InputMaybe<Scalars['String']>;
  word?: InputMaybe<Scalars['String']>;
};

export type QueryClinicUserArgs = {
  id: Scalars['ID'];
};

export type QueryClinicUsersArgs = {
  name?: InputMaybe<Scalars['String']>;
  word?: InputMaybe<Scalars['String']>;
};

export type QueryImageUploadUrlArgs = {
  fileName: Scalars['String'];
  type: FileType;
};

export type QueryResourceArgs = {
  id: Scalars['ID'];
};

export type QueryResourcesArgs = {
  resourceKindID?: InputMaybe<Scalars['ID']>;
  serviceID?: InputMaybe<Scalars['ID']>;
};

export type QueryStaffArgs = {
  id: Scalars['ID'];
};

export type Resource = {
  __typename?: 'Resource';
  clinic: Clinic;
  createdAt: Scalars['DateTime'];
  holidays: Array<ResourceHoliday>;
  id: Scalars['ID'];
  kind: ResourceKind;
  maxParallel: Scalars['Int'];
  name: Scalars['String'];
  order: Scalars['Int'];
  profile: Scalars['String'];
  profileImageURL?: Maybe<Scalars['String']>;
  profileImageUploadURL: Scalars['String'];
  services: Array<Service>;
  updatedAt: Scalars['DateTime'];
  workingHours: Array<ResourceWorkingHour>;
};

export type ResourceHolidaysArgs = {
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
};

export type ResourceProfileImageUploadUrlArgs = {
  fileName: Scalars['String'];
  type: FileType;
};

export type ResourceHoliday = {
  __typename?: 'ResourceHoliday';
  date?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  resource?: Maybe<Resource>;
};

export type ResourceKind = {
  __typename?: 'ResourceKind';
  id: Scalars['ID'];
  name: Scalars['String'];
  resources: Array<Resource>;
};

export type ResourceWorkingHour = {
  __typename?: 'ResourceWorkingHour';
  day: DayOfWeek;
  end: Time;
  id: Scalars['ID'];
  start: Time;
};

export type Service = {
  __typename?: 'Service';
  category: ServiceCategory;
  clinicService: ClinicService;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  durationMinutes?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  resources: Array<Resource>;
  updatedAt: Scalars['DateTime'];
};

export type ServiceCategory = {
  __typename?: 'ServiceCategory';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  services: Array<Service>;
  updatedAt: Scalars['DateTime'];
};

export type Staff = {
  __typename?: 'Staff';
  clinic?: Maybe<Clinic>;
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  id: Scalars['ID'];
  locale: Locale;
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type Time = {
  __typename?: 'Time';
  hour: Scalars['Int'];
  minute: Scalars['Int'];
};

export type TimeInput = {
  hour: Scalars['Int'];
  minute: Scalars['Int'];
};

export type UpdateAppointmentInput = {
  clinicNote?: InputMaybe<Scalars['String']>;
  clinicPetId?: InputMaybe<Scalars['ID']>;
  clinicServiceId?: InputMaybe<Scalars['ID']>;
  clinicUserId?: InputMaybe<Scalars['ID']>;
  endAt?: InputMaybe<Scalars['DateTime']>;
  guestUser?: InputMaybe<UpdateGuestUserInput>;
  history?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  isFirst?: InputMaybe<Scalars['Boolean']>;
  note?: InputMaybe<Scalars['String']>;
  onset?: InputMaybe<Scalars['String']>;
  resourceId?: InputMaybe<Scalars['ID']>;
  startAt?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<AppointmentStatus>;
  symptom?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<AppointmentType>;
};

export type UpdateClinicInput = {
  animalTypes?: InputMaybe<AnimalTypesInput>;
  cancellationDeadlineDays?: InputMaybe<Scalars['Int']>;
  cancellationDeadlineHours?: InputMaybe<Scalars['Int']>;
  /**  FIXME: gqlgen generates non nullable type for array of scalars, but it should be nullable  */
  clinicBussinessHour?: InputMaybe<ClinicBussinessHourInput>;
  holidays?: InputMaybe<HolidaysInput>;
  logoImageURL?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  reservationLimitDaysFrom?: InputMaybe<Scalars['Int']>;
  reservationLimitDaysTo?: InputMaybe<Scalars['Int']>;
  reservationLimitHoursFrom?: InputMaybe<Scalars['Int']>;
  reservationLimitHoursTo?: InputMaybe<Scalars['Int']>;
  slotDuration?: InputMaybe<Scalars['Int']>;
};

export type UpdateClinicPetInput = {
  id: Scalars['ID'];
  note?: InputMaybe<Scalars['String']>;
  patientNumber?: InputMaybe<Scalars['String']>;
};

export type UpdateClinicUserInput = {
  id: Scalars['ID'];
  marked?: InputMaybe<Scalars['Boolean']>;
  note?: InputMaybe<Scalars['String']>;
  patientNumber?: InputMaybe<Scalars['String']>;
};

export type UpdateGuestUserInput = {
  address?: InputMaybe<Scalars['String']>;
  animalId?: InputMaybe<Scalars['ID']>;
  animalTypeId?: InputMaybe<Scalars['ID']>;
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  petName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type UpdateResourceInput = {
  id: Scalars['ID'];
  kindId?: InputMaybe<Scalars['ID']>;
  maxParallel?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  profile?: InputMaybe<Scalars['String']>;
  profileImageURL?: InputMaybe<Scalars['String']>;
  serviceIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type UpdateResourceWorkingHourInput = {
  day?: InputMaybe<DayOfWeek>;
  end?: InputMaybe<TimeInput>;
  id: Scalars['ID'];
  start?: InputMaybe<TimeInput>;
};

export type UpdateStaffInput = {
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  acceptedAppAgreement?: Maybe<AppAgreement>;
  building?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  displayName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  id: Scalars['ID'];
  locale: Locale;
  name: Scalars['String'];
  pet: Pet;
  pets: Array<Pet>;
  phoneNumber?: Maybe<Scalars['String']>;
  prefecture?: Maybe<Prefecture>;
  profileImageURL?: Maybe<Scalars['String']>;
  profileImageUploadURL: Scalars['String'];
  street?: Maybe<Scalars['String']>;
  trimmingFrequency?: Maybe<Frequency>;
  updatedAt: Scalars['DateTime'];
  visitingFrequency?: Maybe<Frequency>;
  zipCode?: Maybe<Scalars['String']>;
};

export type UserPetArgs = {
  id: Scalars['ID'];
};

export type UserProfileImageUploadUrlArgs = {
  fileName: Scalars['String'];
  type: FileType;
};

export type AppointmentDetailFragment = {
  __typename?: 'Appointment';
  id: string;
  status: AppointmentStatus;
  startAt: any;
  endAt: any;
  isFirst: boolean;
  type?: AppointmentType | null;
  symptom?: string | null;
  history?: string | null;
  onset?: string | null;
  note?: string | null;
  createdAt: any;
  updatedAt: any;
  clinicNote?: string | null;
  resource?: { __typename?: 'Resource'; id: string } | null;
  clinicPet?: {
    __typename?: 'ClinicPet';
    id: string;
    patientNumber?: string | null;
    pet?: {
      __typename?: 'Pet';
      id: string;
      name: string;
      animal?: { __typename?: 'Animal'; id: string; name: string } | null;
      animalType?: { __typename?: 'AnimalType'; id: string; name: string } | null;
    } | null;
  } | null;
  guestUser?: {
    __typename?: 'GuestUser';
    id: string;
    name: string;
    email: string;
    petName: string;
    phoneNumber: string;
    address: string;
    animal?: { __typename?: 'Animal'; id: string; name: string } | null;
    animalType?: { __typename?: 'AnimalType'; id: string; name: string } | null;
  } | null;
  service?: {
    __typename?: 'Service';
    clinicService: { __typename?: 'ClinicService'; id: string };
  } | null;
  clinicUser?: {
    __typename?: 'ClinicUser';
    id: string;
    patientNumber?: string | null;
    user: {
      __typename?: 'User';
      id: string;
      name: string;
      phoneNumber?: string | null;
      email: string;
      city?: string | null;
      street?: string | null;
      building?: string | null;
      profileImageURL?: string | null;
      prefecture?: { __typename?: 'Prefecture'; name: string } | null;
    };
  } | null;
} & { ' $fragmentName'?: 'AppointmentDetailFragment' };

export type AppointmentsFragment = {
  __typename?: 'Query';
  appointments: {
    __typename?: 'AppointmentsConnection';
    totalCount: number;
    nodes: Array<{
      __typename?: 'Appointment';
      id: string;
      startAt: any;
      endAt: any;
      isFirst: boolean;
      status: AppointmentStatus;
      createdAt: any;
      updatedAt: any;
      note?: string | null;
      resource?: {
        __typename?: 'Resource';
        id: string;
        name: string;
        profileImageURL?: string | null;
      } | null;
      clinicUser?: {
        __typename?: 'ClinicUser';
        id: string;
        patientNumber?: string | null;
        user: {
          __typename?: 'User';
          id: string;
          name: string;
          phoneNumber?: string | null;
          profileImageURL?: string | null;
        };
      } | null;
      service?: {
        __typename?: 'Service';
        id: string;
        name: string;
        category: { __typename?: 'ServiceCategory'; id: string; name: string };
      } | null;
      guestUser?: {
        __typename?: 'GuestUser';
        id: string;
        name: string;
        petName: string;
        phoneNumber: string;
        animal?: { __typename?: 'Animal'; id: string; name: string } | null;
        animalType?: { __typename?: 'AnimalType'; id: string; name: string } | null;
      } | null;
      clinicPet?: {
        __typename?: 'ClinicPet';
        patientNumber?: string | null;
        pet?: {
          __typename?: 'Pet';
          id: string;
          name: string;
          profileImageURL?: string | null;
          birthday: any;
          animal?: { __typename?: 'Animal'; id: string; name: string } | null;
          animalType?: { __typename?: 'AnimalType'; id: string; name: string } | null;
        } | null;
      } | null;
    }>;
  };
  appointmentTimestamps: Array<{
    __typename?: 'AppointmentTimestamp';
    id: string;
    createdAt: any;
    updatedAt: any;
  }>;
} & { ' $fragmentName'?: 'AppointmentsFragment' };

export type ResourceDetailFragment = {
  __typename?: 'Resource';
  id: string;
  name: string;
  profileImageURL?: string | null;
  profile: string;
  maxParallel: number;
  services: Array<{
    __typename?: 'Service';
    id: string;
    name: string;
    category: { __typename?: 'ServiceCategory'; id: string; name: string };
  }>;
  kind: { __typename?: 'ResourceKind'; id: string; name: string };
} & { ' $fragmentName'?: 'ResourceDetailFragment' };

export type ResourceWorkingHoursFragment = ({
  __typename?: 'Resource';
  workingHours: Array<{
    __typename?: 'ResourceWorkingHour';
    id: string;
    day: DayOfWeek;
    start: { __typename?: 'Time'; hour: number; minute: number };
    end: { __typename?: 'Time'; hour: number; minute: number };
  }>;
} & { ' $fragmentRefs'?: { ResourceDetailFragment: ResourceDetailFragment } }) & {
  ' $fragmentName'?: 'ResourceWorkingHoursFragment';
};

export type CreateAppointmentMutationVariables = Exact<{
  input: CreateAppointmentInput;
}>;

export type CreateAppointmentMutation = {
  __typename?: 'Mutation';
  createAppointment: { __typename?: 'Appointment' } & {
    ' $fragmentRefs'?: { AppointmentDetailFragment: AppointmentDetailFragment };
  };
};

export type CreateNewClinicAgreementMutationVariables = Exact<{
  text: Scalars['String'];
}>;

export type CreateNewClinicAgreementMutation = {
  __typename?: 'Mutation';
  createNewClinicAgreement: {
    __typename?: 'ClinicAgreement';
    id: string;
    text: string;
    createdAt: any;
    updatedAt: any;
    version: string;
  };
};

export type CreateResourceMutationVariables = Exact<{
  input: CreateResourceInput;
}>;

export type CreateResourceMutation = {
  __typename?: 'Mutation';
  createResource: { __typename?: 'Resource' } & {
    ' $fragmentRefs'?: { ResourceDetailFragment: ResourceDetailFragment };
  };
};

export type CreateResourceHolidayMutationVariables = Exact<{
  input: CreateResourceHolidayInput;
}>;

export type CreateResourceHolidayMutation = {
  __typename?: 'Mutation';
  createResourceHoliday: { __typename?: 'ResourceHoliday'; id: string; date?: any | null };
};

export type CreateResourceWorkingHourMutationVariables = Exact<{
  input: CreateResourceWorkingHourInput;
}>;

export type CreateResourceWorkingHourMutation = {
  __typename?: 'Mutation';
  createResourceWorkingHour: {
    __typename?: 'ResourceWorkingHour';
    id: string;
    day: DayOfWeek;
    start: { __typename?: 'Time'; hour: number; minute: number };
    end: { __typename?: 'Time'; hour: number; minute: number };
  };
};

export type DeleteResourceMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteResourceMutation = {
  __typename?: 'Mutation';
  deleteResource?: { __typename?: 'Resource'; id: string } | null;
};

export type DeleteResourceHolidayMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteResourceHolidayMutation = {
  __typename?: 'Mutation';
  deleteResourceHoliday?: { __typename?: 'ResourceHoliday'; id: string } | null;
};

export type DeleteResourceWorkingHourMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteResourceWorkingHourMutation = {
  __typename?: 'Mutation';
  deleteResourceWorkingHour?: { __typename?: 'ResourceWorkingHour'; id: string } | null;
};

export type UpdateAppointmentMutationVariables = Exact<{
  input: UpdateAppointmentInput;
}>;

export type UpdateAppointmentMutation = {
  __typename?: 'Mutation';
  updateAppointment: { __typename?: 'Appointment' } & {
    ' $fragmentRefs'?: { AppointmentDetailFragment: AppointmentDetailFragment };
  };
};

export type UpdateClinicMutationVariables = Exact<{
  input: UpdateClinicInput;
}>;

export type UpdateClinicMutation = {
  __typename?: 'Mutation';
  updateClinic: {
    __typename?: 'Clinic';
    id: string;
    name: string;
    slug: string;
    createdAt: any;
    updatedAt: any;
    logoImageURL?: string | null;
    slotDuration?: number | null;
    reservationLimitDaysFrom?: number | null;
    reservationLimitDaysTo?: number | null;
    reservationLimitHoursFrom?: number | null;
    reservationLimitHoursTo?: number | null;
    cancellationDeadlineDays?: number | null;
    cancellationDeadlineHours?: number | null;
    holidays: Array<DayOfWeek>;
    serviceCategories: Array<{
      __typename?: 'ServiceCategory';
      id: string;
      name: string;
      services: Array<{ __typename?: 'Service'; id: string; name: string }>;
    }>;
    clinicBussinessHour?: {
      __typename?: 'ClinicBussinessHour';
      id: string;
      start: { __typename?: 'Time'; hour: number; minute: number };
      end: { __typename?: 'Time'; hour: number; minute: number };
    } | null;
    animals: Array<{
      __typename?: 'Animal';
      id: string;
      name: string;
      types: Array<{ __typename?: 'AnimalType'; id: string; name: string }>;
    }>;
    latestAgreement?: {
      __typename?: 'ClinicAgreement';
      id: string;
      text: string;
      version: string;
      createdAt: any;
      updatedAt: any;
    } | null;
  };
};

export type UpdateClinicPetMutationVariables = Exact<{
  input: UpdateClinicPetInput;
}>;

export type UpdateClinicPetMutation = {
  __typename?: 'Mutation';
  updateClinicPet: { __typename?: 'ClinicPet'; id: string };
};

export type UpdateClinicUserMutationVariables = Exact<{
  input: UpdateClinicUserInput;
}>;

export type UpdateClinicUserMutation = {
  __typename?: 'Mutation';
  updateClinicUser: { __typename?: 'ClinicUser'; id: string };
};

export type UpdateResourceMutationVariables = Exact<{
  input: UpdateResourceInput;
}>;

export type UpdateResourceMutation = {
  __typename?: 'Mutation';
  updateResource: { __typename?: 'Resource' } & {
    ' $fragmentRefs'?: { ResourceDetailFragment: ResourceDetailFragment };
  };
};

export type UpdateResourceWorkingHourMutationVariables = Exact<{
  input: UpdateResourceWorkingHourInput;
}>;

export type UpdateResourceWorkingHourMutation = {
  __typename?: 'Mutation';
  updateResourceWorkingHour: {
    __typename?: 'ResourceWorkingHour';
    id: string;
    day: DayOfWeek;
    start: { __typename?: 'Time'; hour: number; minute: number };
    end: { __typename?: 'Time'; hour: number; minute: number };
  };
};

export type AppointmentCountQueryVariables = Exact<{
  todayFrom: Scalars['DateTime'];
  todayTo: Scalars['DateTime'];
  tomorrowFrom: Scalars['DateTime'];
  tomorrowTo: Scalars['DateTime'];
}>;

export type AppointmentCountQuery = {
  __typename?: 'Query';
  todayApppointmentCount: { __typename?: 'AppointmentsConnection'; totalCount: number };
  tomorrowApppointmentCount: { __typename?: 'AppointmentsConnection'; totalCount: number };
};

export type AppointmentDetailQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type AppointmentDetailQuery = {
  __typename?: 'Query';
  appointment: { __typename?: 'Appointment' } & {
    ' $fragmentRefs'?: { AppointmentDetailFragment: AppointmentDetailFragment };
  };
};

export type AppointmentFormOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type AppointmentFormOptionsQuery = {
  __typename?: 'Query';
  clinicUsers: Array<{
    __typename?: 'ClinicUser';
    id: string;
    patientNumber?: string | null;
    user: { __typename?: 'User'; id: string; name: string };
    clinicPets: Array<{
      __typename?: 'ClinicPet';
      id: string;
      patientNumber?: string | null;
      pet?: {
        __typename?: 'Pet';
        name: string;
        animal?: { __typename?: 'Animal'; id: string; name: string } | null;
        animalType?: { __typename?: 'AnimalType'; id: string; name: string } | null;
      } | null;
    }>;
  }>;
  resources: Array<{
    __typename?: 'Resource';
    id: string;
    name: string;
    services: Array<{ __typename?: 'Service'; id: string }>;
  }>;
  viewer: {
    __typename?: 'Staff';
    clinic?: {
      __typename?: 'Clinic';
      serviceCategories: Array<{
        __typename?: 'ServiceCategory';
        id: string;
        name: string;
        services: Array<{
          __typename?: 'Service';
          id: string;
          name: string;
          clinicService: { __typename?: 'ClinicService'; id: string };
        }>;
      }>;
      animals: Array<{
        __typename?: 'Animal';
        id: string;
        name: string;
        types: Array<{ __typename?: 'AnimalType'; id: string; name: string }>;
      }>;
    } | null;
  };
};

export type AppointmentsQueryVariables = Exact<{
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
  statuses?: InputMaybe<Array<AppointmentStatus> | AppointmentStatus>;
  resourceId?: InputMaybe<Scalars['ID']>;
  serviceId?: InputMaybe<Scalars['ID']>;
  serviceCategoryId?: InputMaybe<Scalars['ID']>;
  updatedSince?: InputMaybe<Scalars['DateTime']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;

export type AppointmentsQuery = { __typename?: 'Query' } & {
  ' $fragmentRefs'?: { AppointmentsFragment: AppointmentsFragment };
};

export type AppointmentsCalendarQueryVariables = Exact<{
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
  statuses?: InputMaybe<Array<AppointmentStatus> | AppointmentStatus>;
  resourceId?: InputMaybe<Scalars['ID']>;
  serviceId?: InputMaybe<Scalars['ID']>;
  serviceCategoryId?: InputMaybe<Scalars['ID']>;
  offset?: InputMaybe<Scalars['Int']>;
  updatedSince?: InputMaybe<Scalars['DateTime']>;
}>;

export type AppointmentsCalendarQuery = {
  __typename?: 'Query';
  resources: Array<{
    __typename?: 'Resource';
    id: string;
    name: string;
    holidays: Array<{ __typename?: 'ResourceHoliday'; date?: any | null; id: string }>;
    workingHours: Array<{
      __typename?: 'ResourceWorkingHour';
      id: string;
      day: DayOfWeek;
      start: { __typename?: 'Time'; hour: number; minute: number };
      end: { __typename?: 'Time'; hour: number; minute: number };
    }>;
  }>;
  viewer: {
    __typename?: 'Staff';
    clinic?: {
      __typename?: 'Clinic';
      slotDuration?: number | null;
      holidays: Array<DayOfWeek>;
    } | null;
  };
} & { ' $fragmentRefs'?: { AppointmentsFragment: AppointmentsFragment } };

export type AppointmentsFilterOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type AppointmentsFilterOptionsQuery = {
  __typename?: 'Query';
  serviceCategories: Array<{
    __typename?: 'ServiceCategory';
    id: string;
    name: string;
    services: Array<{ __typename?: 'Service'; id: string; name: string }>;
  }>;
  resources: Array<{
    __typename?: 'Resource';
    id: string;
    name: string;
    kind: { __typename?: 'ResourceKind'; id: string; name: string };
  }>;
};

export type ClinicDetailQueryVariables = Exact<{ [key: string]: never }>;

export type ClinicDetailQuery = {
  __typename?: 'Query';
  animals: Array<{
    __typename?: 'Animal';
    id: string;
    name: string;
    types: Array<{ __typename?: 'AnimalType'; id: string; name: string }>;
  }>;
  viewer: {
    __typename?: 'Staff';
    clinic?: {
      __typename?: 'Clinic';
      id: string;
      name: string;
      slug: string;
      createdAt: any;
      updatedAt: any;
      logoImageURL?: string | null;
      slotDuration?: number | null;
      reservationLimitDaysFrom?: number | null;
      reservationLimitDaysTo?: number | null;
      reservationLimitHoursFrom?: number | null;
      reservationLimitHoursTo?: number | null;
      cancellationDeadlineDays?: number | null;
      cancellationDeadlineHours?: number | null;
      holidays: Array<DayOfWeek>;
      serviceCategories: Array<{
        __typename?: 'ServiceCategory';
        id: string;
        name: string;
        services: Array<{ __typename?: 'Service'; id: string; name: string }>;
      }>;
      clinicBussinessHour?: {
        __typename?: 'ClinicBussinessHour';
        id: string;
        start: { __typename?: 'Time'; hour: number; minute: number };
        end: { __typename?: 'Time'; hour: number; minute: number };
      } | null;
      animals: Array<{
        __typename?: 'Animal';
        id: string;
        name: string;
        types: Array<{ __typename?: 'AnimalType'; id: string; name: string }>;
      }>;
      latestAgreement?: {
        __typename?: 'ClinicAgreement';
        id: string;
        text: string;
        version: string;
        createdAt: any;
        updatedAt: any;
      } | null;
    } | null;
  };
};

export type CurrentStaffQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentStaffQuery = {
  __typename?: 'Query';
  viewer: {
    __typename?: 'Staff';
    id: string;
    name: string;
    email: string;
    locale: Locale;
    createdAt: any;
    updatedAt: any;
    clinic?: {
      __typename?: 'Clinic';
      id: string;
      name: string;
      slug: string;
      createdAt: any;
      updatedAt: any;
      logoImageURL?: string | null;
      slotDuration?: number | null;
      reservationLimitDaysFrom?: number | null;
      reservationLimitDaysTo?: number | null;
      reservationLimitHoursFrom?: number | null;
      reservationLimitHoursTo?: number | null;
      cancellationDeadlineDays?: number | null;
      cancellationDeadlineHours?: number | null;
      holidays: Array<DayOfWeek>;
    } | null;
  };
};

export type PetDetailQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type PetDetailQuery = {
  __typename?: 'Query';
  clinicPet: {
    __typename?: 'ClinicPet';
    id: string;
    note: string;
    patientNumber?: string | null;
    clinicUser: { __typename?: 'ClinicUser'; id: string };
    pet?: {
      __typename?: 'Pet';
      id: string;
      name: string;
      profileImageURL?: string | null;
      gender: PetGender;
      insurance: string;
      birthday: any;
      weight?: number | null;
      furColor?: string | null;
      insuranceNumber?: string | null;
      food?: string | null;
      microChip?: string | null;
      note?: string | null;
      animal?: { __typename?: 'Animal'; id: string; name: string } | null;
      animalType?: { __typename?: 'AnimalType'; id: string; name: string } | null;
    } | null;
  };
};

export type ResourceDetailQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ResourceDetailQuery = {
  __typename?: 'Query';
  resource: { __typename?: 'Resource' } & {
    ' $fragmentRefs'?: { ResourceDetailFragment: ResourceDetailFragment };
  };
};

export type ResourceFormOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type ResourceFormOptionsQuery = {
  __typename?: 'Query';
  resourceKinds: Array<{ __typename?: 'ResourceKind'; id: string; name: string }>;
  serviceCategories: Array<{
    __typename?: 'ServiceCategory';
    id: string;
    name: string;
    services: Array<{ __typename?: 'Service'; id: string; name: string }>;
  }>;
};

export type ResourceHolidaysQueryVariables = Exact<{
  id: Scalars['ID'];
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
}>;

export type ResourceHolidaysQuery = {
  __typename?: 'Query';
  resource: {
    __typename?: 'Resource';
    holidays: Array<{ __typename?: 'ResourceHoliday'; date?: any | null; id: string }>;
  } & { ' $fragmentRefs'?: { ResourceDetailFragment: ResourceDetailFragment } };
  viewer: {
    __typename?: 'Staff';
    clinic?: { __typename?: 'Clinic'; holidays: Array<DayOfWeek> } | null;
  };
};

export type ResourceProfileImageUrlQueryVariables = Exact<{
  resourceId: Scalars['ID'];
  type: FileType;
  fileName: Scalars['String'];
}>;

export type ResourceProfileImageUrlQuery = {
  __typename?: 'Query';
  resource: { __typename?: 'Resource'; profileImageUploadURL: string };
};

export type ResourceSchedulesQueryVariables = Exact<{
  resourceKindID?: InputMaybe<Scalars['ID']>;
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
}>;

export type ResourceSchedulesQuery = {
  __typename?: 'Query';
  resources: Array<{
    __typename?: 'Resource';
    id: string;
    name: string;
    kind: { __typename?: 'ResourceKind'; id: string; name: string };
    holidays: Array<{ __typename?: 'ResourceHoliday'; date?: any | null; id: string }>;
    workingHours: Array<{ __typename?: 'ResourceWorkingHour'; id: string; day: DayOfWeek }>;
  }>;
  resourceKinds: Array<{ __typename?: 'ResourceKind'; id: string; name: string }>;
};

export type ResourceWorkingHoursQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ResourceWorkingHoursQuery = {
  __typename?: 'Query';
  resource: { __typename?: 'Resource' } & {
    ' $fragmentRefs'?: { ResourceWorkingHoursFragment: ResourceWorkingHoursFragment };
  };
  viewer: {
    __typename?: 'Staff';
    clinic?: { __typename?: 'Clinic'; holidays: Array<DayOfWeek> } | null;
  };
};

export type ResourcesQueryVariables = Exact<{
  resourceKindID?: InputMaybe<Scalars['ID']>;
}>;

export type ResourcesQuery = {
  __typename?: 'Query';
  resources: Array<{
    __typename?: 'Resource';
    id: string;
    name: string;
    profileImageURL?: string | null;
    order: number;
    kind: { __typename?: 'ResourceKind'; id: string; name: string };
    services: Array<{
      __typename?: 'Service';
      id: string;
      name: string;
      category: { __typename?: 'ServiceCategory'; id: string; name: string };
    }>;
  }>;
  resourceKinds: Array<{ __typename?: 'ResourceKind'; id: string; name: string }>;
};

export type UserDetailQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type UserDetailQuery = {
  __typename?: 'Query';
  clinicUser?: {
    __typename?: 'ClinicUser';
    id: string;
    marked: boolean;
    patientNumber?: string | null;
    note: string;
    user: {
      __typename?: 'User';
      name: string;
      email: string;
      phoneNumber?: string | null;
      profileImageURL?: string | null;
      city?: string | null;
      street?: string | null;
      building?: string | null;
      prefecture?: { __typename?: 'Prefecture'; name: string } | null;
    };
    clinicPets: Array<{
      __typename?: 'ClinicPet';
      id: string;
      patientNumber?: string | null;
      pet?: {
        __typename?: 'Pet';
        id: string;
        name: string;
        birthday: any;
        gender: PetGender;
        profileImageURL?: string | null;
        animal?: { __typename?: 'Animal'; id: string; name: string } | null;
        animalType?: { __typename?: 'AnimalType'; id: string; name: string } | null;
      } | null;
    }>;
    appointments: Array<{
      __typename?: 'Appointment';
      id: string;
      startAt: any;
      endAt: any;
      status: AppointmentStatus;
      service?: {
        __typename?: 'Service';
        id: string;
        name: string;
        category: { __typename?: 'ServiceCategory'; id: string; name: string };
      } | null;
      clinicPet?: {
        __typename?: 'ClinicPet';
        pet?: {
          __typename?: 'Pet';
          name: string;
          birthday: any;
          profileImageURL?: string | null;
          animal?: { __typename?: 'Animal'; id: string; name: string } | null;
          animalType?: { __typename?: 'AnimalType'; id: string; name: string } | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type UsersQueryVariables = Exact<{
  name?: InputMaybe<Scalars['String']>;
  word?: InputMaybe<Scalars['String']>;
}>;

export type UsersQuery = {
  __typename?: 'Query';
  clinicUsers: Array<{
    __typename?: 'ClinicUser';
    id: string;
    marked: boolean;
    patientNumber?: string | null;
    user: {
      __typename?: 'User';
      name: string;
      phoneNumber?: string | null;
      profileImageURL?: string | null;
    };
    clinicPets: Array<{
      __typename?: 'ClinicPet';
      id: string;
      pet?: {
        __typename?: 'Pet';
        name: string;
        profileImageURL?: string | null;
        birthday: any;
        animal?: { __typename?: 'Animal'; id: string; name: string } | null;
        animalType?: { __typename?: 'AnimalType'; id: string; name: string } | null;
      } | null;
    }>;
    appointments: Array<{
      __typename?: 'Appointment';
      id: string;
      status: AppointmentStatus;
      startAt: any;
      endAt: any;
    }>;
  }>;
};

export const AppointmentDetailFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AppointmentDetail' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Appointment' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFirst' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'symptom' } },
          { kind: 'Field', name: { kind: 'Name', value: 'history' } },
          { kind: 'Field', name: { kind: 'Name', value: 'onset' } },
          { kind: 'Field', name: { kind: 'Name', value: 'note' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resource' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clinicPet' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'patientNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pet' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'animal' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'animalType' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'guestUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'petName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'animal' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'animalType' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'service' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'clinicService' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clinicUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'patientNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'prefecture' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'building' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'profileImageURL' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'clinicNote' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AppointmentDetailFragment, unknown>;
export const AppointmentsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Appointments' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Query' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'from' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'from' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'to' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'to' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'statuses' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'statuses' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resourceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'resourceId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'serviceCategoryId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'serviceCategoryId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'serviceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'serviceId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '50' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'updatedSince' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'updatedSince' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isFirst' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'resource' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'profileImageURL' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'clinicUser' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'patientNumber' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'profileImageURL' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'service' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'category' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'guestUser' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'petName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'animal' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'animalType' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'clinicPet' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'patientNumber' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pet' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'profileImageURL' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'birthday' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'animal' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'animalType' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointmentTimestamps' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'from' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'from' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'to' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'to' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'statuses' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'statuses' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resourceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'resourceId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'serviceCategoryId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'serviceCategoryId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'serviceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'serviceId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AppointmentsFragment, unknown>;
export const ResourceDetailFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceDetail' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Resource' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profileImageURL' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profile' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxParallel' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'services' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'category' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'kind' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResourceDetailFragment, unknown>;
export const ResourceWorkingHoursFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceWorkingHours' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Resource' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ResourceDetail' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workingHours' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'day' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'start' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hour' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'minute' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'end' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hour' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'minute' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ResourceDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<ResourceWorkingHoursFragment, unknown>;
export const CreateAppointmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateAppointment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateAppointmentInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createAppointment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AppointmentDetail' } },
              ],
            },
          },
        ],
      },
    },
    ...AppointmentDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<CreateAppointmentMutation, CreateAppointmentMutationVariables>;
export const CreateNewClinicAgreementDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateNewClinicAgreement' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'text' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createNewClinicAgreement' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'text' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'text' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateNewClinicAgreementMutation,
  CreateNewClinicAgreementMutationVariables
>;
export const CreateResourceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateResource' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateResourceInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createResource' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ResourceDetail' } },
              ],
            },
          },
        ],
      },
    },
    ...ResourceDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<CreateResourceMutation, CreateResourceMutationVariables>;
export const CreateResourceHolidayDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateResourceHoliday' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateResourceHolidayInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createResourceHoliday' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateResourceHolidayMutation, CreateResourceHolidayMutationVariables>;
export const CreateResourceWorkingHourDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateResourceWorkingHour' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateResourceWorkingHourInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createResourceWorkingHour' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'day' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'start' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hour' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'minute' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'end' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hour' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'minute' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateResourceWorkingHourMutation,
  CreateResourceWorkingHourMutationVariables
>;
export const DeleteResourceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteResource' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteResource' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteResourceMutation, DeleteResourceMutationVariables>;
export const DeleteResourceHolidayDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteResourceHoliday' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteResourceHoliday' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteResourceHolidayMutation, DeleteResourceHolidayMutationVariables>;
export const DeleteResourceWorkingHourDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteResourceWorkingHour' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteResourceWorkingHour' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteResourceWorkingHourMutation,
  DeleteResourceWorkingHourMutationVariables
>;
export const UpdateAppointmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateAppointment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateAppointmentInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAppointment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AppointmentDetail' } },
              ],
            },
          },
        ],
      },
    },
    ...AppointmentDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UpdateAppointmentMutation, UpdateAppointmentMutationVariables>;
export const UpdateClinicDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateClinic' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateClinicInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateClinic' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'logoImageURL' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slotDuration' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reservationLimitDaysFrom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reservationLimitDaysTo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reservationLimitHoursFrom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reservationLimitHoursTo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cancellationDeadlineDays' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cancellationDeadlineHours' } },
                { kind: 'Field', name: { kind: 'Name', value: 'holidays' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'serviceCategories' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'services' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'clinicBussinessHour' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'start' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'hour' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'minute' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'end' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'hour' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'minute' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'animals' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'types' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'latestAgreement' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateClinicMutation, UpdateClinicMutationVariables>;
export const UpdateClinicPetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateClinicPet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateClinicPetInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateClinicPet' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateClinicPetMutation, UpdateClinicPetMutationVariables>;
export const UpdateClinicUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateClinicUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateClinicUserInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateClinicUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateClinicUserMutation, UpdateClinicUserMutationVariables>;
export const UpdateResourceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateResource' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateResourceInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateResource' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ResourceDetail' } },
              ],
            },
          },
        ],
      },
    },
    ...ResourceDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<UpdateResourceMutation, UpdateResourceMutationVariables>;
export const UpdateResourceWorkingHourDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateResourceWorkingHour' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateResourceWorkingHourInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateResourceWorkingHour' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'day' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'start' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hour' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'minute' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'end' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hour' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'minute' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateResourceWorkingHourMutation,
  UpdateResourceWorkingHourMutationVariables
>;
export const AppointmentCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AppointmentCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'todayFrom' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'todayTo' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tomorrowFrom' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tomorrowTo' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'todayApppointmentCount' },
            name: { kind: 'Name', value: 'appointments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'from' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'todayFrom' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'to' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'todayTo' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalCount' } }],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'tomorrowApppointmentCount' },
            name: { kind: 'Name', value: 'appointments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'from' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'tomorrowFrom' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'to' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'tomorrowTo' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalCount' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AppointmentCountQuery, AppointmentCountQueryVariables>;
export const AppointmentDetailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AppointmentDetail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'AppointmentDetail' } },
              ],
            },
          },
        ],
      },
    },
    ...AppointmentDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<AppointmentDetailQuery, AppointmentDetailQueryVariables>;
export const AppointmentFormOptionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AppointmentFormOptions' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clinicUsers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'patientNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'clinicPets' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'patientNumber' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pet' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'animal' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'animalType' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resources' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'services' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'clinic' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'serviceCategories' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'services' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'clinicService' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'animals' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'types' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AppointmentFormOptionsQuery, AppointmentFormOptionsQueryVariables>;
export const AppointmentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Appointments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'from' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'to' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'statuses' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'AppointmentStatus' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'resourceId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'serviceId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'serviceCategoryId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'updatedSince' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Appointments' } }],
      },
    },
    ...AppointmentsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<AppointmentsQuery, AppointmentsQueryVariables>;
export const AppointmentsCalendarDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AppointmentsCalendar' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'from' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'to' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'statuses' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'AppointmentStatus' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'resourceId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'serviceId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'serviceCategoryId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'updatedSince' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Appointments' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resources' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'holidays' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'from' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'from' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'to' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'to' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workingHours' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'start' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'hour' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'minute' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'end' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'hour' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'minute' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'day' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'clinic' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'slotDuration' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'holidays' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...AppointmentsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<AppointmentsCalendarQuery, AppointmentsCalendarQueryVariables>;
export const AppointmentsFilterOptionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AppointmentsFilterOptions' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'serviceCategories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'services' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resources' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'kind' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AppointmentsFilterOptionsQuery,
  AppointmentsFilterOptionsQueryVariables
>;
export const ClinicDetailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ClinicDetail' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'animals' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'types' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'clinic' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logoImageURL' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slotDuration' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'reservationLimitDaysFrom' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'reservationLimitDaysTo' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'reservationLimitHoursFrom' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'reservationLimitHoursTo' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cancellationDeadlineDays' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cancellationDeadlineHours' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'holidays' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'serviceCategories' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'services' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'clinicBussinessHour' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'start' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'hour' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'minute' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'end' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'hour' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'minute' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'animals' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'types' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latestAgreement' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClinicDetailQuery, ClinicDetailQueryVariables>;
export const CurrentStaffDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CurrentStaff' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locale' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'clinic' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'logoImageURL' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slotDuration' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'reservationLimitDaysFrom' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'reservationLimitDaysTo' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'reservationLimitHoursFrom' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'reservationLimitHoursTo' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cancellationDeadlineDays' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cancellationDeadlineHours' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'holidays' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CurrentStaffQuery, CurrentStaffQueryVariables>;
export const PetDetailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PetDetail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clinicPet' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'clinicUser' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pet' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'profileImageURL' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'insurance' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'birthday' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'animal' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'animalType' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'furColor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'insuranceNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'food' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'microChip' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                { kind: 'Field', name: { kind: 'Name', value: 'patientNumber' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PetDetailQuery, PetDetailQueryVariables>;
export const ResourceDetailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ResourceDetail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resource' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ResourceDetail' } },
              ],
            },
          },
        ],
      },
    },
    ...ResourceDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<ResourceDetailQuery, ResourceDetailQueryVariables>;
export const ResourceFormOptionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ResourceFormOptions' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceKinds' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'serviceCategories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'services' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResourceFormOptionsQuery, ResourceFormOptionsQueryVariables>;
export const ResourceHolidaysDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ResourceHolidays' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'from' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'to' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resource' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ResourceDetail' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'holidays' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'from' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'from' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'to' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'to' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'clinic' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'holidays' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ResourceDetailFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<ResourceHolidaysQuery, ResourceHolidaysQueryVariables>;
export const ResourceProfileImageUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ResourceProfileImageURL' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'resourceId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'FileType' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileName' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resource' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'resourceId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profileImageUploadURL' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'type' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fileName' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'fileName' } },
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResourceProfileImageUrlQuery, ResourceProfileImageUrlQueryVariables>;
export const ResourceSchedulesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ResourceSchedules' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'resourceKindID' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'from' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'to' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resources' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resourceKindID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'resourceKindID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'kind' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'holidays' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'from' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'from' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'to' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'to' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workingHours' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'day' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceKinds' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResourceSchedulesQuery, ResourceSchedulesQueryVariables>;
export const ResourceWorkingHoursDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ResourceWorkingHours' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resource' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ResourceWorkingHours' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'clinic' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'holidays' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ResourceWorkingHoursFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<ResourceWorkingHoursQuery, ResourceWorkingHoursQueryVariables>;
export const ResourcesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Resources' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'resourceKindID' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resources' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resourceKindID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'resourceKindID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profileImageURL' } },
                { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'kind' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'services' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'category' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resourceKinds' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResourcesQuery, ResourcesQueryVariables>;
export const UserDetailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UserDetail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clinicUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'marked' } },
                { kind: 'Field', name: { kind: 'Name', value: 'patientNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'profileImageURL' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'prefecture' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'building' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'clinicPets' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'patientNumber' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pet' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'birthday' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'profileImageURL' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'animal' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'animalType' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'appointments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'service' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'category' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'clinicPet' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pet' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'birthday' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'profileImageURL' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'animal' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'animalType' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserDetailQuery, UserDetailQueryVariables>;
export const UsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Users' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'word' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clinicUsers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'word' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'word' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'marked' } },
                { kind: 'Field', name: { kind: 'Name', value: 'patientNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'profileImageURL' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'clinicPets' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pet' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'profileImageURL' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'birthday' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'animal' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'animalType' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'appointments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endAt' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UsersQuery, UsersQueryVariables>;
