import { MenuItem, MenuList, Chip as MuiChip, Popover } from '@mui/material';
import type { ComponentProps, FC, MouseEvent } from 'react';
import { useState } from 'react';
import ExpandCircleDownRoundedIcon from '@mui/icons-material/ExpandCircleDownRounded';
import styled from '@emotion/styled';
import {
  allAppointmentStatuses,
  appointmentStatusColor,
  appointmentStatusLabel,
} from 'constants/appointmentStatuses';
import type { AppointmentStatus } from 'graphql/generated/staff/graphql';

interface Props {
  status: AppointmentStatus;
  onChangeStatus?: (status: AppointmentStatus) => void;
}

type ChipProps = Omit<ComponentProps<typeof MuiChip>, 'label' | 'clickable' | 'icon'>;

export const Component: FC<ChipProps & Props> = ({
  onChangeStatus,
  status: defaultStatus,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [status, setStatus] = useState(defaultStatus);

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Chip
        label={appointmentStatusLabel(status)}
        clickable={!!onChangeStatus}
        onClick={onChangeStatus ? handleClick : undefined}
        icon={onChangeStatus && <ExpandCircleDownRoundedIcon />}
        status={status}
        {...props}
      />
      {onChangeStatus && (
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <MenuList>
            {allAppointmentStatuses
              .filter((s) => s !== status)
              .map((s) => (
                <MenuItem
                  key={s}
                  selected={s === status}
                  onClick={() => {
                    setStatus(s);
                    onChangeStatus(s);
                    handleClose();
                  }}
                >
                  {appointmentStatusLabel(s)}
                </MenuItem>
              ))}
          </MenuList>
        </Popover>
      )}
    </>
  );
};

const Chip = styled(MuiChip)<{ status: AppointmentStatus }>`
  color: white;
  background-color: ${({ status }) => appointmentStatusColor(status)};
  transition: opacity 0.3s ease-in-out;
  pointer-events: ${({ clickable }) => (clickable ? 'auto' : 'none')};
  &:hover {
    background-color: ${({ status }) => appointmentStatusColor(status)};
    opacity: 0.8;
  }
  .MuiChip-icon {
    color: white;
  }
`;
