import { enqueueSnackbar } from 'notistack';
import type { FC } from 'react';
import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import type { ConfirmPasswordResetParams } from 'clients/apis/staff';
import { confirmPasswordReset } from 'clients/apis/staff';
import ConfirmPasswordResetForm from 'components/forms/staff/ConfirmPasswordResetForm';
import * as paths from 'constants/paths/staff';

const Page: FC = () => {
  const navigate = useNavigate();
  const [requesting, setRequesting] = useState(false);
  const [error, setError] = useState('');
  const { token } = useParams() as { token: string };

  const handleSubmit = useCallback(async (values: ConfirmPasswordResetParams) => {
    setRequesting(true);
    setError('');
    try {
      const res = await confirmPasswordReset(values);
      if (res.Success) {
        enqueueSnackbar('新しいパスワードを設定しました', { variant: 'success' });
        navigate(paths.loginPath());
      } else {
        throw new Error('Failed to reset password');
      }
    } catch (e) {
      enqueueSnackbar('パスワード再設定に失敗しました', { variant: 'error' });
      setError('パスワード再設定に失敗しました');
      // eslint-disable-next-line no-console
      console.error(e);
    }
    setRequesting(false);
  }, []);

  return (
    <ConfirmPasswordResetForm
      token={token}
      onSubmit={handleSubmit}
      requesting={requesting}
      error={error}
    />
  );
};

export default Page;
