import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Typography } from '@mui/material';
import type { Time } from 'graphql/generated/staff/graphql';

interface Props {
  start: Time;
  end: Time;
  onAdd: () => void;
  onEdit: () => void;
  onDelete: () => void;
}

export const ResourceView = (props: Props) => {
  return (
    <Box width="100%" display="flex" justifyContent="space-between">
      <Box display="flex">
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography>
            {`00${props.start.hour}`.slice(-2)}:{`00${props.start.minute}`.slice(-2)}
          </Typography>
          <Typography variant="body1" sx={{ px: 3 }}>
            〜
          </Typography>
          <Typography>
            {`00${props.end.hour}`.slice(-2)}:{`00${props.end.minute}`.slice(-2)}
          </Typography>
        </Box>
        <Box>
          <Button onClick={props.onAdd}>
            <AddIcon />
          </Button>
        </Box>
      </Box>

      <Box display="flex">
        <Button onClick={props.onEdit}>
          <EditIcon />
        </Button>
        <Button onClick={props.onDelete}>
          <CloseIcon />
        </Button>
      </Box>
    </Box>
  );
};
