import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import type { PetGender } from 'graphql/generated/user/graphql';

interface Props {
  defaultValues?: Partial<PetEditorFormType>;
  catOrDogAnimalIds: string[];
}

export interface PetEditorFormType {
  id?: string;
  gender?: PetGender;
  name?: string;
  animalId?: string;
  animalTypeId?: string;
  birthday?: string;
  weight?: number | null;
  figure?: string;
  furColor?: string | null;
  insurance?: string | null;
  insuranceNumber?: string | null;
  food?: string | null;
  microChip?: string | null;
  note?: string | null;
  profileImageURL?: string | null;
}

export const usePetEditorForm = (props: Props) => {
  // フォームのバリデーションルール
  const formSchema = yup.object({
    gender: yup.string().when('animalId', (animalId, schema) => {
      // 猫 or 犬の場合は性別必須
      if (props.catOrDogAnimalIds.includes(animalId)) {
        return schema.required('性別を入力してください');
      }
      return schema;
    }),
    name: yup.string().required('名前を入力してください'),
    animalId: yup.string().required('種類を選択してください'),
    animalTypeId: yup.string(),
    birthday: yup.string(),
    weight: yup.number().transform((value) => (isNaN(value) ? undefined : value)),
    figure: yup.string(),
    furColor: yup.string(),
    insurance: yup.string(),
    insuranceNumber: yup.string(),
    food: yup.string(),
    microChip: yup.string(),
    note: yup.string(),
  });

  return useForm({
    defaultValues: props.defaultValues,
    resolver: yupResolver(formSchema),
  });
};
