import { PetGender } from 'graphql/generated/staff/graphql';

export const petGenders: Array<{
  label: string;
  value: PetGender;
}> = [
  {
    label: 'オス',
    value: PetGender.Male,
  },
  {
    label: 'メス',
    value: PetGender.Female,
  },
  {
    label: 'オス(去勢済み)',
    value: PetGender.MaleNeutered,
  },
  {
    label: 'メス(避妊済み)',
    value: PetGender.FemaleNeutered,
  },
  {
    label: 'その他',
    value: PetGender.Others,
  },
];
