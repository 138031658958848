import { useCookies } from 'react-cookie';
import { userAccessToken } from 'config/cookieKeys';

export const useAuthCookie = () => {
  const [cookies, setCookie, removeCookie] = useCookies();

  return {
    login: (accessToken: string) => {
      setCookie(userAccessToken, accessToken, {
        path: '/',
      });
      removeCookie('redirectAfterLogin', {
        path: '/',
      });
    },
    logout: () => {
      removeCookie('redirectAfterLogin', {
        path: '/',
      });

      removeCookie('userAccessToken', {
        path: '/',
      });

      removeCookie('authStatus', {
        path: '/',
      });
    },
    redirectAfterLogin: cookies.redirectAfterLogin,
  };
};
