import type { FC } from 'react';
import { useCallback } from 'react';
import { Breadcrumbs, Container, Link, Toolbar, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useMutation, useQuery } from 'urql';
import { useParams, Link as RouterLink, useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import * as pageTitles from 'constants/pageTitles/staff';
import type {
  ResourceDetailQueryVariables,
  ResourceDetailQuery,
} from 'graphql/generated/staff/graphql';
import {
  DeleteResourceDocument,
  ResourceDetailFragmentDoc,
  ResourceDetailDocument,
} from 'graphql/generated/staff/graphql';
import * as paths from 'constants/paths/staff';
import NotFoundPage from 'pages/NotFoundPage';
import FullScreenLoading from 'components/FullScreenLoading';
import ResourceDetailView from 'components/views/staff/ResourceDetailView';
import { useFragment } from 'graphql/generated/staff';

const Page: FC = () => {
  const { id } = useParams() as { id: string };
  const navigate = useNavigate();
  const [{ data, fetching, error }] = useQuery<ResourceDetailQuery, ResourceDetailQueryVariables>({
    query: ResourceDetailDocument,
    variables: { id },
  });

  const [deleteResourceResult, deleteResource] = useMutation(DeleteResourceDocument);

  const resource = useFragment(ResourceDetailFragmentDoc, data?.resource);

  const handleDelete = useCallback(async () => {
    try {
      await deleteResource({ id });
      enqueueSnackbar('削除しました', { variant: 'success' });
      navigate(paths.resourcesPath());
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      enqueueSnackbar('削除に失敗しました', { variant: 'error' });
    }
  }, [deleteResource, id]);

  if (fetching || deleteResourceResult.fetching) {
    return <FullScreenLoading />;
  }

  if (error?.networkError) {
    throw error;
  }

  if (!resource) {
    return <NotFoundPage />;
  }

  return (
    <>
      <Helmet>
        <title>
          {resource.name} - {pageTitles.resourceDetail}
        </title>
      </Helmet>
      <Toolbar>
        <Container sx={{ display: 'flex' }}>
          <Breadcrumbs sx={{ flexGrow: 1 }}>
            <Link
              component={RouterLink}
              to={paths.resourcesPath()}
              color="text.secondary"
              underline="hover"
            >
              {pageTitles.resources}
            </Link>
            <Typography color="text.primary">{resource.name}</Typography>
          </Breadcrumbs>
        </Container>
      </Toolbar>
      <Container>
        <ResourceDetailView resource={resource} onDelete={handleDelete} />
      </Container>
    </>
  );
};

export default Page;
