import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
} from '@mui/material';
import type { FC } from 'react';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  DayOfWeek,
  type ClinicDetailQuery,
  type UpdateClinicMutationVariables,
} from 'graphql/generated/staff/graphql';

type FormValues = UpdateClinicMutationVariables['input'];

interface Props {
  clinic: ClinicDetailQuery['viewer']['clinic'];
  animals: ClinicDetailQuery['animals'];
  onSubmit?: (values: FormValues) => void;
  loading?: boolean;
}

export const Component: FC<Props> = ({ clinic, animals, loading, onSubmit = () => {} }) => {
  const { handleSubmit, control, watch, setValue } = useForm<FormValues>({
    defaultValues: {
      name: clinic?.name,
      slotDuration: clinic?.slotDuration ?? 15,
      logoImageURL: clinic?.logoImageURL ?? null,
      cancellationDeadlineDays: clinic?.cancellationDeadlineDays || 1,
      cancellationDeadlineHours: clinic?.cancellationDeadlineHours || 24,
      reservationLimitDaysTo: clinic?.reservationLimitDaysTo || 3,
      reservationLimitHoursTo: clinic?.reservationLimitHoursTo || 72,
      reservationLimitDaysFrom: clinic?.reservationLimitDaysFrom || 1,
      reservationLimitHoursFrom: clinic?.reservationLimitHoursFrom || 24,
      holidays: clinic?.holidays ? { days: clinic.holidays } : { days: [] },
      clinicBussinessHour: clinic?.clinicBussinessHour
        ? {
            start: {
              hour: clinic.clinicBussinessHour.start.hour,
              minute: clinic.clinicBussinessHour.start.minute,
            },
            end: {
              hour: clinic.clinicBussinessHour.end.hour,
              minute: clinic.clinicBussinessHour.end.minute,
            },
          }
        : {
            start: { hour: 9, minute: 0 },
            end: { hour: 18, minute: 0 },
          },
      animalTypes: clinic?.animals
        ? {
            ids: clinic.animals
              .map((animal) => animal.types)
              .flat()
              .map((animalType) => animalType.id),
          }
        : { ids: [] },
    },
  });

  const preSubmit = (values: FormValues) => {
    if (!useCancellationLimit) {
      values.cancellationDeadlineDays = 0;
      values.cancellationDeadlineHours = null;
    } else if (useCancellationHours) {
      values.cancellationDeadlineDays = null;
      values.cancellationDeadlineHours = Number(values.cancellationDeadlineHours);
    } else {
      values.cancellationDeadlineHours = null;
      values.cancellationDeadlineDays = Number(values.cancellationDeadlineDays);
    }
    if (!useReservationLimit) {
      values.reservationLimitDaysTo = 0;
      values.reservationLimitHoursTo = null;
      values.reservationLimitDaysFrom = 0;
      values.reservationLimitHoursFrom = null;
    } else {
      if (useReservationHoursTo) {
        values.reservationLimitDaysTo = null;
        values.reservationLimitHoursTo = Number(values.reservationLimitHoursTo);
      } else {
        values.reservationLimitHoursTo = null;
        values.reservationLimitDaysTo = Number(values.reservationLimitDaysTo);
      }
      if (useReservationHoursFrom) {
        values.reservationLimitDaysFrom = null;
        values.reservationLimitHoursFrom = Number(values.reservationLimitHoursFrom);
      } else {
        values.reservationLimitHoursFrom = null;
        values.reservationLimitDaysFrom = Number(values.reservationLimitDaysFrom);
      }
    }
    onSubmit(values);
  };

  const [useReservationHoursTo, setUseReservationHoursTo] = useState(
    clinic?.reservationLimitHoursTo ?? 0 > 0
  );
  const [useReservationHoursFrom, setUseReservationHoursFrom] = useState(
    clinic?.reservationLimitHoursFrom ?? 0 > 0
  );
  const [useReservationLimit, setUseReservationLimit] = useState(
    Boolean(
      (clinic?.reservationLimitHoursFrom ?? 0 > 0) ||
        (clinic?.reservationLimitHoursTo ?? 0 > 0) ||
        (clinic?.reservationLimitDaysFrom ?? 0 > 0) ||
        (clinic?.reservationLimitDaysTo ?? 0 > 0)
    )
  );
  const [useCancellationHours, setUseCancellationHours] = useState(
    clinic?.cancellationDeadlineHours ?? 0 > 0
  );
  const [useCancellationLimit, setUseCancellationLimit] = useState(
    Boolean(
      (clinic?.cancellationDeadlineHours ?? 0 > 0) || (clinic?.cancellationDeadlineDays ?? 0 > 0)
    )
  );

  let minReservationLimitTo =
    watch(useReservationHoursFrom ? 'reservationLimitHoursFrom' : 'reservationLimitDaysFrom') ?? 0;
  let maxReservationLimitFrom =
    watch(useReservationHoursTo ? 'reservationLimitHoursTo' : 'reservationLimitDaysTo') ?? 0;
  if (useReservationHoursFrom) {
    if (!useReservationHoursTo) {
      minReservationLimitTo /= 24;
      maxReservationLimitFrom *= 24;
    }
  } else {
    if (useReservationHoursTo) {
      minReservationLimitTo *= 24;
      maxReservationLimitFrom /= 24;
    }
  }

  return (
    <form onSubmit={handleSubmit(preSubmit)}>
      <Stack gap={4}>
        <Controller
          control={control}
          name="name"
          rules={{ required: '必須項目です' }}
          render={({ field, fieldState }) => (
            <TextField
              id="input-name"
              disabled={loading}
              fullWidth
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              label="クリニック名前"
              required
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="slotDuration"
          rules={{
            required: '必須項目です',
          }}
          render={({ field, fieldState }) => (
            <FormControl fullWidth>
              <InputLabel id="input-appointment-slots">予約単位</InputLabel>
              <Select
                labelId="input-appointment-slots"
                label="予約単位"
                error={!!fieldState.error}
                disabled={loading}
                required
                {...field}
              >
                {[5, 10, 15, 20, 30, 60].map((min) => (
                  <MenuItem value={min} key={min}>
                    {min}分
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{fieldState.error?.message}</FormHelperText>
            </FormControl>
          )}
        />
        <Box sx={{ py: 2, px: 3, border: '1px solid #ccc', borderRadius: '5px' }}>
          <FormControlLabel
            control={
              <Switch
                defaultChecked={useCancellationLimit}
                onChange={(e) => setUseCancellationLimit(e.target.checked)}
              />
            }
            label="キャンセル期限を設定する"
          />
          {useCancellationLimit && (
            <Controller
              key={useCancellationHours ? 'cancellationDeadlineHours' : 'cancellationDeadlineDays'}
              control={control}
              name={useCancellationHours ? 'cancellationDeadlineHours' : 'cancellationDeadlineDays'}
              rules={{
                required: '必須項目です',
                min: { value: 1, message: '1以上の数値を入力してください' },
              }}
              render={({ field, fieldState }) => (
                <TextField
                  disabled={loading}
                  fullWidth
                  type="number"
                  sx={{ mt: 2 }}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  label="キャンセル期限"
                  required
                  InputProps={{
                    sx: { pr: 0 },
                    inputMode: 'numeric',
                    endAdornment: (
                      <InputAdornment position="end">
                        <Select
                          disabled={loading}
                          value={useCancellationHours ? 'hours' : 'days'}
                          onChange={(e) => setUseCancellationHours(e.target.value === 'hours')}
                          sx={{ margin: 0 }}
                        >
                          <MenuItem value="days">日前</MenuItem>
                          <MenuItem value="hours">時間前</MenuItem>
                        </Select>
                      </InputAdornment>
                    ),
                  }}
                  {...field}
                />
              )}
            />
          )}
        </Box>
        <Stack sx={{ p: 3, pt: 2, border: '1px solid #ccc', borderRadius: '5px' }} gap={2}>
          <FormControlLabel
            control={
              <Switch
                defaultChecked={useReservationLimit}
                onChange={(e) => setUseReservationLimit(e.target.checked)}
              />
            }
            label="予約期限・期間を設定する"
          />
          {useReservationLimit && (
            <>
              <Controller
                key={`${
                  useReservationHoursFrom ? 'reservationLimitHoursFrom' : 'reservationLimitDaysFrom'
                }${maxReservationLimitFrom}`}
                control={control}
                name={
                  useReservationHoursFrom ? 'reservationLimitHoursFrom' : 'reservationLimitDaysFrom'
                }
                rules={{
                  required: '必須項目です',
                  min: {
                    value: 1,
                    message: '1以上の数値を入力してください',
                  },
                  max: {
                    value:
                      Number(
                        watch(
                          useReservationHoursTo
                            ? 'reservationLimitHoursTo'
                            : 'reservationLimitDaysTo'
                        )
                      ) * (useReservationHoursFrom ? 60 : 1),
                    message: '予約期限は予約期間より前に設定してください',
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    id="input-reservation-limit-days-from"
                    disabled={loading}
                    type="number"
                    label="予約期限"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    required
                    InputProps={{
                      sx: { pr: 0 },
                      inputMode: 'numeric',
                      endAdornment: (
                        <InputAdornment position="end">
                          <Select
                            disabled={loading}
                            value={useReservationHoursFrom ? 'hours' : 'days'}
                            onChange={(e) => setUseReservationHoursFrom(e.target.value === 'hours')}
                            sx={{ margin: 0 }}
                          >
                            <MenuItem value="days">日先</MenuItem>
                            <MenuItem value="hours">時間先</MenuItem>
                          </Select>
                        </InputAdornment>
                      ),
                    }}
                    {...field}
                  />
                )}
              />
              <Controller
                key={`${
                  useReservationHoursTo ? 'reservationLimitHoursTo' : 'reservationLimitDaysTo'
                }${minReservationLimitTo}`}
                control={control}
                name={useReservationHoursTo ? 'reservationLimitHoursTo' : 'reservationLimitDaysTo'}
                rules={{
                  required: '必須項目です',
                  min: {
                    value: minReservationLimitTo,
                    message: '予約期間は予約期限より後に設定してください',
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    id="input-reservation-limit-days-to"
                    disabled={loading}
                    type="number"
                    label="予約期間"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    required
                    InputProps={{
                      sx: { pr: 0 },
                      inputMode: 'numeric',
                      endAdornment: (
                        <InputAdornment position="end">
                          <Select
                            disabled={loading}
                            value={useReservationHoursTo ? 'hours' : 'days'}
                            onChange={(e) => setUseReservationHoursTo(e.target.value === 'hours')}
                            sx={{ margin: 0 }}
                          >
                            <MenuItem value="days">日先</MenuItem>
                            <MenuItem value="hours">時間先</MenuItem>
                          </Select>
                        </InputAdornment>
                      ),
                    }}
                    {...field}
                  />
                )}
              />
            </>
          )}
        </Stack>

        <FormGroup row sx={{ gap: 2, alignItems: 'center' }}>
          <FormLabel>始業・終業時間</FormLabel>
          <Controller
            key="clinicBussinessHour.start"
            control={control}
            name="clinicBussinessHour.start"
            rules={{
              required: '必須項目です',
              validate: {
                isBeforeEnd: (value) => {
                  const end = watch('clinicBussinessHour.end');
                  if (value.hour * 60 + value.minute >= end.hour * 60 + end.minute) {
                    return '終了時間は開始時間より後に設定してください';
                  }

                  return true;
                },
              },
            }}
            render={({ field, fieldState }) => (
              <TextField
                id="input-clinic-business-hour-start"
                disabled={loading}
                type="time"
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                required
                InputProps={{
                  inputMode: 'numeric',
                }}
                {...field}
                value={
                  field.value.hour === 0 && field.value.minute === 0
                    ? ''
                    : `${field.value.hour.toString().padStart(2, '0')}:${field.value.minute
                        .toString()
                        .padStart(2, '0')}`
                }
                onChange={(e) => {
                  const value = e.target.value;
                  const [hourStr, minuteStr] = value.split(':');
                  const hour = Number(hourStr);
                  const minute = Number(minuteStr);
                  field.onChange({ hour, minute });
                }}
              />
            )}
          />
          〜
          <Controller
            key="clinicBussinessHour.end"
            control={control}
            name="clinicBussinessHour.end"
            rules={{
              required: '必須項目です',
              validate: {
                isAfterStart: (value) => {
                  const start = watch('clinicBussinessHour.start');
                  if (value.hour * 60 + value.minute <= start.hour * 60 + start.minute) {
                    return '終了時間は開始時間より後に設定してください';
                  }

                  return true;
                },
              },
            }}
            render={({ field, fieldState }) => (
              <TextField
                id="input-clinic-business-hour-end"
                disabled={loading}
                type="time"
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                required
                InputProps={{
                  inputMode: 'numeric',
                }}
                {...field}
                value={
                  field.value.hour === 0 && field.value.minute === 0
                    ? ''
                    : `${field.value.hour.toString().padStart(2, '0')}:${field.value.minute
                        .toString()
                        .padStart(2, '0')}`
                }
                onChange={(e) => {
                  const value = e.target.value;
                  const [hourStr, minuteStr] = value.split(':');
                  const hour = Number(hourStr);
                  const minute = Number(minuteStr);
                  field.onChange({ hour, minute });
                }}
              />
            )}
          />
        </FormGroup>
        <Controller
          control={control}
          name="holidays.days"
          render={({ field }) => (
            <FormGroup row sx={{ gap: 2, alignItems: 'center' }}>
              <FormLabel sx={{ width: '100%' }}>休診日</FormLabel>
              {(
                [
                  [DayOfWeek.Sunday, '日'],
                  [DayOfWeek.Monday, '月'],
                  [DayOfWeek.Tuesday, '火'],
                  [DayOfWeek.Wednesday, '水'],
                  [DayOfWeek.Thursday, '木'],
                  [DayOfWeek.Friday, '金'],
                  [DayOfWeek.Saturday, '土'],
                ] as const
              ).map(([day, label]) => (
                <FormControlLabel
                  key={day}
                  control={
                    <Checkbox
                      disabled={loading}
                      checked={field.value.includes(day)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          field.onChange([...field.value, day]);
                        } else {
                          field.onChange(field.value.filter((d) => d !== day));
                        }
                      }}
                    />
                  }
                  label={label}
                />
              ))}
            </FormGroup>
          )}
        />
        <FormGroup row sx={{ gap: 2, alignItems: 'center' }}>
          <FormLabel sx={{ width: '100%' }}>対応可能動物</FormLabel>
          {animals.map((animal) => (
            <FormControlLabel
              key={animal.id}
              control={
                <Checkbox
                  disabled={loading}
                  checked={animal.types
                    .map((type) => type.id)
                    .every((typeId) => watch('animalTypes.ids').includes(typeId))}
                  indeterminate={
                    !animal.types
                      .map((type) => type.id)
                      .every((typeId) => watch('animalTypes.ids').includes(typeId)) &&
                    animal.types
                      .map((type) => type.id)
                      .some((typeId) => watch('animalTypes.ids').includes(typeId))
                  }
                  onChange={(e) => {
                    let newIds = [...watch('animalTypes.ids')];

                    if (e.target.checked) {
                      newIds.push(...animal.types.map((type) => type.id));
                    } else {
                      newIds = newIds.filter(
                        (id) => !animal.types.map((type) => type.id).includes(id)
                      );
                    }

                    setValue('animalTypes.ids', newIds);
                  }}
                />
              }
              label={animal.name}
            />
          ))}
        </FormGroup>
        <Box>
          <Button type="submit" variant="contained" color="primary" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : '保存'}
          </Button>
        </Box>
      </Stack>
    </form>
  );
};
