import type { ComponentProps, FC } from 'react';
import {
  Backdrop,
  Breadcrumbs,
  CircularProgress,
  Container,
  Link,
  Toolbar,
  Typography,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useMutation, useQuery } from 'urql';
import { useParams, Link as RouterLink } from 'react-router-dom';
import type { DayOfWeek, Time } from 'graphql/generated/user/graphql';
import * as pageTitles from 'constants/pageTitles/staff';
import type {
  ResourceWorkingHoursQuery,
  ResourceWorkingHoursQueryVariables,
} from 'graphql/generated/staff/graphql';
import {
  ResourceDetailFragmentDoc,
  ResourceWorkingHoursFragmentDoc,
  CreateResourceWorkingHourDocument,
  UpdateResourceWorkingHourDocument,
  ResourceWorkingHoursDocument,
  DeleteResourceWorkingHourDocument,
} from 'graphql/generated/staff/graphql';
import * as paths from 'constants/paths/staff';
import NotFoundPage from 'pages/NotFoundPage';
import FullScreenLoading from 'components/FullScreenLoading';
import { useFragment } from 'graphql/generated/staff';
import WorkingHourEditor from 'components/resourceWorkingHour/WorkingHourEditor';
import StaffScheduleTabs from 'components/StaffScheduleTabs';

const Page: FC = () => {
  const { id } = useParams() as { id: string };
  const [{ data, fetching, error }, refetch] = useQuery<
    ResourceWorkingHoursQuery,
    ResourceWorkingHoursQueryVariables
  >({
    query: ResourceWorkingHoursDocument,
    variables: { id },
  });
  const [createResourceWorkingHourResult, createResourceWorkingHour] = useMutation(
    CreateResourceWorkingHourDocument
  );
  const [updateResourceWorkingHourResult, updateResourceWorkingHour] = useMutation(
    UpdateResourceWorkingHourDocument
  );
  const [deleteResourceWorkingHourResult, deleteResourceWorkingHour] = useMutation(
    DeleteResourceWorkingHourDocument
  );

  const mutating =
    createResourceWorkingHourResult.fetching ||
    updateResourceWorkingHourResult.fetching ||
    deleteResourceWorkingHourResult.fetching;

  const handleCreate: ComponentProps<typeof WorkingHourEditor>['onCreate'] = async (
    day: DayOfWeek,
    start: Time,
    end: Time
  ) => {
    await createResourceWorkingHour({ input: { resourceId: id, day, start, end } });
  };

  const handleUpdate: ComponentProps<typeof WorkingHourEditor>['onUpdate'] = async (
    id: string,
    start: Time,
    end: Time
  ) => {
    await updateResourceWorkingHour({ input: { id, start, end } });
    refetch();
  };

  const handleDelete: ComponentProps<typeof WorkingHourEditor>['onDelete'] = async (id: string) => {
    await deleteResourceWorkingHour({ id });
    refetch();
  };

  const resourceWithWorkingHours = useFragment(ResourceWorkingHoursFragmentDoc, data?.resource);
  const resource = useFragment(ResourceDetailFragmentDoc, resourceWithWorkingHours);
  const holidays = data?.viewer?.clinic?.holidays;

  if (fetching && !resourceWithWorkingHours) {
    return <FullScreenLoading />;
  }

  if (error?.networkError) {
    throw error;
  }

  if (!resource || !resourceWithWorkingHours || !holidays) {
    return <NotFoundPage />;
  }

  const { workingHours } = resourceWithWorkingHours;

  return (
    <>
      <Helmet>
        <title>
          {resource.name} - {pageTitles.resourceWorkingHours}
        </title>
      </Helmet>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={mutating}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Toolbar>
        <Container sx={{ display: 'flex' }}>
          <Breadcrumbs sx={{ flexGrow: 1 }}>
            <Link
              component={RouterLink}
              to={paths.resourcesPath()}
              color="text.secondary"
              underline="hover"
            >
              {pageTitles.resources}
            </Link>
            <Link
              component={RouterLink}
              to={paths.resourceDetailPath(resource.id)}
              color="text.secondary"
              underline="hover"
            >
              {resource.name}
            </Link>
            <Typography color="text.primary">{pageTitles.resourceWorkingHours}</Typography>
          </Breadcrumbs>
        </Container>
      </Toolbar>
      <Container>
        <StaffScheduleTabs resourceId={resource.id} />
        <WorkingHourEditor
          resourceId={id}
          holidays={holidays}
          schedules={workingHours}
          onCreate={handleCreate}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
        />
      </Container>
    </>
  );
};

export default Page;
