import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Button, CircularProgress } from '@mui/material';
import { addDays, addHours, addWeeks, endOfDay, isToday, startOfDay, subWeeks } from 'date-fns';
import { useCallback, useMemo, useState } from 'react';
import type {
  AppointmentFormOptionsQueryClinic,
  AppointmentFormOptionsQueryResource,
  AppointmentFormOptionsQueryService,
} from '../AppointmentForm/types';
import { useAppointmentScheduleHook } from './useAppointmentScheduleHook';
import { WeeklyTimeTable } from 'components/forms/user/WeeklyTimeTable/WeeklyTimeTable';

interface Props {
  clinic: AppointmentFormOptionsQueryClinic;
  service: AppointmentFormOptionsQueryService;
  resource: AppointmentFormOptionsQueryResource | null;
  isStorybook?: boolean;
  onSelect: (startAt: Date) => void;
}

export const SelectDate = (props: Props) => {
  // 1コマの長さ(分)
  const slotDuration = props.clinic.slotDuration ?? 15;

  /* カレンダー用に1コマ区切りに丸めている */
  const now = new Date();

  const reservationLimitFrom = useMemo(() => {
    const { reservationLimitDaysFrom, reservationLimitHoursFrom } = props.clinic;
    if (typeof reservationLimitDaysFrom === 'number') {
      return startOfDay(addDays(now, reservationLimitDaysFrom));
    } else if (typeof reservationLimitHoursFrom === 'number') {
      return addHours(now, reservationLimitHoursFrom);
    }

    return now;
  }, [props.clinic.reservationLimitDaysFrom, props.clinic.reservationLimitHoursFrom, now]);

  const reservationLimitTo = useMemo(() => {
    const { reservationLimitDaysTo, reservationLimitHoursTo } = props.clinic;

    if (typeof reservationLimitDaysTo === 'number') {
      return endOfDay(addDays(now, reservationLimitDaysTo));
    } else if (typeof reservationLimitHoursTo === 'number') {
      return addHours(now, reservationLimitHoursTo);
    }

    return null;
  }, [props.clinic.reservationLimitDaysTo, props.clinic.reservationLimitHoursTo, now]);

  const [selectedDay, setSelectedDay] = useState(now);
  const [{ data, fetching }] = useAppointmentScheduleHook({
    clinicSlug: props.clinic.slug,
    clinicId: props.clinic.id,
    serviceId: props.service.id,
    resourceId: props.resource?.id || null,
    start: selectedDay,
    end: addWeeks(selectedDay, 1),
    isStorybook: props.isStorybook,
  });

  const checkAvailable = useCallback(
    (date: Date): boolean => {
      if (
        date.getTime() < reservationLimitFrom.getTime() ||
        (reservationLimitTo && date.getTime() > reservationLimitTo.getTime())
      ) {
        return false;
      }

      return (
        data?.appointmentSlots.some(
          (a) => a.isAvailable && date.getTime() === new Date(a.date).getTime()
        ) ?? false
      );
    },
    [data, props.service.durationMinutes, slotDuration, reservationLimitFrom, reservationLimitTo]
  );

  const selectOneWeekBefore = useCallback(() => {
    if (now.getTime() < selectedDay.getTime()) {
      const newDate = startOfDay(subWeeks(selectedDay, 1));
      if (isToday(newDate)) {
        setSelectedDay(new Date());
      } else {
        setSelectedDay(newDate);
      }
    }
  }, [selectedDay]);

  const selectOneWeekAfter = useCallback(() => {
    const newDate = startOfDay(addWeeks(selectedDay, 1));
    if (isToday(newDate)) {
      setSelectedDay(new Date());
    } else {
      setSelectedDay(newDate);
    }
  }, [selectedDay]);

  const onSelect = useCallback(
    (startAt: Date) => {
      if (checkAvailable(startAt)) {
        props.onSelect(startAt);
      }
    },
    [props.onSelect, checkAvailable]
  );

  const isAfterFromToday = now.getTime() >= selectedDay.getTime();
  const { start, end } = props.clinic.clinicBussinessHour ?? {
    start: { hour: 0, minute: 0 },
    end: { hour: 23, minute: 59 },
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" my={3}>
        <Button
          onClick={selectOneWeekBefore}
          disabled={isAfterFromToday}
          variant="contained"
          startIcon={<ChevronLeftIcon />}
        >
          前の一週間
        </Button>
        <Button onClick={selectOneWeekAfter} variant="contained" endIcon={<ChevronRightIcon />}>
          次の一週間
        </Button>
      </Box>
      {fetching && (
        <Box display="flex" justifyContent="center" alignItems="center" my={12}>
          <CircularProgress />
        </Box>
      )}
      {!fetching && (
        <WeeklyTimeTable
          minTime={start.hour}
          maxTime={end.hour + (end.minute ? 1 : 0)}
          slotDuration={props.clinic.slotDuration}
          startDate={selectedDay}
          checkAvailable={checkAvailable}
          onSelectDate={onSelect}
        />
      )}
    </Box>
  );
};
