import type { FC } from 'react';
import { useCallback } from 'react';
import { Breadcrumbs, Container, Link, Toolbar, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useClient, useMutation, useQuery } from 'urql';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import * as pageTitles from 'constants/pageTitles/staff';
import type {
  ResourceDetailQueryVariables,
  ResourceDetailQuery,
  ResourceFormOptionsQuery,
  ResourceProfileImageUrlQueryVariables,
  ResourceProfileImageUrlQuery,
  CreateResourceInput,
} from 'graphql/generated/staff/graphql';
import {
  FileType,
  ResourceProfileImageUrlDocument,
  UpdateResourceDocument,
  ResourceFormOptionsDocument,
  ResourceDetailFragmentDoc,
  ResourceDetailDocument,
} from 'graphql/generated/staff/graphql';
import * as paths from 'constants/paths/staff';
import NotFoundPage from 'pages/NotFoundPage';
import FullScreenLoading from 'components/FullScreenLoading';
import { useFragment } from 'graphql/generated/staff';
import ResourceForm from 'components/forms/staff/ResourceForm';

const Page: FC = () => {
  const { id } = useParams() as { id: string };
  const [{ data, fetching, error }] = useQuery<ResourceDetailQuery, ResourceDetailQueryVariables>({
    query: ResourceDetailDocument,
    variables: { id },
  });

  const [{ data: masterData, fetching: masterFetching }] = useQuery<ResourceFormOptionsQuery>({
    query: ResourceFormOptionsDocument,
  });

  const [, updateResoure] = useMutation(UpdateResourceDocument);

  const resource = useFragment(ResourceDetailFragmentDoc, data?.resource);
  const client = useClient();

  const handleSubmit = useCallback(
    async (values: CreateResourceInput) => {
      try {
        await updateResoure({ input: { ...values, id } });
        enqueueSnackbar('保存しました', { variant: 'success' });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        enqueueSnackbar('保存に失敗しました', { variant: 'error' });
      }
    },
    [id, updateResoure]
  );

  const handleSelectFile = useCallback(async (file: File): Promise<string> => {
    const res = await client
      .query<ResourceProfileImageUrlQuery, ResourceProfileImageUrlQueryVariables>(
        ResourceProfileImageUrlDocument,
        {
          fileName: file.name,
          type: file.type === 'image/jpeg' ? FileType.Jpeg : FileType.Png,
          resourceId: id,
        }
      )
      .toPromise();
    const url = res?.data?.resource.profileImageUploadURL;
    if (!url) {
      enqueueSnackbar('画像のアップロードに失敗しました', { variant: 'error' });
      return '';
    }
    await fetch(url, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': file.type,
        'X-Amz-ACL': 'public-read',
      },
    });
    return url.replace(/\?.+/, '');
  }, []);

  if (fetching || masterFetching) {
    return <FullScreenLoading />;
  }

  if (error?.networkError) {
    throw error;
  }

  if (!resource || !masterData) {
    return <NotFoundPage />;
  }

  return (
    <>
      <Helmet>
        <title>
          {resource.name} - {pageTitles.resourceEdit}
        </title>
      </Helmet>
      <Toolbar>
        <Container sx={{ display: 'flex' }}>
          <Breadcrumbs sx={{ flexGrow: 1 }}>
            <Link
              component={RouterLink}
              to={paths.resourcesPath()}
              color="text.secondary"
              underline="hover"
            >
              {pageTitles.resources}
            </Link>
            <Link
              component={RouterLink}
              to={paths.resourceDetailPath(resource.id)}
              color="text.secondary"
              underline="hover"
            >
              {resource.name}
            </Link>
            <Typography color="text.primary">編集</Typography>
          </Breadcrumbs>
        </Container>
      </Toolbar>
      <Container>
        <ResourceForm
          resource={resource}
          serviceCategories={masterData.serviceCategories}
          resourceKinds={masterData.resourceKinds}
          onSubmit={handleSubmit}
          onSelectFile={handleSelectFile}
        />
      </Container>
    </>
  );
};

export default Page;
