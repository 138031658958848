import { useCallback } from 'react';
import { useCookies } from 'react-cookie';

const COOKIE_NAME = 'passwordResetThenRedirectClinicSlug';

export const usePasswordResetCookie = () => {
  const [getCookie, setCookie, removeCookie] = useCookies();

  const getRedirectClinicSlug = useCallback(() => {
    const slug: string | undefined = getCookie[COOKIE_NAME];
    if (slug) {
      return slug;
    }
    return null;
  }, [getCookie]);

  const setRedirectClinicSlug = useCallback(
    (slug?: string) => {
      if (slug) {
        setCookie(COOKIE_NAME, slug, {
          path: '/',
        });
      } else {
        removeCookie(COOKIE_NAME, {
          path: '/',
        });
      }
    },
    [setCookie]
  );

  return {
    getRedirectClinicSlug,
    setRedirectClinicSlug,
  };
};
