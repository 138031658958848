import { Box, Button } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import type { FC } from 'react';
import { useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'urql';
import FullScreenLoading from 'components/FullScreenLoading';
import { ClinicRegisterConfirm } from 'components/forms/user/ClinicRegisterConfirm/ClinicRegisterConfirm';
import * as paths from 'constants/paths/user';
import type { Pet } from 'graphql/generated/user/graphql';
import { DeletePetDocument, RegisterConfirmDetailDocument } from 'graphql/generated/user/graphql';

const Page: FC = () => {
  const navigate = useNavigate();
  const [_deleteResult, deletePetFunc] = useMutation(DeletePetDocument);
  const [{ data, fetching }] = useQuery({
    query: RegisterConfirmDetailDocument,
  });

  const onPetDelete = useCallback(async (pet: Pick<Pet, 'id' | 'name'>) => {
    const exec = confirm(`本当に${pet.name}の登録情報を削除しますか？`);
    if (!exec) {
      return;
    }

    try {
      const res = await deletePetFunc({ id: pet.id });
      if (res.error) {
        throw new Error(`ペット${pet.name}の情報の削除に失敗しました。`);
      }
      enqueueSnackbar(`ペット${pet.name}の情報が削除されました。`, {
        variant: 'success',
      });
    } catch (e: any) {
      enqueueSnackbar(`ペット${pet.name}の情報の削除に失敗しました。`, {
        variant: 'error',
      });
    }
  }, []);

  const onEditPet = useCallback((pet: Pick<Pet, 'id' | 'name'>) => {
    navigate(paths.registerPetDetailPath(pet.id));
  }, []);

  const onEditOwner = useCallback(() => {
    navigate(paths.registerUserEditPath());
  }, []);

  const onAddPet = useCallback(() => {
    navigate(paths.registerPetNewPath());
  }, []);

  if (fetching || !data) {
    return <FullScreenLoading />;
  }

  // 特定の病院に紐づかない新規登録であれば完了とする
  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <ClinicRegisterConfirm
        viewer={data.viewer}
        onAddPet={onAddPet}
        onEditOwner={onEditOwner}
        onEditPet={onEditPet}
        onDeletePet={onPetDelete}
      />
      <Button to={paths.rootPath()} component={Link}>
        登録完了
      </Button>
    </Box>
  );
};

export default Page;
