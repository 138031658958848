import { Box } from '@mui/material';
import type { FC, ReactNode } from 'react';
import Logo from 'graphics/Logo';

interface Props {
  children: ReactNode;
}

export const Component: FC<Props> = ({ children }) => {
  return (
    <div>
      <Box
        minHeight="100vh"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box sx={{ width: '100%', maxWidth: '400px', mb: 5 }}>
          <Logo />
        </Box>
        <Box maxWidth={600} width="100vh" sx={{ textAlign: 'center' }}>
          {children}
        </Box>
      </Box>
    </div>
  );
};
