import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'urql';
import { useCookie } from '../useCookie';
import config from 'config';
import type { CurrentStaffQuery } from 'graphql/generated/staff/graphql';
import { CurrentStaffDocument } from 'graphql/generated/staff/graphql';
import { useConnectivity } from 'hooks/useConnectivity';

export const useCurrentStaff = () => {
  const [accessToken, setAccessToken] = useCookie(config.cookieKeys.staffAccessToken);
  const [currentStaff, setCurrentStaff] = useState<CurrentStaffQuery['viewer'] | null>(null);
  const isOnline = useConnectivity();

  const [{ data, fetching, error }] = useQuery<CurrentStaffQuery>({
    query: CurrentStaffDocument,
    pause: !accessToken || !isOnline,
  });

  const [initialized, setInitialized] = useState(!accessToken);
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    setCurrentStaff(data?.viewer ?? null);
  }, [data?.viewer]);

  useEffect(() => {
    if (currentStaff) {
      setTimeout(() => {
        setLoggedIn(true);
        setInitialized(true);
      }, 100);
    }
  }, [currentStaff]);

  useEffect(() => {
    if (error) {
      throw error;
    }
  }, [error]);

  const logout = useCallback(() => {
    setInitialized(false);
    setAccessToken(null);
    setCurrentStaff(null);
    setLoggedIn(false);
  }, []);

  return {
    initialized,
    loggedIn,
    fetching,
    error,
    logout,
    accessToken: accessToken || null,
    data: currentStaff,
  };
};
