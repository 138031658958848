import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { useCallback, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useLoginForm } from './hooks/useLoginForm';
import { useTermOfServiceDialog } from 'components/dialogs/user/TermOfServiceDialog/hooks/useTermOfServiceDialog';
import * as paths from 'constants/paths/user';
import Logo from 'graphics/Logo';
import type { AppAgreement, Clinic } from 'graphql/generated/user/graphql';

interface Props {
  clinic?: Pick<Clinic, 'name' | 'slug'>;
  agreement: Pick<AppAgreement, 'text' | 'url'>;
  onSubmit: (data: LoginFormInput) => void;
  fetching: boolean;
}

interface LoginFormInput {
  email: string;
  password: string;
}

export const LoginForm = (props: Props) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useLoginForm();
  const dialog = useTermOfServiceDialog({
    title: '利用規約',
    text: props.agreement.text,
  });

  const onSubmit = useCallback(
    (data: LoginFormInput) => {
      props.onSubmit(data);
    },
    [props.onSubmit]
  );

  const onToggleShowPassword = useCallback(() => {
    setShowPassword((prev) => !prev);
  }, []);

  return (
    <>
      <Stack component="form" onSubmit={handleSubmit(onSubmit)}>
        <Box textAlign="center" pb={3}>
          <Box maxWidth="500px" margin="0 auto">
            <Logo />
          </Box>
        </Box>

        {props.clinic?.name && (
          <Box bgcolor={grey[300]} py={2} px={1}>
            <Typography sx={{ textAlign: 'center' }}>{props.clinic.name} Web予約</Typography>
          </Box>
        )}

        <Typography fontWeight="bold" textAlign="center" sx={{ py: 2 }}>
          メールアドレスでログインして予約に進む
        </Typography>

        <FormControl sx={{ my: 1.5 }} variant="outlined">
          <InputLabel size="small">メールアドレス</InputLabel>
          <OutlinedInput
            type="text"
            label="メールアドレス"
            size="small"
            error={'email' in errors}
            {...register('email')}
          />
          {errors.email?.message && <FormHelperText error>{errors.email?.message}</FormHelperText>}
        </FormControl>

        <FormControl sx={{ my: 1.5 }} variant="outlined">
          <InputLabel size="small" error={!!errors.password}>
            パスワード
          </InputLabel>
          <OutlinedInput
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={onToggleShowPassword} edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="パスワード"
            size="small"
            error={'password' in errors}
            {...register('password')}
          />
          {errors.password?.message && (
            <FormHelperText error>{errors.password?.message}</FormHelperText>
          )}
        </FormControl>

        <Button
          sx={{ mt: 1, mx: 'auto', width: '140px', display: 'block', backgroundColor: grey[700] }}
          variant="contained"
          type="submit"
          disabled={props.fetching}
        >
          ログイン
        </Button>

        <Box mt={1} textAlign="center">
          <Button
            component={RouterLink}
            to={
              props.clinic?.slug
                ? paths.clinicPasswordResetPath(props.clinic.slug)
                : paths.passwordResetPath()
            }
          >
            パスワードを忘れた場合
          </Button>
        </Box>
      </Stack>

      <Box sx={{ mt: 5, textAlign: 'center' }}>
        <Typography sx={{ textAlign: 'center', fontSize: '0.9rem' }}>
          会員登録が済んでいない場合はこちら
        </Typography>
        <Button
          component={RouterLink}
          to={
            props.clinic?.slug ? paths.registerClinicPath(props.clinic.slug) : paths.registerPath()
          }
        >
          新規会員登録
        </Button>
      </Box>

      <Stack
        display="flex"
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        sx={{ mt: 5, fontSize: '0.8rem', gap: 2 }}
      >
        <Link
          onClick={() => window.open('https://ani-pro.jp/reserve-system', '_blank')}
          sx={{
            cursor: 'pointer',
            display: 'block',
            textDecoration: 'none',
          }}
        >
          アニプロ予約とは
        </Link>
        <Link
          onClick={() => dialog.open()}
          sx={{
            cursor: 'pointer',
            display: 'block',
            textDecoration: 'none',
          }}
        >
          利用規約
        </Link>
        <Link
          onClick={() => window.open(props.agreement.url, '_blank')}
          sx={{
            cursor: 'pointer',
            display: 'block',
            textDecoration: 'none',
          }}
        >
          個人情報保護方針
        </Link>
      </Stack>
      <Box
        sx={{
          mt: 4,
          flexGrow: 1,
          justifyContent: 'center',
          display: 'flex',
        }}
      >
        <Typography sx={{ fontSize: '12px' }}>
          運営会社：
          <Link onClick={() => window.open('https://pet-tyl.co.jp/', '_blank')}>株式会社TYL</Link>
        </Typography>
      </Box>
      {dialog.render()}
    </>
  );
};
