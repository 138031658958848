import { Stack } from '@mui/material';
import type { AppointmentFormOptionsQueryResource } from '../AppointmentForm/types';
import { ResourceDetail } from './ResourceDetail';
import { ResourceName } from './ResourceName';

interface Props {
  selectedResorce?: AppointmentFormOptionsQueryResource | null;
  resources: AppointmentFormOptionsQueryResource[];
  onChange: (resource: AppointmentFormOptionsQueryResource | null) => void;
}

export const SelectResource = (props: Props) => {
  const selectedResource = props.resources.find((r) => r.id === props.selectedResorce?.id);
  const resources = [null, ...props.resources];

  return (
    <>
      <Stack sx={{ pt: 2 }} direction="row" flexWrap="wrap">
        {resources.map((resource) => (
          <ResourceName
            key={resource?.id || 'null'}
            isSelected={resource?.id == props.selectedResorce?.id}
            resource={resource}
            onChange={props.onChange}
          />
        ))}
      </Stack>
      {selectedResource && <ResourceDetail resource={selectedResource} />}
    </>
  );
};
