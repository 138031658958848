import CallIcon from '@mui/icons-material/Call';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import {
  Avatar,
  Badge,
  Box,
  Button,
  Chip,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { grey } from '@mui/material/colors';
import { format, parseISO } from 'date-fns';
import { useCallback } from 'react';
import PetsIcon from '@mui/icons-material/Pets';
import type { UsersQuery } from 'graphql/generated/staff/graphql';
import { getAge } from 'helpers/date';

interface Props {
  clinicUser: UsersQuery['clinicUsers'][number];
  onSelectUser: (clinicUserId: string) => void;
  onSelectPet: (clinicPetId: string) => void;
  onCreateAppointment: (clinicUserId: string, clinicPetId?: string) => void;
}

export const UserRow = (props: Props) => {
  const clinicPet = props.clinicUser.clinicPets[0];
  const pet = clinicPet ? clinicPet.pet : undefined;
  const lastAppointment = props.clinicUser.appointments[0];

  const onSelectUser = useCallback(() => {
    props.onSelectUser(props.clinicUser.id);
  }, [props.clinicUser.id]);

  const onSelectPet = useCallback(() => {
    if (clinicPet && 'id' in clinicPet) {
      props.onSelectPet(clinicPet.id);
    }
  }, [clinicPet]);

  const onCreateAppointment = useCallback(() => {
    props.onCreateAppointment(props.clinicUser.id, clinicPet?.id);
  }, [props.clinicUser.id, clinicPet]);

  return (
    <TableRow hover>
      <TableCell align="right">{props.clinicUser.patientNumber}</TableCell>
      <TableCell>
        <Button onClick={onSelectUser}>
          <Box display="flex" alignItems="center" color={grey[900]}>
            <Badge color="error" badgeContent="要" invisible={!props.clinicUser.marked}>
              <Avatar src={props.clinicUser.user.profileImageURL ?? ''} />
            </Badge>
            <Box ml={2}>
              <Typography>{props.clinicUser.user.name}</Typography>
            </Box>
          </Box>
        </Button>
      </TableCell>
      <TableCell align="right">
        <Chip icon={<CallIcon />} label={props.clinicUser.user.phoneNumber} size="small" />
      </TableCell>
      <TableCell>
        {pet && (
          <Button onClick={onSelectPet}>
            <Box display="flex" alignItems="center" color={grey[900]}>
              <Avatar src={pet.profileImageURL ?? ''}>
                <PetsIcon />
              </Avatar>
              <Box ml={2} textAlign="left">
                <Typography variant="body2">{pet.name}</Typography>
                <Stack direction="row" spacing={1}>
                  <Typography variant="caption" color={grey[600]}>
                    {pet.animal?.name}
                  </Typography>
                  <Typography variant="caption" color={grey[600]}>
                    {getAge(pet?.birthday, '歳')}
                  </Typography>
                </Stack>
              </Box>
            </Box>
          </Button>
        )}
      </TableCell>
      <TableCell align="right">
        {lastAppointment && (
          <>
            <Typography variant="caption" color={grey[700]}>
              {format(parseISO(lastAppointment.startAt), 'yyyy年MM月dd日')}
            </Typography>
            <Typography variant="body2">
              {format(parseISO(lastAppointment.startAt), 'HH:mm')}
              &nbsp;-&nbsp;
              {format(parseISO(lastAppointment.endAt), 'HH:mm')}
            </Typography>
          </>
        )}
      </TableCell>
      <TableCell align="right">{props.clinicUser.appointments.length} 回</TableCell>
      <TableCell align="center">
        <Tooltip title="新規予約登録">
          <IconButton onClick={onCreateAppointment}>
            <PendingActionsIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};
