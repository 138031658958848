import { Box, Button, Typography } from '@mui/material';
import type { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from 'urql';
import { Helmet } from 'react-helmet-async';
import FullScreenLoading from 'components/FullScreenLoading';
import { UserDetailView } from 'components/views/user/UserDetailView/UserDetailView';
import * as paths from 'constants/paths/user';
import { UserDetailDocument } from 'graphql/generated/user/graphql';
import * as pageTitles from 'constants/pageTitles/user';

const Page: FC = () => {
  const { clinicSlug } = useParams();
  const [{ data, fetching }] = useQuery({
    query: UserDetailDocument,
  });

  if (!data || fetching) {
    return <FullScreenLoading />;
  }

  return (
    <>
      <Helmet>
        <title>{pageTitles.owners}</title>
      </Helmet>
      <Box>
        <Typography variant="h5" textAlign="center">
          飼い主様情報
        </Typography>
        <UserDetailView user={data.viewer} />
        <Box textAlign="center" my={4}>
          <Button variant="contained" component={Link} to={paths.clinicOwnerEditPath(clinicSlug)}>
            編集する
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default Page;
