import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

export interface PasswordResetFormInput {
  password: string;
  passwordConfirm: string;
}

const passwordRegex = /^(?=.*?[a-z])(?=.*?[A-Z]).{8,}$/;
const formSchema = yup.object({
  password: yup
    .string()
    .required('パスワードを入力してください')
    .min(8, 'パスワードは英字(大文字・小文字)含む8桁以上必要です')
    .matches(passwordRegex, 'パスワードは英字(大文字・小文字)含む8桁以上必要です'),
  passwordConfirm: yup.string().oneOf([yup.ref('password')], 'パスワードが一致しません'),
});

export const usePasswordResetForm = () =>
  useForm<PasswordResetFormInput>({
    mode: 'onBlur',
    resolver: yupResolver(formSchema),
  });
