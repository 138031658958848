import AddIcon from '@mui/icons-material/Add';
import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import type { FC } from 'react';
import { useCallback } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'urql';
import { Helmet } from 'react-helmet-async';
import FullScreenLoading from 'components/FullScreenLoading';
import { PetsTable } from 'components/tables/user/PetsTable/PetsTable';
import * as paths from 'constants/paths/user';
import type { Pet } from 'graphql/generated/user/graphql';
import { PetsDocument } from 'graphql/generated/user/graphql';
import * as pageTitles from 'constants/pageTitles/user';

const Page: FC = () => {
  const navigate = useNavigate();
  const { clinicSlug } = useParams();
  const [{ data, fetching }] = useQuery({
    query: PetsDocument,
  });
  const isLoading = fetching || !data;

  const onSelectPet = useCallback(
    (petId: Pet['id']) => {
      if (clinicSlug) {
        navigate(paths.clinicPetDetailPath(clinicSlug, petId));
      } else {
        navigate(paths.petDetailPath(petId));
      }
    },
    [clinicSlug]
  );

  if (isLoading) {
    return <FullScreenLoading />;
  }

  return (
    <>
      <Helmet>
        <title>{pageTitles.pets}</title>
      </Helmet>
      <Box my={4} textAlign="center">
        <Typography variant="h5">ペット一覧</Typography>
      </Box>

      <Box my={4} display="flex" justifyContent="center">
        <Button
          startIcon={<AddIcon />}
          component={RouterLink}
          to={paths.petNewPath()}
          variant="contained"
          size="small"
        >
          新規ペット登録
        </Button>
      </Box>
      <PetsTable pets={data.viewer.pets} onSelect={onSelectPet} />
    </>
  );
};

export default Page;
