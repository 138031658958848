import type { FC } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ja from 'date-fns/locale/ja';
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import styled from '@emotion/styled';

interface Props {
  defaultDate: Date;
  minDate: Date;
  onSelectDate: (date: Date) => void;
}

const Calendar: FC<Props> = ({ onSelectDate, minDate, defaultDate }) => {
  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={ja}
      dateFormats={{
        monthAndYear: 'yyyy年 M月',
      }}
    >
      <DatePicker
        key={defaultDate.toLocaleDateString()}
        displayStaticWrapperAs="mobile"
        displayWeekNumber={false}
        defaultValue={defaultDate}
        minDate={minDate}
        onChange={(date: Date | null) => {
          date && onSelectDate(date);
        }}
        slotProps={{
          toolbar: {
            hidden: true,
          },
          actionBar: {
            sx: {
              display: 'none',
            },
          },
        }}
      />
    </LocalizationProvider>
  );
};

export default Calendar;

const DatePicker = styled(StaticDatePicker<Date>)`
  margin: 0;
  padding: 0;
  width: 100%;
  min-width: auto;
  background-color: transparent;
  .MuiPickersSlideTransition-root {
    min-height: 180px;
    height: 180px;
  }
  .MuiYearCalendar-root {
    width: 100%;
    max-height: 180px;
    height: 180px;
  }
  .MuiDateCalendar-root {
    width: 100%;
    margin: 0;
  }
  .MuiButtonBase-root,
  .MuiPickersDay-root,
  .MuiDayCalendar-weekDayLabel {
    width: 26px;
    height: 26px;
  }
`;
