import { post } from '../methods';
import config from 'config';

export interface LoginParams {
  email: string;
  password: string;
}

interface LoginResponse {
  accessToken: string;
}

export const login = (params: LoginParams) =>
  post<LoginResponse>(config.endpoints.staffEmailLogin, params);
