import { useCallback, useState } from 'react';
import { Box } from '@mui/material';
import { ResourceView } from './ResourceView';
import { SelectTermOfTime } from './SelectTermOfTime';
import type { ResourceWorkingHour, Time } from 'graphql/generated/staff/graphql';

interface Props {
  resourceWorkingHour: ResourceWorkingHour;
  onAdd: () => void;
  onSubmit: (id: ResourceWorkingHour['id'], start: Time, end: Time) => void;
  onDelete: (id: ResourceWorkingHour['id']) => void;
}

export const ResourceRow = (props: Props) => {
  const [isEdit, setEdit] = useState(false);

  const onEdit = useCallback(() => {
    setEdit(true);
  }, []);

  const onCancel = useCallback(() => {
    setEdit(false);
  }, []);

  const onSubmit = useCallback(
    (start: Time, end: Time) => {
      setEdit(false);
      props.onSubmit(props.resourceWorkingHour.id, start, end);
    },
    [props.onSubmit, props.resourceWorkingHour]
  );

  const onDelete = useCallback(() => {
    props.onDelete(props.resourceWorkingHour.id);
  }, [props.onDelete, props.resourceWorkingHour]);

  if (isEdit) {
    return (
      <Box width="100%" display="flex">
        <SelectTermOfTime
          isNew={false}
          start={props.resourceWorkingHour.start}
          end={props.resourceWorkingHour.end}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
      </Box>
    );
  }

  return (
    <ResourceView
      start={props.resourceWorkingHour.start}
      end={props.resourceWorkingHour.end}
      onAdd={props.onAdd}
      onEdit={onEdit}
      onDelete={onDelete}
    />
  );
};
