import { enqueueSnackbar } from 'notistack';
import type { FC } from 'react';
import { useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'urql';
import FullScreenLoading from 'components/FullScreenLoading';
import { AppointmentForm } from 'components/appointments/user/AppointmentForm/AppointmentForm';
import * as pageTitles from 'constants/pageTitles/user';
import * as paths from 'constants/paths/user';
import type { CreateAppointmentInput } from 'graphql/generated/user/graphql';
import {
  AppointmentDetailDocument,
  AppointmentFormOptionsDocument,
  UpdateAppointmentDocument,
} from 'graphql/generated/user/graphql';

const Page: FC = () => {
  const navigate = useNavigate();
  const { clinicSlug, appointmentId } = useParams();
  const [{ data: appointment, fetching: appointmentFetching }] = useQuery({
    query: AppointmentDetailDocument,
    variables: {
      id: appointmentId as string,
    },
    pause: !appointmentId,
  });
  const [{ data: appointmentFormOptions, fetching: appointmentFormOptionsFetching }] = useQuery({
    query: AppointmentFormOptionsDocument,
    variables: {
      slug: appointment?.appointment.clinic.slug as string,
    },
    pause: !appointment?.appointment.clinic.slug,
  });
  const [_updateAppointmentResult, updateAppointment] = useMutation(UpdateAppointmentDocument);

  const onEditPet = useCallback(() => {
    navigate(paths.clinicPetsPath(clinicSlug));
  }, [navigate, clinicSlug]);

  const onNewPet = useCallback(() => {
    navigate(paths.clinicPetNewPath(clinicSlug));
  }, [navigate]);

  const onFinish = useCallback(() => {
    navigate(paths.clinicRootPath(clinicSlug));
  }, [navigate]);

  const onSubmit = useCallback(
    async (input: CreateAppointmentInput, onNext: () => void) => {
      if (!appointment) {
        return;
      }
      try {
        const res = await updateAppointment({
          input: { id: appointment.appointment.id, ...input },
        });
        if (res.error) {
          throw new Error('予約の受付に失敗しました');
        }
        if (res.data?.updateAppointment.id) {
          enqueueSnackbar('予約を受け付けました', { variant: 'success' });
          onNext();
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
        enqueueSnackbar('予約の受付に失敗しました', { variant: 'error' });
      }
    },
    [updateAppointment, appointment]
  );

  if (
    !appointment ||
    appointmentFetching ||
    !appointmentFormOptions?.clinic ||
    appointmentFormOptionsFetching
  ) {
    return <FullScreenLoading />;
  }

  return (
    <>
      <Helmet>
        <title>{pageTitles.appointmentEdit}</title>
      </Helmet>
      <AppointmentForm
        appointment={appointment.appointment}
        clinic={appointmentFormOptions.clinic}
        viewer={appointmentFormOptions.viewer}
        onSubmit={onSubmit}
        onEditPet={onEditPet}
        onNewPet={onNewPet}
        onFinish={onFinish}
      />
    </>
  );
};

export default Page;
