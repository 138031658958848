import { Box, Button, Checkbox, Typography } from '@mui/material';
import { blue } from '@mui/material/colors';
import type { ChangeEvent } from 'react';
import { useCallback, useState } from 'react';
import { useTermOfServiceDialog } from 'components/dialogs/user/TermOfServiceDialog/hooks/useTermOfServiceDialog';
import type { AppAgreement, ClinicAgreement } from 'graphql/generated/user/graphql';

interface Props {
  appAgreement: Pick<AppAgreement, 'text' | 'url'>;
  clinicAgreement: Pick<ClinicAgreement, 'text'>;
  fetching: boolean;
  onSubmit: () => void;
}

export const AcceptClinicTermOfService = (props: Props) => {
  const [checked, setChecked] = useState<boolean>(false);

  const appAgreementDialog = useTermOfServiceDialog({
    title: '利用規約',
    text: props.appAgreement.text,
  });

  const clinicAgreementDialog = useTermOfServiceDialog({
    title: '個人情報保護方針',
    text: props.clinicAgreement?.text,
  });

  const onCheck = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      setChecked(ev.target.checked);
    },
    [setChecked]
  );

  return (
    <>
      <Box display="flex" alignItems="center" my={2}>
        <Checkbox value={checked} onChange={onCheck} />
        <Typography sx={{ fontSize: '15px' }}>
          <span onClick={appAgreementDialog.open} style={{ color: blue[400], cursor: 'pointer' }}>
            利用規約
          </span>
          ・
          <span
            onClick={() => window.open(props.appAgreement.url, '_blank')}
            style={{ color: blue[400], cursor: 'pointer' }}
          >
            個人情報保護方針(TYL)
          </span>
          ・
          <span
            onClick={clinicAgreementDialog.open}
            style={{ color: blue[400], cursor: 'pointer' }}
          >
            個人情報保護方針(動物病院)
          </span>
          に同意し会員登録を完了する
        </Typography>
      </Box>

      <Typography variant="body2" textAlign="center" my={2}>
        登録情報は動物病院等（過去に会員登録もしくは予約をしたことがある動物病院等）及び本サービスを運営する株式会社TYLで共有します
      </Typography>

      <Box my={4} textAlign="center">
        <Button
          disabled={!checked || props.fetching}
          color="inherit"
          variant="contained"
          onClick={props.onSubmit}
        >
          登録完了
        </Button>
      </Box>
      {appAgreementDialog.render()}
      {clinicAgreementDialog.render()}
    </>
  );
};
