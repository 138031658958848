import type { FC } from 'react';
import { Tab, Tabs } from '@mui/material';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import * as pageTitles from 'constants/pageTitles/staff';

import * as paths from 'constants/paths/staff';

interface Props {
  resourceId: string;
}

const StaffScheduleTabs: FC<Props> = ({ resourceId }) => {
  const location = useLocation();
  let value = 0;
  switch (location.pathname) {
    case paths.resourceWorkingHoursPath(resourceId):
      value = 1;
      break;
    case paths.resourceHolidaysPath(resourceId):
      value = 2;
      break;
    default:
      break;
  }

  return (
    <Tabs value={value}>
      <Tab
        label={pageTitles.resourceWorkingHours}
        component={RouterLink}
        to={paths.resourceWorkingHoursPath(resourceId)}
        value={1}
      />
      <Tab
        label={pageTitles.resourceHolidays}
        component={RouterLink}
        to={paths.resourceHolidaysPath(resourceId)}
        value={2}
      />
    </Tabs>
  );
};

export default StaffScheduleTabs;
