import { Box, Button, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import AddIcon from '@mui/icons-material/Add';
import { useCallback, useState } from 'react';
import { ResourceRow as SingleResource } from './SingleResource';
import { SelectTermOfTime } from './SelectTermOfTime';
import type { DayOfWeek, ResourceWorkingHour, Time } from 'graphql/generated/staff/graphql';

interface Props {
  dayOfWeek: DayOfWeek;
  isHoliday: boolean;
  schedules: ResourceWorkingHour[];
  onCreate: (dayOfWeek: DayOfWeek, start: Time, end: Time) => void;
  onUpdate: (id: ResourceWorkingHour['id'], start: Time, end: Time) => void;
  onDelete: (id: ResourceWorkingHour['id']) => void;
}

export const DailyWorkingHourDaily = (props: Props) => {
  const [showAddResourceWorkingHour, setShowAddResourceWorkingHour] = useState(false);

  const onShowAddResourceWorkingHour = useCallback(() => {
    setShowAddResourceWorkingHour(true);
  }, [setShowAddResourceWorkingHour]);

  const onhideAddResourceWorkingHour = useCallback(() => {
    setShowAddResourceWorkingHour(false);
  }, [setShowAddResourceWorkingHour]);

  const onCreate = useCallback(
    (start: Time, end: Time) => {
      props.onCreate(props.dayOfWeek, start, end);
      onhideAddResourceWorkingHour();
    },
    [props.dayOfWeek, props.onCreate]
  );

  if (props.isHoliday) {
    return <Typography color={grey['500']}>定休日</Typography>;
  }

  if (props.schedules.length === 0 && !showAddResourceWorkingHour) {
    return (
      <Button onClick={onShowAddResourceWorkingHour}>
        <AddIcon />
      </Button>
    );
  }

  return (
    <>
      {props.schedules.map((s) => (
        <SingleResource
          key={s.id}
          resourceWorkingHour={s}
          onAdd={onShowAddResourceWorkingHour}
          onSubmit={props.onUpdate}
          onDelete={props.onDelete}
        />
      ))}
      {showAddResourceWorkingHour && (
        <Box width="100%" display="flex">
          <SelectTermOfTime
            start={{
              hour: 8,
              minute: 0,
            }}
            end={{
              hour: 17,
              minute: 0,
            }}
            isNew
            onSubmit={onCreate}
            onCancel={onhideAddResourceWorkingHour}
          />
        </Box>
      )}
    </>
  );
};
