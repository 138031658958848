import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

export interface RequestPasswordResetFormInput {
  email: string;
}

const formSchema = yup.object({
  email: yup
    .string()
    .required('メールアドレスが入力されていません')
    .email('正しい形式でメールアドレスを入力してください'),
});

export const usePasswordResetForm = () =>
  useForm<RequestPasswordResetFormInput>({
    resolver: yupResolver(formSchema),
  });
