import { enqueueSnackbar } from 'notistack';
import type { FC } from 'react';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'urql';
import { Helmet } from 'react-helmet-async';
import FullScreenLoading from 'components/FullScreenLoading';
import { LoginForm } from 'components/forms/user/LoginForm/LoginForm';
import type { LoginFormInput } from 'components/forms/user/LoginForm/hooks/useLoginForm';
import * as paths from 'constants/paths/user';
import { ClinicForLoginDocument, SignInWithEmailDocument } from 'graphql/generated/user/graphql';
import { useAuthCookie } from 'hooks/user/useAuthCookie';
import * as pageTitles from 'constants/pageTitles/user';

const Page: FC = () => {
  const { clinicSlug = null } = useParams();
  const [signInResult, signIn] = useMutation(SignInWithEmailDocument);
  const [{ data }] = useQuery({
    query: ClinicForLoginDocument,
    pause: !clinicSlug,
    variables: {
      slug: clinicSlug as string,
    },
  });
  const navigate = useNavigate();
  const { redirectAfterLogin, login } = useAuthCookie();

  const onSubmit = useCallback(async (input: LoginFormInput) => {
    try {
      const result = await signIn({ input });
      if (result.data?.signInWithEmail?.accessToken) {
        afterLogin(result.data?.signInWithEmail?.accessToken);
      } else if (result.error?.message === '[GraphQL] invalid email or password') {
        throw new Error('メールアドレスまたはパスワードが違います');
      } else {
        throw new Error('ログインに失敗しました');
      }
    } catch (e: any) {
      enqueueSnackbar(e.message, { variant: 'error' });
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }, []);

  const afterLogin = useCallback(
    (accessToken: string) => {
      login(accessToken);
      //ログイン後に操作できる画面からログイン画面に飛ばされた場合ログイン後に戻る
      if (redirectAfterLogin) {
        navigate(redirectAfterLogin);
      } else {
        navigate(paths.clinicAppointmentNewPath(clinicSlug as string));
      }
    },
    [login, clinicSlug, redirectAfterLogin]
  );

  if (!data) {
    return <FullScreenLoading />;
  }

  if (!data.clinic) {
    throw Error('クリニック情報が空です');
  }

  return (
    <>
      <Helmet>
        <title>{pageTitles.login}</title>
      </Helmet>
      <LoginForm
        onSubmit={onSubmit}
        clinic={data.clinic}
        agreement={data.latestAppAgreement}
        fetching={signInResult.fetching}
      />
    </>
  );
};

export default Page;
