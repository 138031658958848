import {
  Avatar,
  Box,
  Button,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import type { FC } from 'react';
import styled from '@emotion/styled';
import { Link as RouterLink } from 'react-router-dom';
import type { ResourceDetailFragment } from 'graphql/generated/staff/graphql';
import ServiceChips from 'components/ServiceChips';
import * as paths from 'constants/paths/staff';
import { useConfirmationDialog } from 'components/dialogs/ConfirmationDialog';

interface Props {
  resource: ResourceDetailFragment;
  onDelete?: () => void;
}

export const Component: FC<Props> = ({ resource, onDelete }) => {
  const deleteConfirmDialog = useConfirmationDialog({
    title: '本当に削除しますか？',
    message: `${resource.kind.name} ${resource.name} を削除します。\n削除したデータは復元できません。`,
    destructive: true,
    onAccept: onDelete,
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <Stack gap={2}>
          <Box sx={{ display: 'flex', justifyContent: 'center', pb: 5 }}>
            <Avatar
              src={resource.profileImageURL || undefined}
              sx={{ width: 200, height: 200 }}
              variant="rounded"
            />
          </Box>
          <Button
            variant="outlined"
            color="secondary"
            size="large"
            fullWidth
            component={RouterLink}
            to={paths.resourceEditPath(resource.id)}
          >
            {resource.kind.name}情報編集
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            size="large"
            fullWidth
            component={RouterLink}
            to={paths.resourceWorkingHoursPath(resource.id)}
          >
            スケジュール管理
          </Button>
          <Button variant="text" color="secondary" size="small" onClick={deleteConfirmDialog.open}>
            {resource.kind.name}登録削除
          </Button>
        </Stack>
      </Grid>
      <Grid item xs={8}>
        <TableContainer>
          <Table>
            <TableBody>
              {[
                ['ID', resource.id],
                ['名前', resource.name],
                ['プロフィール', resource.profile],
                ['種別', resource.kind.name],
                [
                  '対応サービス',
                  <ServiceChips key={`${resource.id}-services`} services={resource.services} />,
                ],
              ].map(([label, value]) => (
                <TableRow key={`${label}-${value}`}>
                  <Th component="th" align="right">
                    {label}
                  </Th>
                  <TableCell>{value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      {deleteConfirmDialog.render()}
    </Grid>
  );
};

const Th = styled(TableCell)`
  width: 10em;
  color: #aaa;
  font-weight: bold;
`;
