import { Box, Button, Paper, Stack, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { DateCard } from './DateCard';
import { PetCard } from './PetCard';
import { ResourceCard } from './ResourceCard';
import type { AppointmentDetailQuery } from 'graphql/generated/user/graphql';

type PartiallyPartial<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export interface AppointmentViewProps {
  appointment: PartiallyPartial<
    Omit<AppointmentDetailQuery['appointment'], 'id' | 'status' | 'clinic'>,
    'resource' | 'symptom' | 'history' | 'note' | 'onset'
  >;
  onSelectPet?: () => void;
  onSelectService?: () => void;
  onSelectDate?: () => void;
  onSelectInterview?: () => void;
}

export const AppointmentView = (props: AppointmentViewProps) => {
  const { appointment } = props;

  if (!appointment.clinicPet.pet) {
    return null;
  }

  return (
    <Stack spacing={2}>
      <Box component={Paper} p={2} pb={3} variant="outlined" elevation={0}>
        <Typography variant="body2" fontWeight="bold">
          ペット
        </Typography>
        <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
          <Box>
            <PetCard pet={appointment.clinicPet.pet} />
          </Box>
          {props.onSelectPet && (
            <Button variant="contained" onClick={props.onSelectPet}>
              変更
            </Button>
          )}
        </Box>
      </Box>

      <Box component={Paper} p={2} pb={3} variant="outlined">
        <Typography variant="body2" fontWeight="bold">
          サービス
        </Typography>
        <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
          <Typography variant="body2">
            {appointment.service ? (
              <>
                {appointment.service.name}({appointment.service.durationMinutes}分)
              </>
            ) : (
              <>サービス未選択</>
            )}
          </Typography>
          {props.onSelectService && (
            <Button variant="contained" onClick={props.onSelectService}>
              変更
            </Button>
          )}
        </Box>
      </Box>

      <Box component={Paper} p={2} pb={3} variant="outlined">
        <Typography variant="body2" fontWeight="bold">
          予約日時・スタッフ
        </Typography>
        <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
          <Box>
            <DateCard startAt={props.appointment.startAt} endAt={props.appointment.endAt} />
            {props.appointment.resource && (
              <Box mt={1}>
                <ResourceCard resource={props.appointment.resource} />
              </Box>
            )}
          </Box>
          {props.onSelectDate && (
            <Button variant="contained" onClick={props.onSelectDate}>
              変更
            </Button>
          )}
        </Box>
      </Box>

      <Box component={Paper} p={2} pb={3} variant="outlined">
        <Typography variant="body2" fontWeight="bold">
          問診内容
        </Typography>
        <Box mt={2}>
          <Stack spacing={3}>
            <Box>
              <Typography variant="caption" fontWeight="bold" color={grey[600]}>
                初診
              </Typography>
              <Typography variant="body2" mt={1}>
                {props.appointment.isFirst ? 'はい' : 'いいえ'}
              </Typography>
            </Box>
            <Box>
              <Typography variant="caption" fontWeight="bold" color={grey[600]}>
                部位・症状
              </Typography>
              <Typography variant="body2" mt={1}>
                {props.appointment.symptom}
              </Typography>
            </Box>
            <Box>
              <Typography variant="caption" fontWeight="bold" color={grey[600]}>
                いつ頃から？
              </Typography>
              <Typography variant="body2" mt={1}>
                {props.appointment.onset}
              </Typography>
            </Box>
            <Box>
              <Typography variant="caption" fontWeight="bold" color={grey[600]}>
                既往歴
              </Typography>
              <Typography variant="body2" mt={1}>
                {props.appointment.history}
              </Typography>
            </Box>
            <Box>
              <Typography variant="caption" fontWeight="bold" color={grey[600]}>
                その他
              </Typography>
              <Typography variant="body2" mt={1}>
                {props.appointment.note}
              </Typography>
            </Box>
          </Stack>
          <Box mt={4} textAlign="right">
            {props.onSelectInterview && (
              <Button variant="contained" onClick={props.onSelectInterview}>
                変更
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Stack>
  );
};
