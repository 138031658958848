import { useCallback } from 'react';
import { useMutation } from 'urql';
import type { UpdatePetInput } from 'graphql/generated/user/graphql';
import { UpdatePetDocument } from 'graphql/generated/user/graphql';
import { useUpdatePetProfileImage } from 'hooks/user/forms/useUpdatePetProfileImage';

// ペット情報の更新を行う
export const useUpdatePet = () => {
  const [updateResult, updatePet] = useMutation(UpdatePetDocument);
  const updatePetProfileImage = useUpdatePetProfileImage();

  const update = useCallback(async (input: UpdatePetInput, file?: File) => {
    if (file) {
      input.profileImageURL = await updatePetProfileImage(input.id, file);
    }

    return await updatePet({ input });
  }, []);

  return {
    updatePet: update,
    fetching: updateResult.fetching,
  };
};
