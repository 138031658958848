import { Typography } from '@mui/material';
import type { FC } from 'react';
import { useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'urql';
import FullScreenLoading from 'components/FullScreenLoading';
import { AppointmentsTable } from 'components/tables/user/AppointmentsTable/AppointmentsTable';
import * as pageTitles from 'constants/pageTitles/user';
import * as paths from 'constants/paths/user';
import type { Appointment } from 'graphql/generated/user/graphql';
import { AppointmentsDocument } from 'graphql/generated/user/graphql';

const Page: FC = () => {
  const { clinicSlug } = useParams();
  const navigate = useNavigate();
  const [{ data, fetching }] = useQuery({
    query: AppointmentsDocument,
  });

  const onSelect = useCallback(
    (appointmentId: Appointment['id']) => {
      navigate(paths.clinicAppointmentDetailPath(clinicSlug, appointmentId));
    },
    [clinicSlug]
  );

  if (!data || fetching) {
    return <FullScreenLoading />;
  }

  return (
    <>
      <Helmet>
        <title>{pageTitles.appointments}</title>
      </Helmet>
      <Typography variant="h5" textAlign="center">
        予約履歴
      </Typography>
      <AppointmentsTable appointments={data.appointments} onSelect={onSelect} />
    </>
  );
};

export default Page;
