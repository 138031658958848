import type { FC } from 'react';
import { useMemo, useEffect } from 'react';

import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { grey } from '@mui/material/colors';
import type {
  AppointmentStatus,
  AppointmentsFilterOptionsQuery,
} from 'graphql/generated/staff/graphql';
import { allAppointmentStatuses, appointmentStatusLabel } from 'constants/appointmentStatuses';

interface Props {
  defaultValues?: FormValues;
  onSubmit?: (values: FormValues) => void;
  optionsData: AppointmentsFilterOptionsQuery;
}

interface FormValues {
  dateFrom?: string;
  dateTo?: string;
  status?: AppointmentStatus | undefined;
  resourceId?: string;
  serviceId?: string;
  serviceCategoryId?: string;
}

export const Component: FC<Props> = ({ onSubmit = () => {}, defaultValues = {}, optionsData }) => {
  const { handleSubmit, watch, control, setValue } = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const resources = optionsData.resources;
  const serviceCategories = optionsData.serviceCategories;

  const services = useMemo(() => {
    const category = serviceCategories.find((service) => service.id === watch('serviceCategoryId'));
    return category?.services || [];
  }, [serviceCategories, watch('serviceCategoryId')]);

  useEffect(() => {
    const category = serviceCategories.find((service) => service.id === watch('serviceCategoryId'));
    const service = category?.services.find((service) => service.id === watch('serviceId'));
    if (!service) {
      setValue('serviceId', undefined);
    }
  }, [watch('serviceCategoryId'), watch('serviceId'), setValue]);

  useEffect(() => {
    const subscription = watch(() => handleSubmit(onSubmit)());
    return () => subscription.unsubscribe();
  }, [watch, handleSubmit, onSubmit]);

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 2,
        alignItems: 'center',
        flexWrap: 'wrap',
        whiteSpace: 'nowrap',
        p: 2,
        background: grey[50],
        borderRadius: 2,
        mb: 4,
      }}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Controller
        control={control}
        name="serviceCategoryId"
        render={({ field }) => (
          <FormControl sx={{ minWidth: 250 }} size="small">
            <InputLabel id="appointement-filter-select-service-category-label">
              サービスカテゴリ
            </InputLabel>
            <Select
              {...field}
              label="サービス種別"
              labelId="appointement-filter-select-service-category-label"
              id="appointement-filter-select-service-category"
            >
              <MenuItem>すべて</MenuItem>
              {serviceCategories.map((serviceCategory) => (
                <MenuItem key={serviceCategory.id} value={serviceCategory.id}>
                  {serviceCategory.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />

      <Controller
        control={control}
        name="serviceId"
        render={({ field }) => (
          <FormControl sx={{ minWidth: 150 }} size="small" disabled={services.length === 0}>
            <InputLabel id="appointement-filter-select-service-label">サービス</InputLabel>
            <Select
              {...field}
              label="サービス"
              labelId="appointement-filter-select-service-label"
              id="appointement-filter-select-service"
            >
              <MenuItem>すべて</MenuItem>
              {services.map((service) => (
                <MenuItem key={service.id} value={service.id}>
                  {service.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />

      <Controller
        control={control}
        name="status"
        render={({ field }) => (
          <FormControl sx={{ minWidth: 150 }} size="small">
            <InputLabel id="appointement-filter-select-status-label">ステータス</InputLabel>
            <Select
              {...field}
              label="ステータス"
              labelId="appointement-filter-select-status-label"
              id="appointement-filter-select-status"
            >
              <MenuItem>すべて</MenuItem>
              {allAppointmentStatuses.map((status) => (
                <MenuItem key={status} value={status}>
                  {appointmentStatusLabel(status)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />

      <Controller
        control={control}
        name="resourceId"
        render={({ field }) => (
          <FormControl sx={{ minWidth: 200 }} size="small">
            <InputLabel id="appointement-filter-select-resource-label">担当</InputLabel>
            <Select
              {...field}
              label="担当"
              labelId="appointement-filter-select-resource-label"
              id="appointement-filter-select-resource"
            >
              <MenuItem>すべて</MenuItem>
              {resources.map((resource) => (
                <MenuItem key={resource.id} value={resource.id}>
                  {resource.name} ({resource.kind.name})
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />

      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', flexWrap: 'nowrap' }}>
        <Typography variant="body2"> 予約日</Typography>
        <Controller
          control={control}
          name="dateFrom"
          rules={{
            required: true,
            max: watch('dateTo'),
          }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              type="date"
              size="small"
              inputProps={{
                max: watch('dateTo'),
              }}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
            />
          )}
        />
        <Typography variant="body2">-</Typography>
        <Controller
          control={control}
          name="dateTo"
          rules={{
            required: true,
            min: watch('dateFrom'),
          }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              type="date"
              size="small"
              inputProps={{
                min: watch('dateFrom'),
              }}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
            />
          )}
        />
      </Box>
    </Box>
  );
};
