import { Avatar, Box, Typography } from '@mui/material';
import type { Resource } from 'graphql/generated/user/graphql';

interface Props {
  resource: Pick<Resource, 'name' | 'photo'>;
}

export const ResourceCard = (props: Props) => {
  return (
    <Box display="flex" alignItems="center">
      <Avatar src={props.resource?.photo ?? undefined} />
      <Box ml={1}>
        <Typography variant="body2">{props.resource?.name}</Typography>
      </Box>
    </Box>
  );
};
