import { useQuery } from 'urql';
import { addYears, isPast } from 'date-fns';
import { AppointmentDetailDocument } from 'graphql/generated/user/graphql';
import { caluculateCancellationDeadline } from 'helpers/caluculateCancellationDeadline';

export const useAppointmentDetail = (id: string) => {
  const [{ data }] = useQuery({
    query: AppointmentDetailDocument,
    variables: { id },
    pause: !id,
  });

  const appointment = data?.appointment;

  const clinic = appointment?.clinic;
  let cancellationDeadline = addYears(new Date(), 1000);

  if (clinic && appointment) {
    const startAt = new Date(appointment.startAt);
    if (typeof clinic.cancellationDeadlineDays === 'number') {
      cancellationDeadline = caluculateCancellationDeadline(
        startAt,
        clinic.cancellationDeadlineDays,
        'days'
      );
    } else {
      cancellationDeadline = caluculateCancellationDeadline(
        startAt,
        clinic.cancellationDeadlineHours ?? 0,
        'hours'
      );
    }
  }

  const isOverDeadline = isPast(cancellationDeadline);

  return { appointment, clinic, cancellationDeadline, isOverDeadline };
};
