import { useCallback, useState } from 'react';
import type {
  AppointmentFormOptionsQueryPet,
  AppointmentFormOptionsQueryResource,
  AppointmentFormOptionsQueryService,
} from './types';
import type { CreateAppointmentInput, Scalars } from 'graphql/generated/user/graphql';

export interface AppointmentValues {
  isFirst: Scalars['Boolean'];
  startAt?: Scalars['DateTime'];
  endAt?: Scalars['DateTime'];
  history?: Scalars['String'];
  note?: Scalars['String'];
  onset?: Scalars['String'];
  symptom?: Scalars['String'];
  pet?: AppointmentFormOptionsQueryPet;
  resource?: AppointmentFormOptionsQueryResource | null;
  service?: AppointmentFormOptionsQueryService;
}

interface Interviews {
  history: Scalars['String'];
  note: Scalars['String'];
  onset: Scalars['String'];
  symptom: Scalars['String'];
}

export const useAppointmentForm = (defaultValues: AppointmentValues) => {
  const [values, setValue] = useState<AppointmentValues>(defaultValues);

  const setPet = useCallback(
    (pet: AppointmentFormOptionsQueryPet) => {
      setValue((prev) => ({
        ...prev,
        pet,
      }));
    },
    [setValue]
  );

  const setService = useCallback(
    (service: AppointmentFormOptionsQueryService) => {
      setValue((prev) => ({
        ...prev,
        service,
      }));
    },
    [setValue]
  );

  const setResource = useCallback(
    (resource: AppointmentFormOptionsQueryResource | null) => {
      setValue((prev) => ({
        ...prev,
        resource: resource ?? undefined,
      }));
    },
    [setValue]
  );

  const setDate = useCallback(
    (startAt: CreateAppointmentInput['startAt'], endAt: CreateAppointmentInput['endAt']) => {
      setValue((prev) => ({
        ...prev,
        startAt,
        endAt,
      }));
    },
    [setValue]
  );

  const setInterviews = useCallback(
    (interviews: Interviews) => {
      setValue((prev) => ({
        ...prev,
        ...interviews,
      }));
    },
    [setValue]
  );

  const setIsFirst = useCallback(
    (isFirst: CreateAppointmentInput['isFirst']) => {
      setValue((prev) => ({
        ...prev,
        isFirst,
      }));
    },
    [setValue]
  );

  return {
    values,
    setPet,
    setService,
    setResource,
    setDate,
    setInterviews,
    setIsFirst,
  };
};
