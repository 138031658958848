import { Avatar, Card, CardContent, Typography } from '@mui/material';
import styled from 'styled-components';
import type { Resource } from 'graphql/generated/user/graphql';

interface Props {
  resource: {
    name: Resource['name'];
    photo?: Resource['photo'];
    profile?: Resource['profile'];
  };
}

const StyledCard = styled(Card)`
  display: flex;
  align-items: center;
`;

const StyledAvatar = styled(Avatar)`
  height: 70px;
  width: 70px;
  border-radius: 50%;
  flex-shrink: 0;
  margin-left: 16px;
`;

export const ResourceDetail = (props: Props) => {
  return (
    <StyledCard>
      <StyledAvatar src={props.resource.photo || ''} />
      <CardContent>
        <Typography variant="subtitle1" component="div">
          {props.resource.name}
        </Typography>
        <Typography variant="caption" color="text.secondary">
          {props.resource.profile}
        </Typography>
      </CardContent>
    </StyledCard>
  );
};
