const basePath = `/user`;
const clinicBasePath = (clinicSlug: string) => `/user/${clinicSlug}`;

// 旧ログイン
export const legacyClinicUserPath = (path: string, clinicSlug: string) =>
  `/user/${path}/${clinicSlug}`;

// ログイン
export const loginPath = () => `${basePath}/login`;
export const clinicLoginPath = (clinicSlug = '') => `${clinicBasePath(clinicSlug)}/login`;

// 新規登録 ユーザー情報入力
export const registerUserEditPath = () => `${basePath}/register/user/edit`;
export const clinicRegisterUserEditPath = (clinicSlug = '') =>
  `${clinicBasePath(clinicSlug)}/register/user/edit`;

// 新規登録 ペット情報入力
export const registerPetNewPath = () => `${basePath}/register/pet/new`;
export const clinicRegisterPetNewPath = (clinicSlug = '') =>
  `${clinicBasePath(clinicSlug)}/register/pet/new`;

// 新規登録 ペット情報編集
export const registerPetDetailPath = (petId = '') => `${basePath}/register/pet/${petId}`;
export const clinicRegisterPetDetailPath = (clinicSlug = '', petId = '') =>
  `${clinicBasePath(clinicSlug)}/register/pet/${petId}`;

// 新規登録 確認画面
export const registerConfirmPath = () => `${basePath}/register/confirm`;
export const clinicRegisterConfirmPath = (clinicSlug = '') =>
  `${clinicBasePath(clinicSlug)}/register/confirm`;

// 新規登録 完了画面
export const registerCompletePath = () => `${basePath}/register/complete`;
export const clinicRegisterCompletePath = (clinicSlug = '') =>
  `${clinicBasePath(clinicSlug)}/register/complete`;

// パスワードリセット
export const passwordResetPath = () => `${basePath}/password-forget`;
export const clinicPasswordResetPath = (clinicSlug = '') =>
  `${clinicBasePath(clinicSlug)}/password-forget`;

// パスワードリセットメール送信後
export const sendPasswordResetMailPath = () => `${basePath}/password-forget/success`;
export const clinicSendPasswordResetMailPath = (clinicSlug = '') =>
  `${clinicBasePath(clinicSlug)}/password-forget/success`;

// パスワードリセット 新パスワード設定
export const confirmPasswordResetPath = () => `${basePath}/confirmPasswordReset`;

// パスワードリセット 完了
export const successConfirmPasswordResetPath = () => `${basePath}/confirmPasswordReset/success`;

// callbacks/line
export const lineCallbackPath = () => `${basePath}/callbacks/line`;

// 新規登録
export const registerPath = () => `${basePath}/register`;

// クリニックからの新規登録
export const registerClinicPath = (clinicSlug = '') => `${clinicBasePath(clinicSlug)}/register/`;

// 新規登録Email認証
export const confirmEmailPath = () => `${basePath}/confirmEmail`;
export const clinicConfirmEmailPath = (clinicSlug = '') =>
  `${clinicBasePath(clinicSlug)}/confirmEmail`;

// トップページ
export const rootPath = () => `${basePath}`;
export const clinicRootPath = (clinicSlug = '') => `${clinicBasePath(clinicSlug)}`;
// アカウント情報
export const ownerPath = () => `${basePath}/owners`;
export const clinicOwnerPath = (clinicSlug = '') => `${clinicBasePath(clinicSlug)}/owners`;
// 飼い主情報編集
export const ownerEditPath = () => `${basePath}/owners/edit`;
export const clinicOwnerEditPath = (clinicSlug = '') => `${clinicBasePath(clinicSlug)}/owners/edit`;
// ペット一覧
export const petsPath = () => `${basePath}/pets`;
export const clinicPetsPath = (clinicSlug = '') => `${clinicBasePath(clinicSlug)}/pets`;
// ペット新規作成
export const petNewPath = () => `${basePath}/pets/new`;
export const clinicPetNewPath = (clinicSlug = '') => `${clinicBasePath(clinicSlug)}/pets/new`;
// ペット詳細
export const petDetailPath = (petId = '') => `${basePath}/pets/${petId}`;
export const clinicPetDetailPath = (clinicSlug = '', petId = '') =>
  `${clinicBasePath(clinicSlug)}/pets/${petId}`;
// ペット編修
export const petEditPath = (clinicSlug = '', id = '') =>
  `${clinicBasePath(clinicSlug)}/pets/${id}/edit`;
export const clinicPetEditPath = (clinicSlug = '', id = '') =>
  `${clinicBasePath(clinicSlug)}/pets/${id}/edit`;
// 予約一覧
export const appointmentsPath = () => `${basePath}/appointments`;
export const clinicAppointmentsPath = (clinicSlug = '') =>
  `${clinicBasePath(clinicSlug)}/appointments`;
// 予約新規作成
export const clinicAppointmentNewPath = (clinicSlug = '') =>
  `${clinicBasePath(clinicSlug)}/appointments/new`;
// 予約詳細
export const appointmentDetailPath = (id = '') => `${basePath}/appointments/${id}`;
export const clinicAppointmentDetailPath = (clinicSlug = '', id = '') =>
  `${clinicBasePath(clinicSlug)}/appointments/${id}`;
// 予約編集
export const appointmentEditPath = (id = '') => `${basePath}/appointments/${id}/edit`;
export const clinicAppointmentEditPath = (clinicSlug = '', id = '') =>
  `${clinicBasePath(clinicSlug)}/appointments/${id}/edit`;
