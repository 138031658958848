import { Outlet, createBrowserRouter } from 'react-router-dom';
import userRoutes from './user';
import staffRoutes from './staff';
import NotFoundPage from 'pages/NotFoundPage';
import ErrorPage from 'pages/ErrorPage';

export default createBrowserRouter([
  {
    element: <Outlet />,
    ErrorBoundary: () => <ErrorPage />,
    children: [
      ...userRoutes,
      ...staffRoutes,
      {
        path: '*',
        element: <NotFoundPage />,
      },
    ],
  },
]);
