import type { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { SuccessPasswordReset } from 'components/forms/user/SuccessPasswordReset/SuccessPasswordReset';
import * as pageTitles from 'constants/pageTitles/user';
import { usePasswordResetCookie } from 'hooks/user/useRasswordResetCookie';

const Page: FC = () => {
  const { getRedirectClinicSlug: getClinicSlug } = usePasswordResetCookie();
  const clinicSlugFromCookie = getClinicSlug();

  return (
    <>
      <Helmet>
        <title>{pageTitles.successSasswordReset}</title>
      </Helmet>
      <SuccessPasswordReset clinicSlug={clinicSlugFromCookie ?? undefined} />
    </>
  );
};

export default Page;
