import type { FC } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'urql';
import { useCallback } from 'react';
import { enqueueSnackbar } from 'notistack';
import { Helmet } from 'react-helmet-async';
import { format } from 'date-fns';
import FullScreenLoading from 'components/FullScreenLoading';
import { AppointmentView } from 'components/appointments/user/AppointmentView/AppointmentView';
import * as paths from 'constants/paths/user';
import * as pageTitles from 'constants/pageTitles/user';

import {
  CancelAppointmentDocument,
  AppointmentFormOptionsDocument,
  AppointmentStatus,
} from 'graphql/generated/user/graphql';
import NotFoundPage from 'pages/NotFoundPage';
import { useAppointmentDetail } from 'hooks/user/useAppointmentDetail';

const Page: FC = () => {
  const { appointmentId = null, clinicSlug = null } = useParams();
  const { appointment, clinic, cancellationDeadline, isOverDeadline } = useAppointmentDetail(
    appointmentId ?? ''
  );
  const common = !clinicSlug;
  const [{ data: appointmentFormOptions }] = useQuery({
    query: AppointmentFormOptionsDocument,
    variables: {
      slug: clinic?.slug ?? '',
    },
    pause: !clinic?.slug,
  });
  const [cancelAppointmentResult, cancelAppointment] = useMutation(CancelAppointmentDocument);

  const onCancel = useCallback(async () => {
    if (!appointment) {
      return;
    }
    if (!confirm('予約をキャンセルしますか？')) {
      return;
    }
    try {
      const res = await cancelAppointment({
        id: appointment.id,
      });
      if (res.data?.cancelAppointment.id) {
        enqueueSnackbar('予約をキャンセルしました', { variant: 'success' });
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      enqueueSnackbar('予約のキャンセルに失敗しました', { variant: 'error' });
    }
  }, [appointment, cancelAppointment]);

  if (!appointmentId) {
    return <NotFoundPage />;
  }

  if (!appointmentFormOptions || !appointment) {
    return <FullScreenLoading />;
  }

  if (!appointmentFormOptions.clinic) {
    throw Error('クリニック情報が空です');
  }

  const Controls = () => {
    if (
      appointment.status === AppointmentStatus.Visited ||
      appointment.status === AppointmentStatus.Canceled
    ) {
      return null;
    }
    if (isOverDeadline) {
      return (
        <Typography variant="caption" component="p" sx={{ mt: 2, textAlign: 'center' }}>
          キャンセル期限を過ぎているため、ご予約の変更・キャンセルご希望の場合は、
          {clinic?.name}へ直接ご連絡ください。
        </Typography>
      );
    }
    return (
      <Stack spacing={4} mt={8}>
        <Box textAlign="center">
          <Button
            variant="contained"
            component={Link}
            to={
              common
                ? paths.appointmentEditPath(appointmentId)
                : paths
                    .appointmentEditPath(appointmentId)
                    .replace(/^\/user\//, `/user/${appointment.clinic.slug}/`)
            }
          >
            予約編集
          </Button>
        </Box>
        <Box textAlign="center">
          <Button color="secondary" disabled={cancelAppointmentResult.fetching} onClick={onCancel}>
            予約をキャンセルする
          </Button>
          <Typography variant="caption" color="textSecondary" component="p" mt={1}>
            (キャンセル期限 {format(cancellationDeadline, 'yyyy/MM/dd HH:mm')})
          </Typography>
        </Box>
      </Stack>
    );
  };

  return (
    <>
      <Helmet>
        <title>{pageTitles.appointmentDetail}</title>
      </Helmet>
      <Box pb={4}>
        <Box my={4} textAlign="center">
          <Typography typography="h6">予約詳細</Typography>
        </Box>
        <AppointmentView appointment={appointment} />
        <Controls />
      </Box>
    </>
  );
};

export default Page;
