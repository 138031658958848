import { Box, Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';
import { useCallback } from 'react';
import type { AppointmentFormOptionsQueryService } from '../AppointmentForm/types';

interface Props {
  service: AppointmentFormOptionsQueryService;
  onSelect: (service: AppointmentFormOptionsQueryService) => void;
}

export const ServiceItem = (props: Props) => {
  const onSelect = useCallback(() => {
    props.onSelect(props.service);
  }, []);

  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
        <Box>
          <Typography variant="body1">{props.service.name}</Typography>
          <Typography variant="body2" color={grey[700]}>
            {props.service.durationMinutes}分
          </Typography>
        </Box>
        <Button variant="contained" onClick={onSelect}>
          選択
        </Button>
      </Box>
      <Box>
        <Typography variant="body2" color={grey[700]} mt={1}>
          {props.service.description}
        </Typography>
      </Box>
    </Box>
  );
};
