import { format } from 'date-fns';
import { memo, useCallback } from 'react';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { ScheduleCell } from './ScheduleCell';

interface TimeCellProps {
  date: Date;
  colIndex: number;
  rowIndex: number;
  isAvailable: boolean;
  isHover: boolean;
  onSelect: (date: Date) => void;
  onMouseEnter: (colIndex: number, rowIndex: number) => void;
  onMouseLeave: (colIndex: number, rowIndex: number) => void;
}

export const TimeCell = memo(function TimeCell(props: TimeCellProps) {
  const onSelect = useCallback(() => {
    props.onSelect(props.date);
  }, [props.date, props.onSelect]);

  const onMouseEnter = useCallback(() => {
    props.onMouseEnter(props.colIndex, props.rowIndex);
  }, [props.colIndex, props.rowIndex]);

  const onMouseLeave = useCallback(() => {
    props.onMouseLeave(props.colIndex, props.rowIndex);
  }, [props.colIndex, props.rowIndex]);

  return (
    <ScheduleCell
      isAvailable={props.isAvailable}
      isHover={props.isAvailable && props.isHover}
      sx={{ fontSize: '1em' }}
      title={format(props.date, 'MM/dd HH:mm')}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onSelect}
    >
      {props.isAvailable ? <EventAvailableIcon color="primary" /> : ''}
    </ScheduleCell>
  );
});
