import PetsIcon from '@mui/icons-material/Pets';
import {
  Avatar,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { useCallback } from 'react';
import { petGenders } from 'constants/petGender';
import type { Pet, PetsQuery } from 'graphql/generated/user/graphql';
import { getAge } from 'helpers/date';

interface Props {
  pet: PetsQuery['viewer']['pets'][number];
  onSelect: (petId: Pet['id']) => void;
}

export const Item = ({ pet, ...props }: Props) => {
  const petMeta: string[] = [];

  const onSelect = useCallback(() => {
    props.onSelect(pet.id);
  }, [props.onSelect, pet.id]);

  if (pet.animal?.name) {
    petMeta.push(pet.animal.name);
  }

  if (pet.animalType?.name) {
    petMeta.push(pet.animalType.name);
  }

  if (pet.gender) {
    const gender = petGenders.find((p) => p.value === pet.gender)?.label;
    if (gender) {
      petMeta.push(gender);
    }
  }

  if (pet.birthday) {
    petMeta.push(getAge(pet?.birthday, '歳'));
  }

  return (
    <>
      <ListItem disablePadding>
        <ListItemButton onClick={onSelect}>
          <ListItemAvatar>
            <Avatar src={pet.profileImageURL || ''}>
              <PetsIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={pet.name} secondary={petMeta.join(' / ')} />
        </ListItemButton>
      </ListItem>
      <Divider />
    </>
  );
};
