import type { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { SendPasswordResetMail } from 'components/forms/user/SendPasswordResetMail/SendPasswordResetMail';
import * as pageTitles from 'constants/pageTitles/user';

const Page: FC = () => (
  <>
    <Helmet>
      <title>{pageTitles.sendPasswordResetEmail}</title>
    </Helmet>
    <SendPasswordResetMail />
  </>
);

export default Page;
