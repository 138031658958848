import { enqueueSnackbar } from 'notistack';
import type { FC } from 'react';
import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useMutation } from 'urql';
import { Helmet } from 'react-helmet-async';
import { useRegisterCookie } from 'hooks/user/useRegisterCookie';
import { ConfirmEmailDocument } from 'graphql/generated/user/graphql';
import * as paths from 'constants/paths/user';
import { ConfirmEmail } from 'components/forms/user/ConfirmEmail/ConfirmEmail';
import * as pageTitles from 'constants/pageTitles/user';

const Page: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as { email: string } | null;
  const email = state?.email;
  const { setAccessToken, removeAuthStatus } = useRegisterCookie(true);
  const [searchParams] = useSearchParams();
  const [_, confirmEmail] = useMutation(ConfirmEmailDocument);
  const token = searchParams.get('token');

  const onConfirm = useCallback(async (token: string) => {
    try {
      const { data, error } = await confirmEmail({
        input: {
          confirmationToken: token,
        },
      });

      if (error || !data) {
        if (error?.message == 'already confirmed') {
          setAccessToken();
          throw new Error('すでに認証済みです');
        }
        throw new Error('認証に失敗しました');
      }

      enqueueSnackbar('認証に成功しました。', { variant: 'success' });
      setAccessToken(data.confirmEmail.accessToken);
      navigate(paths.registerUserEditPath());
    } catch (e: any) {
      // eslint-disable-next-line no-console
      console.log(e);
      enqueueSnackbar(e.message, { variant: 'error' });
    }
  }, []);

  useEffect(() => {
    // 厳密モードの場合は開発時に2回呼ばれるが仕様
    if (token) {
      onConfirm(token);
    }
  }, []);

  const onRetry = useCallback(() => {
    removeAuthStatus();
    navigate(paths.registerPath());
  }, []);

  // メールからトークンの認証で飛んできた場合
  if (token) {
    return (
      <>
        <Helmet>
          <title>{pageTitles.confirmEmail}</title>
        </Helmet>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{pageTitles.confirmEmail}</title>
      </Helmet>
      <ConfirmEmail authEmail={email || ''} onRetry={onRetry} />
    </>
  );
};

export default Page;
