import { format, intervalToDuration, parse, parseISO } from 'date-fns';

const dateFormat = 'yyyy-MM-dd';
const monthFormat = 'yyyy-MM';

export const formatDate = (date: Date) => {
  return format(date, dateFormat);
};

export const parseDate = (str: string) => {
  const parsed = parse(str, dateFormat, new Date());
  if (isNaN(parsed.getTime())) {
    return undefined;
  }
  return parsed;
};

export const formatMonth = (date: Date) => {
  return format(date, monthFormat);
};

export const parseMonth = (str: string) => {
  const parsed = parse(str, monthFormat, new Date());
  if (isNaN(parsed.getTime())) {
    return undefined;
  }
  return parsed;
};

export const formatInputDateTime = (date: Date | string) => {
  if (typeof date === 'string') {
    date = new Date(date);
  }
  if (isNaN(date.getTime())) {
    return undefined;
  }
  return format(date, "yyyy-MM-dd'T'HH:mm");
};

/**
 *
 * @param birthday yyyy-MM-dd HH:mm:ss
 * @returns
 */
export const getAge = (birthday?: string | null, suffix?: string) => {
  if (!birthday || birthday === '') {
    return '';
  }
  return `${
    intervalToDuration({
      start: parseISO(birthday),
      end: new Date(),
    }).years
  }${suffix}`;
};
