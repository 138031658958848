export const email = {
  pattern: {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    message: '正しい形式でメールアドレスを入力してください',
  },
};

export const password = {
  pattern: {
    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*+-])(?=.{8,})/,
    message: 'パスワードは8文字以上で、大文字・小文字・数字・記号を含めてください',
  },
};
