export const login = 'ログイン';
export const confirmEmail = 'メールアドレス確認';
export const resetPassword = 'パスワードリセット';
export const confirmResetPassword = 'パスワード再設定';
export const calendar = '予約カレンダー';
export const staffs = '管理ユーザー一覧';
export const staffNew = '管理ユーザー新規作成';
export const staffDetail = '管理ユーザー詳細';
export const resources = 'スタッフ一覧';
export const resourceNew = 'スタッフ新規作成';
export const resourceDetail = 'スタッフ詳細';
export const resourceEdit = 'スタッフ編集';
export const resourceWorkingHours = 'スタッフ対応時間';
export const resourceHolidays = 'スタッフ休日';
export const schedules = 'スケジュール管理';
export const settings = '設定';
export const users = '飼い主様一覧';
export const userDetail = '飼い主詳細';
export const petDetail = 'ペット詳細';
export const appointments = '予約一覧';
export const appointmentNew = '予約新規作成';
export const appointmentDetail = '予約詳細';
export const logout = 'ログアウト';
