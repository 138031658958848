import { Box } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import type { FC } from 'react';
import { useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'urql';
import FullScreenLoading from 'components/FullScreenLoading';
import { AcceptClinicTermOfService } from 'components/forms/user/AcceptClinicTermOfService/AcceptClinicTermOfService';
import { ClinicRegisterConfirm } from 'components/forms/user/ClinicRegisterConfirm/ClinicRegisterConfirm';
import * as pageTitles from 'constants/pageTitles/user';
import * as paths from 'constants/paths/user';
import type { Pet } from 'graphql/generated/user/graphql';
import {
  ClinicAgreementDocument,
  DeletePetDocument,
  RegisterConfirmDetailDocument,
} from 'graphql/generated/user/graphql';
import { useRegisterCookie } from 'hooks/user/useRegisterCookie';

const Page: FC = () => {
  const navigate = useNavigate();
  const { clinicSlug } = useParams();
  const { setComplete } = useRegisterCookie(true);
  const [_deleteResult, deletePetFunc] = useMutation(DeletePetDocument);
  const [{ data, fetching }] = useQuery({
    query: RegisterConfirmDetailDocument,
  });

  const [{ data: clinicAgreement, fetching: clinicAgreementFetching }] = useQuery({
    query: ClinicAgreementDocument,
    variables: {
      clinicSlug: clinicSlug as string,
    },
  });

  const onDeletePet = useCallback(async (pet: Pick<Pet, 'id' | 'name'>) => {
    const exec = confirm(`本当に${pet.name}の登録情報を削除しますか？`);
    if (!exec) {
      return;
    }

    try {
      const res = await deletePetFunc({ id: pet.id });
      if (res.error) {
        throw new Error(`ペット${pet.name}の情報の削除に失敗しました。`);
      }
      enqueueSnackbar(`ペット${pet.name}の情報が削除されました。`, {
        variant: 'success',
      });
    } catch (e: any) {
      enqueueSnackbar(`ペット${pet.name}の情報の削除に失敗しました。`, {
        variant: 'error',
      });
    }
  }, []);

  const onEditPet = useCallback(
    (pet: Pick<Pet, 'id' | 'name'>) => {
      navigate(paths.clinicRegisterPetDetailPath(clinicSlug, pet.id));
    },
    [clinicSlug]
  );

  const onAddPet = useCallback(() => {
    navigate(paths.clinicRegisterPetNewPath(clinicSlug));
  }, [clinicSlug]);

  const onEditOwner = useCallback(() => {
    navigate(paths.clinicRegisterUserEditPath(clinicSlug));
  }, [clinicSlug]);

  // TODO
  const onSubmit = useCallback(() => {
    setComplete();
    navigate(paths.clinicRegisterCompletePath(clinicSlug));
    // enqueueSnackbar('利用規約に同意処理に失敗しました', { variant: 'error' });
  }, [setComplete]);

  if (fetching || !data || clinicAgreementFetching || !clinicAgreement?.clinic?.latestAgreement) {
    return <FullScreenLoading />;
  }

  return (
    <>
      <Helmet>
        <title>{pageTitles.registerConfirm}</title>
      </Helmet>
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <ClinicRegisterConfirm
          viewer={data.viewer}
          onDeletePet={onDeletePet}
          onEditPet={onEditPet}
          onAddPet={onAddPet}
          onEditOwner={onEditOwner}
        />
        <AcceptClinicTermOfService
          onSubmit={onSubmit}
          fetching={false}
          appAgreement={data.latestAppAgreement}
          clinicAgreement={clinicAgreement.clinic.latestAgreement}
        />
      </Box>
    </>
  );
};

export default Page;
