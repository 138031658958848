import type { FC } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Box, Button, Container, Typography } from '@mui/material';
import { useQuery } from 'urql';
import { Helmet } from 'react-helmet-async';
import FullScreenLoading from 'components/FullScreenLoading';
import { PetDetailView } from 'components/views/user/PetDetailView/PetDetailView';
import * as paths from 'constants/paths/user';
import { PetDetailDocument } from 'graphql/generated/user/graphql';
import * as pageTitles from 'constants/pageTitles/user';

const Page: FC = () => {
  const { petId } = useParams() as { petId: string };
  const [{ data, fetching }] = useQuery({
    query: PetDetailDocument,
    variables: {
      petId,
    },
    pause: !petId,
  });

  const pet = data?.viewer.pet;

  if (fetching || !data) {
    return <FullScreenLoading />;
  }

  if (!pet) {
    return <Container>ペットが見つかりませんでした</Container>;
  }

  return (
    <>
      <Helmet>
        <title>{pageTitles.petDetail}</title>
      </Helmet>
      <Box>
        <Typography variant="h5" textAlign="center" mt={2}>
          ペット情報
        </Typography>
        <PetDetailView pet={pet} />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1, py: 2 }}>
        <Button component={Link} to={paths.petsPath()} variant="contained" color="secondary">
          戻る
        </Button>
        <Button
          component={Link}
          to={paths.petEditPath(petId)}
          variant="contained"
          color="secondary"
        >
          編集
        </Button>
      </Box>
    </>
  );
};

export default Page;
