import { useMemo } from 'react';
import type { ClientOptions } from 'urql';
import { cacheExchange, fetchExchange } from 'urql';
import { useCookie } from '../useCookie';
import config from 'config';

export const useStaffGraphQLContext = () => {
  const [accessToken] = useCookie(config.cookieKeys.staffAccessToken);
  return useMemo(
    (): ClientOptions => ({
      url: config.endpoints.staffGraphQL,
      fetchOptions: () => {
        return {
          headers: {
            Authorization: `Bearer ${accessToken || ';'}`,
          },
        };
      },
      exchanges: [cacheExchange, fetchExchange],
    }),
    [accessToken]
  );
};
