import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from '@mui/material';
import type { FC } from 'react';
import { useCallback, useState } from 'react';
import { useConfirmationDialog } from 'components/dialogs/ConfirmationDialog';

interface Props {
  open: boolean;
  defaultText: string;
  onSubmit?: (text: string) => void;
  onCancel?: () => void;
}

export const Component: FC<Props> = ({ open, onCancel = () => {}, ...props }) => {
  const [isDirty, setIsDirty] = useState(false);
  const [text, setText] = useState(props.defaultText);
  const confirmationDialog = useConfirmationDialog({
    title: '入力を破棄しますか？',
    message: '変更した内容は破棄されます。',
    acceptLabel: '破棄する',
    destructive: true,
    onAccept: onCancel,
  });

  const handleChangeDirty = useCallback(
    (dirty: boolean) => {
      setIsDirty(dirty);
    },
    [props]
  );

  return (
    <Dialog
      open={open}
      onClose={isDirty ? confirmationDialog.open : onCancel}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        飼い主情報編集
        <IconButton
          aria-label="キャンセル"
          onClick={isDirty ? confirmationDialog.open : onCancel}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form>
          <TextField
            autoFocus
            fullWidth
            multiline
            minRows={10}
            variant="outlined"
            defaultValue={text}
            onChange={(e) => {
              const value = e.target.value;
              handleChangeDirty(value !== props.defaultText);
              setText(value);
            }}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          onClick={isDirty ? confirmationDialog.open : onCancel}
        >
          キャンセル
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => props.onSubmit?.(text)}
          disabled={!isDirty}
        >
          保存
        </Button>
      </DialogActions>
      {confirmationDialog.render()}
    </Dialog>
  );
};
