import { useMemo } from 'react';
import type { ClientOptions } from 'urql';
import { cacheExchange, fetchExchange } from 'urql';
import { useCookie } from '../useCookie';
import config from 'config';

export const useUserGraphQLContext = () => {
  const [accessToken] = useCookie(config.cookieKeys.userAccessToken);
  return useMemo(
    (): ClientOptions => ({
      url: config.endpoints.userGraphQL,
      fetchOptions: () => {
        return {
          headers: {
            Authorization: accessToken ? `Bearer ${accessToken}` : ':',
          },
        };
      },
      exchanges: [cacheExchange, fetchExchange],
    }),
    [accessToken]
  );
};
