import { Box, Button, Stack } from '@mui/material';
import Logo from 'graphics/Logo';

interface Props {
  authEmail: string;
  onRetry?: () => void;
}

export const ConfirmEmail = (props: Props) => {
  return (
    <Stack sx={{ textAlign: 'center', pt: 8 }}>
      <Box sx={{ textAlign: 'center', pt: 2, pb: 2 }}>
        <Box sx={{ maxWidth: '500px', margin: '0 auto' }}>
          <Logo />
        </Box>
      </Box>
      <Box sx={{ py: 4 }}>
        {props.authEmail} にメールを送信しました。
        <br />
        メールに記載されているURLをご確認ください
      </Box>

      <Box sx={{ mt: 3, width: 'fit-content', mx: 'auto' }}>
        <Button
          onClick={props.onRetry}
          sx={{
            mt: 4,
            ml: 'auto',
            display: 'block',
            width: 'fit-content',
            textDecoration: 'none',
          }}
        >
          新規登録画面に戻る
        </Button>
      </Box>
    </Stack>
  );
};
