import { Breadcrumbs, Button, Container, Stack, Toolbar, Typography } from '@mui/material';
import type { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { useQuery } from 'urql';
import FullScreenLoading from 'components/FullScreenLoading';
import * as pageTitles from 'constants/pageTitles/staff';
import type { PetDetailQuery, PetDetailQueryVariables } from 'graphql/generated/staff/graphql';
import { PetDetailDocument } from 'graphql/generated/staff/graphql';
import NotFoundPage from 'pages/NotFoundPage';
import { PetDetailView } from 'components/pets/PetDetailView/PetDetailView';
import { useNewAppointmentDialog } from 'components/dialogs/staff/AppointmentDialog';
import { useClinicPetDialog } from 'components/dialogs/staff/ClinicPetDialog/hooks/useClinicPetDialog';

const Page: FC = () => {
  const { id } = useParams() as { id: string };
  const appointmentDialog = useNewAppointmentDialog();
  const [{ data, fetching, error }] = useQuery<PetDetailQuery, PetDetailQueryVariables>({
    query: PetDetailDocument,
    variables: {
      id,
    },
  });

  const clinicPetDialog = useClinicPetDialog(data?.clinicPet);

  if (fetching) {
    return <FullScreenLoading />;
  }

  if (error?.networkError) {
    throw error;
  }

  if (!data) {
    return <NotFoundPage />;
  }

  return (
    <>
      <Helmet>
        <title>{pageTitles.petDetail}</title>
      </Helmet>

      <Toolbar>
        <Container sx={{ display: 'flex' }}>
          <Breadcrumbs aria-label="breadcrumb" sx={{ flexGrow: 1 }}>
            <Typography color="text.primary">{pageTitles.petDetail}</Typography>
          </Breadcrumbs>
          <Button
            variant="contained"
            onClick={() =>
              appointmentDialog.open({
                clinicPetId: data.clinicPet.id,
                clinicUserId: data.clinicPet.clinicUser.id,
              })
            }
          >
            新規予約登録
          </Button>
        </Container>
      </Toolbar>

      <Container>
        <Stack spacing={18} mt={10} pb={18}>
          <PetDetailView clinicPet={data.clinicPet} onEdit={clinicPetDialog.open} />
        </Stack>
      </Container>
      {appointmentDialog.render()}
      {clinicPetDialog.render()}
    </>
  );
};

export default Page;
