import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box } from '@mui/material';
import { grey } from '@mui/material/colors';
import { AppointmentRow } from './AppointmentRow';
import type { AppointmentStatus, AppointmentsFragment } from 'graphql/generated/staff/graphql';

type Appointments = AppointmentsFragment['appointments']['nodes'];

interface Props {
  appointments: Appointments;
  onSelectAppointment: (appointmentId: string) => void;
  onChangeStatus?: (appointmentId: string, status: AppointmentStatus) => void;
}

export const AppointmentsTable = (props: Props) => {
  if (props.appointments.length === 0) {
    return (
      <Box sx={{ p: 5, textAlign: 'center', bgcolor: grey[100], borderRadius: '10px' }}>
        予約がありません
      </Box>
    );
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="right" width={130}>
              日時
            </TableCell>
            <TableCell width={140} align="center">
              ステータス
            </TableCell>
            <TableCell width={180}>飼い主/診察券番号</TableCell>
            <TableCell width={220}>ペット</TableCell>
            <TableCell width={300}>担当者/サービス</TableCell>
            <TableCell align="center">問診</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.appointments.map((appointment) => (
            <AppointmentRow
              key={`${appointment.id}-${appointment.updatedAt}`}
              appointment={appointment}
              onSelectAppointment={props.onSelectAppointment}
              onChangeStatus={props.onChangeStatus}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
