import { Box } from '@mui/material';
import { Item } from './Item';
import type { Appointment, AppointmentsQuery } from 'graphql/generated/user/graphql';

interface Props {
  appointments: AppointmentsQuery['appointments'];
  onSelect?: (appointmentId: Appointment['id']) => void;
}

export const AppointmentsTable = ({ appointments, ...props }: Props) => {
  if (appointments.length === 0) {
    return <Box sx={{ textAlign: 'center' }}>データがありません</Box>;
  }

  return (
    <>
      {appointments.map((appointment) => (
        <Item key={appointment.id} appointment={appointment} onSelect={props.onSelect} />
      ))}
    </>
  );
};
