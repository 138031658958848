import { Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import type { FC } from 'react';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'urql';
import { Helmet } from 'react-helmet-async';
import FullScreenLoading from 'components/FullScreenLoading';
import { UserPetForm } from 'components/forms/user/UserPetForm';
import * as paths from 'constants/paths/user';
import type { CreatePetInput } from 'graphql/generated/user/graphql';
import { RegisterPetNewDocument } from 'graphql/generated/user/graphql';
import { useCreatePet } from 'hooks/user/forms/useCreatePet';
import { useRegisterCookie } from 'hooks/user/useRegisterCookie';
import * as pageTitles from 'constants/pageTitles/user';

const Page: FC = () => {
  useRegisterCookie(true);
  const navigate = useNavigate();
  const { clinicSlug } = useParams();
  const [{ data, fetching }] = useQuery({
    query: RegisterPetNewDocument,
  });

  const { createPet } = useCreatePet();

  const onSubmit = useCallback(
    async (input: CreatePetInput, profileImageFile?: File) => {
      try {
        const { error } = await createPet(input, profileImageFile);

        if (error?.message) {
          throw new Error(`ペット ${input.name}の追加に失敗しました。`);
        }

        enqueueSnackbar(`ペット ${input.name}を追加しました`, { variant: 'success' });

        window.scrollTo(0, 0);
        navigate(paths.clinicRegisterConfirmPath(clinicSlug));
      } catch (e: any) {
        // eslint-disable-next-line no-console
        console.log(e);
        enqueueSnackbar(e.message, { variant: 'error' });
      }
    },
    [clinicSlug]
  );

  if (!data || fetching) {
    return <FullScreenLoading />;
  }

  return (
    <>
      <Helmet>
        <title>{pageTitles.registerPetNew}</title>
      </Helmet>
      <Typography variant="h5" textAlign="center">
        ペット情報登録
      </Typography>
      <UserPetForm animals={data.animals} onSubmit={onSubmit} />
      <Typography sx={{ fontSize: '10px' }}>
        ２頭目以降のペット情報はマイページからいつでも登録・編集が可能です
      </Typography>
    </>
  );
};

export default Page;
