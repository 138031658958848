import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import PetsIcon from '@mui/icons-material/Pets';
import { Box, Button, Grid, Typography } from '@mui/material';
import type { FC } from 'react';
import { useCallback } from 'react';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useAuthCookie } from 'hooks/user/useAuthCookie';
import * as paths from 'constants/paths/user';
import * as pageTitles from 'constants/pageTitles/user';

const Page: FC = () => {
  const { clinicSlug } = useParams();
  const navigate = useNavigate();
  const { logout } = useAuthCookie();

  const onLogout = useCallback(() => {
    const answer = window.confirm('ログアウトしますか？');
    if (!answer) {
      return;
    }
    logout();
    navigate(paths.clinicLoginPath(clinicSlug));
  }, [logout, clinicSlug]);

  return (
    <>
      <Helmet>
        <title>{pageTitles.mypage}</title>
      </Helmet>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              component={Link}
              to={paths.clinicAppointmentNewPath(clinicSlug)}
              variant="contained"
              fullWidth
            >
              <Box display="flex" flexDirection="column" alignItems="center" py={2}>
                <EventAvailableIcon fontSize="large" />
                新規予約
              </Box>
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              component={Link}
              to={paths.clinicAppointmentsPath(clinicSlug)}
              variant="outlined"
              fullWidth
            >
              <Box display="flex" flexDirection="column" alignItems="center" py={2}>
                <CalendarMonthIcon fontSize="large" />
                予約履歴
              </Box>
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              component={Link}
              to={paths.clinicOwnerPath(clinicSlug)}
              variant="outlined"
              fullWidth
            >
              <Box display="flex" flexDirection="column" alignItems="center" py={2}>
                <PersonIcon fontSize="large" />
                飼い主様情報
              </Box>
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              component={Link}
              to={paths.clinicPetsPath(clinicSlug)}
              variant="outlined"
              fullWidth
            >
              <Box display="flex" flexDirection="column" alignItems="center" py={2}>
                <PetsIcon fontSize="large" />
                ペット一覧
              </Box>
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              component="a"
              href="https://ani-pro.jp/reserve-system/#form"
              target="_blank"
              rel="noopener noreferrer"
              variant="outlined"
              fullWidth
            >
              <Box display="flex" flexDirection="column" alignItems="center" py={2}>
                <ContactSupportIcon fontSize="large" />
                お問い合わせ
              </Box>
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button onClick={onLogout} variant="outlined" fullWidth>
              <Box display="flex" flexDirection="column" alignItems="center" py={2}>
                <LogoutIcon fontSize="large" />
                ログアウト
              </Box>
            </Button>
          </Grid>
        </Grid>
        <Box sx={{ mt: 5 }}>
          <Typography sx={{ fontSize: '11px' }}>
            予約をする場合は病院HPや配布されたチラシのQRコードなどから予約専用サイトにアクセスしてください
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default Page;
