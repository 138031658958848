import { enqueueSnackbar } from 'notistack';
import type { FC } from 'react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'urql';
import { Helmet } from 'react-helmet-async';
import FullScreenLoading from 'components/FullScreenLoading';
import type { LoginFormInput } from 'components/forms/user/LoginForm/hooks/useLoginForm';
import { RegisterForm } from 'components/forms/user/RegisterForm/RegisterForm';
import * as paths from 'constants/paths/user';
import type { SignUpWithEmailMutation } from 'graphql/generated/user/graphql';
import {
  LatestAppAgreementDocument,
  SignUpWithEmailDocument,
} from 'graphql/generated/user/graphql';
import * as pageTitles from 'constants/pageTitles/user';

const Page: FC = () => {
  const navigate = useNavigate();
  const [{ data: agreement }] = useQuery({
    query: LatestAppAgreementDocument,
  });
  const [signUpResult, signUpWithEmail] =
    useMutation<SignUpWithEmailMutation>(SignUpWithEmailDocument);

  const onSubmit = useCallback(
    async (body: LoginFormInput) => {
      try {
        const { data, error } = await signUpWithEmail({
          input: { ...body, appAgreementID: agreement?.latestAppAgreement.id },
        });
        if (data?.signUpWithEmail?.success) {
          navigate(paths.confirmEmailPath(), {
            state: { email: body?.email },
          });
        } else if (error?.message.match('email already used')) {
          throw new Error('すでに登録済みメールアドレスです');
        } else {
          throw new Error('ログインに失敗しました');
        }
      } catch (e: any) {
        enqueueSnackbar(e.message, { variant: 'error' });
        // eslint-disable-next-line no-console
        console.error(e);
      }
    },
    [agreement]
  );

  if (!agreement) {
    return <FullScreenLoading />;
  }

  return (
    <>
      <Helmet>
        <title>{pageTitles.register}</title>
      </Helmet>
      <RegisterForm
        onSubmit={onSubmit}
        agreement={agreement.latestAppAgreement}
        fetching={signUpResult.fetching}
      />
    </>
  );
};

export default Page;
