import { Box, Button, Stack, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import type {
  AppointmentFormOptionsQueryClinic,
  AppointmentFormOptionsQueryPet,
} from '../AppointmentForm/types';
import { PetCard } from 'components/appointments/user/SelectPet/PetCard';

interface Props {
  pets: AppointmentFormOptionsQueryPet[];
  clinic: AppointmentFormOptionsQueryClinic;
  defaultPet?: AppointmentFormOptionsQueryPet;
  onNewPet: () => void;
  onEditPet: () => void;
  onSelect: (pet: AppointmentFormOptionsQueryPet) => void;
}

export const SelectPet = (props: Props) => {
  const { clinic } = props;
  const [selectedPet, setSelectedPet] = useState<AppointmentFormOptionsQueryPet | null>(
    props.defaultPet || null
  );

  const onSelectPet = useCallback(
    (pet: AppointmentFormOptionsQueryPet) => {
      setSelectedPet(pet);
    },
    [setSelectedPet]
  );

  const onSubmit = useCallback(() => {
    if (selectedPet) {
      props.onSelect(selectedPet);
    }
  }, [selectedPet]);

  // 病院が取り扱っているペットの種類のみを表示する
  const clinicAnimalIds = clinic.animals.map((a) => a.id) || [];
  const pets =
    props.pets.filter((pet) => pet.animal?.id && clinicAnimalIds.includes(pet.animal.id)) || [];

  if (pets.length === 0) {
    return (
      <Box>
        <Stack sx={{ textAlign: 'center', alignItems: 'center', pt: 2 }} spacing={2}>
          <Box>
            <Typography variant="body1" sx={{ textAlign: 'center', pt: 2 }}>
              ペット情報がありません。
            </Typography>
            <Typography variant="caption" sx={{ textAlign: 'center', pt: 2 }}>
              この病院は {clinic.animals.map((a) => a.name).join('/')} に対応しています
            </Typography>
          </Box>
          <Button
            onClick={props.onNewPet}
            sx={{ width: '150px' }}
            variant="contained"
            color="primary"
          >
            ペットを登録する
          </Button>
        </Stack>
      </Box>
    );
  }

  return (
    <Stack>
      <Box mb={2}>
        <Typography variant="h6" textAlign="center">
          ペットを選択してください
        </Typography>
        <Typography color="error" textAlign="center" fontSize={12} mt={2}>
          *一度に予約できるのは1頭までです。
          <br />
          複数のペットの予約をご希望の場合は続けて予約してください。
        </Typography>
      </Box>

      <Stack spacing={1}>
        {pets.map((pet) => (
          <PetCard
            key={pet.id}
            pet={pet}
            onChecked={onSelectPet}
            checked={selectedPet?.id === pet.id}
          />
        ))}
      </Stack>
      <Stack sx={{ textAlign: 'center', alignItems: 'center', mt: 6 }} spacing={4}>
        <Button
          onClick={onSubmit}
          sx={{ width: '150px' }}
          variant="contained"
          disabled={!selectedPet?.id}
          color="primary"
        >
          次へ
        </Button>
        <Button onClick={props.onEditPet}>ペット情報の編集はこちら</Button>
      </Stack>
    </Stack>
  );
};
