import PetsIcon from '@mui/icons-material/Pets';
import { Avatar, Box, Stack, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { petGenders } from 'constants/petGender';
import type { Pet } from 'graphql/generated/user/graphql';
import { getAge } from 'helpers/date';

interface Props {
  pet: Pick<Pet, 'name' | 'birthday' | 'gender' | 'profileImageURL'>;
}

export const PetCard = (props: Props) => {
  return (
    <Box display="flex" alignItems="center">
      <Avatar src={props.pet.profileImageURL ?? ''}>
        <PetsIcon />
      </Avatar>
      <Box ml={2}>
        <Typography>{props.pet.name}</Typography>
        <Stack direction="row" spacing={0.5}>
          <Typography variant="caption" color={grey[700]}>
            {getAge(props.pet.birthday, '歳')}
          </Typography>
          <Typography variant="caption" color={grey[700]}>
            {petGenders.find((g) => g.value === props.pet.gender)?.label}
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
};
