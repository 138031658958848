import { AppointmentType } from 'graphql/generated/staff/graphql';

interface TypeProps {
  label: string;
  value: AppointmentType;
}

const appointmentTypes: TypeProps[] = [
  {
    label: 'Web',
    value: AppointmentType.Web,
  },
  {
    label: '電話',
    value: AppointmentType.PhoneCall,
  },
  {
    label: '受付',
    value: AppointmentType.Contact,
  },
  {
    label: 'その他',
    value: AppointmentType.Others,
  },
];

export const allAppointmentTypes = appointmentTypes.map((item) => item.value);

const find = (type: AppointmentType) => appointmentTypes.find((item) => item.value === type);

export const appointmentTypeLabel = (type: AppointmentType) => {
  return find(type)?.label;
};
