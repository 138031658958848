import { Badge, ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import type { ComponentProps, FC } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

interface Props {
  text: string;
  to: string;
  Icon: FC;
  open: boolean;
  selected?: boolean;
  count?: number;
  sx?: ComponentProps<typeof ListItemButton>['sx'];
}

const NavigationItem: FC<Props> = ({ text, Icon, open, selected, to, count, sx }) => {
  const location = useLocation();

  return (
    <ListItemButton
      component={RouterLink}
      to={to}
      selected={selected === undefined ? location.pathname === to.replace(/\?.*$/, '') : selected}
      sx={sx}
    >
      <Tooltip title={open ? undefined : text} placement="right">
        <ListItemIcon>
          <Badge badgeContent={count} color="secondary" max={100}>
            <Icon />
          </Badge>
        </ListItemIcon>
      </Tooltip>
      <ListItemText primary={text} />
    </ListItemButton>
  );
};

export default NavigationItem;
