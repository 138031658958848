import { createTheme } from '@mui/material/styles';

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: '#36A2DC',
      contrastText: '#fff',
    },
    secondary: {
      main: '#666666',
      contrastText: '#fff',
    },
  },
});
export default theme;
