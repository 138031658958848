import { Box, CircularProgress } from '@mui/material';
import type { FC } from 'react';

export const Component: FC = () => (
  <Box
    sx={{
      height: '100vh',
      widtth: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <CircularProgress />
  </Box>
);
