import { grey } from '@mui/material/colors';
import { Box } from '@mui/system';
import { isSameDay, isSameMonth } from 'date-fns';
import { allDayOfWeeks } from 'constants/allDayOfWeeks';
import type { DayOfWeek } from 'graphql/generated/staff/graphql';
import 'react-calendar/dist/Calendar.css';

interface Props {
  activeStartDate: Date;
  date: Date;
  view: 'month' | 'year' | 'decade' | 'century';
  clinicHolidays: DayOfWeek[]; // 曜日
  staffHolidays: Date[]; // 日付
}

const genBackgroundColor = (
  date: Date,
  startDate: Date,
  clinicHolidays: DayOfWeek[],
  staffHolidays: Date[]
) => {
  if (!isSameMonth(startDate, date)) {
    return '#FFF';
  }

  if (clinicHolidays.includes(allDayOfWeeks[date.getDay()])) {
    return grey[200];
  }

  if (staffHolidays.some((h) => isSameDay(h, date))) {
    return '#FFF';
  }

  return '#FA7A54';
};

const genTextColor = (
  date: Date,
  startDate: Date,
  clinicHolidays: DayOfWeek[],
  staffHolidays: Date[]
) => {
  if (!isSameMonth(startDate, date)) {
    return '#FFF';
  }

  if (clinicHolidays.includes(allDayOfWeeks[date.getDay()])) {
    return grey[900];
  }

  if (staffHolidays.some((h) => isSameDay(h, date))) {
    return grey[900];
  }
  return '#FFF';
};

export const TitleContent = (props: Props) => {
  if (props.view !== 'month') {
    return null;
  }

  const bgcolor = genBackgroundColor(
    props.date,
    props.activeStartDate,
    props.clinicHolidays,
    props.staffHolidays
  );

  const color = genTextColor(
    props.date,
    props.activeStartDate,
    props.clinicHolidays,
    props.staffHolidays
  );

  return (
    <Box
      width="100%"
      p={2}
      border="1px solid #fff"
      fontSize={18}
      color={color}
      sx={{
        backgroundColor: bgcolor,
      }}
    >
      {props.date.getDate()}
    </Box>
  );
};
