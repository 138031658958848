import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import type { FC } from 'react';

interface Props {
  open: boolean;
  onCancel?: () => void;
  onAccept?: () => void;
  title?: string;
  message?: string;
  acceptLabel?: string;
  cancelLabel?: string;
  destructive?: boolean;
}

export const Component: FC<Props> = ({
  open,
  onCancel = () => {},
  onAccept = () => {},
  title,
  message,
  acceptLabel = 'はい',
  cancelLabel = 'いいえ',
  destructive = false,
}) => (
  <Dialog open={open} onClose={onCancel}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText sx={{ whiteSpace: 'pre-wrap' }}>{message}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel} color="secondary">
        {cancelLabel}
      </Button>
      <Button onClick={onAccept} color={destructive ? 'error' : 'primary'}>
        {acceptLabel}
      </Button>
    </DialogActions>
  </Dialog>
);
