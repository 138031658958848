import styled from '@emotion/styled';
import { Box, Chip, Typography } from '@mui/material';
import { green } from '@mui/material/colors';
import { format } from 'date-fns';
import type { FC } from 'react';
import type { AppointmentsFragment } from 'graphql/generated/staff/graphql';

interface Props {
  appointment: AppointmentsFragment['appointments']['nodes'][number];
}

const StyledBox = styled(Box)`
  background-color: ${green['100']};
  padding: 1px 4px;
  box-sizing: border-box;
  height: 100%;
  border-radius: 2px;
  border-left: 4px solid ${green['400']};
`;

const StyledChip = styled(Chip)`
  font-size: 10px;
  height: 16px;
  width: 16px;
  margin-right: 3px;
  & > span {
    padding: 2px !important;
  }
`;

export const AppointmentCell: FC<Props> = ({ appointment }) => {
  return (
    <StyledBox>
      <Box>
        <Typography variant="caption" color={green['700']} lineHeight={1.8} fontSize={10}>
          {format(new Date(appointment.startAt), 'HH:mm')}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        {appointment.isFirst && <StyledChip label="初" color="error" size="small" />}
        <Typography variant="subtitle2" color={green['700']} lineHeight={1} fontWeight="bold">
          {appointment.clinicUser?.user?.name ?? appointment.guestUser?.name}
        </Typography>
      </Box>
      <Typography variant="caption" color={green['700']} lineHeight={1}>
        {appointment?.clinicPet?.pet?.name ?? appointment?.guestUser?.petName}(
        {appointment?.clinicPet?.pet?.animal?.name ?? appointment?.guestUser?.animal?.name})
      </Typography>
    </StyledBox>
  );
};
