import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  TableContainer as MuiTableContainer,
  TableHead as MuiTableHead,
  Select,
  TableRow,
} from '@mui/material';
import type { ComponentProps, FC } from 'react';
import { useCallback, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Row from './components/Row';
import type { ResourcesQuery } from 'graphql/generated/staff/graphql';

interface Props {
  data: ResourcesQuery;
  kindId?: string | null;
  onSelectKind?: (id: string | null) => void;
  onUpdateOrder?: (id: string, order: number) => void;
}

export const Component: FC<Props> = ({
  data,
  onSelectKind = () => {},
  onUpdateOrder = () => {},
  kindId,
}) => {
  const [resources, setResources] = useState(data.resources);
  const { resourceKinds } = data;

  const handleMove = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const newResources = [...resources];
      const [dragResource] = newResources.splice(dragIndex, 1);
      newResources.splice(hoverIndex, 0, dragResource);
      setResources(newResources);
    },
    [resources]
  );

  const handleDrop = useCallback(() => {
    for (let i = 0; i < resources.length; i++) {
      const resource = resources[i];
      if (i !== resource.order) {
        onUpdateOrder(resource.id, i);
        resource.order = i;
      }
    }
    setResources(resources);
  }, [resources]);

  return (
    <>
      {resourceKinds.length > 1 && (
        <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel htmlFor="select-resource-kind" id="select-resource-kind-label">
              種別
            </InputLabel>
            <Select
              id="select-resource-kind"
              labelId="select-resource-kind-label"
              value={kindId || ''}
              onChange={(e) => onSelectKind(e.target.value)}
              label="種別"
            >
              <MenuItem selected={!!kindId} value="">
                <em>全て</em>
              </MenuItem>
              {resourceKinds.map((kind) => (
                <MenuItem key={kind.id} value={kind.id}>
                  {kind.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}
      {resources.length > 0 ? (
        <DndProvider backend={HTML5Backend}>
          <TableContainer sx={{ width: '100%' }}>
            <Table sx={{ width: '100%' }}>
              <TableHead>
                <TableRow component="div">
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>名前</TableCell>
                  <TableCell>種別</TableCell>
                  <TableCell>サービス</TableCell>
                  <TableCell sx={{ textAlign: 'center' }}>操作</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {resources.map((resource, index) => (
                  <Row
                    key={resource.id}
                    resource={resource}
                    index={index}
                    onMove={handleMove}
                    onDrop={handleDrop}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DndProvider>
      ) : (
        <Box sx={{ textAlign: 'center' }}>データがありません</Box>
      )}
    </>
  );
};

const Table: FC<ComponentProps<typeof MuiTable>> = (props) => (
  <MuiTable {...props} component="div" />
);
const TableBody: FC<ComponentProps<typeof MuiTableBody>> = (props) => (
  <MuiTableBody {...props} component="div" />
);
const TableCell: FC<ComponentProps<typeof MuiTableCell>> = (props) => (
  <MuiTableCell {...props} component="div" />
);
const TableContainer: FC<ComponentProps<typeof MuiTableContainer>> = (props) => (
  <MuiTableContainer {...props} component="div" />
);
const TableHead: FC<ComponentProps<typeof MuiTableHead>> = (props) => (
  <MuiTableHead {...props} component="div" />
);
