import { Box, Button } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Link } from 'react-router-dom';
import type { FC } from 'react';
import Logo from 'graphics/Logo';
import * as paths from 'constants/paths/user';

interface Props {
  clinicSlug?: string;
}

export const SuccessPasswordReset: FC<Props> = ({ clinicSlug }) => {
  return (
    <Box sx={{ textAlign: 'center', mx: 'auto', pt: 5 }}>
      <Box sx={{ textAlign: 'center', pt: 2, pb: 2 }}>
        <Box sx={{ maxWidth: '500px', margin: '0 auto' }}>
          <Logo />
        </Box>
      </Box>
      <Box component="h3" sx={{ textAlign: 'center' }}>
        パスワードの変更が完了しました。
      </Box>
      <Box sx={{ mt: 6, mb: 8 }}>
        パスワードの変更が完了しました。
        <br />
        新しいパスワードでログインしてご利用下さい。
      </Box>
      <Button
        sx={{
          mt: 5,
          mx: 'auto',
          px: 10,
          fontSize: '1.125rem',
          display: 'block',
          backgroundColor: grey[700],
        }}
        to={clinicSlug ? paths.clinicLoginPath(clinicSlug) : paths.loginPath()}
        variant="contained"
        type="submit"
        component={Link}
      >
        ログイン画面へ
      </Button>
    </Box>
  );
};
