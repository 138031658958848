import { useCallback } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import * as paths from 'constants/paths/user';

enum registerCookieType {
  authStatus = 'authStatus',
  userAccessToken = 'userAccessToken',
}

enum authStatus {
  confirmEmail = 'confirmEmail',
  agreement = 'agreement',
  complete = 'complete',
}

export const useRegisterCookie = (afterConfirmEmail: boolean) => {
  const navigate = useNavigate();
  const [getCookie, setCookie, removeCookie] = useCookies();

  const currentAuthStatus = getCookie[registerCookieType.authStatus] as authStatus;

  // Eメール認証後に authStatus が設定されていなければログインページに遷移する
  if (afterConfirmEmail && !currentAuthStatus) {
    navigate(paths.loginPath());
  }

  // 認証要承認URLの確認完了
  const setAccessToken = useCallback((accessToken?: string) => {
    setCookie(registerCookieType.authStatus, authStatus.agreement, {
      path: '/',
    });
    if (accessToken) {
      setCookie(registerCookieType.userAccessToken, accessToken, {
        path: '/',
      });
    }
  }, []);

  // 登録完了
  const setComplete = useCallback(() => {
    setCookie('authStatus', 'complete', {
      path: '/',
    });
  }, []);

  const removeAuthStatus = useCallback(() => {
    removeCookie(registerCookieType.authStatus, {
      path: '/',
    });
  }, []);

  return {
    setAccessToken,
    setComplete,
    removeAuthStatus,
    authStatus: getCookie[registerCookieType.authStatus] as authStatus,
  };
};
