export const mypage = 'マイページ';
export const newAppointment = '新規予約';
export const appointments = '予約一覧';
export const appointmentDetail = '予約詳細';
export const appointmentEdit = '予約編集';
export const owners = '飼い主様情報';
export const ownerEdit = '飼い主様情報更新';
export const pets = 'ペット一覧';
export const petEdit = 'ペット編集';
export const petDetail = 'ペット情報';
export const newPet = 'ペット情報登録';

export const login = 'ログイン';
export const register = '新規登録';
export const confirmEmail = 'メールを送信しました';
export const passwordReset = 'パスワードを忘れた場合';
export const sendPasswordResetEmail = 'パスワードリセットメールをお送りしました';
export const successSasswordReset = 'パスワードを変更しました';
export const confirmPasswordReset = '新しいパスワードを設定する';
export const registerComplete = '会員登録が完了しました';
export const registerConfirm = '会員情報の確認';
export const registerPetEdit = 'ペットを編集する';
export const registerPetNew = 'ペットを追加する';
export const registerUserEdit = '会員情報を編集する';
