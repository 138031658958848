import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import type { FC } from 'react';
import { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import type {
  CreateResourceInput,
  ResourceDetailFragment,
  ResourceFormOptionsQuery,
} from 'graphql/generated/staff/graphql';
import AvatarDropzone from 'components/AvatarDropzone';

type FormValues = CreateResourceInput;

interface Props {
  resource?: ResourceDetailFragment;
  serviceCategories: ResourceFormOptionsQuery['serviceCategories'];
  resourceKinds: ResourceFormOptionsQuery['resourceKinds'];
  onSubmit?: (values: FormValues) => void;
  onSelectFile?: (file: File) => Promise<string>;
}

export const Component: FC<Props> = ({
  resource,
  resourceKinds,
  serviceCategories,
  onSelectFile,
  onSubmit = () => {},
}) => {
  const onSelect = useCallback(async (file: File) => {
    if (onSelectFile) {
      const url = await onSelectFile(file);
      if (url) setValue('profileImageURL', url);
    }
  }, []);

  const { handleSubmit, control, setValue } = useForm<FormValues>({
    defaultValues: resource
      ? {
          name: resource.name,
          kindId: resource.kind.id,
          serviceIds: resource.services.map((service) => service.id),
          profile: resource.profile,
          maxParallel: resource.maxParallel,
        }
      : {
          serviceIds: [],
          maxParallel: 1,
        },
    mode: 'onBlur',
  });

  const services = serviceCategories
    .flatMap((category) =>
      category.services.map((service) => ({
        ...service,
        category: {
          id: category.id,
          name: category.name,
        },
      }))
    )
    .filter((service, index, ar) => ar.findIndex((s) => s.id === service.id) === index);
  const findService = (id: string) => services.find((service) => service.id === id);

  const serviceOptions = serviceCategories
    .flatMap((category) => [category, ...category.services])
    .filter(
      (option, index, ar) =>
        ar.findIndex((s) => s.id === option.id && s.__typename === option.__typename) === index
    );

  return (
    <Stack gap={4} component="form" onSubmit={handleSubmit(onSubmit)}>
      {onSelectFile && (
        <AvatarDropzone onSelect={onSelect} defaultSource={resource?.profileImageURL ?? null} />
      )}
      <Controller
        control={control}
        name="name"
        rules={{ required: true }}
        render={({ field, fieldState }) => (
          <TextField
            error={!!fieldState.error}
            helperText={fieldState.error?.message}
            label="名前"
            fullWidth
            required
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="maxParallel"
        rules={{ required: true, min: 1, pattern: /^\d+$/ }}
        render={({ field, fieldState }) => (
          <TextField
            type="number"
            inputProps={{
              min: 1,
              pattern: '\\d*',
              inputMode: 'numeric',
            }}
            error={!!fieldState.error}
            helperText={fieldState.error?.message}
            label="同時に受付できる予約数"
            fullWidth
            required
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="kindId"
        rules={{ required: true }}
        render={({ field, fieldState }) => (
          <FormControl fullWidth required error={!!fieldState.error}>
            <InputLabel id="select-resource-kind">種別</InputLabel>
            <Select labelId="select-resource-kind" label="種別" {...field}>
              {resourceKinds.map((kind) => (
                <MenuItem key={kind.id} value={kind.id}>
                  {kind.name}
                </MenuItem>
              ))}
            </Select>
            {fieldState.error?.message && (
              <FormHelperText>{fieldState.error.message}</FormHelperText>
            )}
          </FormControl>
        )}
      />
      <Controller
        control={control}
        name="serviceIds"
        rules={{ required: true }}
        render={({ field, fieldState }) => (
          <FormControl fullWidth required error={!!fieldState.error}>
            <InputLabel id="select-service-category">対応サービス</InputLabel>
            <Select
              labelId="select-service-category"
              label="対応サービス"
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={findService(value)?.name ?? value} />
                  ))}
                </Box>
              )}
              multiple
              {...field}
            >
              {serviceOptions.map((option) => {
                if (option.__typename === 'ServiceCategory') {
                  return <ListSubheader key={option.id}>{option.name}</ListSubheader>;
                }
                return (
                  <MenuItem key={option.id} value={option.id}>
                    <Checkbox checked={field.value.includes(option.id)} />
                    {option.name}
                  </MenuItem>
                );
              })}
            </Select>
            {fieldState.error?.message && (
              <FormHelperText>{fieldState.error.message}</FormHelperText>
            )}
          </FormControl>
        )}
      />
      <Controller
        control={control}
        name="profile"
        render={({ field, fieldState }) => (
          <TextField
            error={!!fieldState.error}
            helperText={fieldState.error?.message}
            label="プロフィール"
            fullWidth
            multiline
            rows={4}
            {...field}
          />
        )}
      />
      <Button type="submit" variant="contained" color="primary" size="large" fullWidth>
        保存
      </Button>
    </Stack>
  );
};
