import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

interface Props {
  defaultValues?: Partial<UserFormType>;
}

interface UserFormType {
  profileImageURL?: string | null;
  name?: string | null;
  phoneNumber?: string | null;
  zipCode?: string | null;
  prefectureId?: string | null;
  city?: string | null;
  street?: string | null;
  building?: string | null;
  visitingFrequencyId?: string | null;
  trimmingFrequencyId?: string | null;
}

// フォームのバリデーションルール
const formSchema = yup.object({
  profileImageURL: yup.string(),
  name: yup.string().required('氏名を入力してください'),
  phoneNumber: yup.string().required('電話番号を入力してください'),
  zipCode: yup.string().required('郵便番号を入力してください'),
  prefectureId: yup.string().required('都道府県を選択してください'),
  city: yup.string().required('市区町村を入力してください'),
  street: yup.string().required('番地を入力してください'),
  building: yup.string(),
  visitingFrequencyId: yup.string(),
  trimmingFrequencyId: yup.string(),
});

export const useUserForm = (props: Props) => {
  return useForm({
    defaultValues: props.defaultValues,
    resolver: yupResolver(formSchema),
  });
};
