/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
};

export type Animal = {
  __typename?: 'Animal';
  genders: Array<PetGender>;
  id: Scalars['ID'];
  name: Scalars['String'];
  types: Array<AnimalType>;
};

export type AnimalType = {
  __typename?: 'AnimalType';
  animal: Animal;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type AppAgreement = {
  __typename?: 'AppAgreement';
  acceptedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  text: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
  version: Scalars['Int'];
};

export type Appointment = {
  __typename?: 'Appointment';
  clinic: Clinic;
  clinicPet: ClinicPet;
  clinicUser: ClinicUser;
  endAt: Scalars['DateTime'];
  history: Scalars['String'];
  id: Scalars['ID'];
  isFirst: Scalars['Boolean'];
  note: Scalars['String'];
  onset: Scalars['String'];
  resource: Resource;
  service?: Maybe<Service>;
  startAt: Scalars['DateTime'];
  status: AppointmentStatus;
  symptom: Scalars['String'];
  type: AppointmentType;
};

export type AppointmentSlot = {
  __typename?: 'AppointmentSlot';
  date: Scalars['DateTime'];
  isAppointment: Scalars['Boolean'];
  isAvailable: Scalars['Boolean'];
  isClinicHoliday: Scalars['Boolean'];
  isResourceHoliday: Scalars['Boolean'];
  isResourceWork: Scalars['Boolean'];
  resource: Resource;
};

export enum AppointmentStatus {
  Booked = 'BOOKED',
  Canceled = 'CANCELED',
  Visited = 'VISITED',
  Waiting = 'WAITING',
}

export enum AppointmentType {
  Contact = 'CONTACT',
  Others = 'OTHERS',
  PhoneCall = 'PHONE_CALL',
  Web = 'WEB',
}

export type AppointmentsConnection = {
  __typename?: 'AppointmentsConnection';
  nodes: Array<Appointment>;
  totalCount: Scalars['Int'];
};

export type Authentication = {
  __typename?: 'Authentication';
  createdAt: Scalars['DateTime'];
  externalIdentifier: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type Clinic = {
  __typename?: 'Clinic';
  agreements: Array<ClinicAgreement>;
  animals: Array<Animal>;
  cancellationDeadlineDays?: Maybe<Scalars['Int']>;
  cancellationDeadlineHours?: Maybe<Scalars['Int']>;
  clinicBussinessHour?: Maybe<ClinicBussinessHour>;
  createdAt: Scalars['DateTime'];
  holidays: Array<DayOfWeek>;
  id: Scalars['ID'];
  latestAgreement?: Maybe<ClinicAgreement>;
  logoImageURL?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  reservationLimitDaysFrom?: Maybe<Scalars['Int']>;
  reservationLimitDaysTo?: Maybe<Scalars['Int']>;
  reservationLimitHoursFrom?: Maybe<Scalars['Int']>;
  reservationLimitHoursTo?: Maybe<Scalars['Int']>;
  serviceCategories: Array<ServiceCategory>;
  slotDuration?: Maybe<Scalars['Int']>;
  slug: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ClinicAgreement = {
  __typename?: 'ClinicAgreement';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  text: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  version: Scalars['String'];
};

export type ClinicBussinessHour = {
  __typename?: 'ClinicBussinessHour';
  end: Time;
  id: Scalars['ID'];
  start: Time;
};

export type ClinicPet = {
  __typename?: 'ClinicPet';
  clinic?: Maybe<Clinic>;
  clinicUser: ClinicUser;
  id: Scalars['ID'];
  note: Scalars['String'];
  patientNumber?: Maybe<Scalars['String']>;
  pet?: Maybe<Pet>;
};

export type ClinicService = {
  __typename?: 'ClinicService';
  clinic: Clinic;
  id: Scalars['ID'];
  service: Service;
};

export type ClinicUser = {
  __typename?: 'ClinicUser';
  acceptedClinicAgreement?: Maybe<ClinicAgreement>;
  appointments: Array<Appointment>;
  clinic: Clinic;
  clinicPets: Array<ClinicPet>;
  id: Scalars['ID'];
  marked: Scalars['Boolean'];
  note: Scalars['String'];
  patientNumber?: Maybe<Scalars['String']>;
  point: Scalars['Int'];
  user: User;
};

export type ConfirmEmailInput = {
  confirmationToken: Scalars['String'];
};

export type ConfirmEmailPayload = {
  __typename?: 'ConfirmEmailPayload';
  accessToken: Scalars['String'];
  success: Scalars['Boolean'];
};

export type ConfirmLineSignInInput = {
  code: Scalars['String'];
  redirectUrl?: InputMaybe<Scalars['String']>;
};

export type ConfirmLineSignInPayload = {
  __typename?: 'ConfirmLineSignInPayload';
  accessToken: Scalars['String'];
};

export type ConfirmPasswordResetInput = {
  confirmationToken: Scalars['String'];
  newPassword: Scalars['String'];
};

export type ConfirmPasswordResetPayload = {
  __typename?: 'ConfirmPasswordResetPayload';
  success: Scalars['Boolean'];
};

export type CreateAppointmentInput = {
  clinicServiceId: Scalars['ID'];
  endAt: Scalars['DateTime'];
  history?: InputMaybe<Scalars['String']>;
  isFirst: Scalars['Boolean'];
  note?: InputMaybe<Scalars['String']>;
  onset?: InputMaybe<Scalars['String']>;
  petId: Scalars['ID'];
  resourceId?: InputMaybe<Scalars['ID']>;
  startAt: Scalars['DateTime'];
  symptom?: InputMaybe<Scalars['String']>;
};

export type CreatePetInput = {
  animalId?: InputMaybe<Scalars['ID']>;
  animalText?: InputMaybe<Scalars['String']>;
  animalTypeId?: InputMaybe<Scalars['ID']>;
  birthday?: InputMaybe<Scalars['String']>;
  figure?: InputMaybe<Scalars['String']>;
  food?: InputMaybe<Scalars['String']>;
  furColor?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<PetGender>;
  insurance?: InputMaybe<Scalars['String']>;
  insuranceNumber?: InputMaybe<Scalars['String']>;
  microChip?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  note?: InputMaybe<Scalars['String']>;
  profileImageURL?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Float']>;
};

export enum DayOfWeek {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
}

export enum FileType {
  Jpeg = 'JPEG',
  Png = 'PNG',
}

export type Frequency = {
  __typename?: 'Frequency';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export enum Locale {
  En = 'EN',
  Ja = 'JA',
}

export type Mutation = {
  __typename?: 'Mutation';
  acceptAppAgreement: AppAgreement;
  acceptClinicAgreement: ClinicAgreement;
  cancelAppointment: Appointment;
  confirmEmail: ConfirmEmailPayload;
  confirmLineSignIn: ConfirmLineSignInPayload;
  confirmPasswordReset: ConfirmPasswordResetPayload;
  createAppointment: Appointment;
  createPet: Pet;
  deletePet: Pet;
  requestPasswordReset: RequestPasswordResetPayload;
  signInWithEmail: SignInWithEmailPayload;
  signInWithLine: SignInWithLinePayload;
  signUpWithEmail: SignUpWithEmailPayload;
  updateAppointment: Appointment;
  updatePet: Pet;
  updateViewer: User;
};

export type MutationAcceptAppAgreementArgs = {
  id: Scalars['ID'];
};

export type MutationAcceptClinicAgreementArgs = {
  id: Scalars['ID'];
};

export type MutationCancelAppointmentArgs = {
  id: Scalars['ID'];
};

export type MutationConfirmEmailArgs = {
  input: ConfirmEmailInput;
};

export type MutationConfirmLineSignInArgs = {
  input: ConfirmLineSignInInput;
};

export type MutationConfirmPasswordResetArgs = {
  input: ConfirmPasswordResetInput;
};

export type MutationCreateAppointmentArgs = {
  input: CreateAppointmentInput;
};

export type MutationCreatePetArgs = {
  input: CreatePetInput;
};

export type MutationDeletePetArgs = {
  id: Scalars['ID'];
};

export type MutationRequestPasswordResetArgs = {
  input: RequestPasswordResetInput;
};

export type MutationSignInWithEmailArgs = {
  input: SignInWithEmailInput;
};

export type MutationSignInWithLineArgs = {
  input: SignInWithLineInput;
};

export type MutationSignUpWithEmailArgs = {
  input: SignUpWithEmailInput;
};

export type MutationUpdateAppointmentArgs = {
  input: UpdateAppointmentInput;
};

export type MutationUpdatePetArgs = {
  input: UpdatePetInput;
};

export type MutationUpdateViewerArgs = {
  input: UpdateViewerInput;
};

export type PasswordReset = {
  __typename?: 'PasswordReset';
  createdAt: Scalars['DateTime'];
  externalIdentifier: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type Pet = {
  __typename?: 'Pet';
  animal?: Maybe<Animal>;
  animalType?: Maybe<AnimalType>;
  appointments: Array<Appointment>;
  birthday: Scalars['DateTime'];
  figure: Scalars['String'];
  food?: Maybe<Scalars['String']>;
  furColor?: Maybe<Scalars['String']>;
  gender: PetGender;
  id: Scalars['ID'];
  insurance: Scalars['String'];
  insuranceNumber?: Maybe<Scalars['String']>;
  microChip?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  profileImageURL?: Maybe<Scalars['String']>;
  profileImageUploadURL: Scalars['String'];
  user?: Maybe<User>;
  weight?: Maybe<Scalars['Float']>;
};

export type PetProfileImageUploadUrlArgs = {
  fileName: Scalars['String'];
  type: FileType;
};

export enum PetGender {
  Female = 'FEMALE',
  FemaleNeutered = 'FEMALE_NEUTERED',
  Male = 'MALE',
  MaleNeutered = 'MALE_NEUTERED',
  Others = 'OTHERS',
}

export type Prefecture = {
  __typename?: 'Prefecture';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  animals: Array<Animal>;
  appointment: Appointment;
  appointmentSlots: Array<AppointmentSlot>;
  appointments: Array<Appointment>;
  clinic?: Maybe<Clinic>;
  clinicUser?: Maybe<ClinicUser>;
  frequencies: Array<Frequency>;
  latestAppAgreement: AppAgreement;
  prefectures: Array<Prefecture>;
  viewer: User;
};

export type QueryAppointmentArgs = {
  id: Scalars['ID'];
};

export type QueryAppointmentSlotsArgs = {
  clinicId: Scalars['ID'];
  clinicSlug: Scalars['String'];
  end: Scalars['DateTime'];
  resourceId?: InputMaybe<Scalars['ID']>;
  serviceId: Scalars['ID'];
  start: Scalars['DateTime'];
};

export type QueryAppointmentsArgs = {
  clinicSlug?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryClinicArgs = {
  slug: Scalars['String'];
};

export type QueryClinicUserArgs = {
  clinicSlug: Scalars['String'];
};

export type RequestPasswordResetInput = {
  email: Scalars['String'];
};

export type RequestPasswordResetPayload = {
  __typename?: 'RequestPasswordResetPayload';
  success: Scalars['Boolean'];
};

export type Resource = {
  __typename?: 'Resource';
  id: Scalars['ID'];
  name: Scalars['String'];
  photo?: Maybe<Scalars['String']>;
  profile?: Maybe<Scalars['String']>;
  type: ResourceType;
};

export type ResourceType = {
  __typename?: 'ResourceType';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Service = {
  __typename?: 'Service';
  category: ServiceCategory;
  clinicService: ClinicService;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  durationMinutes?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  resources: Array<Resource>;
  updatedAt: Scalars['DateTime'];
};

export type ServiceCategory = {
  __typename?: 'ServiceCategory';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  services: Array<Service>;
  updatedAt: Scalars['DateTime'];
};

export type SignInWithEmailInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type SignInWithEmailPayload = {
  __typename?: 'SignInWithEmailPayload';
  accessToken: Scalars['String'];
  success: Scalars['Boolean'];
};

export type SignInWithLineInput = {
  redirectUrl?: InputMaybe<Scalars['String']>;
  state: Scalars['String'];
};

export type SignInWithLinePayload = {
  __typename?: 'SignInWithLinePayload';
  authenticationUrl: Scalars['String'];
};

export type SignUpWithEmailInput = {
  appAgreementID: Scalars['ID'];
  clinicSlug?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  password: Scalars['String'];
};

export type SignUpWithEmailPayload = {
  __typename?: 'SignUpWithEmailPayload';
  success: Scalars['Boolean'];
};

export type Time = {
  __typename?: 'Time';
  hour: Scalars['Int'];
  minute: Scalars['Int'];
};

export type TimeInput = {
  hour: Scalars['Int'];
  minute: Scalars['Int'];
};

export type UpdateAppointmentInput = {
  clinicServiceId?: InputMaybe<Scalars['ID']>;
  endAt?: InputMaybe<Scalars['DateTime']>;
  history?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  isFirst?: InputMaybe<Scalars['Boolean']>;
  note?: InputMaybe<Scalars['String']>;
  onset?: InputMaybe<Scalars['String']>;
  petId?: InputMaybe<Scalars['ID']>;
  resourceId?: InputMaybe<Scalars['ID']>;
  startAt?: InputMaybe<Scalars['DateTime']>;
  symptom?: InputMaybe<Scalars['String']>;
};

export type UpdateClinicPetInput = {
  id: Scalars['ID'];
  note?: InputMaybe<Scalars['String']>;
  patientNumber?: InputMaybe<Scalars['String']>;
};

export type UpdatePetInput = {
  animalId?: InputMaybe<Scalars['ID']>;
  animalTypeId?: InputMaybe<Scalars['ID']>;
  birthday?: InputMaybe<Scalars['Date']>;
  figure?: InputMaybe<Scalars['String']>;
  food?: InputMaybe<Scalars['String']>;
  furColor?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<PetGender>;
  id: Scalars['ID'];
  insurance?: InputMaybe<Scalars['String']>;
  insuranceNumber?: InputMaybe<Scalars['String']>;
  microChip?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  profileImageURL?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Float']>;
};

export type UpdateViewerInput = {
  building?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  clinicSlug?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  patientNumber?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  prefectureId?: InputMaybe<Scalars['ID']>;
  profileImageURL?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  trimmingFrequencyId?: InputMaybe<Scalars['ID']>;
  visitingFrequencyId?: InputMaybe<Scalars['ID']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  acceptedAppAgreement?: Maybe<AppAgreement>;
  building?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  displayName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  id: Scalars['ID'];
  locale: Locale;
  name: Scalars['String'];
  pet: Pet;
  pets: Array<Pet>;
  phoneNumber?: Maybe<Scalars['String']>;
  prefecture?: Maybe<Prefecture>;
  profileImageURL?: Maybe<Scalars['String']>;
  profileImageUploadURL: Scalars['String'];
  street?: Maybe<Scalars['String']>;
  trimmingFrequency?: Maybe<Frequency>;
  updatedAt: Scalars['DateTime'];
  visitingFrequency?: Maybe<Frequency>;
  zipCode?: Maybe<Scalars['String']>;
};

export type UserPetArgs = {
  id: Scalars['ID'];
};

export type UserProfileImageUploadUrlArgs = {
  fileName: Scalars['String'];
  type: FileType;
};

export type AcceptClinicAgreementMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type AcceptClinicAgreementMutation = {
  __typename?: 'Mutation';
  acceptClinicAgreement: { __typename: 'ClinicAgreement'; id: string };
};

export type CancelAppointmentMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type CancelAppointmentMutation = {
  __typename?: 'Mutation';
  cancelAppointment: { __typename?: 'Appointment'; id: string };
};

export type ConfirmEmailMutationVariables = Exact<{
  input: ConfirmEmailInput;
}>;

export type ConfirmEmailMutation = {
  __typename?: 'Mutation';
  confirmEmail: { __typename?: 'ConfirmEmailPayload'; success: boolean; accessToken: string };
};

export type ConfirmPasswordResetMutationVariables = Exact<{
  input: ConfirmPasswordResetInput;
}>;

export type ConfirmPasswordResetMutation = {
  __typename?: 'Mutation';
  confirmPasswordReset: { __typename?: 'ConfirmPasswordResetPayload'; success: boolean };
};

export type CreateAppointmentMutationVariables = Exact<{
  input: CreateAppointmentInput;
}>;

export type CreateAppointmentMutation = {
  __typename?: 'Mutation';
  createAppointment: { __typename?: 'Appointment'; id: string };
};

export type CreatePetMutationVariables = Exact<{
  input: CreatePetInput;
}>;

export type CreatePetMutation = {
  __typename?: 'Mutation';
  createPet: { __typename: 'Pet'; id: string };
};

export type DeletePetMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeletePetMutation = {
  __typename?: 'Mutation';
  deletePet: { __typename: 'Pet'; id: string };
};

export type PasswordResetMutationVariables = Exact<{
  input: RequestPasswordResetInput;
}>;

export type PasswordResetMutation = {
  __typename?: 'Mutation';
  requestPasswordReset: { __typename?: 'RequestPasswordResetPayload'; success: boolean };
};

export type SignInWithEmailMutationVariables = Exact<{
  input: SignInWithEmailInput;
}>;

export type SignInWithEmailMutation = {
  __typename?: 'Mutation';
  signInWithEmail: { __typename?: 'SignInWithEmailPayload'; success: boolean; accessToken: string };
};

export type SignUpWithEmailMutationVariables = Exact<{
  input: SignUpWithEmailInput;
}>;

export type SignUpWithEmailMutation = {
  __typename?: 'Mutation';
  signUpWithEmail: { __typename?: 'SignUpWithEmailPayload'; success: boolean };
};

export type UpdateAppointmentMutationVariables = Exact<{
  input: UpdateAppointmentInput;
}>;

export type UpdateAppointmentMutation = {
  __typename?: 'Mutation';
  updateAppointment: { __typename?: 'Appointment'; id: string };
};

export type UpdatePetMutationVariables = Exact<{
  input: UpdatePetInput;
}>;

export type UpdatePetMutation = {
  __typename?: 'Mutation';
  updatePet: { __typename: 'Pet'; id: string };
};

export type UpdateViewerMutationVariables = Exact<{
  input: UpdateViewerInput;
}>;

export type UpdateViewerMutation = {
  __typename?: 'Mutation';
  updateViewer: { __typename: 'User'; id: string };
};

export type AnimalsQueryVariables = Exact<{ [key: string]: never }>;

export type AnimalsQuery = {
  __typename?: 'Query';
  animals: Array<{
    __typename?: 'Animal';
    id: string;
    name: string;
    types: Array<{ __typename?: 'AnimalType'; id: string; name: string }>;
  }>;
};

export type AppointmentDetailQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type AppointmentDetailQuery = {
  __typename?: 'Query';
  appointment: {
    __typename?: 'Appointment';
    id: string;
    status: AppointmentStatus;
    startAt: any;
    endAt: any;
    isFirst: boolean;
    symptom: string;
    onset: string;
    history: string;
    note: string;
    clinic: {
      __typename?: 'Clinic';
      id: string;
      slug: string;
      name: string;
      cancellationDeadlineDays?: number | null;
      cancellationDeadlineHours?: number | null;
    };
    clinicPet: {
      __typename?: 'ClinicPet';
      pet?: {
        __typename?: 'Pet';
        id: string;
        name: string;
        birthday: any;
        gender: PetGender;
        profileImageURL?: string | null;
      } | null;
    };
    service?: {
      __typename?: 'Service';
      id: string;
      name: string;
      description: string;
      durationMinutes?: number | null;
    } | null;
    resource: { __typename?: 'Resource'; id: string; name: string; photo?: string | null };
  };
};

export type AppointmentFormOptionsQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type AppointmentFormOptionsQuery = {
  __typename?: 'Query';
  viewer: {
    __typename?: 'User';
    pets: Array<{
      __typename?: 'Pet';
      id: string;
      name: string;
      profileImageURL?: string | null;
      gender: PetGender;
      birthday: any;
      animal?: { __typename?: 'Animal'; id: string; name: string } | null;
      animalType?: { __typename?: 'AnimalType'; id: string; name: string } | null;
    }>;
  };
  clinic?: {
    __typename?: 'Clinic';
    id: string;
    slug: string;
    name: string;
    slotDuration?: number | null;
    reservationLimitDaysFrom?: number | null;
    reservationLimitHoursFrom?: number | null;
    reservationLimitDaysTo?: number | null;
    reservationLimitHoursTo?: number | null;
    clinicBussinessHour?: {
      __typename?: 'ClinicBussinessHour';
      start: { __typename?: 'Time'; hour: number; minute: number };
      end: { __typename?: 'Time'; hour: number; minute: number };
    } | null;
    latestAgreement?: { __typename?: 'ClinicAgreement'; text: string } | null;
    animals: Array<{ __typename?: 'Animal'; id: string; name: string }>;
    serviceCategories: Array<{
      __typename?: 'ServiceCategory';
      id: string;
      name: string;
      services: Array<{
        __typename?: 'Service';
        id: string;
        name: string;
        durationMinutes?: number | null;
        description: string;
        clinicService: { __typename?: 'ClinicService'; id: string };
        resources: Array<{
          __typename?: 'Resource';
          id: string;
          name: string;
          photo?: string | null;
          profile?: string | null;
        }>;
      }>;
    }>;
  } | null;
};

export type AppointmentScheduleQueryVariables = Exact<{
  clinicSlug: Scalars['String'];
  clinicId: Scalars['ID'];
  serviceId: Scalars['ID'];
  resourceId?: InputMaybe<Scalars['ID']>;
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
}>;

export type AppointmentScheduleQuery = {
  __typename?: 'Query';
  appointmentSlots: Array<{ __typename?: 'AppointmentSlot'; isAvailable: boolean; date: any }>;
};

export type AppointmentsQueryVariables = Exact<{ [key: string]: never }>;

export type AppointmentsQuery = {
  __typename?: 'Query';
  appointments: Array<{
    __typename?: 'Appointment';
    id: string;
    status: AppointmentStatus;
    startAt: any;
    endAt: any;
    clinic: { __typename?: 'Clinic'; name: string };
    service?: { __typename?: 'Service'; name: string } | null;
    clinicPet: {
      __typename?: 'ClinicPet';
      id: string;
      pet?: { __typename?: 'Pet'; name: string; profileImageURL?: string | null } | null;
    };
  }>;
};

export type ClinicAgreementQueryVariables = Exact<{
  clinicSlug: Scalars['String'];
}>;

export type ClinicAgreementQuery = {
  __typename?: 'Query';
  clinic?: {
    __typename?: 'Clinic';
    latestAgreement?: {
      __typename?: 'ClinicAgreement';
      id: string;
      text: string;
      version: string;
    } | null;
  } | null;
};

export type ClinicForLoginQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type ClinicForLoginQuery = {
  __typename?: 'Query';
  clinic?: {
    __typename?: 'Clinic';
    id: string;
    name: string;
    slug: string;
    serviceCategories: Array<{ __typename?: 'ServiceCategory'; id: string; name: string }>;
    latestAgreement?: { __typename?: 'ClinicAgreement'; text: string } | null;
  } | null;
  latestAppAgreement: { __typename?: 'AppAgreement'; text: string; url: string };
};

export type ClinicUserLayoutQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type ClinicUserLayoutQuery = {
  __typename?: 'Query';
  clinic?: { __typename?: 'Clinic'; name: string } | null;
};

export type LatestAppAgreementQueryVariables = Exact<{ [key: string]: never }>;

export type LatestAppAgreementQuery = {
  __typename?: 'Query';
  latestAppAgreement: {
    __typename?: 'AppAgreement';
    id: string;
    url: string;
    text: string;
    version: number;
    createdAt: any;
    updatedAt: any;
    acceptedAt?: any | null;
  };
};

export type PetDetailQueryVariables = Exact<{
  petId: Scalars['ID'];
}>;

export type PetDetailQuery = {
  __typename?: 'Query';
  viewer: {
    __typename?: 'User';
    pet: {
      __typename?: 'Pet';
      id: string;
      name: string;
      insurance: string;
      birthday: any;
      weight?: number | null;
      furColor?: string | null;
      insuranceNumber?: string | null;
      food?: string | null;
      microChip?: string | null;
      note?: string | null;
      profileImageURL?: string | null;
      gender: PetGender;
      animal?: { __typename?: 'Animal'; id: string; name: string } | null;
      animalType?: { __typename?: 'AnimalType'; id: string; name: string } | null;
    };
  };
  animals: Array<{
    __typename?: 'Animal';
    id: string;
    name: string;
    types: Array<{ __typename?: 'AnimalType'; id: string; name: string }>;
  }>;
};

export type PetProfileImageUploadUrlQueryVariables = Exact<{
  petId: Scalars['ID'];
  type: FileType;
  fileName: Scalars['String'];
}>;

export type PetProfileImageUploadUrlQuery = {
  __typename?: 'Query';
  viewer: {
    __typename?: 'User';
    id: string;
    pet: { __typename?: 'Pet'; profileImageUploadURL: string };
  };
};

export type PetsQueryVariables = Exact<{ [key: string]: never }>;

export type PetsQuery = {
  __typename?: 'Query';
  viewer: {
    __typename?: 'User';
    pets: Array<{
      __typename?: 'Pet';
      id: string;
      name: string;
      gender: PetGender;
      birthday: any;
      profileImageURL?: string | null;
      animal?: { __typename?: 'Animal'; id: string; name: string } | null;
      animalType?: { __typename?: 'AnimalType'; id: string; name: string } | null;
    }>;
  };
};

export type RegisterConfirmDetailQueryVariables = Exact<{ [key: string]: never }>;

export type RegisterConfirmDetailQuery = {
  __typename?: 'Query';
  viewer: {
    __typename?: 'User';
    id: string;
    name: string;
    email: string;
    profileImageURL?: string | null;
    phoneNumber?: string | null;
    zipCode?: string | null;
    city?: string | null;
    street?: string | null;
    building?: string | null;
    prefecture?: { __typename?: 'Prefecture'; name: string } | null;
    visitingFrequency?: { __typename?: 'Frequency'; name: string } | null;
    trimmingFrequency?: { __typename?: 'Frequency'; name: string } | null;
    pets: Array<{
      __typename?: 'Pet';
      id: string;
      name: string;
      gender: PetGender;
      birthday: any;
      weight?: number | null;
      furColor?: string | null;
      insurance: string;
      insuranceNumber?: string | null;
      food?: string | null;
      microChip?: string | null;
      note?: string | null;
      profileImageURL?: string | null;
      animal?: { __typename?: 'Animal'; id: string; name: string } | null;
      animalType?: { __typename?: 'AnimalType'; id: string; name: string } | null;
    }>;
    acceptedAppAgreement?: {
      __typename?: 'AppAgreement';
      id: string;
      acceptedAt?: any | null;
    } | null;
  };
  latestAppAgreement: {
    __typename?: 'AppAgreement';
    id: string;
    url: string;
    text: string;
    version: number;
    createdAt: any;
    updatedAt: any;
    acceptedAt?: any | null;
  };
};

export type RegisterPetEditQueryVariables = Exact<{
  petId: Scalars['ID'];
}>;

export type RegisterPetEditQuery = {
  __typename?: 'Query';
  animals: Array<{
    __typename?: 'Animal';
    id: string;
    name: string;
    types: Array<{ __typename?: 'AnimalType'; id: string; name: string }>;
  }>;
  viewer: {
    __typename?: 'User';
    id: string;
    pet: {
      __typename?: 'Pet';
      id: string;
      name: string;
      profileImageURL?: string | null;
      gender: PetGender;
      insurance: string;
      birthday: any;
      weight?: number | null;
      figure: string;
      furColor?: string | null;
      insuranceNumber?: string | null;
      food?: string | null;
      microChip?: string | null;
      note?: string | null;
      animal?: { __typename?: 'Animal'; id: string; name: string } | null;
      animalType?: { __typename?: 'AnimalType'; id: string; name: string } | null;
    };
  };
};

export type RegisterPetNewQueryVariables = Exact<{ [key: string]: never }>;

export type RegisterPetNewQuery = {
  __typename?: 'Query';
  animals: Array<{
    __typename?: 'Animal';
    id: string;
    name: string;
    types: Array<{ __typename?: 'AnimalType'; id: string; name: string }>;
  }>;
  viewer: { __typename?: 'User'; id: string };
};

export type RegisterUserDetailQueryVariables = Exact<{ [key: string]: never }>;

export type RegisterUserDetailQuery = {
  __typename?: 'Query';
  viewer: {
    __typename?: 'User';
    id: string;
    name: string;
    email: string;
    profileImageURL?: string | null;
    phoneNumber?: string | null;
    zipCode?: string | null;
    city?: string | null;
    street?: string | null;
    building?: string | null;
    prefecture?: { __typename?: 'Prefecture'; id: string; name: string } | null;
    visitingFrequency?: { __typename?: 'Frequency'; id: string; name: string } | null;
    trimmingFrequency?: { __typename?: 'Frequency'; id: string; name: string } | null;
    acceptedAppAgreement?: { __typename?: 'AppAgreement'; acceptedAt?: any | null } | null;
    pets: Array<{ __typename?: 'Pet'; id: string }>;
  };
  prefectures: Array<{ __typename?: 'Prefecture'; id: string; name: string }>;
  frequencies: Array<{ __typename?: 'Frequency'; id: string; name: string }>;
};

export type UserDetailQueryVariables = Exact<{ [key: string]: never }>;

export type UserDetailQuery = {
  __typename?: 'Query';
  viewer: {
    __typename?: 'User';
    id: string;
    name: string;
    email: string;
    profileImageURL?: string | null;
    phoneNumber?: string | null;
    zipCode?: string | null;
    city?: string | null;
    street?: string | null;
    building?: string | null;
    prefecture?: { __typename?: 'Prefecture'; name: string } | null;
    visitingFrequency?: { __typename?: 'Frequency'; name: string } | null;
    trimmingFrequency?: { __typename?: 'Frequency'; name: string } | null;
  };
};

export type UserFormQueryVariables = Exact<{ [key: string]: never }>;

export type UserFormQuery = {
  __typename?: 'Query';
  viewer: {
    __typename?: 'User';
    id: string;
    name: string;
    email: string;
    profileImageURL?: string | null;
    phoneNumber?: string | null;
    zipCode?: string | null;
    city?: string | null;
    street?: string | null;
    building?: string | null;
    prefecture?: { __typename?: 'Prefecture'; id: string; name: string } | null;
    visitingFrequency?: { __typename?: 'Frequency'; id: string; name: string } | null;
    trimmingFrequency?: { __typename?: 'Frequency'; id: string; name: string } | null;
  };
  prefectures: Array<{ __typename?: 'Prefecture'; id: string; name: string }>;
  frequencies: Array<{ __typename?: 'Frequency'; id: string; name: string }>;
};

export type ViewerProfileImageUploadUrlQueryVariables = Exact<{
  type: FileType;
  fileName: Scalars['String'];
}>;

export type ViewerProfileImageUploadUrlQuery = {
  __typename?: 'Query';
  viewer: { __typename?: 'User'; id: string; profileImageUploadURL: string };
};

export const AcceptClinicAgreementDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'acceptClinicAgreement' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'acceptClinicAgreement' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AcceptClinicAgreementMutation, AcceptClinicAgreementMutationVariables>;
export const CancelAppointmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CancelAppointment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cancelAppointment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CancelAppointmentMutation, CancelAppointmentMutationVariables>;
export const ConfirmEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'confirmEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ConfirmEmailInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'confirmEmail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accessToken' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ConfirmEmailMutation, ConfirmEmailMutationVariables>;
export const ConfirmPasswordResetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ConfirmPasswordReset' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ConfirmPasswordResetInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'confirmPasswordReset' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ConfirmPasswordResetMutation, ConfirmPasswordResetMutationVariables>;
export const CreateAppointmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateAppointment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateAppointmentInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createAppointment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateAppointmentMutation, CreateAppointmentMutationVariables>;
export const CreatePetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createPet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreatePetInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPet' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreatePetMutation, CreatePetMutationVariables>;
export const DeletePetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deletePet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deletePet' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeletePetMutation, DeletePetMutationVariables>;
export const PasswordResetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'PasswordReset' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'RequestPasswordResetInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requestPasswordReset' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PasswordResetMutation, PasswordResetMutationVariables>;
export const SignInWithEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'signInWithEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SignInWithEmailInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'signInWithEmail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accessToken' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SignInWithEmailMutation, SignInWithEmailMutationVariables>;
export const SignUpWithEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'signUpWithEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SignUpWithEmailInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'signUpWithEmail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SignUpWithEmailMutation, SignUpWithEmailMutationVariables>;
export const UpdateAppointmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateAppointment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateAppointmentInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAppointment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateAppointmentMutation, UpdateAppointmentMutationVariables>;
export const UpdatePetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updatePet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdatePetInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePet' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdatePetMutation, UpdatePetMutationVariables>;
export const UpdateViewerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateViewer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateViewerInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateViewer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateViewerMutation, UpdateViewerMutationVariables>;
export const AnimalsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Animals' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'animals' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'types' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AnimalsQuery, AnimalsQueryVariables>;
export const AppointmentDetailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AppointmentDetail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isFirst' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'clinic' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cancellationDeadlineDays' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cancellationDeadlineHours' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'clinicPet' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pet' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'birthday' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'profileImageURL' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'service' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'durationMinutes' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resource' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'photo' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'symptom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'onset' } },
                { kind: 'Field', name: { kind: 'Name', value: 'history' } },
                { kind: 'Field', name: { kind: 'Name', value: 'note' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AppointmentDetailQuery, AppointmentDetailQueryVariables>;
export const AppointmentFormOptionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AppointmentFormOptions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pets' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'profileImageURL' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'birthday' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'animal' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'animalType' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clinic' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'slug' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slotDuration' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reservationLimitDaysFrom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reservationLimitHoursFrom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reservationLimitDaysTo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reservationLimitHoursTo' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'clinicBussinessHour' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'start' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'hour' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'minute' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'end' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'hour' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'minute' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'latestAgreement' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'text' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'animals' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'serviceCategories' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'services' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'durationMinutes' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'clinicService' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'resources' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'photo' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'profile' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AppointmentFormOptionsQuery, AppointmentFormOptionsQueryVariables>;
export const AppointmentScheduleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AppointmentSchedule' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'clinicSlug' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'clinicId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'serviceId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'resourceId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'start' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'end' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointmentSlots' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clinicSlug' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'clinicSlug' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clinicId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'clinicId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'serviceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'serviceId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'resourceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'resourceId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'start' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'start' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'end' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'end' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'isAvailable' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AppointmentScheduleQuery, AppointmentScheduleQueryVariables>;
export const AppointmentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Appointments' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appointments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'clinic' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'service' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'clinicPet' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pet' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'profileImageURL' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AppointmentsQuery, AppointmentsQueryVariables>;
export const ClinicAgreementDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'clinicAgreement' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'clinicSlug' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clinic' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'slug' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'clinicSlug' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'latestAgreement' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClinicAgreementQuery, ClinicAgreementQueryVariables>;
export const ClinicForLoginDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ClinicForLogin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clinic' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'slug' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'serviceCategories' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'latestAgreement' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'text' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestAppAgreement' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClinicForLoginQuery, ClinicForLoginQueryVariables>;
export const ClinicUserLayoutDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'clinicUserLayout' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clinic' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'slug' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClinicUserLayoutQuery, ClinicUserLayoutQueryVariables>;
export const LatestAppAgreementDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'latestAppAgreement' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestAppAgreement' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'acceptedAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LatestAppAgreementQuery, LatestAppAgreementQueryVariables>;
export const PetDetailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PetDetail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'petId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pet' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'petId' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'insurance' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'birthday' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'furColor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'insuranceNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'food' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'microChip' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'profileImageURL' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'animal' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'animalType' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'animals' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'types' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PetDetailQuery, PetDetailQueryVariables>;
export const PetProfileImageUploadUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PetProfileImageUploadUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'petId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'FileType' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileName' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pet' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'petId' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'profileImageUploadURL' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'type' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'fileName' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'fileName' } },
                          },
                        ],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PetProfileImageUploadUrlQuery, PetProfileImageUploadUrlQueryVariables>;
export const PetsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'pets' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pets' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'birthday' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'profileImageURL' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'animal' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'animalType' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PetsQuery, PetsQueryVariables>;
export const RegisterConfirmDetailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RegisterConfirmDetail' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profileImageURL' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zipCode' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'prefecture' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'building' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'visitingFrequency' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trimmingFrequency' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pets' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'birthday' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'furColor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'insurance' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'insuranceNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'food' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'microChip' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'profileImageURL' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'animal' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'animalType' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'acceptedAppAgreement' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'acceptedAt' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestAppAgreement' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'acceptedAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RegisterConfirmDetailQuery, RegisterConfirmDetailQueryVariables>;
export const RegisterPetEditDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RegisterPetEdit' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'petId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'animals' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'types' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pet' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'petId' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'profileImageURL' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'insurance' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'birthday' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'animal' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'animalType' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'weight' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'figure' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'furColor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'insuranceNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'food' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'microChip' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RegisterPetEditQuery, RegisterPetEditQueryVariables>;
export const RegisterPetNewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RegisterPetNew' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'animals' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'types' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RegisterPetNewQuery, RegisterPetNewQueryVariables>;
export const RegisterUserDetailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'registerUserDetail' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profileImageURL' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zipCode' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'prefecture' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'building' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'visitingFrequency' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trimmingFrequency' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'acceptedAppAgreement' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'acceptedAt' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pets' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'prefectures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frequencies' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RegisterUserDetailQuery, RegisterUserDetailQueryVariables>;
export const UserDetailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'userDetail' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profileImageURL' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zipCode' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'prefecture' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'building' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'visitingFrequency' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trimmingFrequency' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserDetailQuery, UserDetailQueryVariables>;
export const UserFormDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'userForm' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'profileImageURL' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'zipCode' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'prefecture' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'building' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'visitingFrequency' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trimmingFrequency' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'prefectures' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'frequencies' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserFormQuery, UserFormQueryVariables>;
export const ViewerProfileImageUploadUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ViewerProfileImageUploadUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'FileType' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileName' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profileImageUploadURL' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'type' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fileName' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'fileName' } },
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ViewerProfileImageUploadUrlQuery,
  ViewerProfileImageUploadUrlQueryVariables
>;
