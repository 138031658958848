import type { FC } from 'react';
import { useCallback } from 'react';
import { useMutation } from 'urql';
import { enqueueSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { RequestPasswordResetForm } from 'components/forms/user/RequestPasswordResetForm/RequestPasswordResetForm';
import type { RequestPasswordResetInput } from 'graphql/generated/user/graphql';
import { PasswordResetDocument } from 'graphql/generated/user/graphql';
import * as paths from 'constants/paths/user';
import * as pageTitles from 'constants/pageTitles/user';
import { usePasswordResetCookie } from 'hooks/user/useRasswordResetCookie';

const Page: FC = () => {
  const navigate = useNavigate();
  const { clinicSlug } = useParams() as { clinicSlug: string };
  const [_passwordResetResult, passwordReset] = useMutation(PasswordResetDocument);
  const { setRedirectClinicSlug: setClinicSlug } = usePasswordResetCookie();

  const onSubmit = useCallback(
    async (body: RequestPasswordResetInput) => {
      try {
        const result = await passwordReset({ input: body });

        if (result.error?.message) {
          if (result.error?.message.match('invalid email')) {
            throw new Error('このメールアドレスは登録されていません');
          }
          throw new Error('システムエラーが発生しました。');
        }

        if (result?.data?.requestPasswordReset?.success) {
          enqueueSnackbar('メールが送信されました。', {
            variant: 'success',
          });
          setClinicSlug(clinicSlug);
          navigate(
            clinicSlug
              ? paths.clinicSendPasswordResetMailPath(clinicSlug)
              : paths.sendPasswordResetMailPath()
          );
        }
      } catch (e: any) {
        // eslint-disable-next-line no-console
        console.log(e);

        enqueueSnackbar(e.message, {
          variant: 'error',
        });
      }
    },
    [clinicSlug]
  );

  return (
    <>
      <Helmet>
        <title>{pageTitles.passwordReset}</title>
      </Helmet>
      <RequestPasswordResetForm clinicSlug={clinicSlug} onSubmit={onSubmit} />
    </>
  );
};

export default Page;
