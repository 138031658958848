import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

export interface RegisterFormInput {
  email: string;
  password: string;
}

const passwordRegex = /^(?=.*?[a-z])(?=.*?[A-Z]).{8,}$/;
const formSchema = yup.object({
  email: yup
    .string()
    .required('メールアドレスが入力されていません')
    .email('正しい形式でメールアドレスを入力してください'),
  password: yup
    .string()
    .required('パスワードを入力してください')
    .min(8, 'パスワードは8桁以上必要です')
    .matches(passwordRegex, 'パスワードは英字(大文字・小文字)含む8桁以上必要です'),
});

export const useRegisterForm = () =>
  useForm<RegisterFormInput>({
    resolver: yupResolver(formSchema),
  });
