import { Avatar, Box, Button, Typography } from '@mui/material';
import { blue, green, grey } from '@mui/material/colors';
import PetsIcon from '@mui/icons-material/Pets';
// eslint-disable-next-line import/no-duplicates
import { format, parseISO } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import ja from 'date-fns/locale/ja';
import { useCallback } from 'react';
import { appointmentStatusLabel } from 'constants/appointmentStatuses';
import type { Appointment, AppointmentsQuery } from 'graphql/generated/user/graphql';
import { AppointmentStatus } from 'graphql/generated/user/graphql';

interface Props {
  appointment: AppointmentsQuery['appointments'][number];
  onSelect?: (appointmentId: Appointment['id']) => void;
}

const colors = {
  [AppointmentStatus.Booked]: { bgColor: blue[400], borderColor: blue[100] },
  [AppointmentStatus.Canceled]: { bgColor: grey[400], borderColor: grey[100] },
  [AppointmentStatus.Visited]: { bgColor: '#F9C941', borderColor: '#fff1cc' },
  [AppointmentStatus.Waiting]: { bgColor: green[400], borderColor: green[100] },
};

export const Item = ({ appointment, onSelect }: Props) => {
  const onClick = useCallback(() => {
    if (onSelect) {
      onSelect(appointment.id);
    }
  }, [onSelect, appointment.id]);

  return (
    <Box key={appointment.id} my={2}>
      <Button fullWidth onClick={onClick}>
        <Box width="100%" color={grey[900]}>
          <Box bgcolor={colors[appointment.status].bgColor} textAlign="center">
            <Typography variant="body2" color={grey[50]} py={0.5}>
              {appointmentStatusLabel(appointment.status)}
            </Typography>
          </Box>
          <Box
            border={`2px solid ${colors[appointment.status].borderColor}`}
            borderTop="none"
            p={1}
          >
            <Typography textAlign="center" variant="h6">
              {format(parseISO(appointment.startAt), 'yyyy年M月dd日')}(
              {format(parseISO(appointment.startAt), 'E', { locale: ja })})
            </Typography>
            <Typography textAlign="center" variant="body1">
              {format(parseISO(appointment.startAt), 'HH時mm分')}〜
              {format(parseISO(appointment.endAt), 'HH時mm分')}
            </Typography>
            <Typography textAlign="center" variant="h5" py={2} color={grey[700]} fontWeight="bold">
              {appointment.service?.name}
            </Typography>
            <Box bgcolor={grey[100]} p={1} display="flex" alignItems="center">
              <Avatar src={appointment.clinicPet.pet?.profileImageURL ?? ''}>
                <PetsIcon />
              </Avatar>
              <Typography ml={1}>{appointment.clinicPet.pet?.name}</Typography>
            </Box>
            <Box mt={1} textAlign="center" color={grey[700]}>
              <Typography>{appointment.clinic.name}</Typography>
            </Box>
          </Box>
        </Box>
      </Button>
    </Box>
  );
};
