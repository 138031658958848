import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import type { FC } from 'react';

interface Props {
  open: boolean;
  onCancel?: () => void;
  title: string;
  text: string;
}

export const Component: FC<Props> = ({ open, onCancel = () => {}, text, title }) => {
  return (
    <Dialog open={open} onClose={onCancel} fullWidth maxWidth="sm">
      <DialogTitle>
        {title}
        <IconButton
          aria-label="キャンセル"
          onClick={onCancel}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box width="100%" height="350px" textAlign="center">
          <Typography
            sx={{
              fontSize: '12px',
              height: '100%',
              overflowY: 'scroll',
              textAlign: 'left',
              whiteSpace: 'pre-wrap',
            }}
            dangerouslySetInnerHTML={{ __html: text }}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};
