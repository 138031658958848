import SearchIcon from '@mui/icons-material/Search';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  FormControl,
  FormHelperText,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { format, formatISO, parseISO } from 'date-fns';
import { useCallback, useState } from 'react';
import PetsIcon from '@mui/icons-material/Pets';
import { UploadImageButton } from '../UploadImageButton/UploadImageButton';
import type { PetEditorFormType } from './hooks/usePetEditor';
import { usePetEditorForm } from './hooks/usePetEditor';
import type {
  Animal,
  AnimalType,
  CreatePetInput,
  PetGender,
  UpdatePetInput,
} from 'graphql/generated/user/graphql';
import { petGenders } from 'constants/petGender';

interface Props {
  animals: {
    id: Animal['id'];
    name: Animal['name'];
    types: {
      id: AnimalType['id'];
      name: AnimalType['name'];
    }[];
  }[];
  initialValues?: PetEditorFormType;
  onSubmit: (data: CreatePetInput & { id?: UpdatePetInput['id'] }, profileImageFile?: File) => void;
  onCancel?: () => void;
}

export const UserPetForm = (props: Props) => {
  const { initialValues } = props;
  const [imageData, setImageData] = useState<{ url: string; file: File } | null>(null);
  const catOrDogAnimalIds = props.animals
    .filter((a) => ['犬', '猫'].includes(a.name))
    .map((a) => a.id);

  const {
    handleSubmit,
    register,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = usePetEditorForm({
    defaultValues: {
      ...props.initialValues,
      birthday: props.initialValues?.birthday
        ? format(parseISO(props.initialValues.birthday), 'yyyy-MM-dd')
        : '',
    },
    catOrDogAnimalIds,
  });

  watch('animalId');

  const onChangeAnimal = useCallback(
    (
      e: React.SyntheticEvent<Element, Event>,
      animal: { id: Animal['id']; name: Animal['name'] } | null
    ) => {
      setValue('animalId', animal?.id ?? '');
    },
    [setValue]
  );

  const onChangeAnimalType = useCallback(
    (
      e: React.SyntheticEvent<Element, Event>,
      animalType: { id: Animal['id']; name: Animal['name'] } | null
    ) => {
      setValue('animalTypeId', animalType?.id);
    },
    [setValue]
  );

  const onChangeGender = useCallback(
    (
      e: React.SyntheticEvent<Element, Event>,
      gender: { label: string; value: PetGender } | null
    ) => {
      setValue('gender', gender?.value);
    },
    [setValue]
  );

  const onSelectProfileImage = useCallback(
    (file: File, imageUrl: string) => {
      setImageData({ url: imageUrl, file: file });
    },
    [setImageData]
  );

  const onSubmit = useCallback(
    (data: PetEditorFormType) => {
      if (!data.name || !data.animalId) {
        return;
      }

      const input: CreatePetInput & { id?: UpdatePetInput['id'] } = {
        name: data.name,
        gender: data.gender,
        birthday: data.birthday ? formatISO(new Date(data.birthday)) : '',
        animalTypeId: data.animalTypeId,
        figure: data.figure,
        food: data.food,
        furColor: data.furColor,
        insurance: data.insurance,
        insuranceNumber: data.insuranceNumber,
        animalId: data.animalId ?? '',
        microChip: data.microChip,
        note: data.note,
        weight: data.weight,
      };

      if (initialValues?.id) {
        input.id = initialValues.id;
      }

      const keys = Object.keys(input) as unknown as (keyof CreatePetInput)[];
      keys.forEach((key) => {
        if (input[key] === '') {
          delete input[key];
        }
      });

      props.onSubmit(input, imageData?.file);
    },
    [props.onSubmit, imageData]
  );

  const selectedAnimal = props.animals.find((a) => a.id === getValues('animalId'));

  return (
    <Box onSubmit={handleSubmit(onSubmit)} component="form" width="100%">
      <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" my={4}>
        <Box p={2}>
          <Avatar
            src={imageData?.url || initialValues?.profileImageURL || ''}
            sx={{
              textAlign: 'center',
              width: 100,
              height: 100,
            }}
          >
            <PetsIcon fontSize="large" />
          </Avatar>
        </Box>
        <UploadImageButton onSelect={onSelectProfileImage} />
      </Box>

      <Stack spacing={3} mt={4} mb={4}>
        <Box>
          <TextField
            label="ペット名"
            required
            fullWidth
            error={'name' in errors}
            helperText={errors.name?.message as string}
            {...register('name')}
          />
        </Box>
        <Box>
          <Autocomplete
            renderInput={(params) => (
              <TextField
                {...params}
                required
                InputProps={{
                  ...params.InputProps,
                  startAdornment: <SearchIcon />,
                }}
                label="種類"
              />
            )}
            options={props.animals.map((a) => ({ id: a.id, name: a.name }))}
            getOptionLabel={(option) => option.name}
            defaultValue={props.animals.find(({ id }) => id === initialValues?.animalId)}
            onChange={onChangeAnimal}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />

          {'animalId' in errors && (
            <FormHelperText error color="danger">
              {errors.animalId?.message as string}
            </FormHelperText>
          )}
        </Box>

        <Box>
          <Autocomplete
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: <SearchIcon />,
                }}
                label="種別"
              />
            )}
            options={selectedAnimal?.types || []}
            getOptionLabel={(option) => option.name}
            defaultValue={selectedAnimal?.types.find(
              ({ id }) => id === initialValues?.animalTypeId
            )}
            onChange={onChangeAnimalType}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
          {'animalTypeId' in errors && (
            <FormHelperText error color="danger">
              {errors.animalTypeId?.message as string}
            </FormHelperText>
          )}
        </Box>

        <Box>
          <FormControl sx={{ width: '100%' }}>
            <Autocomplete
              renderInput={(params) => (
                <TextField
                  {...params}
                  required={catOrDogAnimalIds.includes(getValues('animalId') || '')}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: <SearchIcon />,
                  }}
                  label="性別"
                />
              )}
              options={petGenders}
              getOptionLabel={(option) => option.label}
              defaultValue={petGenders.find((g) => g.value === initialValues?.gender)}
              onChange={onChangeGender}
            />
            {'gender' in errors && (
              <FormHelperText error color="danger">
                {errors.gender?.message as string}
              </FormHelperText>
            )}
          </FormControl>
        </Box>

        <Box>
          <TextField
            fullWidth
            type="date"
            inputProps={{
              max: format(new Date(), 'yyyy-MM-dd'),
            }}
            InputLabelProps={{
              shrink: true,
            }}
            label="生年月日"
            {...register('birthday')}
          />
          {'birthday' in errors && (
            <FormHelperText error color="danger">
              {errors.birthday?.message as string}
            </FormHelperText>
          )}
        </Box>

        <Box>
          <TextField
            fullWidth
            inputProps={{
              step: '0.1',
            }}
            type="number"
            label="体重(kg)"
            {...register('weight')}
          />
        </Box>

        <Box>
          <TextField fullWidth label="毛色" {...register('furColor')} />
        </Box>

        <Box>
          <TextField fullWidth label="保険証券番号" {...register('insuranceNumber')} />
        </Box>

        <Box>
          <TextField fullWidth label="加入しているペット保険" {...register('insurance')} />
        </Box>

        <Box>
          <TextField fullWidth label="普段食べているフード" {...register('food')} />
        </Box>

        <Box>
          <TextField fullWidth label="マイクロチップ番号" {...register('microChip')} />
        </Box>

        <Box>
          <TextField
            fullWidth
            multiline
            maxRows={2}
            sx={{ minHeight: '50px', justifyContent: 'center' }}
            label="メモ"
            {...register('note')}
          />
        </Box>
      </Stack>

      <Box my={4}>
        <Typography variant="body2" textAlign="center">
          登録情報は動物病院等（過去に会員登録もしくは予約をしたことがある動物病院等）及び本サービスを運営する株式会社TYLで共有します
        </Typography>
      </Box>

      <Box>
        <Button type="submit" variant="contained" color="primary" fullWidth>
          保存
        </Button>
      </Box>

      {props.onCancel && (
        <Box my={4} textAlign="center">
          <Button onClick={props.onCancel} variant="contained" color="secondary">
            保存せずに戻る
          </Button>
        </Box>
      )}
    </Box>
  );
};
