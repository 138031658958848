import { Step, StepLabel, Stepper } from '@mui/material';
import type { AppointmentStep } from './AppointmentForm';

interface Props {
  step: AppointmentStep;
}

const steps = ['ペット選択', 'サービス選択', '日時選択', '問診入力'];

export const AppointmentFormHeader = (props: Props) => {
  return (
    <Stepper activeStep={props.step} alternativeLabel>
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel sx={{ whiteSpace: 'nowrap' }}>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};
