import { Backdrop, CircularProgress } from '@mui/material';
import { Fragment, useCallback, useState } from 'react';
import { useMutation } from 'urql';
import { enqueueSnackbar } from 'notistack';
import { Component } from '../component';
import { CreateNewClinicAgreementDocument } from 'graphql/generated/staff/graphql';

export const useAgreementEditDialog = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [result, create] = useMutation(CreateNewClinicAgreementDocument);

  const openDialog = useCallback(() => setOpen(true), [setOpen]);

  const onSubmit = useCallback(
    async (text: string) => {
      try {
        await create({ text });
        enqueueSnackbar('新しい利用規約を作成しました', { variant: 'success' });
        setOpen(false);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        enqueueSnackbar('利用規約の作成に失敗しました', { variant: 'error' });
      }
    },
    [create]
  );

  const onCancel = useCallback(() => setOpen(false), [setOpen]);

  const render = ({ text }: { text: string }) => (
    <Fragment key={text}>
      <Component defaultText={text} open={open} onSubmit={onSubmit} onCancel={onCancel} />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }}
        open={result.fetching}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Fragment>
  );

  return {
    open: openDialog,
    render,
  };
};
