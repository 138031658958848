import type { FC } from 'react';
import { useEffect, useState } from 'react';
import {
  Backdrop,
  Breadcrumbs,
  CircularProgress,
  Container,
  Link,
  Toolbar,
  Typography,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useMutation, useQuery } from 'urql';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { startOfMonth, endOfMonth, isSameDay } from 'date-fns';
import * as pageTitles from 'constants/pageTitles/staff';
import type {
  ResourceHolidaysQuery,
  ResourceHolidaysQueryVariables,
} from 'graphql/generated/staff/graphql';
import {
  DeleteResourceHolidayDocument,
  CreateResourceHolidayDocument,
  ResourceHolidaysDocument,
  ResourceDetailFragmentDoc,
} from 'graphql/generated/staff/graphql';
import * as paths from 'constants/paths/staff';
import NotFoundPage from 'pages/NotFoundPage';
import FullScreenLoading from 'components/FullScreenLoading';
import { useFragment } from 'graphql/generated/staff';
import StaffScheduleTabs from 'components/StaffScheduleTabs';
import { StaffHolidayCalendar } from 'components/calendars/staff/StaffHolidayCalendar/StaffHolidayCalendar';

const Page: FC = () => {
  const { id } = useParams() as { id: string };
  const [month, setMonth] = useState(startOfMonth(new Date()));
  const [dates, setDates] = useState<Date[]>([]);
  const [{ data, fetching, error }] = useQuery<
    ResourceHolidaysQuery,
    ResourceHolidaysQueryVariables
  >({
    query: ResourceHolidaysDocument,
    requestPolicy: 'network-only',
    variables: {
      id,
      from: startOfMonth(month).toISOString(),
      to: endOfMonth(month).toISOString(),
    },
  });

  const [createResourceHolidayResult, createResourceHoliday] = useMutation(
    CreateResourceHolidayDocument
  );
  const [deleteResourceHolidayResult, deleteResourceHoliday] = useMutation(
    DeleteResourceHolidayDocument
  );

  const resourceHolidays = data?.resource?.holidays?.map((h) => ({ ...h, date: new Date(h.date) }));
  const resource = useFragment(ResourceDetailFragmentDoc, data?.resource);
  const holidays = data?.viewer?.clinic?.holidays;

  useEffect(() => {
    if (!resourceHolidays) return;
    const dates = resourceHolidays.map((h) => h.date);
    setDates(dates);
  }, [data?.resource?.holidays]);

  const handleClickDay = async (date: Date) => {
    if (!resourceHolidays) return;
    const found = resourceHolidays?.find((h) => isSameDay(h.date, date));
    if (found) {
      await deleteResourceHoliday({ id: found.id });
      setDates(dates.filter((d) => !isSameDay(d, date)));
    } else {
      await createResourceHoliday({
        input: {
          date: date.toISOString(),
          resourceId: id,
        },
      });
      setDates([...dates, date]);
    }
  };

  if (fetching && !resource) {
    return <FullScreenLoading />;
  }

  if (error?.networkError) {
    throw error;
  }

  if (!resource || !resource || !resourceHolidays || !holidays) {
    return <NotFoundPage />;
  }

  const mutating = createResourceHolidayResult.fetching || deleteResourceHolidayResult.fetching;

  return (
    <>
      <Helmet>
        <title>
          {resource.name} - {pageTitles.resourceHolidays}
        </title>
      </Helmet>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={fetching || mutating}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Toolbar>
        <Container sx={{ display: 'flex' }}>
          <Breadcrumbs sx={{ flexGrow: 1 }}>
            <Link
              component={RouterLink}
              to={paths.resourcesPath()}
              color="text.secondary"
              underline="hover"
            >
              {pageTitles.resources}
            </Link>
            <Link
              component={RouterLink}
              to={paths.resourceDetailPath(resource.id)}
              color="text.secondary"
              underline="hover"
            >
              {resource.name}
            </Link>
            <Typography color="text.primary">{pageTitles.resourceHolidays}</Typography>
          </Breadcrumbs>
        </Container>
      </Toolbar>
      <Container>
        <StaffScheduleTabs resourceId={resource.id} />
        <StaffHolidayCalendar
          clinicHolidays={holidays}
          activeStartDate={new Date()}
          staffHolidays={dates}
          onClickDay={handleClickDay}
          onChangeMonth={setMonth}
        />
      </Container>
    </>
  );
};

export default Page;
