import { useMemo, useCallback, useState, useEffect } from 'react';
import { useMutation, useQuery } from 'urql';
import { enqueueSnackbar } from 'notistack';
import { Backdrop, CircularProgress } from '@mui/material';
import { Component } from '../component';
import type { Props } from '../types';
import { useOptionData } from './useOptionData';
import { useFragment } from 'graphql/generated/user';
import type { CreateAppointmentInput, CreateGuestUserInput } from 'graphql/generated/staff/graphql';
import {
  AppointmentDetailDocument,
  UpdateAppointmentDocument,
  AppointmentDetailFragmentDoc,
} from 'graphql/generated/staff/graphql';

export const useEditAppointmentDialog = ({ onSave, ...params }: Props = {}) => {
  const [id, setId] = useState('');
  const [{ data, fetching }] = useQuery({
    query: AppointmentDetailDocument,
    variables: { id },
    pause: !id,
    requestPolicy: 'network-only',
  });
  const [open, setOpen] = useState(false);
  const [{ fetching: fetchingOptionData, data: optionData }] = useOptionData();
  const handleCancel = useCallback(() => {
    setOpen(false);
    params.onCancel?.();
  }, [setOpen, params.onCancel]);

  const appointment = useMemo(
    () => useFragment(AppointmentDetailFragmentDoc, data?.appointment),
    [data?.appointment]
  );
  useEffect(() => {
    if (!open) {
      setId('');
    }
  }, [open]);
  const [updateAppointmentResult, updateAppointment] = useMutation(UpdateAppointmentDocument);
  const onSubmit = useCallback(
    async (values: CreateAppointmentInput) => {
      if (!appointment) return;
      const input = {
        ...values,
        guestUser: values.guestUser && {
          ...values.guestUser,
          id: appointment.guestUser?.id ?? '',
        },
        id: appointment.id,
      };
      for (const key in input) {
        const value = input[key as keyof CreateAppointmentInput];
        if (!value) {
          delete input[key as keyof CreateAppointmentInput];
        }
      }
      for (const key in input?.guestUser) {
        const value = input?.guestUser[key as keyof CreateGuestUserInput];
        if (!value) {
          delete input?.guestUser[key as keyof CreateGuestUserInput];
        }
      }
      try {
        const res = await updateAppointment({ input });
        const appointment = useFragment(AppointmentDetailFragmentDoc, res.data?.updateAppointment);
        if (!appointment) {
          throw new Error('予約の更新に失敗しました');
        }
        onSave?.(appointment);
        enqueueSnackbar('予約を更新しました', { variant: 'success' });
        setOpen(false);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        enqueueSnackbar('予約の更新に失敗しました', { variant: 'error' });
      }
    },
    [onSave]
  );

  const render = () => (
    <>
      {!fetching && appointment && optionData && (
        <Component
          key={appointment.id}
          {...params}
          open={open}
          optionData={optionData}
          appointment={appointment}
          onSubmit={onSubmit}
          onCancel={handleCancel}
        />
      )}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }}
        open={open && (updateAppointmentResult.fetching || fetching || fetchingOptionData)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );

  const openDialog = useCallback(
    (id: string) => {
      setId(id);
      setOpen(true);
    },
    [setOpen]
  );

  return {
    open: openDialog,
    render,
  };
};
