import { Stack, Paper, Typography } from '@mui/material';
import type { FC } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import * as validationRules from '../../validationRules';
import SecureInput from 'components/forms/SecureInput';
import type { ConfirmPasswordResetParams } from 'clients/apis/staff';

interface Props {
  onSubmit?: (values: ConfirmPasswordResetParams) => void;
  requesting?: boolean;
  error?: string;
  token: string;
}

export const Component: FC<Props> = ({
  token,
  onSubmit = () => {},
  requesting = false,
  error = '',
}) => {
  const { handleSubmit, control, watch } = useForm<
    ConfirmPasswordResetParams & {
      passwordConfirmation: string;
    }
  >({
    mode: 'onTouched',
  });

  return (
    <Paper sx={{ p: 5 }}>
      <Stack
        component="form"
        onSubmit={handleSubmit(({ password }) => onSubmit({ password, token }))}
        gap={2}
      >
        <Typography variant="h5" component="h2">
          パスワード再設定
        </Typography>
        <Typography variant="body2">
          新しいパスワードを入力して変更ボタンを押して下さい。
        </Typography>
        <Controller
          control={control}
          name="password"
          rules={{
            ...validationRules.password,
            required: true,
          }}
          render={({ field, fieldState }) => (
            <SecureInput
              {...field}
              type="password"
              label="パスワード"
              variant="outlined"
              fullWidth
              required
              error={!!fieldState.error || !!error}
              helperText={fieldState.error?.message}
              disabled={requesting}
            />
          )}
        />
        <Controller
          control={control}
          name="passwordConfirmation"
          rules={{
            validate: (value) =>
              value === watch('password') ? true : 'パスワードが一致しません。',
          }}
          render={({ field, fieldState }) => (
            <SecureInput
              {...field}
              type="password"
              label="パスワード (確認)"
              variant="outlined"
              fullWidth
              required
              error={!!fieldState.error || !!error}
              helperText={fieldState.error?.message}
              disabled={requesting}
            />
          )}
        />
        {error && (
          <Typography variant="body2" color="error">
            {error}
          </Typography>
        )}
        <LoadingButton
          type="submit"
          variant="contained"
          loading={requesting}
          color="primary"
          size="large"
        >
          変更
        </LoadingButton>
      </Stack>
    </Paper>
  );
};
