import { Box, Button, Divider } from '@mui/material';
import { useCallback } from 'react';
import { PetDetailView } from 'components/views/user/PetDetailView/PetDetailView';
import type { Pet, RegisterConfirmDetailQuery } from 'graphql/generated/user/graphql';

interface Props {
  pet: RegisterConfirmDetailQuery['viewer']['pets'][number];
  onEditPet: (pet: Pick<Pet, 'id' | 'name'>) => void;
  onDeletePet: (pet: Pick<Pet, 'id' | 'name'>) => void;
}

export const PetItem = (props: Props) => {
  const { pet } = props;

  const onDeletePet = useCallback(() => {
    props.onDeletePet({ id: pet.id, name: pet.name });
  }, [props.onDeletePet, pet.id]);

  const onEditPet = useCallback(() => {
    props.onEditPet({ id: pet.id, name: pet.name });
  }, [props.onDeletePet, pet.id]);

  return (
    <Box key={pet.id} sx={{ width: '100%' }}>
      <PetDetailView pet={pet} />
      <Box sx={{ display: 'flex', pt: 2, pb: 4, justifyContent: 'space-evenly' }}>
        <Button variant="contained" sx={{ width: '40%' }} color="info" onClick={onEditPet}>
          修正
        </Button>
        <Button sx={{ width: '40%' }} variant="contained" color="error" onClick={onDeletePet}>
          削除
        </Button>
      </Box>
      <Divider />
    </Box>
  );
};
