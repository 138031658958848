import type { FC } from 'react';
import { useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useMutation } from 'urql';
import { enqueueSnackbar } from 'notistack';
import { Helmet } from 'react-helmet-async';
import { ConfirmPasswordResetDocument } from 'graphql/generated/user/graphql';
import { PasswordResetForm } from 'components/forms/user/PasswordResetForm/PasswordResetForm';
import type { PasswordResetFormInput } from 'components/forms/user/PasswordResetForm/hooks/usePasswordResetForm';
import * as paths from 'constants/paths/user';
import * as pageTitles from 'constants/pageTitles/user';

const Page: FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  // eslint-disable-next-line no-console
  const [_passwordConfirmResult, passwordConfirm] = useMutation(ConfirmPasswordResetDocument);

  const onSubmit = useCallback(
    async (body: PasswordResetFormInput) => {
      try {
        if (!token) {
          throw new Error('トークンが無効です');
        }
        const result = await passwordConfirm({
          input: {
            newPassword: body?.password,
            confirmationToken: token,
          },
        });

        if (result.error) {
          throw new Error('パスワードの更新に失敗しました');
        }

        enqueueSnackbar('パスワードの更新に成功しました', { variant: 'success' });
        navigate(paths.successConfirmPasswordResetPath());
      } catch (e: any) {
        enqueueSnackbar(e.message, { variant: 'error' });
        // eslint-disable-next-line no-console
        console.error(e);
      }
    },
    [token]
  );
  return (
    <>
      <Helmet>
        <title>{pageTitles.confirmPasswordReset}</title>
      </Helmet>
      <PasswordResetForm onSubmit={onSubmit} />
    </>
  );
};

export default Page;
