import type { FC } from 'react';
import { Stack, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { grey } from '@mui/material/colors';
import type { AppointmentDetailFragment, AppointmentStatus } from 'graphql/generated/staff/graphql';
import AppointmentStatusChip from 'components/appointments/AppointmentStatusChip';

interface Props {
  appointment: AppointmentDetailFragment;
  onChangeStatus: (status: AppointmentStatus) => void;
}

const InfoTable: FC<Props> = ({ appointment, onChangeStatus }) => {
  const rows = [
    ['予約 ID', appointment.id],
    ['受付日時', new Date(appointment.createdAt).toLocaleString('ja-JP')],
    [
      'ステータス',
      <AppointmentStatusChip
        status={appointment.status}
        key={`appointment-status-${appointment.id}-${appointment.status}`}
        onChangeStatus={onChangeStatus}
      />,
    ],
    ['診察券番号', appointment.clinicUser?.patientNumber ?? appointment.clinicPet?.patientNumber],
    ['飼い主様名', appointment.clinicUser?.user?.name],
    ['ペット名', appointment.clinicPet?.pet?.name],
    ['電話番号', appointment.clinicUser?.user?.phoneNumber],
    [
      '住所',
      [
        appointment.clinicUser?.user?.prefecture?.name,
        appointment.clinicUser?.user?.city,
        appointment.clinicUser?.user?.street,
        appointment.clinicUser?.user?.building,
      ]
        .filter((n) => !!n)
        .join(' '),
    ],
    ['メールアドレス', appointment.clinicUser?.user?.email],
    [
      '種類',
      [appointment.clinicPet?.pet?.animal?.name, appointment.clinicPet?.pet?.animalType?.name]
        .filter((n) => !!n)
        .join(' / '),
    ],
    ['部位・症状', appointment.symptom],
    ['発症時期', appointment.onset],
    ['病歴', appointment.history],
    ['備考', appointment.note],
  ].filter(([, value]) => !!value);

  return (
    <Stack>
      <TableContainer>
        <Table>
          <TableBody>
            {rows.map(([label, value], i) => (
              <TableRow key={`${label}-${i}`}>
                <TableCell sx={{ color: grey[600], whiteSpace: 'nowrap' }} align="right">
                  {label}
                </TableCell>
                <TableCell sx={{ pl: 0, whiteSpace: 'pre-wrap' }}>{value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default InfoTable;
