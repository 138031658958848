import { post } from '../methods';
import config from 'config';

export interface ResetPasswordParams {
  email: string;
}

interface ResetPasswordResponse {
  Success: boolean;
}

export const resetPassword = (params: ResetPasswordParams) =>
  post<ResetPasswordResponse>(config.endpoints.staffResetPassword, params);
