import { Button, styled } from '@mui/material';
import type { ChangeEvent } from 'react';
import { useCallback, useRef } from 'react';

interface Props {
  fetching?: boolean;
  onSelect: (file: File, image: string) => void;
}

const StyledButton = styled(Button)({
  width: '150px',
  backgroundColor: 'gray',
});

const createDataImageUrlFromFile = (file: File) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  return new Promise<string>((resolve, reject) => {
    reader.onload = () => {
      if (reader.result) {
        resolve(reader.result.toString());
      }
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });
};

export const UploadImageButton = (props: Props) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleButtonClick = useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, []);

  const selectFile = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const file = e.target.files[0];
        const imageUrl = await createDataImageUrlFromFile(file);
        props.onSelect(file, imageUrl);
      }
    },
    [props.onSelect]
  );

  return (
    <label>
      <input onChange={selectFile} ref={fileInputRef} type="file" accept="image/*" hidden />
      <StyledButton disabled={props.fetching} variant="contained" onClick={handleButtonClick}>
        写真アップロード
      </StyledButton>
    </label>
  );
};
