import { useMemo } from 'react';
import { Client } from 'urql';
import { useUserGraphQLContext } from '../useUserGraphQLContext';
import type { Pet } from 'graphql/generated/user/graphql';
import { PetProfileImageUploadUrlDocument } from 'graphql/generated/user/graphql';

export const useUpdatePetProfileImage = () => {
  const context = useUserGraphQLContext();
  const client = useMemo(() => new Client(context), [context]);
  return async (petId: Pet['id'], profileImageFile: File) => {
    const { data } = await client
      .query(PetProfileImageUploadUrlDocument, {
        petId: petId,
        type: profileImageFile.type.replace('image/', '').toUpperCase() as any,
        fileName: profileImageFile.name,
      })
      .toPromise();

    if (!data?.viewer.pet.profileImageUploadURL) {
      throw Error('profileImageUploadURLが取得できませんでした');
    }

    await fetch(data?.viewer.pet.profileImageUploadURL, {
      method: 'PUT',
      body: profileImageFile,
      headers: {
        'X-AMZ-ACL': 'public-read',
        'Content-Type': profileImageFile.type,
      },
    });

    const profileImageUrl = `${process.env.REACT_APP_S3_BUCKET}/users/${data.viewer.id}/pets/${petId}/profile-image/${profileImageFile.name}`;
    return profileImageUrl;
  };
};
