import { FormControl, FormLabel } from '@mui/material';
import { grey } from '@mui/material/colors';
import type { ReactNode, FC } from 'react';

interface Props {
  children: ReactNode;
  label: string;
  error?: boolean;
  required?: boolean;
}

const FieldSet: FC<Props> = ({ children, label, error = false, required = false }) => (
  <FormControl
    required={required}
    error={error}
    component="fieldset"
    sx={{ border: `1px solid ${grey[300]}`, px: 3, pt: 2, pb: 4, borderRadius: '10px' }}
  >
    <FormLabel error={error} component="legend" sx={{ ml: -1, px: 1, background: '#fff' }}>
      {label}
    </FormLabel>
    {children}
  </FormControl>
);

export default FieldSet;
