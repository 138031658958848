import { Button, Stack, TextField } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import type { UpdateClinicPetInput } from 'graphql/generated/staff/graphql';

type FormValues = Partial<UpdateClinicPetInput>;

interface Props {
  clinicPet?: FormValues | null;
  onChangeDirty?: (dirty: boolean) => void;
  onSubmit: (data: FormValues) => void;
}

export const ClinicPetForm = ({ onChangeDirty = () => {}, ...props }: Props) => {
  const { register, handleSubmit, formState } = useForm<FormValues>({
    defaultValues: props.clinicPet
      ? {
          note: props.clinicPet.note,
          patientNumber: props.clinicPet.patientNumber,
        }
      : {},
  });

  const onSubmit = useCallback(
    (data: FormValues) => {
      return props.onSubmit(data);
    },
    [props.onSubmit]
  );

  useEffect(() => {
    onChangeDirty(formState.isDirty);
  }, [formState.isDirty, onChangeDirty]);

  return (
    <Stack spacing={4} component="form" onSubmit={handleSubmit(onSubmit)} mt={2}>
      <TextField fullWidth label="診察券番号" variant="outlined" {...register('patientNumber')} />
      <TextField
        fullWidth
        label="メモ(飼い主には表示されません)"
        variant="outlined"
        multiline
        rows={6}
        {...register('note')}
      />
      <Button variant="contained" fullWidth type="submit">
        保存
      </Button>
    </Stack>
  );
};
