import { Box, Button, Stack, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import type {
  AppointmentFormOptionsQueryClinic,
  AppointmentFormOptionsQueryResource,
  AppointmentFormOptionsQueryService,
  AppointmentFormOptionsQueryServiceCategory,
} from '../AppointmentForm/types';
import { SelectDate } from '../SelectDate/SelectDate';
import { SelectResource } from '../SelectResource/SelectResource';
import type { Appointment } from 'graphql/generated/user/graphql';

interface Props {
  defaultResource?: AppointmentFormOptionsQueryResource | null;
  clinic: AppointmentFormOptionsQueryClinic;
  serviceCategory: AppointmentFormOptionsQueryServiceCategory;
  service: AppointmentFormOptionsQueryService;
  isStorybook?: boolean;
  onSelect: (
    resourceId: AppointmentFormOptionsQueryResource | null,
    startAt: Appointment['startAt']
  ) => void;
  onBack: () => void;
}

const determineDefaultResourceIfneeded = (props: Props) => {
  const resources = props.service.resources || [];
  if (props.defaultResource) {
    return resources.find((r) => r.id === props.defaultResource?.id) || null;
  }
  return null;
};

export const SelectDataAndStaff = (props: Props) => {
  const [selectedResource, setSelectedResource] =
    useState<AppointmentFormOptionsQueryResource | null>(determineDefaultResourceIfneeded(props));
  const isPetHotel = props.serviceCategory.name === 'ペットホテル・一時預かり';

  const onSelect = useCallback(
    (startAt: Appointment['startAt']) => {
      props.onSelect(selectedResource, startAt);
    },
    [props.onSelect, selectedResource]
  );

  const resources = props.service.resources || [];

  return (
    <Box>
      <Box my={2}>
        <Typography variant="h6" textAlign="center">
          担当者と日時を選択してください
        </Typography>
      </Box>
      <Stack spacing={3}>
        {!isPetHotel && (
          <SelectResource
            resources={resources}
            selectedResorce={selectedResource}
            onChange={setSelectedResource}
          />
        )}
        <SelectDate
          clinic={props.clinic}
          service={props.service}
          resource={resources.find((r) => r.id === selectedResource?.id) || null}
          onSelect={onSelect}
          isStorybook={props.isStorybook}
        />

        <Box display="flex" alignItems="center" justifyContent="center">
          <Button onClick={props.onBack} sx={{ px: 5, mt: 3 }} variant="contained" color="inherit">
            戻る
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};
