import type { FC } from 'react';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Toolbar, Container, Breadcrumbs, Typography } from '@mui/material';
import { useQuery } from 'urql';
import { useSearchParams } from 'react-router-dom';
import { endOfMonth, startOfMonth } from 'date-fns';
import * as pageTitles from 'constants/pageTitles/staff';
import type {
  ResourceSchedulesQuery,
  ResourceSchedulesQueryVariables,
} from 'graphql/generated/staff/graphql';
import { ResourceSchedulesDocument } from 'graphql/generated/staff/graphql';
import FullScreenLoading from 'components/FullScreenLoading';
import NotFoundPage from 'pages/NotFoundPage';
import SchedulesTable from 'components/tables/staff/SchedulesTable';
import { formatMonth, parseMonth } from 'helpers/date';

const Page: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const resourceKindID = searchParams.get('kind') || undefined;
  const [month, setMonth] = useState<Date>(
    parseMonth(searchParams.get('month') || '') || new Date()
  );
  const [{ data, fetching, error }] = useQuery<
    ResourceSchedulesQuery,
    ResourceSchedulesQueryVariables
  >({
    query: ResourceSchedulesDocument,
    variables: {
      from: startOfMonth(month).toISOString(),
      to: endOfMonth(month).toISOString(),
      resourceKindID,
    },
  });

  if (fetching) {
    return <FullScreenLoading />;
  }

  if (error?.networkError) {
    throw error;
  }

  if (!data) {
    return <NotFoundPage />;
  }

  return (
    <>
      <Helmet>
        <title>{pageTitles.schedules}</title>
      </Helmet>
      <Toolbar>
        <Container sx={{ display: 'flex' }}>
          <Breadcrumbs sx={{ flexGrow: 1 }}>
            <Typography color="text.primary">{pageTitles.schedules}</Typography>
          </Breadcrumbs>
        </Container>
      </Toolbar>
      <Container>
        <SchedulesTable
          data={data}
          month={month}
          kindId={resourceKindID}
          onChangeMonth={(month) => {
            setMonth(month);
            searchParams.set('month', formatMonth(month));
            setSearchParams(searchParams);
          }}
          onSelectKind={(id) => {
            searchParams.set('kind', id || '');
            setSearchParams(searchParams);
          }}
        />
      </Container>
    </>
  );
};

export default Page;
