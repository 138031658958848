import { toHiragana } from 'wanakana';

export const filterOptions = <T>(options: T[], keys: (keyof T)[], value: string) => {
  const kanaValue = toHiragana(value).toLowerCase();

  return options.filter((option) => {
    return keys.some((key) => {
      const strValue = toHiragana(String(option[key])).toLowerCase();
      return strValue.includes(kanaValue);
    });
  });
};
