import { addDays, addHours, endOfDay, endOfHour } from 'date-fns';

export const caluculateCancellationDeadline = (
  startAt: Date,
  amount: number,
  unit: 'days' | 'hours'
) => {
  if (amount === 0) {
    return new Date(startAt.getTime() - 1);
  }
  // if unit is days, returns end of the day before the startAt
  if (unit === 'days') {
    return endOfDay(addDays(startAt, -amount));
  }
  // if unit is hours, returns startAt - amount hours
  return endOfHour(addHours(startAt, -amount - 1));
};
