import { post } from '../methods';
import config from 'config';

export interface ConfirmPasswordResetParams {
  token: string;
  password: string;
}

interface ConfirmPasswordResetResponse {
  Success: boolean;
}

export const confirmPasswordReset = (params: ConfirmPasswordResetParams) =>
  post<ConfirmPasswordResetResponse>(config.endpoints.staffConfirmPasswordReset, params);
