import {
  Box,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import type { FC, ReactNode } from 'react';
import { useMemo, useState } from 'react';
import { Link as RouterLink, useLocation, useSearchParams } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import TodayIcon from '@mui/icons-material/Today';
import EventIcon from '@mui/icons-material/Event';
import SettingsIcon from '@mui/icons-material/Settings';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { addDays } from 'date-fns';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PeopleIcon from '@mui/icons-material/People';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PunchClockIcon from '@mui/icons-material/PunchClock';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import DrawerHeader from './components/DrawerHeader';
import Drawer from './components/Drawer';
import Calendar from './components/Calendar';
import CalendarListItem from './components/CalendarListItem';
import NavigationItem from './components/NavigationItem';
import Logo from 'graphics/Logo';
import * as paths from 'constants/paths/staff';
import type { CurrentStaffQuery } from 'graphql/generated/staff/graphql';
import { formatDate, parseDate } from 'helpers/date';
import * as pageTitles from 'constants/pageTitles/staff';

interface Props {
  children: ReactNode;
  currentStaff: CurrentStaffQuery['viewer'];
  appointmentCount: {
    today: number;
    tomorrow: number;
  } | null;
}

export const Component: FC<Props> = ({ children, currentStaff, appointmentCount }) => {
  const [params, setParam] = useSearchParams();
  const [open, setOpen] = useState(true);
  const location = useLocation();
  const [expandDates, setExpandDates] = useState(true);
  const paramDate = useMemo(() => params.get('date') || params.get('dateFrom'), [params]);
  const paramDateTo = useMemo(() => params.get('dateTo') || params.get('date'), [params]);
  const defaultDate = useMemo(() => parseDate(paramDate || '') || new Date(), [paramDate]);

  const fadeItemProps = { opacity: open ? 1 : 0, transition: 'opacity 0.2s ease-out' };

  return (
    <Box sx={{ display: 'flex' }}>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <Box sx={{ width: '100%', ...fadeItemProps }}>
            <RouterLink to={paths.rootPath()}>
              <Logo />
            </RouterLink>
          </Box>
          <IconButton onClick={() => setOpen(!open)}>
            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Box
          sx={{
            height: open ? '260px' : 0,
            transition: 'height 0.2s ease-out',
            overflow: 'hidden',
          }}
        >
          <Calendar
            key={defaultDate.toLocaleDateString()}
            onSelectDate={(date) => {
              if (location.pathname === paths.appointmentsPath()) {
                setParam({ dateFrom: formatDate(date), dateTo: formatDate(date) });
                return;
              }
              setParam({ date: formatDate(date) });
            }}
            minDate={new Date(currentStaff.clinic?.createdAt)}
            defaultDate={defaultDate}
          />
        </Box>
        <Divider sx={fadeItemProps} />
        <List>
          <Collapse in={open} timeout="auto">
            <ListItemButton onClick={() => setExpandDates(!expandDates)}>
              <ListItemIcon>
                <CalendarMonthIcon />
              </ListItemIcon>
              <ListItemText primary={pageTitles.calendar} />
              {expandDates ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </Collapse>
          <Collapse in={expandDates || !open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding={open}>
              <CalendarListItem
                date={new Date()}
                text="今日の予約"
                count={appointmentCount?.today ?? 0}
                Icon={TodayIcon}
                open={open}
              />
              <CalendarListItem
                date={addDays(new Date(), 1)}
                text="明日の予約"
                count={appointmentCount?.tomorrow ?? 0}
                Icon={EventIcon}
                open={open}
              />
            </List>
          </Collapse>
          <NavigationItem
            text={pageTitles.appointments}
            to={
              paths.appointmentsPath() +
              (paramDate ? `?dateFrom=${paramDate}&dateTo=${paramDateTo}` : '')
            }
            Icon={AssignmentIcon}
            open={open}
          />
          <NavigationItem
            text={pageTitles.users}
            to={paths.usersPath()}
            Icon={PeopleIcon}
            open={open}
          />
          <NavigationItem
            text={pageTitles.resources}
            to={paths.resourcesPath()}
            Icon={ManageAccountsIcon}
            open={open}
          />
          <NavigationItem
            text={pageTitles.schedules}
            to={paths.schedulesPath()}
            Icon={PunchClockIcon}
            open={open}
          />
          <NavigationItem
            text={pageTitles.settings}
            to={paths.settingsPath()}
            Icon={SettingsIcon}
            open={open}
            sx={{ display: 'none' }}
          />
        </List>
        <Box sx={{ marginTop: 'auto' }}>
          <Divider />
          <List>
            <NavigationItem
              text={pageTitles.logout}
              to={paths.logoutPath()}
              Icon={ExitToAppIcon}
              open={open}
            />
          </List>
        </Box>
      </Drawer>
      <Box sx={{ width: '100%' }}>{children}</Box>
    </Box>
  );
};
