import { Drawer as MuiDrawer, styled } from '@mui/material';
import { closedMixin, drawerWidth, openedMixin, paperMixin } from '../mixins';

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': {
        ...openedMixin(theme),
        ...paperMixin,
      },
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': {
        ...closedMixin(theme),
        ...paperMixin,
      },
    }),
  })
);

export default Drawer;
