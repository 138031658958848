import { Toolbar, Container, Breadcrumbs, Typography, Button } from '@mui/material';
import type { FC } from 'react';
import { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSearchParams } from 'react-router-dom';
import { startOfDay, endOfDay, setHours, setMinutes, isBefore, addMilliseconds } from 'date-fns';
import { useQuery } from 'urql';
import * as pageTitles from 'constants/pageTitles/staff';
import {
  AppointmentsFilterOptionsDocument,
  AppointmentsFragmentDoc,
  AppointmentsDocument,
} from 'graphql/generated/staff/graphql';
import type {
  AppointmentStatus,
  AppointmentsFilterOptionsQuery,
  AppointmentsFragment,
  AppointmentsQuery,
  AppointmentsQueryVariables,
} from 'graphql/generated/staff/graphql';
import { formatDate, parseDate } from 'helpers/date';
import FullScreenLoading from 'components/FullScreenLoading';
import { useFragment } from 'graphql/generated/staff';
import NotFoundPage from 'pages/NotFoundPage';
import {
  useEditAppointmentDialog,
  useNewAppointmentDialog,
} from 'components/dialogs/staff/AppointmentDialog';
import AppointmentsFilter from 'components/appointments/staff/AppointmentsFilter';
import { useUpdateAppointmentStatus } from 'hooks/staff/useUpdateAppointmentStatus';
import { AppointmentsTable } from 'components/appointments/staff/AppointmentsTable/AppointmentsTable';

const Page: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const timeoutId = useRef<number | null>(null);
  const [filterChanged, setFilterChanged] = useState(false);
  const [offset, setOffset] = useState(0);
  const [fetchInitialzied, setFetchInitialized] = useState(false);
  const [updatedSince, setUpdatedSince] = useState<Date | null>(null);
  const [appointments, setAppointments] = useState<AppointmentsFragment['appointments']['nodes']>(
    []
  );
  const editAppointmentDialog = useEditAppointmentDialog({
    onSave: () => refetch({ requestPolicy: 'network-only' }),
  });
  const newAppointmentDialog = useNewAppointmentDialog({
    onSave: () => refetch({ requestPolicy: 'network-only' }),
  });
  const dateFrom = useMemo(
    () => parseDate(searchParams.get('dateFrom') || '') || new Date(),
    [searchParams]
  );
  const dateTo = useMemo(
    () => parseDate(searchParams.get('dateTo') || '') || new Date(),
    [searchParams]
  );

  const [, updateStatus] = useUpdateAppointmentStatus();

  const defaultValues = useMemo(() => {
    const status = (searchParams.get('status') as AppointmentStatus) || undefined;
    const resourceId = searchParams.get('resourceId') || undefined;
    const serviceId = searchParams.get('serviceId') || undefined;
    const serviceCategoryId = searchParams.get('serviceCategoryId') || undefined;
    return {
      status,
      resourceId,
      serviceId,
      serviceCategoryId,
      dateFrom: formatDate(dateFrom),
      dateTo: formatDate(dateTo),
    };
  }, [searchParams, dateFrom, dateTo]);

  useEffect(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
      timeoutId.current = null;
    }
    setFetchInitialized(false);
    setUpdatedSince(null);
    setOffset(0);
    setAppointments([]);
    setFilterChanged(true);
  }, [defaultValues]);

  const title = useMemo(() => {
    const locale = 'ja-JP';
    const to = dateTo.toLocaleDateString(locale);
    const from = dateFrom.toLocaleDateString(locale);
    if (to === from) {
      return to;
    }
    return `${from} - ${to}`;
  }, [searchParams]);

  const [{ data, fetching }, refetch] = useQuery<AppointmentsQuery, AppointmentsQueryVariables>({
    query: AppointmentsDocument,
    requestPolicy: 'network-only',
    variables: {
      from: startOfDay(dateFrom).toISOString(),
      to: endOfDay(dateTo).toISOString(),
      statuses: defaultValues.status ? [defaultValues.status] : undefined,
      resourceId: defaultValues.resourceId,
      serviceId: defaultValues.serviceId,
      serviceCategoryId: defaultValues.serviceCategoryId,
      updatedSince: updatedSince?.toISOString(),
      offset,
    },
  });

  const [{ data: optionsData, fetching: fetchingOptionsData }] =
    useQuery<AppointmentsFilterOptionsQuery>({
      query: AppointmentsFilterOptionsDocument,
    });

  useEffect(() => {
    if (filterChanged && !fetching) {
      setFilterChanged(false);
    }
  }, [filterChanged, fetching]);

  const scheduleRefetch = useCallback(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }

    timeoutId.current = window.setTimeout(() => {
      refetch({ requestPolicy: 'network-only' });
    }, 10000);
  }, [refetch]);

  useEffect(() => {
    const appointmentsData = useFragment(AppointmentsFragmentDoc, data);
    if (fetching || !appointmentsData) {
      return;
    }

    const ids = appointmentsData.appointmentTimestamps.map((a) => a.id);
    const appointmentNodes = appointmentsData.appointments.nodes;
    if (!appointmentNodes) {
      return;
    }

    let newAppointments = [...appointments];

    appointmentNodes.forEach((appointment) => {
      const index = newAppointments.findIndex((a) => a.id === appointment.id);
      if (index === -1) {
        newAppointments.push(appointment);
      } else {
        newAppointments[index] = appointment;
      }
    });

    newAppointments = newAppointments.filter((a) => ids.includes(a.id));
    setAppointments(newAppointments);
    if (appointmentNodes.length === 50) {
      setOffset(offset + 50);
      return;
    }
    setOffset(0);
    const last = appointments
      .map((a) => new Date(a.updatedAt))
      .sort((a, b) => (isBefore(b, a) ? -1 : 1))[0];
    setUpdatedSince(last ? addMilliseconds(last, 1) : null);
    scheduleRefetch();
    setFetchInitialized(true);
  }, [fetching, data]);

  useEffect(() => {
    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, []);

  if (!fetchInitialzied || ((fetching || fetchingOptionsData) && (!optionsData || !appointments))) {
    return <FullScreenLoading />;
  }

  if (!optionsData || !appointments) {
    return <NotFoundPage />;
  }

  return (
    <div>
      <Helmet>
        <title>
          {pageTitles.appointments} - {title}
        </title>
      </Helmet>
      <Toolbar>
        <Container sx={{ display: 'flex', gap: 2 }}>
          <Breadcrumbs sx={{ flexGrow: 1 }}>
            <Typography color="text.primary">{pageTitles.appointments}</Typography>
            <Typography color="text.primary">{title}</Typography>
          </Breadcrumbs>
          <Button
            variant="outlined"
            onClick={() => {
              window.print();
            }}
            color="secondary"
          >
            印刷
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              newAppointmentDialog.open({
                startAt: setHours(dateFrom, 10).toISOString(),
                endAt: setMinutes(setHours(dateFrom, 10), 15).toISOString(),
              });
            }}
            color="primary"
          >
            新規予約
          </Button>
        </Container>
      </Toolbar>
      <Container>
        <AppointmentsFilter
          key={searchParams.toString()}
          optionsData={optionsData}
          defaultValues={defaultValues}
          onSubmit={(values) => {
            const newParams = new URLSearchParams();
            if (values.status) {
              newParams.set('status', values.status);
            }
            if (values.resourceId) {
              newParams.set('resourceId', values.resourceId);
            }
            if (values.serviceId) {
              newParams.set('serviceId', values.serviceId);
            }
            if (values.serviceCategoryId) {
              newParams.set('serviceCategoryId', values.serviceCategoryId);
            }
            if (values.dateFrom) {
              newParams.set('dateFrom', values.dateFrom);
            }
            if (values.dateTo) {
              newParams.set('dateTo', values.dateTo);
            }
            setSearchParams(newParams);
          }}
        />
        {filterChanged ? (
          <FullScreenLoading />
        ) : (
          <AppointmentsTable
            appointments={appointments.sort((a, b) =>
              isBefore(new Date(a.startAt), new Date(b.startAt)) ? -1 : 1
            )}
            onSelectAppointment={(id) => {
              editAppointmentDialog.open(id);
            }}
            onChangeStatus={(id, status) => {
              updateStatus(id, status);
            }}
          />
        )}
      </Container>
      {editAppointmentDialog.render()}
      {newAppointmentDialog.render()}
    </div>
  );
};

export default Page;
