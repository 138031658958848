import { Stack, TextField, Link, Box, Paper, Typography } from '@mui/material';
import type { FC } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import * as validationRules from '../../validationRules';
import type { ResetPasswordParams } from 'clients/apis/staff';
import * as paths from 'constants/paths/staff';

interface Props {
  onSubmit?: (values: ResetPasswordParams) => void;
  requesting?: boolean;
  error?: string;
}

export const Component: FC<Props> = ({ onSubmit = () => {}, requesting = false, error = '' }) => {
  const { handleSubmit, control } = useForm<ResetPasswordParams>();

  return (
    <Paper sx={{ p: 5 }}>
      <Stack component="form" onSubmit={handleSubmit(onSubmit)} gap={2}>
        <Typography variant="h5" component="h2">
          パスワード再設定
        </Typography>
        <Typography variant="body2">
          パスワードを忘れた場合は、登録したメールアドレスを入力してください。
          <br />
          パスワード再設定用のメールが送信されます。
        </Typography>
        <Controller
          control={control}
          name="email"
          rules={{
            ...validationRules.email,
            required: true,
          }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              type="email"
              label="メールアドレス"
              variant="outlined"
              fullWidth
              required
              error={!!fieldState.error || !!error}
              helperText={fieldState.error?.message}
              disabled={requesting}
            />
          )}
        />
        {error && (
          <Typography variant="body2" color="error">
            {error}
          </Typography>
        )}
        <LoadingButton
          type="submit"
          variant="contained"
          loading={requesting}
          color="primary"
          size="large"
        >
          パスワード再設定用メールを送信
        </LoadingButton>
        <Typography variant="body2" color="textSecondary" align="center">
          上記アドレスに再設定用のメールを送信します。
        </Typography>
        <Box sx={{ mt: 5 }}>
          <Link component={RouterLink} to={paths.loginPath()}>
            ログイン画面に戻る
          </Link>
        </Box>
      </Stack>
    </Paper>
  );
};
