import { useCallback, useState } from 'react';
import { useMutation } from 'urql';
import { enqueueSnackbar } from 'notistack';
import { Backdrop, CircularProgress } from '@mui/material';
import type { Props, FormValues } from '../types';
import { Component } from '../component';
import { useOptionData } from './useOptionData';
import type {
  AppointmentDetailFragment,
  CreateAppointmentInput,
} from 'graphql/generated/staff/graphql';
import {
  AppointmentDetailFragmentDoc,
  CreateAppointmentDocument,
} from 'graphql/generated/staff/graphql';
import { useFragment } from 'graphql/generated/staff';

export const useNewAppointmentDialog = ({ onSave, ...params }: Props = {}) => {
  const [defaultValues, setDefaultValues] = useState<FormValues>();
  const [createAppointmentResult, createAppointment] = useMutation(CreateAppointmentDocument);
  const [open, setOpen] = useState(false);
  const [{ fetching: fetchingOptionData, data: optionData }] = useOptionData();

  const handleCancel = useCallback(() => {
    setOpen(false);
    params.onCancel?.();
  }, [setOpen, params.onCancel]);

  const onSubmit = useCallback(
    async (input: CreateAppointmentInput) => {
      try {
        const res = await createAppointment({ input });
        const appointment = useFragment(AppointmentDetailFragmentDoc, res.data?.createAppointment);
        if (!appointment) {
          throw new Error('予約の作成に失敗しました');
        }
        onSave?.(appointment);
        enqueueSnackbar('予約を作成しました', { variant: 'success' });
        setOpen(false);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        enqueueSnackbar('予約の作成に失敗しました', { variant: 'error' });
      }
    },
    [onSave]
  );

  const render = () => (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }}
        open={open && (createAppointmentResult.fetching || fetchingOptionData)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {optionData && (
        <Component
          {...params}
          optionData={optionData}
          open={open}
          onSubmit={onSubmit}
          onCancel={handleCancel}
          defaultValues={defaultValues}
        />
      )}
    </>
  );

  const openDialog = useCallback(
    (defaultValues: FormValues) => {
      setDefaultValues(defaultValues);
      setOpen(true);
      onSave?.({} as AppointmentDetailFragment);
    },
    [setOpen, setDefaultValues]
  );

  return {
    open: openDialog,
    render,
  };
};
