import { Backdrop, CircularProgress } from '@mui/material';
import { useCallback, useState } from 'react';
import { useMutation } from 'urql';
import { enqueueSnackbar } from 'notistack';
import { Component } from '../component';
import type { UpdateClinicUserInput } from 'graphql/generated/staff/graphql';
import { UpdateClinicUserDocument } from 'graphql/generated/staff/graphql';

export const useClinicUserDialog = (props?: Partial<UpdateClinicUserInput> | null) => {
  const [open, setOpen] = useState<boolean>(false);
  const [updateClinicUserResult, updateClinicUser] = useMutation(UpdateClinicUserDocument);

  const openDialog = useCallback(() => setOpen(true), [setOpen]);

  const onSubmit = useCallback(
    async (input: Pick<UpdateClinicUserInput, 'marked' | 'note' | 'patientNumber'>) => {
      if (props?.id) {
        try {
          const clinicUser = await updateClinicUser({
            input: {
              id: props.id,
              ...input,
            },
          });

          if (clinicUser.error) {
            throw new Error('ユーザー情報の更新に失敗しました');
          }
          enqueueSnackbar('ユーザー情報を更新しました', { variant: 'success' });
          setOpen(false);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
          enqueueSnackbar('ユーザー情報の更新に失敗しました', { variant: 'error' });
        }
      }
    },
    [props, updateClinicUser]
  );

  const onCancel = useCallback(() => setOpen(false), [setOpen]);

  const render = () => (
    <>
      <Component clinicUser={props} open={open} onSubmit={onSubmit} onCancel={onCancel} />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }}
        open={updateClinicUserResult.fetching}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );

  return {
    open: openDialog,
    render,
  };
};
