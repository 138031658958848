import { Box, Typography } from '@mui/material';
import type { FC } from 'react';
import { useCallback } from 'react';
import { useMutation, useQuery } from 'urql';
import { useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { Helmet } from 'react-helmet-async';
import { UserForm } from 'components/forms/user/UserForm';
import type { UpdateViewerInput } from 'graphql/generated/user/graphql';
import { UpdateViewerDocument, UserFormDocument } from 'graphql/generated/user/graphql';
import * as paths from 'constants/paths/user';
import { useUpdateUserProfileImage } from 'hooks/user/forms/useUpdateUserProfileImage';
import FullScreenLoading from 'components/FullScreenLoading';
import * as pageTitles from 'constants/pageTitles/user';

const Page: FC = () => {
  const navigate = useNavigate();
  const updateProfileImage = useUpdateUserProfileImage();
  const [{ data, fetching }] = useQuery({
    query: UserFormDocument,
  });
  const [updateResult, update] = useMutation(UpdateViewerDocument);

  const onSubmit = useCallback(
    async (input: UpdateViewerInput, profileImageFile?: File) => {
      try {
        if (profileImageFile) {
          input.profileImageURL = await updateProfileImage(profileImageFile);
        }
        const res = await update({ input });
        if (res.error) {
          throw new Error('飼い主情報の更新に失敗しました');
        }
        enqueueSnackbar('飼い主情報を更新しました', { variant: 'success' });
        navigate(paths.ownerPath());
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        enqueueSnackbar('飼い主情報の更新に失敗しました', { variant: 'error' });
      }
    },
    [update]
  );

  const onCancel = useCallback(() => {
    navigate(paths.ownerPath());
  }, []);

  if (!data || fetching) {
    return <FullScreenLoading />;
  }

  return (
    <>
      <Helmet>
        <title>{pageTitles.ownerEdit}</title>
      </Helmet>
      <Typography variant="h5" textAlign="center">
        飼い主様情報更新
      </Typography>
      <Box mt={2}>
        <Typography variant="body2" sx={{ padding: 1, backgroundColor: 'lightgray' }}>
          病院からの連絡や健康診断結果の送付などで使用するため情報は正確に入力をお願いします
        </Typography>
      </Box>
      <UserForm
        fetching={updateResult.fetching}
        user={data.viewer}
        prefectures={data.prefectures}
        frequencies={data.frequencies}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </>
  );
};

export default Page;
