import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { parseISO } from 'date-fns';
import { AppointmentFormHeader } from './AppoinetmentFormHeader';
import { AppointmentFormContent } from './AppointmentFormContent';
import type { AppointmentFormOptionsQueryClinic } from './types';
import type { AppointmentValues } from './useAppointmentForm';
import type {
  AppointmentDetailQuery,
  AppointmentFormOptionsQuery,
  CreateAppointmentInput,
  Service,
} from 'graphql/generated/user/graphql';

interface Props {
  appointment?: AppointmentDetailQuery['appointment'];
  viewer: AppointmentFormOptionsQuery['viewer'];
  clinic: AppointmentFormOptionsQueryClinic;
  onNewPet: () => void;
  onEditPet: () => void;
  onFinish: () => void;
  onSubmit: (input: CreateAppointmentInput, onNext: () => void) => Promise<void>;
}

export enum AppointmentStep {
  SelectPet,
  SelectService,
  SelectStaffAndDate,
  Interview,
  Confirm,
  Complete,
}

const serviceFromId = (clinic: AppointmentFormOptionsQueryClinic, id?: Service['id']) => {
  return clinic.serviceCategories
    .find((sc) => sc.services.find((s) => s.id === id))
    ?.services.find((s) => s.id === id);
};

const createDefaultAppointmentValues = (
  viewer: AppointmentFormOptionsQuery['viewer'],
  clinic: AppointmentFormOptionsQueryClinic,
  appointment?: AppointmentDetailQuery['appointment']
): AppointmentValues | undefined => {
  if (!appointment) {
    return undefined;
  }
  return {
    isFirst: appointment.isFirst,
    startAt: parseISO(appointment.startAt),
    endAt: parseISO(appointment.endAt),
    history: appointment.history,
    note: appointment.note,
    onset: appointment.onset,
    symptom: appointment.symptom,
    pet: viewer.pets.find((p) => p.id === appointment.clinicPet.pet?.id),
    service: serviceFromId(clinic, appointment.service?.id),
    resource: serviceFromId(clinic, appointment.service?.id)?.resources.find(
      (r) => r.id === appointment.resource.id
    ),
  };
};

export const AppointmentForm = (props: Props) => {
  const [step, setStep] = useState<AppointmentStep>(
    props.appointment ? AppointmentStep.Confirm : AppointmentStep.SelectPet
  );

  useEffect(() => {
    scrollTo(0, 0);
  }, [step]);

  return (
    <Box my={2}>
      {step !== AppointmentStep.Complete && <AppointmentFormHeader step={step} />}
      <Box mt={4}>
        <AppointmentFormContent
          {...props}
          defaultValues={createDefaultAppointmentValues(
            props.viewer,
            props.clinic,
            props.appointment
          )}
          step={step}
          onSetStep={setStep}
          onEditPet={props.onEditPet}
          onNewPet={props.onNewPet}
          onFinish={props.onFinish}
        />
      </Box>
    </Box>
  );
};
