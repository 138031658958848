import type { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { Toolbar, Container, Breadcrumbs, Typography, Button } from '@mui/material';
import { useMutation, useQuery } from 'urql';
import { enqueueSnackbar } from 'notistack';
import { format } from 'date-fns';
import * as pageTitles from 'constants/pageTitles/staff';

import FullScreenLoading from 'components/FullScreenLoading';
import NotFoundPage from 'pages/NotFoundPage';
import SettingsForm from 'components/forms/staff/SettingsForm';
import type {
  ClinicDetailQueryVariables,
  ClinicDetailQuery,
  UpdateClinicMutationVariables,
  UpdateClinicMutation,
} from 'graphql/generated/staff/graphql';
import { UpdateClinicDocument, ClinicDetailDocument } from 'graphql/generated/staff/graphql';
import { useAgreementEditDialog } from 'components/dialogs/staff/AgreementEditDialog/hooks/useAgreementEditDialog';

const Page: FC = () => {
  const [{ fetching, error, data }] = useQuery<ClinicDetailQuery, ClinicDetailQueryVariables>({
    query: ClinicDetailDocument,
  });

  const agreementEditDialog = useAgreementEditDialog();

  const [updateClinicResult, updateClinic] = useMutation<
    UpdateClinicMutation,
    UpdateClinicMutationVariables
  >(UpdateClinicDocument);

  const handleSubmit = async (input: UpdateClinicMutationVariables['input']) => {
    try {
      await updateClinic({ input });
      enqueueSnackbar('更新しました', { variant: 'success' });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      enqueueSnackbar('更新に失敗しました', { variant: 'error' });
    }
  };

  if (fetching) {
    return <FullScreenLoading />;
  }

  if (error?.networkError) {
    throw error;
  }

  if (!data?.viewer?.clinic || !data?.animals) {
    return <NotFoundPage />;
  }

  return (
    <>
      <Helmet>
        <title>{pageTitles.settings}</title>
      </Helmet>
      <Toolbar>
        <Container sx={{ display: 'flex' }}>
          <Breadcrumbs sx={{ flexGrow: 1 }}>
            <Typography color="text.primary">{pageTitles.settings}</Typography>
          </Breadcrumbs>
        </Container>
      </Toolbar>
      <Container>
        <SettingsForm
          clinic={data.viewer.clinic}
          animals={data.animals}
          onSubmit={handleSubmit}
          loading={updateClinicResult.fetching}
        />
        <Typography variant="h6" component="h2" sx={{ mt: 4, mb: 2 }}>
          利用規約
        </Typography>
        <Typography variant="body1" component="p">
          {data.viewer.clinic.latestAgreement
            ? `バージョン ${data.viewer.clinic.latestAgreement.version} (${format(
                new Date(data.viewer.clinic.latestAgreement.createdAt),
                'yyyy/MM/dd HH:mm'
              )} 更新)`
            : '未設定'}
          <Button variant="text" color="primary" onClick={agreementEditDialog.open}>
            編集
          </Button>
        </Typography>
      </Container>
      {agreementEditDialog.render({ text: data.viewer.clinic.latestAgreement?.text || '' })}
    </>
  );
};

export default Page;
