import PetsIcon from '@mui/icons-material/Pets';
import { Avatar, Box, Button, Paper, Radio, Stack, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useCallback } from 'react';
import type { AppointmentFormOptionsQueryPet } from '../AppointmentForm/types';
import { petGenders } from 'constants/petGender';
import { getAge } from 'helpers/date';

interface Props {
  pet: AppointmentFormOptionsQueryPet;
  checked: boolean;
  onChecked: (petInfo: Props['pet']) => void;
}

export const PetCard = (props: Props) => {
  const onChecked = useCallback(() => props.onChecked(props.pet), [props.onChecked, props.pet]);

  return (
    <Button
      onClick={onChecked}
      component={Paper}
      fullWidth
      sx={{
        border: props.checked ? '2px solid #1976d2' : '2px solid white',
      }}
    >
      <Box width="100%" display="flex" alignItems="center" justifyItems="start">
        <Radio
          onChange={onChecked}
          checked={props.checked}
          inputProps={{ 'aria-label': 'controlled' }}
        />
        <Box display="flex" alignItems="center">
          <Avatar
            sx={{ width: 48, height: 48, margin: '0 auto' }}
            src={props.pet.profileImageURL || ''}
          >
            <PetsIcon />
          </Avatar>
          <Box ml={2}>
            <Typography color={grey[900]}>{props.pet.name}</Typography>
            <Stack spacing={1} direction="row" alignItems="center">
              <Typography variant="caption" color={grey[700]}>
                {getAge(props.pet?.birthday, '歳')}
              </Typography>
              <Typography variant="caption" color={grey[700]}>
                {petGenders.find((p) => p.value === props.pet.gender)?.label}
              </Typography>
            </Stack>
          </Box>
        </Box>
      </Box>
    </Button>
  );
};
