import { Box, Button, Grid, Stack, Typography } from '@mui/material';

interface Props {
  onMypage: () => void;
}

export const AppointmentComplete = (props: Props) => {
  return (
    <Box textAlign="center">
      <Typography variant="h6" textAlign="center">
        予約が完了しました
      </Typography>
      <Box py={4}>
        <Typography variant="subtitle2">
          ご予約ありがとうございました。
          <br />
          予約完了メールをお送りしたのでご確認ください。
          <br />
          当日は気をつけてお越しください。
        </Typography>
      </Box>
      <Grid container xs={5} mx="auto" py={2}>
        <Stack spacing={7} width="100%">
          <Button onClick={props.onMypage} variant="contained">
            マイページに戻る
          </Button>
        </Stack>
      </Grid>
    </Box>
  );
};
