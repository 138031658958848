import {
  Autocomplete,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  ListSubheader,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import type { FC } from 'react';
import { useEffect, useState, useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as validationRules from '../../validationRules';
import InfoTable from './components/InfoTable';
import FieldSet from './components/FieldSet';
import type {
  AppointmentDetailFragment,
  AppointmentFormOptionsQuery,
  AppointmentStatus,
  CreateAppointmentInput,
} from 'graphql/generated/staff/graphql';
import { AppointmentType } from 'graphql/generated/staff/graphql';
import { formatInputDateTime } from 'helpers/date';
import { allAppointmentTypes, appointmentTypeLabel } from 'constants/appointmentTypes';
import { useUpdateAppointmentStatus } from 'hooks/staff/useUpdateAppointmentStatus';
import { filterOptions } from 'helpers/filterOptions';

type FormValues = CreateAppointmentInput;

interface Props {
  appointment?: AppointmentDetailFragment;
  optionData: AppointmentFormOptionsQuery;
  defaultValues?: Partial<FormValues>;
  onSubmit?: (values: FormValues) => void;
  onChangeDirty?: (dirty: boolean) => void;
}

export const Component: FC<Props> = ({
  appointment,
  optionData,
  onSubmit = () => {},
  onChangeDirty = () => {},
  defaultValues = {},
}) => {
  const [isGuest, setIsGuest] = useState(
    appointment?.guestUser !== null && !defaultValues.clinicUserId
  );
  const isNew = useMemo(() => !appointment, [appointment]);
  const { handleSubmit, control, watch, setValue, formState } = useForm<FormValues>({
    defaultValues: appointment
      ? {
          clinicNote: appointment.clinicNote,
          clinicPetId: appointment.clinicPet?.id ?? '',
          clinicServiceId: appointment.service?.clinicService?.id ?? '',
          history: appointment.history,
          isFirst: appointment.isFirst ?? false,
          onset: appointment.onset,
          resourceId: appointment.resource?.id,
          type: appointment.type || AppointmentType.Web,
          guestUser: appointment.guestUser && {
            name: appointment.guestUser.name ?? '',
            petName: appointment.guestUser.petName ?? '',
            phoneNumber: appointment.guestUser.phoneNumber ?? '',
            address: appointment.guestUser.address ?? '',
            animalId: appointment.guestUser.animal?.id ?? '',
            animalTypeId: appointment.guestUser.animalType?.id ?? '',
          },
          startAt: formatInputDateTime(appointment.startAt),
          endAt: formatInputDateTime(appointment.endAt),
        }
      : {
          ...defaultValues,
          isFirst: defaultValues.isFirst ?? false,
          startAt: formatInputDateTime(defaultValues.startAt || ''),
          endAt: formatInputDateTime(defaultValues.endAt || ''),
        },
    mode: 'onBlur',
  });

  useEffect(() => {
    onChangeDirty(formState.isDirty);
  }, [formState.isDirty, onChangeDirty]);

  const serviceOptions = useMemo(() => {
    const serviceCategories = optionData.viewer.clinic?.serviceCategories ?? [];
    const resourceId = watch('resourceId');
    const resourceServiceIds =
      optionData.resources.find((r) => r.id === resourceId)?.services.map((s) => s.id) ?? [];
    return serviceCategories
      .map((category) => {
        return {
          ...category,
          services:
            resourceServiceIds.length > 0
              ? category.services.filter((s) => resourceServiceIds.includes(s.id))
              : category.services,
        };
      })
      .filter((category) => category.services.length > 0)
      .flatMap((category) => [
        { ...category, __typename: 'ServiceCategory' as const },
        ...category.services.map((service) => ({ ...service, __typename: 'Service' as const })),
      ])
      .filter(
        (option, index, ar) =>
          ar.findIndex((s) => s.id === option.id && s.__typename === option.__typename) === index
      );
  }, [optionData.viewer.clinic?.serviceCategories, watch('resourceId'), optionData.resources]);

  const clinicUserId = watch('clinicUserId');
  const clinicUserOptions = useMemo(
    () => optionData.clinicUsers,
    [optionData.clinicUsers, clinicUserId]
  );

  const animalOptions = optionData.viewer.clinic?.animals ?? [];

  const findAnimal = useCallback(
    (id: string) => animalOptions.find((a) => a.id === id),
    [animalOptions]
  );

  const animalTypeOptions = useMemo(() => {
    const animal = findAnimal(watch('guestUser.animalId') || '');
    return animal?.types ?? [];
  }, [watch('guestUser.animalId')]);

  const resources = useMemo(() => {
    const clinicServiceId = watch('clinicServiceId');
    const serviceIds = serviceOptions
      .filter(
        (o) =>
          o.__typename === 'Service' && (!clinicServiceId || o.clinicService.id === clinicServiceId)
      )
      .map((o) => o.id);
    return (
      optionData.resources?.filter((r) => r.services.some((s) => serviceIds.includes(s.id))) ?? []
    );
  }, [optionData.resources, serviceOptions, watch('clinicServiceId')]);

  const findService = useCallback(
    (id: string) =>
      serviceOptions.find(
        (service) => service.__typename === 'Service' && service.clinicService.id === id
      ),
    [serviceOptions]
  );
  const findResource = useCallback(
    (id: string) => resources.find((resource) => resource.id === id),
    [resources]
  );
  const findAnimalType = useCallback(
    (id: string) => animalTypeOptions.find((a) => a.id === id),
    [animalTypeOptions]
  );

  const findClinicUser = useCallback(
    (id: string) => clinicUserOptions.find((clinicUser) => clinicUser.id === id),
    [clinicUserOptions]
  );

  const clinicPetOptions = useMemo(() => {
    if (!clinicUserId) return [];
    const found = findClinicUser(clinicUserId);
    return found?.clinicPets ?? [];
  }, [clinicUserOptions, clinicUserId, findClinicUser]);

  const [, updateStatus] = useUpdateAppointmentStatus();

  const handleChangeStatus = useCallback(async (status: AppointmentStatus) => {
    const id = appointment?.id;
    if (!id) return;
    await updateStatus(id, status);
  }, []);

  const beforeCallOnSubmit = useCallback(
    (values: FormValues) => {
      onSubmit({
        ...values,
        guestUser: isGuest ? values.guestUser : undefined,
        startAt: values.startAt && new Date(values.startAt).toISOString(),
        endAt: values.endAt && new Date(values.endAt).toISOString(),
      });
    },
    [onSubmit, isGuest]
  );

  const findClinicPet = useCallback(
    (id: string) => clinicPetOptions.find((clinicPet) => clinicPet.id === id),
    [clinicPetOptions]
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={appointment ? 7 : 12}>
        <Stack gap={4} component="form" onSubmit={handleSubmit(beforeCallOnSubmit)}>
          <FieldSet label="予約日時">
            <FormGroup
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                flexWrap: 'nowrap',
              }}
            >
              <Controller
                control={control}
                name="startAt"
                rules={{
                  required: '必須項目です',
                  validate: (value) => {
                    const startAt = new Date(value);
                    if (!watch('endAt')) {
                      return true;
                    }
                    const endAt = new Date(watch('endAt'));
                    if (endAt.getTime() - startAt.getTime() <= 0) {
                      return '開始日時は終了日時より前に設定してください';
                    }
                    return true;
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    id="input-start-at"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    label="開始日時"
                    type="datetime-local"
                    required
                    sx={{ flexGrow: 1 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...field}
                  />
                )}
              />
              <Typography variant="body2" sx={{ mx: 1, display: 'flex', alignItems: 'center' }}>
                〜
              </Typography>
              <Controller
                control={control}
                name="endAt"
                rules={{ required: '必須項目です' }}
                render={({ field, fieldState }) => (
                  <TextField
                    id="input-end-at"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    label="終了日時"
                    type="datetime-local"
                    required
                    sx={{ flexGrow: 1 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...field}
                  />
                )}
              />
            </FormGroup>
          </FieldSet>
          <FieldSet label="受付内容">
            <Stack gap={3}>
              <Controller
                control={control}
                name="isFirst"
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Switch defaultChecked={field.value} onChange={(e) => field.onChange(e)} />
                    }
                    label="初診"
                  />
                )}
              />
              <Controller
                control={control}
                name="type"
                render={({ field, fieldState }) => (
                  <FormControl error={!!fieldState.error}>
                    <FormLabel id="radio-type-label">種別</FormLabel>
                    <RadioGroup row aria-labelledby="radio-type-label" {...field}>
                      {allAppointmentTypes.map((type) => (
                        <FormControlLabel
                          value={type}
                          control={<Radio />}
                          label={appointmentTypeLabel(type)}
                          key={type}
                        />
                      ))}
                    </RadioGroup>
                    {fieldState.error?.message && (
                      <FormHelperText>{fieldState.error?.message}</FormHelperText>
                    )}
                  </FormControl>
                )}
              />
              <Controller
                control={control}
                name="clinicServiceId"
                render={({ field, fieldState }) => (
                  <FormControl fullWidth error={!!fieldState.error}>
                    <InputLabel id="select-service">サービス</InputLabel>
                    <Select
                      labelId="select-service"
                      label="サービス"
                      renderValue={(value) => (value && findService(value)?.name) || '未選択'}
                      {...field}
                    >
                      {serviceOptions.map((option) => {
                        if (option.__typename === 'ServiceCategory') {
                          return <ListSubheader key={option.id}>{option.name}</ListSubheader>;
                        }
                        if (option.__typename === 'Service') {
                          return (
                            <MenuItem key={option.id} value={option.clinicService.id}>
                              {option.name}
                            </MenuItem>
                          );
                        }
                        return null;
                      })}
                    </Select>
                    {fieldState.error?.message && (
                      <FormHelperText>{fieldState.error.message}</FormHelperText>
                    )}
                  </FormControl>
                )}
              />
              <Controller
                control={control}
                name="resourceId"
                rules={{ required: true }}
                render={({ field, fieldState }) => (
                  <FormControl fullWidth required error={!!fieldState.error}>
                    <InputLabel id="select-resource">担当</InputLabel>
                    <Select
                      labelId="select-resource"
                      label="担当"
                      renderValue={(value) => findResource(value)?.name || '担当を選択してください'}
                      {...field}
                    >
                      {resources.map((option) => {
                        return (
                          <MenuItem key={option.id} value={option.id}>
                            {option.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {fieldState.error?.message && (
                      <FormHelperText>{fieldState.error.message}</FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Stack>
          </FieldSet>
          {(isNew || isGuest) && (
            <FieldSet label="飼い主様情報">
              <Stack gap={3}>
                {isNew && (
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="radio-type-label"
                      value={isGuest ? 'guest' : 'user'}
                      onChange={(e) => setIsGuest(e.target.value === 'guest')}
                    >
                      <FormControlLabel value="guest" label="未登録" control={<Radio />} />
                      <FormControlLabel value="user" label="登録済" control={<Radio />} />
                    </RadioGroup>
                  </FormControl>
                )}
                {isGuest && (
                  <>
                    <Controller
                      control={control}
                      name="guestUser.name"
                      rules={{ required: '必須項目です' }}
                      render={({ field, fieldState }) => (
                        <TextField
                          id="input-guest-name"
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          label="名前"
                          required
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="guestUser.petName"
                      rules={{ required: '必須項目です' }}
                      render={({ field, fieldState }) => (
                        <TextField
                          id="input-guest-pet-name"
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          label="ペット名"
                          required
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="guestUser.animalId"
                      render={({ field, fieldState }) => (
                        <FormControl fullWidth error={!!fieldState.error}>
                          <InputLabel id="select-guest-animal">ペット動物</InputLabel>
                          <Select
                            labelId="select-guest-animal"
                            label="ペット動物"
                            renderValue={(value) =>
                              (value && findAnimal(value)?.name) || 'ペット動物を選択してください'
                            }
                            {...field}
                          >
                            {animalOptions.map((option) => {
                              return (
                                <MenuItem key={option.id} value={option.id}>
                                  {option.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          {fieldState.error?.message && (
                            <FormHelperText>{fieldState.error.message}</FormHelperText>
                          )}
                        </FormControl>
                      )}
                    />
                    <Controller
                      control={control}
                      name="guestUser.animalTypeId"
                      render={({ field, fieldState }) => (
                        <Autocomplete
                          {...field}
                          disablePortal
                          noOptionsText="該当する種類が見つかりません"
                          fullWidth
                          id="select-animal-type"
                          disabled={animalTypeOptions.length === 0}
                          options={animalTypeOptions.map((o) => o.id)}
                          filterOptions={(_options, state) => {
                            return filterOptions(animalTypeOptions, ['name'], state.inputValue).map(
                              (o) => o.id
                            );
                          }}
                          getOptionLabel={(id) => {
                            const found = findAnimalType(id);
                            if (!found) return '';
                            return found.name;
                          }}
                          renderInput={(params) => (
                            <TextField {...params} label="ペット種類" error={!!fieldState.error} />
                          )}
                          onChange={(_, value) => {
                            setValue('guestUser.animalTypeId', value);
                          }}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="guestUser.phoneNumber"
                      render={({ field, fieldState }) => (
                        <TextField
                          id="input-guest-phone-number"
                          type="tel"
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          label="電話番号"
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="guestUser.email"
                      rules={{ ...validationRules.email }}
                      render={({ field, fieldState }) => (
                        <TextField
                          type="email"
                          id="input-guest-email"
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          label="メールアドレス"
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="guestUser.address"
                      render={({ field, fieldState }) => (
                        <TextField
                          id="input-guest-address"
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          label="住所"
                          {...field}
                        />
                      )}
                    />
                  </>
                )}
                {!isGuest && (
                  <>
                    <Controller
                      control={control}
                      name="clinicUserId"
                      rules={{ required: true }}
                      render={({ field, fieldState }) => (
                        <Autocomplete
                          {...field}
                          disablePortal
                          noOptionsText="該当する飼い主様が見つかりません"
                          fullWidth
                          id="select-user"
                          options={clinicUserOptions.map((o) => o.id)}
                          getOptionLabel={(id) => {
                            const found = findClinicUser(id);
                            if (!found) return '';
                            return `${found.user?.name ?? '-'} ${
                              found.patientNumber ? `(${found.patientNumber})` : ''
                            }`;
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="飼い主様名前・診察券番号"
                              error={!!fieldState.error}
                            />
                          )}
                          onChange={(_, value) => {
                            setValue('clinicUserId', value);
                          }}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="clinicPetId"
                      rules={{ required: true }}
                      render={({ field, fieldState }) => (
                        <Autocomplete
                          {...field}
                          disablePortal
                          noOptionsText="該当するペットが見つかりません"
                          fullWidth
                          id="select-pet"
                          options={clinicPetOptions.map((o) => o.id)}
                          getOptionLabel={(id) => {
                            const found = findClinicPet(id);
                            if (!found) return '';
                            return `${found.pet?.name ?? '-'} (${[
                              found.pet?.animal?.name,
                              found.pet?.animalType?.name,
                            ]
                              .filter((n) => !!n)
                              .join(' - ')} ${
                              found.patientNumber ? `・${found.patientNumber}` : ''
                            })`;
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="ペット名前・種類・診察券番号"
                              error={!!fieldState.error}
                            />
                          )}
                          onChange={(_, value) => {
                            setValue('clinicPetId', value);
                          }}
                        />
                      )}
                    />
                  </>
                )}
              </Stack>
            </FieldSet>
          )}
          <Controller
            control={control}
            name="clinicNote"
            render={({ field, fieldState }) => (
              <TextField
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                label="メモ (飼い主様には表示されません)"
                fullWidth
                multiline
                rows={4}
                {...field}
              />
            )}
          />
          <Button type="submit" variant="contained" color="primary" size="large" fullWidth>
            保存
          </Button>
        </Stack>
      </Grid>
      {appointment && (
        <Grid item xs={12} md={5}>
          <InfoTable appointment={appointment} onChangeStatus={handleChangeStatus} />
        </Grid>
      )}
    </Grid>
  );
};
