import type { Identifier, XYCoord } from 'dnd-core';
import {
  Avatar,
  Box,
  IconButton,
  TableCell as MuiTableCell,
  TableRow,
  Tooltip,
} from '@mui/material';
import type { ComponentProps, FC } from 'react';
import { useRef } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import PunchClockIcon from '@mui/icons-material/PunchClock';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { useDrop, useDrag } from 'react-dnd';
import type { ResourcesQuery } from 'graphql/generated/staff/graphql';
import * as paths from 'constants/paths/staff';
import ServiceChips from 'components/ServiceChips';

type Resource = ResourcesQuery['resources'][number];
type ResourceWithIndex = Resource & { index: number };

interface CollectedProps {
  handlerId: Identifier | null;
  isDragging?: boolean;
}

interface Props {
  resource: Resource;
  index: number;
  onMove: (dragIndex: number, hoverIndex: number) => void;
  onDrop: () => void;
}

const Row: FC<Props> = ({ resource, index, onMove, onDrop }) => {
  const ref = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const navigateToDetail = () => {
    navigate(paths.resourceDetailPath(resource.id));
  };

  // based on https://github.com/react-dnd/react-dnd/blob/gh-pages/examples_ts/04-sortable/simple/src/Card.tsx
  const [{ handlerId }, drop] = useDrop<ResourceWithIndex, void, CollectedProps>({
    accept: 'resource',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop: () => {
      onDrop();
    },
    hover(item: ResourceWithIndex, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      onMove(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag<ResourceWithIndex, void, CollectedProps>({
    type: 'resource',
    item: () => {
      return { ...resource, index };
    },
    collect: (monitor) => ({
      handlerId: monitor.getHandlerId(),
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <TableRow
      hover
      component="div"
      ref={ref}
      data-handler-id={handlerId}
      sx={{
        textDecoration: 'none',
        cursor: 'pointer',
        opacity: isDragging ? 0 : 1,
        '.drag-handle': { cursor: 'grab', visibility: 'hidden', color: '#ccc' },
        '&:hover': {
          '.drag-handle': { visibility: 'visible' },
        },
      }}
    >
      <TableCell sx={{ px: 0, width: 10 }}>
        <div className="drag-handle">
          <DragIndicatorIcon />
        </div>
      </TableCell>
      <TableCell sx={{ pl: 0 }} onClick={navigateToDetail}>
        <Box display="flex" alignItems="center">
          <Avatar src={resource.profileImageURL || undefined} />
          <Box ml={1} sx={{ whiteSpace: 'nowrap' }}>
            {resource.name}
          </Box>
        </Box>
      </TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }} onClick={navigateToDetail}>
        {resource.kind.name}
      </TableCell>
      <TableCell onClick={navigateToDetail}>
        <ServiceChips services={resource.services} />
      </TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        <Tooltip title="編集">
          <IconButton component={RouterLink} to={paths.resourceEditPath(resource.id)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="スケジュール管理">
          <IconButton component={RouterLink} to={paths.resourceWorkingHoursPath(resource.id)}>
            <PunchClockIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

export default Row;

const TableCell: FC<ComponentProps<typeof MuiTableCell>> = (props) => (
  <MuiTableCell {...props} component="div" />
);
