import { blue, green, grey } from '@mui/material/colors';
import { AppointmentStatus } from 'graphql/generated/staff/graphql';

interface StatusProps {
  label: string;
  color: string;
  value: AppointmentStatus;
}

const appointmentStatuses: StatusProps[] = [
  {
    label: '予約確定',
    value: AppointmentStatus.Booked,
    color: blue[400],
  },
  {
    label: '来院待ち',
    value: AppointmentStatus.Waiting,
    color: green[400],
  },
  {
    label: 'キャンセル',
    value: AppointmentStatus.Canceled,
    color: grey[400],
  },
  {
    label: '来院済み',
    value: AppointmentStatus.Visited,
    color: '#F9C941',
  },
];

export const allAppointmentStatuses = appointmentStatuses.map((item) => item.value);

const find = (status: AppointmentStatus) =>
  appointmentStatuses.find((item) => item.value === status);

export const appointmentStatusColor = (status: AppointmentStatus) => {
  return find(status)?.color;
};

export const appointmentStatusLabel = (status: AppointmentStatus) => {
  return find(status)?.label;
};
